/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "@mui/material";
import moment from "moment";
import { toWords } from "number-to-words";
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { useForm } from "react-hook-form";
import { FaCalendarAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { useData } from "../../layouts/shared/DataProvider";
import { fontFamilyGet } from "../../redux/actions/FontFamilyAction";
import { InternalGetCount } from "../../redux/actions/InternalInvoiceAction";
import { currencyGet } from "../../redux/actions/currencyAction";
import { salesFixedAssetFindOne, SalesFixedAssetSave, salesFixedAssetUpdate } from "../../redux/actions/salesFixedAssetAction";
import { registerAll } from "../../redux/actions/userAction";
import api from "../../redux/axiosConfig";
import ClientPlusIcon from "../Icons/ClientPlusIcon";
import ConfirmationDownload from "../commen/ConfirmationDownload";
import NumberFormat from "../commen/NumberFormat";
import PaymentUpdateModel from "../commen/PaymentUpdateModel";
import { defaultInputSmStyle } from "../commen/defaultStyles";
import ClientModel from "../models/ClientModel";
import SendEmailModel from "../models/SendEmailModel";
import "./../../layouts/App/App.css";
import GstSection from "./InvoiceDetails/GstSection";
import PreviewSalesFABillWise from "./PreviewSalesFABillWise";
import PreviewSalesFAProductWise from "./PreviewSalesFAProductWise";
import PrintSalesFABillWise from "./PrintSalesFABillWise";
import PrintSalesFAProductWise from "./PrintSalesFAProductWise";
import SalesFABillData from "./SalesFABillData";
import SalesFAProductData from "./SalesFAProductData";

const SalesFixedAssetPage = () => {
  // const ref = useRef(null);
  const [isToggled, setIsToggled] = useState(true);


  const ref = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch<any>();
  const params = useParams();
  const { id } = params;
  const usersignin = useSelector((state: any) => state?.userSignin);
  const token = usersignin?.userInfo?.token;
  const template = useSelector((state: any) => state.userColorTemplate);
  const { userdata } = template;

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const { selectedFont, setSelectedFont }: any = useData();
  const [selectedPayment, setSelectedPayment] = useState("");

  // ******************************Invoice Number Autogenarate section*****************************************

  // ****************************Date Picker and Date Section******************************************

  const [invoiceUpdateId, setInvoiceUpdateId] = useState(null as any);

  const [salesFADate, setsalesFADate] = useState(new Date() as any);

  const [billModules, setbillModule] = useState(null as any);
  const [balanceAmount, setbalanceAmount] = useState(0 as any);
  const [receivedAmount, setreceivedAmount] = useState(0 as any);

  const [chequeNum, setchequeNum] = useState("");
  const formatDate = moment(new Date()).format("DD/MM/YYYY");

  const CustomInputWithIcon = forwardRef(
    ({ value, onClick }: any, ref: any) => (
      <div className="relative">
        <input
          type="text"
          value={value}
          readOnly
          className={
            "border-2 focus:outline-none rounded-[7px] px-2 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12 "
          }
          style={{
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: 400,
            borderColor: "#008065",
          }}
          ref={ref}
        />
        <FaCalendarAlt
          className="absolute right-0 top-0 mt-2 mr-2   cursor-pointer lg:right-2  lg:mr-0  xl:right-12  xl:mr-9   2xl:right-8  2xl:mr-4"
          onClick={onClick}
        />
      </div>
    )
  );

  const [CashAmount, setCashAmount] = useState(0 as any);

  const [creditAmount, setcreditAmount] = useState(0 as any);
  const [chequeAmount, setchequeAmount] = useState(0 as any);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [formData, setFormData] = useState({
    errorMessage: "",
    errors: {},
    invoicehead: "SALES FIXED ASSETS",
    invoiceNo: "",
    salesFADate: formatDate,
    clientName: "",
    clientAddress: "",
    clientCity: "",
    clientState: "",
    clientPostalCode: "",
    clientMobile: "",
    clientEmail: "",
    clientPan: "",
    clientgst: "",
    clientId: "",
    subtotal: 0,
    toatalAmount: 0,
    asset: [],
    tax: [],
    user_id: token?._id,
    To: "",
    totalInWords: "",
    billTaxType: userdata?.taxType,
    invoiceTemplateType: "",
    colorTemplateType: "",
    billModule: "",
    paymentType: selectedPayment,
    cashAmount: "",
    creditAmount: creditAmount,
    chequeAmount: chequeAmount,
    balanceAmount: balanceAmount,
    chequeNum: chequeNum,
    receivedAmount: receivedAmount,
    paymentStatus: "",
    taxpersentage: ""
  } as any);



  useEffect(() => {
    dispatch(fontFamilyGet(token?._id) as any).then((res: any) => {
      if (res?.type === "FONTFAMILY_GET_SUCCESS") {
        let data = res?.payload?.find(
          (value: any) => value?.defaultInfo === true
        );

        if (data) {
          setSelectedFont(data?.fontfamily);
        } else {
          setSelectedFont("poppins");
        }
      }
    });
    dispatch(registerAll(userId?.token?._id) as any).then((res: any) => {
      if (res && res.type === "REGISTER_GET_SUCCESS") {
        setbillModule(
          res?.payload?.billModule ? res?.payload?.billModule : "PRODUCT"
        );
        setFormData((prev: any) => ({
          ...prev,
          billTaxType: res?.payload?.taxType
            ? res?.payload?.taxType
            : "Bill Wise",
          // billModule: res?.payload?.billModule ? res?.payload?.billModule : "PRODUCT"
        }));
      }
    });
  }, []);

  const [currencySymbol, setcurrencySymbol] = useState("₹");

  useEffect(() => {
    dispatch(currencyGet(token?._id) as any).then((res: any) => {
      if (res) {
        let data = res.payload.find((value: any) => value.defaultInfo === true);
        if (data?.currencySymbol) {
          setcurrencySymbol(data.currencySymbol);
        } else {
          setcurrencySymbol("₹");
        }
      }
    });
  }, []);

  useEffect(() => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      balanceAmount: balanceAmount ? balanceAmount : 0,
      receivedAmount: receivedAmount ? Number(receivedAmount) : 0,
      cashAmount: CashAmount ? CashAmount : "",
      creditAmount: creditAmount ? creditAmount : "",
      chequeAmount: chequeAmount ? chequeAmount : "",
      paymentType: selectedPayment ? selectedPayment : "",
    }));
  }, [balanceAmount, receivedAmount, CashAmount, chequeAmount]);

  // ****************************************Image section***************************************************

  const [clientErrors, setClientErrors] = useState<{
    clientName?: string;
    paymentType?: string;
    chequeNum?: string;
    balanceAmount?: string;
  }>({});

  const [sendEmailErrors, setSendEmailErrors] = useState<{ To?: string }>({});

  const validateProdForm = () => {
    if (formData.clientName?.trim() === "") {
      clientErrors["clientName"] = "Client Name is Requried  ";
    }
    if (formData.paymentType?.trim() === "") {
      clientErrors["paymentType"] = "Payment Type is Requried  ";
    }
    if (formData.chequeNum?.trim() === "") {
      clientErrors["chequeNum"] = "Cheque Number is Requried  ";
    }

    if (id) {
      if (parseFloat(formData.toatalAmount) < formData.receivedAmount) {
        clientErrors["balanceAmount"] = "Balance Amount Should not Exceeds";
      }
    }
  };

  // const [bankErrors, setBankErrors] = useState<{ companyName?: string }>({},);

  interface FieldErrors {
    cashAmount?: string;
    creditAmount?: string;
    chequeAmount?: string;
  }

  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newValue = value.trimStart();
    const numericValue = Number(newValue);
    const fieldName = name as keyof FieldErrors;
    let error = "";
    if (
      (fieldName === "cashAmount" ||
        fieldName === "creditAmount" ||
        fieldName === "chequeAmount") &&
      value
    ) {
      if (formData?.toatalAmount < numericValue) {
        error = "Invalid Amount";
      } else if (numericValue <= 0) {
        error = " Amount must be Greater than 0";
      }
    }
    if (fieldName === "cashAmount") {
      if (!newValue) {
        error = "Cash amount cannot be empty";
      } else if (numericValue > formData?.toatalAmount) {
        error = "Invalid Amount";
      } else if (numericValue <= 0) {
        error = "Amount must be greater than 0";
      }
    }
    if (fieldName === "chequeAmount") {
      if (!newValue) {
        error = "Cash amount cannot be empty";
      } else if (numericValue > formData?.toatalAmount) {
        error = "Invalid Amount";
      } else if (numericValue <= 0) {
        error = "Amount must be greater than 0";
      }
    }
    setFieldErrors((prevErrors: any) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
    if (name == "paymentType") {
      setSelectedPayment(e.target.value);
      setbalanceAmount(0);
      setchequeNum("");
      fieldErrors.cashAmount = "";
      fieldErrors.creditAmount = "";
      fieldErrors.chequeAmount = "";
      clientErrors["chequeNum"] = "";
      formData.chequeNum = "";
      if (value == "CASH") {
        setCashAmount(formData.toatalAmount);
        setreceivedAmount(formData.toatalAmount);
        setcreditAmount("");
        setchequeAmount("");
        clientErrors["balanceAmount"] = "";
        // if (formData.product.length === 0) {
        //   setShowConfirmation(true)
        // }
        for (let i = 0; i < assetRows.length; i++) {
          const { asset_name, qty, rate } = assetRows[i];
          if (!asset_name || !qty || !rate) {
            setShowConfirmation(true);
          }
        }
      }
      if (value == "CREDIT") {
        setcreditAmount(formData.toatalAmount);
        setbalanceAmount(formData.toatalAmount);
        setreceivedAmount(formData.toatalAmount);
        setchequeAmount("");
        setCashAmount("");
        for (let i = 0; i < assetRows.length; i++) {
          const { asset_name, qty, rate } = assetRows[i];
          if (!asset_name || !qty || !rate) {
            setShowConfirmation(true);
          }
        }
      }
      if (value == "CHEQUE") {
        setchequeAmount(formData.toatalAmount);
        setreceivedAmount(formData.toatalAmount);
        setCashAmount("");
        setcreditAmount("");
        for (let i = 0; i < assetRows.length; i++) {
          const { asset_name, qty, rate } = assetRows[i];
          if (!asset_name || !qty || !rate) {
            setShowConfirmation(true);
          }
        }
      }
    }
    if (name == "cashAmount") {
      let cashvalue = e.target.value;
      let balance = formData.toatalAmount - Number(cashvalue);
      setbalanceAmount(parseFloat(balance.toFixed(2)));
      setCashAmount(Number(cashvalue));
      setreceivedAmount(cashvalue);
    }
    if (name == "creditAmount") {
      let creditvalue = e.target.value;
      let balance = Number(creditvalue);
      setbalanceAmount(parseFloat(balance.toFixed(2)));
      setcreditAmount(balance);
    }
    if (name == "chequeAmount") {
      let chequevalue = e.target.value;
      let balance = formData.toatalAmount - Number(chequevalue);
      setbalanceAmount(parseFloat(balance.toFixed(2)));
      setchequeAmount(Number(chequevalue));
      setreceivedAmount(chequevalue);
    }
    setClientErrors({ ...clientErrors, [e.target.name]: "" });

    if (name == "chequeNum") {
      let checkNumber = e.target.value;
      const isValid = /^[0-9]{6}$/.test(checkNumber);

      setchequeNum(checkNumber);
      if (!isValid) {
        // Example validation condition
        setClientErrors((prevErrors) => ({
          ...prevErrors,
          chequeNum: "Cheque number must be at least 5 characters",
        }));
      } else {
        setClientErrors((prevErrors) => ({
          ...prevErrors,
          chequeNum: "",
        }));
      }
    }

    setErrorEmail(null);
    setSendEmailErrors({ ...sendEmailErrors, [e.target.name]: "" });
    let errorMessage = "";
    let isSendEmail = true;
    let isvendorMobile = true;
    if (e.target.name === "To" && value) {
      isSendEmail = emailRegex.test(value);
      errorMessage = isSendEmail ? "" : "Invalid Email Address";
    }

    if (e.target.name === "vendorMobile" && value) {
      isvendorMobile = value.length === 10;
      errorMessage = isvendorMobile ? "" : "Invalid  Mobile Number";
    }

    setFormData({
      ...formData,
      [e.target.name]: newValue,
      errors: {
        ...formData.errors,
        [`${e.target.name}ErrorMessage`]: errorMessage,
      },
      isvendorMobile:
        e.target.name === "vendorMobile"
          ? isvendorMobile
          : formData.isvendorMobile,
      isSendEmail: e.target.name === "To" ? isSendEmail : formData.isSendEmail,
    });
  };

  // ***********************************Start***********************************

  const [TaxAllList, setTaxAllList] = useState([] as any);
  const fetchData = () => {
    dispatch(InternalGetCount() as any).then((data: any) => {
      if (data) {
        setTaxAllList(JSON.parse(data?.payload?.appsettings));
        let newCountValue = data?.payload?.salesFACount;
        let match = newCountValue?.match(/\d{4}$/);
        let result = "";
        if (match) {
          let value = match[0];
          result = (parseInt(value) + 1).toString().padStart(4, "0");
        }
        let datevalue = moment(new Date()).format("YYYY-MM-DD");
        const [year, month] = datevalue.split("-");
        let invoice =
          `${"SFA-"}` +
          String(`${month}/`) +
          String(`${year}/`) +
          String(result);

        setFormData((formData: any) => ({
          ...formData,
          invoiceNo: invoice,
          // paymentOutReceiptNo: data?.payload?.paymentOutReceiptCount,
        }));
      }
    });
  };

  useEffect(() => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      salesFADate: formData?.salesFADate
        ? formData?.salesFADate
        : formatDate,
    }));
  }, [salesFADate]);

  // ***********************************End***********************************

  // ***********************************Submit Section***********************************

  const [confirmDownload, setConfirmDownload] = useState(false);
  const [AssetError, setAssetError] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [assetRows, setAssetRows] = useState<any>([
    {
      asset_name: "",
      rate: "",
      qty: "",
      discount: "",
      taxpersentage: "",
      amount: 0,
      asset_id: "",
      transactionType: ""
    },
  ] as any);


  // taxtype:"",
  // taxamount:"",
  const [assetErrors, setassetErrors] = useState<any>([
    { asset_name: false, units: false, rate: false, qty: false },
  ] as any);


  const updateAssetErrors = (index: any, fieldName: any, value: any) => {
    const newErrors = [...assetErrors];
    newErrors[index][fieldName] = value ? true : false;
    setassetErrors(newErrors);
  };

  const addRow = () => {
    setAssetRows([
      ...assetRows,
      { asset_name: "", discount: "", rate: "", qty: "", amount: 0, taxpersentage: "" },
    ]);

    setassetErrors([
      ...assetErrors,
      {
        prodcut: AssetError,
        // hsn: AssetError,
        rate: AssetError,
        qty: AssetError,
      },
    ]);
  };



  const getAssetCal = (values: any) => {
    let products = [...values.asset];
    let finalProduct = products?.map((item: any) => {
      item.prodcgst = TaxAllList[0]?.cgstPercentage || 0;
      item.prodsgst = TaxAllList[0]?.sgstPercentage || 0;
      item.prodigst = TaxAllList[0]?.igstPercentage || 0;
      item.prodvat = TaxAllList[0]?.vatPercentage || 0;
      item.prodothers = TaxAllList[0]?.othersPercentage || 0;
      item.totalgst =
        parseFloat(TaxAllList[0]?.cgstPercentage || 0) +
        parseFloat(TaxAllList[0]?.sgstPercentage || 0) +
        parseFloat(TaxAllList[0]?.igstPercentage || 0) +
        parseFloat(TaxAllList[0]?.vatPercentage || 0) +
        parseFloat(TaxAllList[0]?.othersPercentage || 0);
      item.grandTotal =
        ((parseFloat(item.totalgst) / 100) * parseFloat(item.rate) +
          parseFloat(item.rate)) *
        parseFloat(item.qty);
      item.total =
        (parseFloat(item.totalgst) / 100) * parseFloat(item.rate) +
        parseFloat(item.rate);
      item.creation_date = new Date();
      return item;
    });
    return finalProduct;
  };

  const fetchDownload = (data: any) => {

  };

  const downloadInvoice = () => {
    fetchDownload(formData);
  };

  const [invoiceUpdateNewId, setInvoiceUpdateNewId] = useState(null as any);
  const saveAndDownloadInvoice = () => {
    if (!id && !invoiceUpdateId) {
      if (userdata.AmountFormat === "International Format") {
        const toWords = (num: any) => {
          const numberToWords = require('number-to-words');
          return numberToWords.toWords(num);
        };

        const convertAmount = (amount: any) => {
          const roundedAmount = Math.round(amount);
          const amountInWords = Number.isFinite(roundedAmount) ? toWords(roundedAmount) : "Invalid amount";
          const internationalAmountInWords = `${amountInWords} Only. `;

          return {
            internationalAmountInWords
          };
        };

        const totalAmount = formData.toatalAmount;
        const result = convertAmount(totalAmount);
        formData.totalInWords = result.internationalAmountInWords;
      } else {
        const totalAmount = formData.toatalAmount;
        const roundedtotalAmount = Math.round(totalAmount);
        const totalAmountInWords = Number.isFinite(roundedtotalAmount)
          ? toWords(roundedtotalAmount)
          : "Invalid amount";
        const totalAmountInWordsPrint =
          "Rupees " + totalAmountInWords + " Only";
        formData.totalInWords = totalAmountInWordsPrint;
      }
      formData.salesFADate = formatDate;
      formData.gstValue = userdata?.gstValue;
      if (formData.billTaxType == "Bill Wise") {
        formData.product = getAssetCal(formData);
      }
      formData.billTaxType = userdata?.taxType;
      if (billModules != "SERVICE")
        formData.invoiceTemplateType = userdata?.invoiceType;
      formData.colorTemplateType = userdata?.template;

      if (invoiceUpdateNewId) {
        dispatch(salesFixedAssetUpdate(invoiceUpdateNewId, formData) as any).then(
          (response: any) => {
            const queryParams = new URLSearchParams();
            if (formData.colorTemplateType) {
              queryParams.append("param5", formData.colorTemplateType);
            }
            queryParams.append("param6", selectedFont);
            queryParams.append("param7", currencySymbol);
            if (formData.invoiceTemplateType) {
              queryParams.append("param8", formData.invoiceTemplateType);
            }



            // Construct the URL with the query parameters

            const url = `/api/salesFA_pdf/${invoiceUpdateNewId}?${queryParams.toString()}`;
            fetch(url, {
              method: "GET",
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Network response was not ok");
                }
                return response.blob();
              })
              .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link: any = document.createElement("a");
                link.href = url;
                const formattedDate = moment().format("YYYY-MM-DD");
                link.setAttribute(
                  "download",
                  `invoice_${formData.clientName}_${formattedDate}.pdf`
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setAssetError(false);
                setConfirmDownload(false);
              })
              .catch((error) => console.error("Error downloading PDF:", error));
          }
        );
      } else {
        if (userdata.AmountFormat === "International Format") {
          const toWords = (num: any) => {
            const numberToWords = require('number-to-words');
            return numberToWords.toWords(num);
          };

          const convertAmount = (amount: any) => {
            const roundedAmount = Math.round(amount);
            const amountInWords = Number.isFinite(roundedAmount) ? toWords(roundedAmount) : "Invalid amount";
            const internationalAmountInWords = `${amountInWords} Only. `;

            return {
              internationalAmountInWords
            };
          };

          const totalAmount = formData.toatalAmount;
          const result = convertAmount(totalAmount);
          formData.totalInWords = result.internationalAmountInWords;
        } else {
          const totalAmount = formData.toatalAmount;
          const roundedtotalAmount = Math.round(totalAmount);
          const totalAmountInWords = Number.isFinite(roundedtotalAmount)
            ? toWords(roundedtotalAmount)
            : "Invalid amount";
          const totalAmountInWordsPrint =
            "Rupees " + totalAmountInWords + " Only";
          formData.totalInWords = totalAmountInWordsPrint;
        }
        dispatch(SalesFixedAssetSave(formData) as any).then((response: any) => {
          if (userdata.AmountFormat === "International Format") {
            const toWords = (num: any) => {
              const numberToWords = require('number-to-words');
              return numberToWords.toWords(num);
            };

            const convertAmount = (amount: any) => {
              const roundedAmount = Math.round(amount);
              const amountInWords = Number.isFinite(roundedAmount) ? toWords(roundedAmount) : "Invalid amount";
              const internationalAmountInWords = `${amountInWords} Only. `;

              return {
                internationalAmountInWords
              };
            };

            const totalAmount = formData.toatalAmount;
            const result = convertAmount(totalAmount);
            formData.totalInWords = result.internationalAmountInWords;
          } else {
            const totalAmount = formData.toatalAmount;
            const roundedtotalAmount = Math.round(totalAmount);
            const totalAmountInWords = Number.isFinite(roundedtotalAmount)
              ? toWords(roundedtotalAmount)
              : "Invalid amount";
            const totalAmountInWordsPrint =
              "Rupees " + totalAmountInWords + " Only";
            formData.totalInWords = totalAmountInWordsPrint;
          }
          setInvoiceUpdateNewId(response?.payload?.purchaseFA_id);
          formData?.asset.forEach((item: any) => {
            item.amount = Number(item.amount).toFixed(2);
            item.rate = Number(item.rate).toFixed(2);
          });
          if (response?.payload?.purchaseFA_id) {

            const queryParams = new URLSearchParams();

            if (userdata?.template) {
              queryParams.append("param5", userdata?.template);
            }
            queryParams.append("param6", selectedFont);
            queryParams.append("param7", currencySymbol);
            if (userdata?.invoiceType) {
              queryParams.append("param8", userdata?.invoiceType);
            }

            queryParams.append("param9", formData.totalInWords);
            // Construct the URL with the query parameters
            const url = `/api/salesFA_pdf/${response?.payload?.purchaseFA_id
              }?${queryParams.toString()}`;
            fetch(url, {
              method: "GET",
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Network response was not ok");
                }
                return response.blob();
              })
              .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link: any = document.createElement("a");
                link.href = url;
                const formattedDate = moment().format("YYYY-MM-DD");
                link.setAttribute(
                  "download",
                  `invoice_${formData.clientName}_${formattedDate}.pdf`
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setAssetError(false);
                setInvoiceUpdateId(response?.payload?.invoice_id);
                setConfirmDownload(false);
              })
              .catch((error) => console.error("Error downloading PDF:", error));
          }
        });
      }
    } else if (id || invoiceUpdateId) {
      let invoiceId = id ? id : invoiceUpdateId;
      formData.salesFADate = formatDate;
      formData.product = getAssetCal(formData);
      formData.gstValue = userdata?.gstValue;
      formData?.product.forEach((item: any) => {
        item.amount = Number(item.amount).toFixed(2);
        item.rate = Number(item.rate).toFixed(2);
      });
      if (userdata.AmountFormat === "International Format") {
        const toWords = (num: any) => {
          const numberToWords = require('number-to-words');
          return numberToWords.toWords(num);
        };

        const convertAmount = (amount: any) => {
          const roundedAmount = Math.round(amount);
          const amountInWords = Number.isFinite(roundedAmount) ? toWords(roundedAmount) : "Invalid amount";
          const internationalAmountInWords = `${amountInWords} Only. `;

          return {
            internationalAmountInWords
          };
        };

        const totalAmount = formData.toatalAmount;
        const result = convertAmount(totalAmount);
        formData.totalInWords = result.internationalAmountInWords;
      } else {
        const totalAmount = formData.toatalAmount;
        const roundedtotalAmount = Math.round(totalAmount);
        const totalAmountInWords = Number.isFinite(roundedtotalAmount)
          ? toWords(roundedtotalAmount)
          : "Invalid amount";
        const totalAmountInWordsPrint =
          "Rupees " + totalAmountInWords + " Only";
        formData.totalInWords = totalAmountInWordsPrint;
      }
      dispatch(salesFixedAssetUpdate(invoiceId, formData) as any).then(
        (response: any) => {
          const queryParams = new URLSearchParams();

          if (formData.colorTemplateType) {
            queryParams.append("param5", formData.colorTemplateType);
          }
          queryParams.append("param6", selectedFont);
          queryParams.append("param7", currencySymbol);
          if (formData.invoiceTemplateType) {
            queryParams.append("param8", formData.invoiceTemplateType);
          }
          queryParams.append("param9", formData.totalInWords);

          // Construct the URL with the query parameters

          const url = `/api/salesFA_pdf/${invoiceId}?${queryParams.toString()}`;
          fetch(url, {
            method: "GET",
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.blob();
            })
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link: any = document.createElement("a");
              link.href = url;
              const formattedDate = moment().format("YYYY-MM-DD");
              link.setAttribute(
                "download",
                `invoice_${formData.vendorName}_${formattedDate}.pdf`
              );
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              setAssetError(false);
              setConfirmDownload(false);
            })
            .catch((error) => console.error("Error downloading PDF:", error));
        }
      );
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let finalErrors = [...assetErrors];
    var ProductValidation = assetRows?.some(
      (item: any) => !item.asset_name || !item.rate || !item.qty
    );

    assetRows.forEach((item: any, index: any) => {
      finalErrors[index].asset_name = item.asset_name ? true : false;
      finalErrors[index].rate = item.rate ? true : false;
      finalErrors[index].qty = item.qty ? true : false;
    });
    setassetErrors(finalErrors);

    if (
      !ProductValidation &&
      formData.clientName &&
      formData.paymentType &&
      (selectedPayment != "CHEQUE" ||
        (selectedPayment === "CHEQUE" && formData.chequeNum)) &&
      !fieldErrors.cashAmount &&
      !fieldErrors.creditAmount &&
      !fieldErrors.chequeAmount &&
      !formData.errors?.clientMobileErrorMessage &&
      (id
        ? parseFloat(formData.subtotal) >= formData.receivedAmount
        : formData.subtotal) &&
      !clientErrors["chequeNum"]
    ) {
      setConfirmDownload(true);
    } else {
      setAssetError(true);

      validateProdForm();
      setConfirmDownload(false);
    }
  };

  // *********************************************EMail Sctions Start**************************************

  const [openmail, setOpenMail] = useState(false as any);
  const handleSectionOpen = (e: any) => {
    let finalErrors = [...assetErrors];
    var ProductValidation = assetRows?.some(
      (item: any) => !item.asset_name || !item.rate || !item.qty
    );

    assetRows.forEach((item: any, index: any) => {
      finalErrors[index].asset_name = item.asset_name ? true : false;
      finalErrors[index].rate = item.rate ? true : false;
      finalErrors[index].qty = item.qty ? true : false;
    });
    setassetErrors(finalErrors);

    if (
      !ProductValidation &&
      formData.clientName &&
      formData.paymentType &&
      (selectedPayment != "CHEQUE" ||
        (selectedPayment === "CHEQUE" && formData.chequeNum)) &&
      !fieldErrors.cashAmount &&
      !fieldErrors.creditAmount &&
      !fieldErrors.chequeAmount &&
      !formData.errors?.clientMobileErrorMessage &&
      (id
        ? parseFloat(formData.subtotal) >= formData.receivedAmount
        : formData.subtotal) &&
      !clientErrors["chequeNum"]
    ) {
      setOpenMail(true);
    } else {
      setAssetError(true);

      validateProdForm();
    }
  };
  const [errorEmail, setErrorEmail] = useState(null as any);
  const handleSendEmail = () => {
    if (formData.To?.trim() !== "" && emailRegex.test(formData.To)) {
      formData.salesFADate = formatDate;
      formData.gstValue = userdata?.gstValue;
      const emailViewPdf = {
        fromEmail: formData.From,
        toEmail: formData.To,
        subject: formData.Subject,
        message: formData.Message,
        clientName: formData.clientName,
      };
      if (!id) {
        if (userdata.AmountFormat === "International Format") {
          const toWords = (num: any) => {
            const numberToWords = require('number-to-words');
            return numberToWords.toWords(num);
          };

          const convertAmount = (amount: any) => {
            const roundedAmount = Math.round(amount);
            const amountInWords = Number.isFinite(roundedAmount) ? toWords(roundedAmount) : "Invalid amount";
            const internationalAmountInWords = `${amountInWords} Only. `;

            return {
              internationalAmountInWords
            };
          };

          const totalAmount = formData.toatalAmount;
          const result = convertAmount(totalAmount);
          formData.totalInWords = result.internationalAmountInWords;
        } else {
          const totalAmount = formData.toatalAmount;
          const roundedtotalAmount = Math.round(totalAmount);
          const totalAmountInWords = Number.isFinite(roundedtotalAmount)
            ? toWords(roundedtotalAmount)
            : "Invalid amount";
          const totalAmountInWordsPrint =
            "Rupees " + totalAmountInWords + " Only";
          formData.totalInWords = totalAmountInWordsPrint;
        }
        if (invoiceUpdateNewId) {
          dispatch(salesFixedAssetUpdate(invoiceUpdateNewId, formData) as any).then(
            (response: any) => {
              const queryParams = new URLSearchParams();

              queryParams.append("param5", formData.colorTemplateType);
              queryParams.append("param6", selectedFont);
              queryParams.append("param7", currencySymbol);
              if (formData.invoiceTemplateType) {
                queryParams.append("param8", formData.invoiceTemplateType);
              }

              queryParams.append("param9", formData.totalInWords);
              fetch(
                `/api/salesFixedAssetSendemail_pdf/${invoiceUpdateNewId}?${queryParams.toString()}`
              )
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("Network response was not ok");
                  }
                  return response.blob();
                })
                .then((blob) => {
                  api
                    .post(`/api/salesFA_upload`, emailViewPdf)
                    .then((pdfResp: any) => {
                      if (pdfResp.data.message) {
                        toast.success("Email Send Successfully", {
                          position: toast.POSITION.BOTTOM_CENTER,
                          autoClose: 800,
                        });
                      }
                    })
                    .catch((error: any) => {
                      console.error("Error uploading blob:", error);
                    });
                })
                .catch((error) =>
                  console.error("Error downloading PDF:", error)
                );
            }
          );
        } else {
          if (userdata.AmountFormat === "International Format") {
            const toWords = (num: any) => {
              const numberToWords = require('number-to-words');
              return numberToWords.toWords(num);
            };
  
            const convertAmount = (amount: any) => {
              const roundedAmount = Math.round(amount);
              const amountInWords = Number.isFinite(roundedAmount) ? toWords(roundedAmount) : "Invalid amount";
              const internationalAmountInWords = `${amountInWords} Only. `;
  
              return {
                internationalAmountInWords
              };
            };
  
            const totalAmount = formData.toatalAmount;
            const result = convertAmount(totalAmount);
            formData.totalInWords = result.internationalAmountInWords;
          } else {
            const totalAmount = formData.toatalAmount;
            const roundedtotalAmount = Math.round(totalAmount);
            const totalAmountInWords = Number.isFinite(roundedtotalAmount)
              ? toWords(roundedtotalAmount)
              : "Invalid amount";
            const totalAmountInWordsPrint =
              "Rupees " + totalAmountInWords + " Only";
            formData.totalInWords = totalAmountInWordsPrint;
          }
          dispatch(SalesFixedAssetSave(formData) as any).then((response: any) => {
            setInvoiceUpdateNewId(response?.payload?.purchaseFA_id);
            formData?.asset.forEach((item: any) => {
              item.amount = Number(item.amount).toFixed(2);
            });
            const queryParams = new URLSearchParams();

            if (userdata?.template) {
              queryParams.append("param5", userdata?.template);
            }
            queryParams.append("param6", selectedFont);
            queryParams.append("param7", currencySymbol);
            if (userdata?.invoiceType) {
              queryParams.append("param8", userdata?.invoiceType);
            }

            // formData.totalInWords = totalAmountInWordsPrint;
            queryParams.append("param9", formData.totalInWords);

            if (response?.payload?.purchaseFA_id) {
              fetch(
                `/api/salesFixedAssetSendemail_pdf/${response?.payload?.purchaseFA_id
                }?${queryParams.toString()}`
              )
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("Network response was not ok");
                  }
                  return response.blob();
                })
                .then((blob) => {
                  api
                    .post(`/api/salesFA_upload`, emailViewPdf)
                    .then((pdfResp: any) => {
                      if (pdfResp.data.message) {
                        toast.success("Email Send Successfully", {
                          position: toast.POSITION.BOTTOM_CENTER,
                          autoClose: 800,
                        });
                      }
                    })
                    .catch((error: any) => {
                      console.error("Error uploading blob:", error);
                    });
                })

                .catch((error) =>
                  console.error("Error downloading PDF:", error)
                );
            }
          });
        }
      } else if (id || invoiceUpdateId) {
        let invoiceId = id ? id : invoiceUpdateId;
        formData.salesFADate = formatDate;
        formData.gstValue = userdata?.gstValue;
        formData.product = getAssetCal(formData);
        formData?.product.forEach((item: any) => {
          item.amount = Number(item.amount).toFixed(2);
        });
        if (userdata.AmountFormat === "International Format") {
          const toWords = (num: any) => {
            const numberToWords = require('number-to-words');
            return numberToWords.toWords(num);
          };

          const convertAmount = (amount: any) => {
            const roundedAmount = Math.round(amount);
            const amountInWords = Number.isFinite(roundedAmount) ? toWords(roundedAmount) : "Invalid amount";
            const internationalAmountInWords = `${amountInWords} Only. `;

            return {
              internationalAmountInWords
            };
          };

          const totalAmount = formData.toatalAmount;
          const result = convertAmount(totalAmount);
          formData.totalInWords = result.internationalAmountInWords;
        } else {
          const totalAmount = formData.toatalAmount;
          const roundedtotalAmount = Math.round(totalAmount);
          const totalAmountInWords = Number.isFinite(roundedtotalAmount)
            ? toWords(roundedtotalAmount)
            : "Invalid amount";
          const totalAmountInWordsPrint =
            "Rupees " + totalAmountInWords + " Only";
          formData.totalInWords = totalAmountInWordsPrint;
        }
        dispatch(salesFixedAssetUpdate(invoiceId, formData) as any).then(
          (response: any) => {
            const queryParams = new URLSearchParams();

            if (formData.colorTemplateType) {
              queryParams.append("param5", formData.colorTemplateType);
            }
            queryParams.append("param6", selectedFont);
            queryParams.append("param7", currencySymbol);
            if (formData.invoiceTemplateType) {
              queryParams.append("param8", formData.invoiceTemplateType);
            }

            queryParams.append("param9", formData.totalInWords);

            // Construct the URL with the query parameters

            fetch(
              `/api/salesFixedAssetSendemail_pdf/${invoiceId}?${queryParams.toString()}`
            )
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Network response was not ok");
                }
                return response.blob();
              })
              .then((blob) => {
                api
                  .post(`/api/salesFA_upload`, emailViewPdf)
                  .then((pdfResp: any) => {
                    if (pdfResp.data.message) {
                      toast.success("Email Send Successfully", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 800,
                      });
                    }
                  })
                  .catch((error: any) => {
                    console.error("Error uploading blob:", error);
                  });
              })
              .catch((error) => console.error("Error downloading PDF:", error));
          }
        );
      }

      setOpenMail(false);
      setErrorEmail(null);
      setOpenView(false);
      setFormData({
        ...formData,
        To: "",
        Subject: "",
        Message: "",
        errors: { ...formData.errors, ToErrorMessage: "" },
      });
    } else {
      if (formData.To?.trim() === "") {
        sendEmailErrors["To"] = "Email is requried  ";
        setErrorEmail(sendEmailErrors);
      }
    }
  };

  // *********************************************EMail Sctions End**************************************
  const [editValue, setEditValue] = useState(false as any);

  useEffect(() => {
    if (!id) {
      fetchData();
      // formData.billTaxType = userdata?.taxType
      // formData.invoiceTemplateType = userdata?.invoiceType
      // formData.colorTemplateType = userdata?.template
    } else {
      dispatch(salesFixedAssetFindOne(id) as any).then((response: any) => {
        let accountSettings = JSON.parse(response?.payload.app_settings);
        setTaxAllList(accountSettings);
        let invoiceDetails = JSON.parse(response?.payload.products);
        let products = invoiceDetails.asset;
        if (id) {
          setEditValue(true);
          let newTaxStatus: any = [];
          let newTaxArr: any = [];
          for (let o in accountSettings[0]) {
            if (accountSettings[0][o] === true) {
              newTaxStatus.push(o);
            }
          }

          let finalTotal = invoiceDetails.subtotal;
          newTaxStatus.forEach((element: string) => {
            if (element === "cgstStatus") {
              let taxAmt =
                (invoiceDetails.subtotal -
                  (Number(accountSettings[0].discountPercentage) / 100) *
                  invoiceDetails.subtotal) *
                (Number(accountSettings[0].cgstPercentage) / 100);

              newTaxArr.push({
                taxName: "CGST",
                cgstStatus: accountSettings[0].cgstStatus,
                taxAmount: taxAmt.toFixed(2).toString(),
                taxPersent: accountSettings[0].cgstPercentage,
              });
              finalTotal = Number(finalTotal) + Number(taxAmt);
            } else if (element === "sgstStatus") {
              let taxAmt =
                (invoiceDetails.subtotal -
                  (Number(accountSettings[0].discountPercentage) / 100) *
                  invoiceDetails.subtotal) *
                (Number(accountSettings[0].sgstPercentage) / 100);
              newTaxArr.push({
                taxName: "SGST",
                cgstStatus: accountSettings[0].sgstStatus,
                taxAmount: taxAmt.toFixed(2).toString(),
                taxPersent: accountSettings[0].sgstPercentage,
              });
              finalTotal = Number(finalTotal) + Number(taxAmt);
            } else if (element === "extraFeesStatus") {
              newTaxArr.push({
                taxName: "Extra Fees",
                cgstStatus: accountSettings[0].extraFeesStatus,
                taxAmount: accountSettings[0].extraFees,
                taxPersent: accountSettings[0].extraFees,
              });
              finalTotal =
                Number(finalTotal) + Number(accountSettings[0].extraFees);
            } else if (element === "igstStatus") {
              let taxAmt =
                (invoiceDetails.subtotal -
                  (Number(accountSettings[0].discountPercentage) / 100) *
                  invoiceDetails.subtotal) *
                (Number(accountSettings[0].igstPercentage) / 100);
              newTaxArr.push({
                taxName: "IGST",
                cgstStatus: accountSettings[0].igstStatus,
                taxAmount: taxAmt.toFixed(2).toString(),
                taxPersent: accountSettings[0].igstPercentage,
              });
              finalTotal = Number(finalTotal) + Number(taxAmt);
            } else if (element === "vatStatus") {
              let taxAmt =
                (invoiceDetails.subtotal *
                  Number(accountSettings[0].vatPercentage)) /
                100;
              newTaxArr.push({
                taxName: "VAT",
                cgstStatus: accountSettings[0].vatStatus,
                taxAmount: taxAmt.toFixed(2).toString(),
                taxPersent: accountSettings[0].vatPercentage,
              });
              finalTotal = Number(finalTotal) + Number(taxAmt);
            } else if (element === "othersStatus") {
              let taxAmt =
                (invoiceDetails.subtotal *
                  Number(accountSettings[0].othersPercentage)) /
                100;
              newTaxArr.push({
                taxName: "OTHERS",
                cgstStatus: accountSettings[0].othersStatus,
                taxAmount: taxAmt.toFixed(2).toString(),
                taxPersent: accountSettings[0].othersPercentage,
              });
              finalTotal = Number(finalTotal) + Number(taxAmt);
            }
          });
          if (newTaxStatus.includes("discountStatus")) {
            let taxAmt =
              (Number(accountSettings[0].discountPercentage) / 100) *
              invoiceDetails.subtotal;
            newTaxArr.push({
              taxName: "Discount",
              cgstStatus: accountSettings[0].discountStatus,
              taxAmount: taxAmt.toFixed(2).toString(),
              taxPersent: accountSettings[0].discountPercentage,
            });
            finalTotal -= taxAmt;
          }
          let isSendEmail = invoiceDetails.isSendEmail ? true : true;
          let vendormobile = invoiceDetails.vendorMobile ? true : true;
          let billTaxType = invoiceDetails.billTaxType;
          let invoiceTemplateType = invoiceDetails.invoiceTemplateType;
          let colorTemplateType = invoiceDetails.colorTemplateType;

          // let termsanddconvalue = invoiceDetails.termsanddcon
          setFormData({
            ...invoiceDetails,
            tax: [...newTaxArr],
            toatalAmount:
              invoiceDetails.billTaxType == "Bill Wise"
                ? finalTotal
                : Number(invoiceDetails.subtotal),
            isSendEmail: isSendEmail,
            isvendorMobile: vendormobile,
            billTaxType: billTaxType,
            invoiceTemplateType: invoiceTemplateType,
            colorTemplateType: colorTemplateType,

            // termsanddcon: termsanddconvalue
          });

          setreceivedAmount(invoiceDetails.receivedAmount);
          setSelectedPayment(invoiceDetails.paymentType);
          setchequeNum(invoiceDetails.chequeNum);
          setbalanceAmount(invoiceDetails.balanceAmount);
          let finalErrors: any = [];

          let value = products?.map((item: any, index: any) => {
            let object: any = {};
            object.asset_name = item.asset_name ? true : false;
            object.rate = item.rate ? true : false;
            object.qty = item.qty ? true : false;
            finalErrors.push(object);
            return object;
          });
          setassetErrors(value);
          setIsToggled(!isToggled);
          setbillModule("PRODUCT");
        }
      });
    }
  }, [id]);

  const closeModal = () => {
    setErrorEmail(null);
    setOpenView(false);
    setOpenMail(false);
    setFormData((formData: any) => ({
      ...formData,
      To: "",
      Subject: "",
      Message: "",
      errors: { ...formData.errors, ToErrorMessage: "" },
    }));
    setIsModalOpen(false);

  };

  const [viewOpen, setOpenView] = useState(false);
  const cancelButtonRef = useRef(null);
  const componentRef = useRef(null);

  // const [preViewdDis, setPreViewDis] = useState(true)

  const openChoosePreview = () => {
    if (userdata.AmountFormat === "International Format") {
      const toWords = (num: any) => {
        const numberToWords = require('number-to-words');
        return numberToWords.toWords(num);
      };

      const convertAmount = (amount: any) => {
        const roundedAmount = Math.round(amount);
        const amountInWords = Number.isFinite(roundedAmount) ? toWords(roundedAmount) : "Invalid amount";
        const internationalAmountInWords = `${amountInWords} Only. `;

        return {
          internationalAmountInWords
        };
      };

      const totalAmount = formData.toatalAmount;
      const result = convertAmount(totalAmount);
      formData.totalInWords = result.internationalAmountInWords;
    } else {
      const totalAmount = formData.toatalAmount;
      const roundedtotalAmount = Math.round(totalAmount);
      const totalAmountInWords = Number.isFinite(roundedtotalAmount)
        ? toWords(roundedtotalAmount)
        : "Invalid amount";
      const totalAmountInWordsPrint =
        "Rupees " + totalAmountInWords + " Only";
      formData.totalInWords = totalAmountInWordsPrint;
    }
    let finalErrors = [...assetErrors];
    var ProductValidation = assetRows?.some(
      (item: any) => !item.asset_name || !item.rate || !item.qty
    );

    assetRows.forEach((item: any, index: any) => {
      finalErrors[index].asset_name = item.asset_name ? true : false;
      finalErrors[index].rate = item.rate ? true : false;
      finalErrors[index].qty = item.qty ? true : false;
    });
    setassetErrors(finalErrors);

    if (
      !ProductValidation &&
      formData.clientName &&
      formData.paymentType &&
      (selectedPayment != "CHEQUE" ||
        (selectedPayment === "CHEQUE" && formData.chequeNum)) &&
      !fieldErrors.cashAmount &&
      !fieldErrors.creditAmount &&
      !fieldErrors.chequeAmount &&
      !formData.errors?.clientMobileErrorMessage &&
      (id
        ? parseFloat(formData.subtotal) >= formData.receivedAmount
        : formData.subtotal) &&
      !clientErrors["chequeNum"]
    ) {
      setOpenView(true);
    } else {
      setAssetError(true);
      validateProdForm();
    }
  };

  const handlePrint = useReactToPrint({
    content: () => getPrintContent(),
    documentTitle: "Invoice Letter",
    onAfterPrint: () => {
      console.log("Print completed!");
    },
  });

  const getPrintContent = (): any => {
    if (userdata.AmountFormat === "International Format") {
      const toWords = (num: any) => {
        const numberToWords = require('number-to-words');
        return numberToWords.toWords(num);
      };

      const convertAmount = (amount: any) => {
        const roundedAmount = Math.round(amount);
        const amountInWords = Number.isFinite(roundedAmount) ? toWords(roundedAmount) : "Invalid amount";
        const internationalAmountInWords = `${amountInWords} Only. `;

        return {
          internationalAmountInWords
        };
      };

      const totalAmount = formData.toatalAmount;
      const result = convertAmount(totalAmount);
      formData.totalInWords = result.internationalAmountInWords;
    } else {
      const totalAmount = formData.toatalAmount;
      const roundedtotalAmount = Math.round(totalAmount);
      const totalAmountInWords = Number.isFinite(roundedtotalAmount)
        ? toWords(roundedtotalAmount)
        : "Invalid amount";
      const totalAmountInWordsPrint =
        "Rupees " + totalAmountInWords + " Only";
      formData.totalInWords = totalAmountInWordsPrint;
    }
    let finalErrors = [...assetErrors];
    var ProductValidation = assetRows?.some(
      (item: any) => !item.asset_name || !item.rate || !item.qty
    );

    assetRows.forEach((item: any, index: any) => {
      finalErrors[index].asset_name = item.asset_name ? true : false;
      finalErrors[index].rate = item.rate ? true : false;
      finalErrors[index].qty = item.qty ? true : false;
    });
    setassetErrors(finalErrors);

    if (
      !ProductValidation &&
      formData.clientName &&
      formData.paymentType &&
      (selectedPayment != "CHEQUE" ||
        (selectedPayment === "CHEQUE" && formData.chequeNum)) &&
      !fieldErrors.cashAmount &&
      !fieldErrors.creditAmount &&
      !fieldErrors.chequeAmount &&
      !formData.errors?.clientMobileErrorMessage &&
      (id
        ? parseFloat(formData.subtotal) >= formData.receivedAmount
        : formData.subtotal) &&
      !clientErrors["chequeNum"]
    ) {
      return componentRef.current;
    } else {
      setAssetError(true);
      validateProdForm();
    }
  };
  const handleCancel = () => {
    setConfirmDownload(false);
  };

  const handleCancelForPaymentType = () => {
    setShowConfirmation(false);
    setSelectedPayment("");
    formData.paymentType = "";
  };
  const User_id = sessionStorage.getItem("userInfo");
  const userId = JSON.parse(User_id as any);

  // ***********************************search******************************
  const handleConfirm = () => {
    setIsModalOpen(true);
  };

  const handleSelect = (data: any) => {
    // errors.vendorName = null;
    clientErrors.clientName = "";
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      clientName: data.client_name,
      clientAddress: data.client_address,
      clientCity: data.city,
      clientState: data.state,
      clientPostalCode: data.postal_code,
      clientMobile: data.client_mobile,
      clientEmail: data.client_email,
      clientPan: data.pan_no,
      clientgst: data.gst,
      clientId: data._id?.$oid,
      isClientGst: true,
      isClientPan: true,
      isClientEmail: true,
      isclientPostalCode: true,
      isclientMobile: true,
      errors: { ...formData.errors, clientgstErrorMessage: '', clientEmailErrorMessage: '', clientPanErrorMessage: '', clientMobileErrorMessage: '', clientPostalCodeErrorMessage: "" }
    }));



    setIsModalOpen(false);
  };

  return (
    <>
      <div>
        {formData?.billTaxType == "Bill Wise" ? (
          <>
            <div style={{ display: "none" }}>
              <PrintSalesFABillWise
                Invoice={formData}
                componentRef={componentRef}
                selectedFont={selectedFont}
              />
            </div>
          </>
        ) : (
          <>
            <div style={{ display: "none" }}>
              <PrintSalesFAProductWise
                Invoice={formData}
                componentRef={componentRef}
              />
            </div>
          </>
        )}
      </div>
      <div className="flex flex-wrap  ">
        <div className="w-full lg:w-10/12 pl-4 pr-4 sm:pl-4 sm:pr-0 mb-4 sm:mb-1 margin  items-start bg-[white] min-h-screen pb-5">
          <form>
            <div className="bg-[white] sticky z-10 top-0 left-0 right-0 pb-2 ">
              <div className="justify-center flex flex-row relative">
                <input
                  className={` rounded-[7px] text-center xs:w-1/4 mt-2 focus:outline-none lg:text-3xl xl:text-3xl 2xl:text-3xl md:text-3xl text-base`}
                  autoComplete="nope"
                  style={{
                    fontFamily: "poppins",
                    fontWeight: 700,
                  }}
                  defaultValue={formData.invoicehead}
                  type="text"
                  ref={ref}
                  id="input_focus"
                  aria-label="Search"
                  name="invoicehead"
                />
              </div>
            </div>

            <div className={"flex flex-col sm:flex-row pt-3 pr-4 sm:pr-8 "}>
              <div className="  flex-1 md:mx-2 mt-2 md:mt-[2px] ">
                <div className="flex flex-col md:flex-row justify-between items-left">
                  <div
                    className="flex flex-1 items-center whitespace-nowrap justify-start"
                    style={{
                      color: "#008065",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Client Details
                  </div>
                  <div className="flex flex-1 items-center justify-center md:justify-end mr-2">
                    <ClientPlusIcon className="w-4 h-4" />
                    <Button onClick={handleConfirm}>
                      <span
                        style={{
                          color: "#008065",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                        className="text-[#008065] ml-1"
                      >
                        EXISTING
                      </span>
                    </Button>
                  </div>
                  {isModalOpen && (
                    <ClientModel closeModal={closeModal} handleSelect={handleSelect} />
                  )}
                </div>
                <div className="flex flex-row justify-between items-center mb-2">
                  <div
                    className="flex-1  required"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    <span>Name</span>
                  </div>
                  <div className="font-title flex-1 text-left">
                    <input
                      autoComplete="nope"
                      placeholder="client Name"
                      className={
                        "border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "
                      }
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: clientErrors.clientName
                          ? "red"
                          : "#008065",
                      }}
                      value={formData.clientName}
                      name="vendorName"
                    // value={formData.vendorName}
                    // onChange={(e) => handleChange(e)}
                    readOnly
                    />
                    {clientErrors.clientName && (
                      <p
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {clientErrors.clientName}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center mb-2">
                  <div
                    className=" flex-1 mb-2"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Mobile
                  </div>
                  <div className="font-title flex-1 text-left">
                    <input
                      type="text"
                      autoComplete="nope"
                      placeholder="Mobile Number"
                      className={
                        "border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "
                      }
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: "#008065",
                      }}
                      maxLength={10}
                      name="vendorMobile"
                      value={formData.clientMobile}
                      onChange={(e) => {
                        // const re = /^[0-9\b]+$/;
                        // if (re.test(e.target.value) || e.target.value == "") {
                        //   handleChange(e);
                        // }
                      }}
                      readOnly
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center mb-2">
                  <div
                    className="flex-1"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Email
                  </div>
                  <div className="font-title flex-1 text-left ">
                    <input
                      autoComplete="nope"
                      placeholder="Email (Optional)"
                      className={
                        "border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "
                      }
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: "#008065",
                      }}
                      name="vendorEmail"
                      value={formData.clientEmail}
                    // onChange={(e) => handleChange(e)}
                    readOnly
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center mb-2">
                  <div
                    className="flex-1"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    GST
                  </div>
                  <div className="font-title flex-1 text-left ">
                    <input
                      autoComplete="nope"
                      placeholder="GST (Optional)"
                      className={
                        "border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "
                      }
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: "#008065",
                      }}
                      name="vendorgst"
                      value={formData.clientgst}
                    // onChange={(e) => handleChange(e)}
                    readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="  flex-1 md:mx-2">
                <div>
                  <div className="sm:flex-1 mt-7 p-0 sm:p-2 ">
                    <div className="flex flex-row justify-between items-center mb-2">
                      <div
                        className="flex-1  required"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        <span>Sales FA No</span>
                      </div>
                      <div className="font-title flex-1 text-left">
                        <input
                          autoComplete="nope"
                          placeholder="Sales-FA No"
                          className={
                            "border-2 focus:outline-none rounded-[7px] px-2 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12"
                          }
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: "#008065",
                          }}
                          name="invoiceNo"
                          value={formData.invoiceNo}
                        // onChange={(e: any) => handleChange(e)}
                        readOnly
                        />

                      </div>
                    </div>
                    <div className="flex flex-row justify-between items-center mb-2">
                      <div
                        className=" flex-1 mb-2"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        Sales FA Date
                      </div>

                      <div className=" font-title flex-1 text-left">
                        <DatePicker
                          // style={{ fill: "red !important" }}
                          selected={salesFADate}
                          value={formData?.salesFADate}
                          onChange={(date) => {
                            setsalesFADate(date);
                            setFormData({
                              ...formData,
                              salesFADate:
                                moment(date).format("DD/MM/YYYY"),
                            });
                          }}
                          dateFormat={"dd/MM/yyyy"}
                          wrapperClassName="return-datepicker"
                          scrollableYearDropdown
                          customInput={<CustomInputWithIcon />}
                          showPopperArrow={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr className="mr-8 ml-4 mt-2" style={{ borderColor: "#008065" }} />
            <div className="py-2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4">
              {(formData.billTaxType == "Product Wise") ? (
                <>
                  <SalesFAProductData
                    creditAmount={creditAmount}
                    fieldErrors={fieldErrors}
                    clientErrors={clientErrors}
                    setCashAmount={setCashAmount}
                    setchequeAmount={setchequeAmount}
                    setcreditAmount={setcreditAmount}
                    setSelectedPayment={setSelectedPayment}
                    setbalanceAmount={setbalanceAmount}
                    setreceivedAmount={setreceivedAmount}
                    formData={formData}
                    setFormData={setFormData}
                    TaxAllList={TaxAllList}
                    paramsId={id}
                    updateAssetErrors={updateAssetErrors}
                    assetErrors={assetErrors}
                    assetRows={assetRows}
                    setAssetRows={setAssetRows}
                    AssetError={AssetError}
                    addRow={addRow}
                    setAssetError={setAssetError}
                    setassetErrors={setassetErrors}
                    editValue={editValue}
                  />
                </>
              ) : (
                <>
                  <SalesFABillData

                    creditAmount={creditAmount}
                    fieldErrors={fieldErrors}
                    clientErrors={clientErrors}
                    setCashAmount={setCashAmount}
                    setchequeAmount={setchequeAmount}
                    setcreditAmount={setcreditAmount}
                    setSelectedPayment={setSelectedPayment}
                    setbalanceAmount={setbalanceAmount}
                    formData={formData}
                    setFormData={setFormData}
                    TaxAllList={TaxAllList}
                    paramsId={id}
                    updateAssetErrors={updateAssetErrors}
                    assetErrors={assetErrors}
                    assetRows={assetRows}
                    setAssetRows={setAssetRows}
                    AssetError={AssetError}
                    addRow={addRow}
                    setAssetError={setAssetError}
                    setassetErrors={setassetErrors}
                    editValue={editValue}
                  />
                </>
              )}
              <div
                className={
                  "flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 items-center"
                }
              >
                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className={
                    "font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                  }
                >
                  SUBTOTAL
                </div>
                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className={
                    "font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1 whitespace-nowrap"
                  }
                >
                  <NumberFormat
                    value={formData.subtotal ? formData.subtotal : 0}
                  />
                </div>
              </div>

              {formData.billTaxType == "Product Wise" ? (
                <></>
              ) : (
                <>
                  <GstSection TaxAllList={TaxAllList} formData={formData} />
                </>
              )}

              <div
                className={
                  "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                }
              >
                <div
                  className={
                    "w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
                  }
                >
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={
                      "w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                    }
                  >
                    TOTAL
                  </div>
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={
                      "font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                    }
                  >
                    <NumberFormat value={formData.toatalAmount} />
                  </div>
                </div>
              </div>

              <div
                className={
                  "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                }
              >
                <div className="w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center">
                  <div
                    className="w-full sm:w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                    style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }}
                  >
                    PAYMENT TYPE
                  </div>

                  <div
                    className="font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                  >
                    <select
                      name="paymentType"
                      id="paymentType"
                      className={`border-2 rounded-[7px] px-2 h-9 w-full focus:outline-none ${clientErrors.paymentType ? "border-red-500" : "border-[#008065]"
                        }`}
                      style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: 400 }}
                      value={selectedPayment || formData.paymentType || ""}
                      onChange={(e: any) => handleChange(e)}
                    >
                      {id && formData.paymentType ? (
                        <option value={formData.paymentType}>{formData.paymentType}</option>
                      ) : (
                        <>
                          <option value="">Payment Type</option>
                          <option value="CASH">CASH</option>
                          <option value="CREDIT">CREDIT</option>
                          <option value="CHEQUE">CHEQUE</option>
                        </>
                      )}
                    </select>

                    {clientErrors.paymentType && (
                      <p
                        className="text-red-500 text-xs"
                        style={{ fontFamily: "poppins", fontWeight: 700 }}
                      >
                        {clientErrors.paymentType}
                      </p>
                    )}
                  </div>
                </div>

              </div>

              {id && (
                <div className="flex flex-row sm:justify-end w-full items-center text-black">
                  <div className="w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center">
                    <div
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className="w-full sm:w-1/2 text-right sm:pr-9 flex sm:block items-center"
                    >
                      RECEIVED AMOUNT
                    </div>
                    <div
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className="font-title text-lg w-1/2 text-right sm:pr-9 flex justify-end sm:block items-center whitespace-nowrap"
                    >
                      <input
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: "#00A787",
                        }}
                        readOnly
                        autoComplete="off"
                        name="receivedAmount"
                        value={id ? formData.receivedAmount : 0.0}
                        onChange={handleChange}
                        placeholder="0.00"
                        type="number"
                        className={
                          defaultInputSmStyle +
                          " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {!id && selectedPayment === "CASH" && (
                <div
                  className={
                    "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                  }
                >
                  <div
                    className={
                      "w-full sm:w-1/2 py-2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
                    }
                  >
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "w-full sm:w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                      }
                    >
                      CASH AMOUNT
                    </div>
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "font-title text-lg w-1/2 text-right  sm:pr-9  flex flex-row justify-end sm:block items-center whitespace-nowrap"
                      }
                    >
                      <input
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: fieldErrors.cashAmount
                            ? "red"
                            : "#008065",
                        }}
                        name="cashAmount"
                        // value={formData.cashAmount}
                        value={CashAmount}
                        autoComplete="nope"
                        onChange={(e: any) => handleChange(e)}
                        placeholder="0.00"
                        type="Number"
                        className={
                          defaultInputSmStyle +
                          " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                        }
                      />
                      {fieldErrors.cashAmount && (
                        <span
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 700,
                            color: "#ff0000",
                          }}
                          className="text-red-400 text-xs"
                        >
                          {fieldErrors.cashAmount}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {!id && selectedPayment === "CREDIT" && (
                <div
                  className={
                    "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                  }
                >
                  <div
                    className={
                      "w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4  py-1 flex flex-row rounded-lg items-center"
                    }
                  >
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "w-full sm:w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                      }
                    >
                      CREDIT AMOUNT
                    </div>
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                      }
                    >
                      <input
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: fieldErrors.creditAmount
                            ? "red"
                            : "#008065",
                        }}
                        readOnly
                        autoComplete="nope"
                        name="creditAmount"
                        value={creditAmount}
                        onChange={(e: any) => handleChange(e)}
                        placeholder="0.00"
                        type="text"
                        className={
                          defaultInputSmStyle +
                          " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                        }
                      />
                      {fieldErrors.creditAmount && (
                        <span
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 700,
                            color: "#ff0000",
                          }}
                          className="text-red-400 text-xs"
                        >
                          {fieldErrors.creditAmount}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {!id && selectedPayment === "CHEQUE" && (
                <div
                  className={
                    "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                  }
                >
                  <div
                    className={
                      "w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4  py-1 flex flex-row rounded-lg items-center"
                    }
                  >
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "w-full sm:w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                      }
                    >
                      CHEQUE AMOUNT
                    </div>
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                      }
                    >
                      <input
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: fieldErrors.chequeAmount
                            ? "red"
                            : "#008065",
                        }}
                        autoComplete="nope"
                        name="chequeAmount"
                        placeholder="0.00"
                        value={chequeAmount}
                        onChange={(e: any) => handleChange(e)}
                        type="Number"
                        className={
                          defaultInputSmStyle +
                          " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                        }
                      />
                      {fieldErrors.chequeAmount && (
                        <span
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 700,
                            color: "#ff0000",
                          }}
                          className="text-red-400 text-xs"
                        >
                          {fieldErrors.chequeAmount}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {selectedPayment === "UPI" && (
                <div
                  className={
                    "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                  }
                >
                  <div
                    className={
                      "w-full sm:w-1/2 px-4 py-1 flex flex-row rounded-lg items-center"
                    }
                  >
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                      }
                    >
                      UPI AMOUNT
                    </div>
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                      }
                    >
                      <input
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: "#00A787",
                        }}
                        autoComplete="nope"
                        readOnly
                        onChange={(e: any) => handleChange(e)}
                        value={formData?.toatalAmount}
                        placeholder="0.00"
                        type="Number"
                        className={
                          defaultInputSmStyle +
                          " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {selectedPayment === "NETBANKING" && (
                <div
                  className={
                    "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                  }
                >
                  <div
                    className={
                      "w-full sm:w-1/2 px-4 py-1 flex flex-row rounded-lg items-center"
                    }
                  >
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                      }
                    >
                      NET BANKING AMOUNT
                    </div>
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                      }
                    >
                      <input
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: "#00A787",
                        }}
                        autoComplete="nope"
                        readOnly
                        onChange={(e: any) => handleChange(e)}
                        value={formData?.toatalAmount}
                        placeholder="0.00"
                        type="Number"
                        className={
                          defaultInputSmStyle +
                          " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {selectedPayment === "EMI" && (
                <div
                  className={
                    "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                  }
                >
                  <div
                    className={
                      "w-full sm:w-1/2 px-4 py-1 flex flex-row rounded-lg items-center"
                    }
                  >
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                      }
                    >
                      EMI AMOUNT
                    </div>
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={
                        "font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                      }
                    >
                      <input
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: "#00A787",
                        }}
                        readOnly
                        autoComplete="nope"
                        value={formData?.toatalAmount}
                        onChange={(e: any) => handleChange(e)}
                        placeholder="0.00"
                        type="Number"
                        className={
                          defaultInputSmStyle +
                          " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                        }
                      />
                    </div>
                  </div>
                </div>
              )}

              <div
                className={
                  "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                }
              >
                <div
                  className={
                    "w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
                  }
                >
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={
                      "w-full sm:w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                    }
                  >
                    BALANCE AMOUNT
                  </div>

                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={
                      "font-title text-lg w-1/2  text-right sm:pr-9  flex flex-row justify-end sm:block items-center whitespace-nowrap"
                    }
                  >
                    <input
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: clientErrors.balanceAmount
                          ? "red"
                          : "#00A787",
                      }}
                      value={balanceAmount}
                      readOnly={selectedPayment == "CREDIT" && balanceAmount}
                      onChange={(e: any) => handleChange(e)}
                      autoComplete="nope"
                      placeholder="0.00"
                      type=""
                      className={
                        defaultInputSmStyle +
                        " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                      }
                    />
                    {clientErrors.balanceAmount && (
                      <p
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {clientErrors.balanceAmount}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                }
              >
                <div
                  className={
                    "w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4  py-1 flex flex-row rounded-lg items-center"
                  }
                >
                  {selectedPayment == "CHEQUE" && (
                    <>
                      <div
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                        className={
                          "w-full sm:w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                        }
                      >
                        CHEQUE Number
                      </div>
                      <div
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                        className={
                          "font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                        }
                      >
                        <input
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: clientErrors.chequeNum
                              ? "red"
                              : "#00A787",
                          }}
                          value={chequeNum}
                          onChange={(e: any) => handleChange(e)}
                          name="chequeNum"
                          autoComplete="nope"
                          // placeholder="0.00"
                          type=""
                          className={
                            defaultInputSmStyle +
                            " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                          }
                        />
                        {/* {vendorErrors.chequeNum && (
                          <p
                            style={{
                              fontFamily: "poppins",
                              fontWeight: 700,
                              color: "#ff0000",
                            }}
                            className="text-red-400 text-xs"
                          >
                            {vendorErrors.chequeNum}
                          </p>
                        )} */}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="sticky top-[12px] right-0 w-full lg:w-2/12 pl-2 pr-4 sm:pr-2 mt-3 mx-4 md:mx-0 self-start xl:pb-20 sm:pb-40 xs:pb-40">
          <div style={{ fontFamily: "poppins", fontSize: "14px" }}>
            <button
              type="button"
              className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full "
              style={{ backgroundColor: "#008065" }}
              onClick={openChoosePreview}
            >
              Pre View
            </button>
          </div>
          <div
            style={{ fontFamily: "poppins", fontSize: "14px" }}
            className="mt-2"
          >
            <button
              className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full "
              style={{ backgroundColor: "#008065" }}
              onClick={handlePrint}
            >
              Print
            </button>
          </div>
          <div
            style={{ fontFamily: "poppins", fontSize: "14px" }}
            className=" w-full text-right mt-2 "
          >
            <button
              // size="sm"
              type="submit"
              className="rounded-xl font-bold text-white  flex flex-row-reverse flex-row items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full "
              style={{ backgroundColor: "#008065" }}
              onClick={(e: any) => handleSubmit(e)}
            >
              <svg
                className="pr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 20 20"
                fill="white"
              >
                <mask
                  id="mask0_278_27"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <rect width="20" height="20" fill="white" />
                </mask>
                <g mask="url(#mask0_278_27)">
                  <path
                    d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z"
                    fill="white"
                  />
                </g>
              </svg>
              {/* {params.id !== "new" ? "Update" : "Download"} */}
              Download
            </button>
          </div>
          <div
            style={{ fontFamily: "poppins", fontSize: "14px" }}
            className=" w-full text-right mt-2 "
          >
            <button
              type="button"
              className="rounded-xl font-bold text-white  flex flex-row-reverse  items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full whitespace-nowrap"
              style={{ backgroundColor: "#008065" }}
              onClick={(e: any) => handleSectionOpen(e)}
            >
              <svg
                className="pl-2"
                width="30"
                height="30"
                viewBox="0 0 20 20"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_278_36"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="23"
                  height="23"
                >
                  <rect
                    y="12.0752"
                    width="16"
                    height="16"
                    transform="rotate(-49 0 12.0752)"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_278_36)">
                  <path
                    d="M11.3749 19.3134L3.32462 12.3154L15.6598 6.25476L11.3749 19.3134ZM10.7402 16.995L13.4073 8.84593L5.70878 12.6213L7.46977 14.1521L10.8487 11.7893L8.97919 15.4642L10.7402 16.995Z"
                    fill="white"
                  />
                </g>
              </svg>
              Send Email
            </button>
          </div>

          {/* <div
            style={{ fontFamily: "poppins", fontSize: "14px" }}
            className=" w-full  mt-2 sm:mb-auto mb-16"
          >
            <ColorScheme
              clientList={vendorList}
              handleColorChange={handleColorChange}
              setColorPicker={setColorPicker}
              ColourEmpty={ColourEmpty}
            />
          </div> */}
        </div>
      </div>

      {openmail && (
        <Transition.Root show={openmail} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpenMail}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <SendEmailModel
                      closeModal={closeModal}
                      handleChange={handleChange}
                      formData={formData}
                      setFormData={setFormData}
                      handleSendEmail={handleSendEmail}
                      sendEmailErrors={sendEmailErrors}
                      errorEmail={errorEmail}
                      setErrorEmail={setErrorEmail}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
      {confirmDownload && (
        <ConfirmationDownload
          onDownload={downloadInvoice}
          onSaveDownload={saveAndDownloadInvoice}
          onCancel={handleCancel}
        />
      )}
      {showConfirmation && (
        <PaymentUpdateModel
          message="Are you sure you want to proceed?"
          // onConfirm={handleConfirm}
          onCancel={handleCancelForPaymentType}
        />
      )}
      <Transition.Root show={viewOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpenView}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:mx-8 lg:w-10/12 md:w-11/12 ">
                  {formData?.billTaxType == "Bill Wise" ? (
                    <PreviewSalesFABillWise
                      Invoice={formData}
                      componentRef={componentRef}
                      closeModal={closeModal}
                      selectedFont={selectedFont}
                    />
                  ) : (
                    <>
                      <PreviewSalesFAProductWise
                        Invoice={formData}
                        componentRef={componentRef}
                        closeModal={closeModal}
                        selectedFont={selectedFont}
                      />
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SalesFixedAssetPage;
