import { useCallback, useEffect, useMemo, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BanksGet } from "../../../redux/actions/bankAction";
import { cashInHandGet } from "../../../redux/actions/CashInHandActions";
import { companyAll } from "../../../redux/actions/companyAction";
import { currencyGet } from "../../../redux/actions/currencyAction";
import { expenseDetailAll } from "../../../redux/actions/expensePageActions";
import { fixedAssetAll } from "../../../redux/actions/fixedAssetsAction";
import { InternalFindAll } from "../../../redux/actions/InternalInvoiceAction";
import { serviceFindAll } from "../../../redux/actions/invoiceServiceActions";
import { PurchaseFixedAssetGetList } from "../../../redux/actions/purchaseFixedAssetAction";
import { PurchaseFindAll } from "../../../redux/actions/purchasePageNewAction";
import { SalesFixedAssetGetList } from "../../../redux/actions/salesFixedAssetAction";
import { salesReturnGet } from "../../../redux/actions/salesReturnAction";
import { registerAll } from "../../../redux/actions/userAction";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { ChequeFindAll } from "../../../redux/actions/chequeAction";
import { ProductsGet } from "../../../redux/actions/productAction";
import { stockPurchaseGet } from "../../../redux/actions/stockAction";
import { purchaseReturnFindAll } from "../../../redux/actions/purchaseReturnActions";
import { styled } from '@mui/system';
const BalanceSheet = () => {
	const dispatch = useDispatch();
	const currentDate = new Date();

	const formattedDate = currentDate.toLocaleDateString("en-US", {
		month: "short",
		day: "numeric",
		year: "numeric",
	});

// dropdown fix
 const [openRows, setOpenRows] = useState<string[]>([]);
 const scrollRef1 = useRef<HTMLDivElement>(null);
 const scrollRef2 = useRef<HTMLDivElement>(null);
 const scrollPositionRef1 = useRef<number>(0); // Store scroll position for the first element
 const scrollPositionRef2 = useRef<number>(0);

const toggleRow = (rowName: string, event: any, scrollIndex: number) => {
    if (scrollIndex === 1 && scrollRef1.current) {
        scrollPositionRef1.current = scrollRef1.current.scrollTop;
    } else if (scrollIndex === 2 && scrollRef2.current) {
        scrollPositionRef2.current = scrollRef2.current.scrollTop;
    }
    // Update state for openRows
    if (openRows.includes(rowName)) {
        setOpenRows(openRows.filter((name) => name !== rowName));
    } else {
        setOpenRows([...openRows, rowName]);
    }
    // Prevent default scroll behavior
    event.preventDefault();
    event.stopPropagation();
};

	// Restore scroll position after state changes
	useLayoutEffect(() => {
		if (scrollRef1.current) {
			scrollRef1.current.scrollTop = scrollPositionRef1.current;
		}
		if (scrollRef2.current) {
			scrollRef2.current.scrollTop = scrollPositionRef2.current;
		}
	}, [openRows])


// scroll bar
const StyledDiv = styled("div")({
	position: "relative",
	marginRight: "0.5rem",
	marginTop: "",
	overflowY: "auto",
	overflowX: "auto",
	height: "43vh",
	"@media (min-width: 2000px)": {
		height: "50vh",
	},
	"&::-webkit-scrollbar": {
		width: "4px",
		height: "5px",
	},
	"&::-webkit-scrollbar-track": {
		backgroundColor: "text-emerald-200",
	},
	"&::-webkit-scrollbar-thumb": {
		// backgroundColor: "#008065",
		backgroundColor: "rgba(24, 120, 99, 0.5)",
		borderRadius: "4px"
	},
});

	const [companyLists, setCompanyList] = useState(
		[] as { company_name: string }[]
	);

	//  select Date

	const [selectedPeriod, setSelectedPeriod] = useState<string>("All");
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [endDate, setEndDate] = useState<Date | null>(new Date());

	// Function to format date as dd/MM/yyyy
	const formatDate = (date: Date | null): string =>
		date ? date.toLocaleDateString("en-GB") : "";

	const handlePeriodChange = (period: string) => {
		setSelectedPeriod(period);

		const today = new Date();
		const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
		const lastDayOfMonth = new Date(
			today.getFullYear(),
			today.getMonth() + 1,
			0
		);

		if (period === "All") {
			setStartDate(today);
			setEndDate(today);
		} else if (period === "This Month") {
			setStartDate(firstDayOfMonth);
			setEndDate(lastDayOfMonth);
		} else if (period === "Last Month") {
			const firstDayLastMonth = new Date(
				today.getFullYear(),
				today.getMonth() - 1,
				1
			);
			const lastDayLastMonth = new Date(
				today.getFullYear(),
				today.getMonth(),
				0
			);
			setStartDate(firstDayLastMonth);
			setEndDate(lastDayLastMonth);
		} else if (period === "This Quarter") {
			const currentQuarter = Math.floor(today.getMonth() / 3);
			const firstDayQuarter = new Date(
				today.getFullYear(),
				currentQuarter * 3,
				1
			);
			const lastDayQuarter = new Date(
				today.getFullYear(),
				(currentQuarter + 1) * 3,
				0
			);
			setStartDate(firstDayQuarter);
			setEndDate(lastDayQuarter);
		} else if (period === "This Year") {
			const firstDayYear = new Date(today.getFullYear(), 0, 1);
			const lastDayYear = new Date(today.getFullYear(), 11, 31);
			setStartDate(firstDayYear);
			setEndDate(lastDayYear);
		} else if (period === "Custom") {
			setStartDate(null);
			setEndDate(null);
		}
	};

	useEffect(() => {
		if (selectedPeriod === "All") {
			const today = new Date();
			setStartDate(today);
			setEndDate(today);
		}
	}, [selectedPeriod]);

	const [fixedAssetDetails, setFixedAssetDetails] = useState([] as any);
	const usersignin = useSelector((state: any) => state.userSignin);
	const token = usersignin?.userInfo?.token;
	const productdetails = useSelector((state: any) => state.stockPurchaseList);
	const { stocklist } = productdetails;

	const [banklist, setBankList] = useState([] as any);
	const [currencySymbol, setcurrencySymbol] = useState("₹");
	// const [salesTotal, setsalesTotal] = useState(0);
	const [serviceInvoiceTable, setserviceInvoiceTable] = useState([] as any);
	const [invoiceTable, setInvoiceTable] = useState([] as any);
	const [purchaseList, setPurchaseList] = useState([] as any);
	const [expenseDetails, setExpenseDetails] = useState([] as any);
	const [salesReturnDetails, setSalseDetails] = useState([] as any);
	const [stockvalue, setStockValue] = useState([] as any);
	const [purchasefixedAsset, setPurchaseFixedAssetTable] = useState([] as any);
	const [salesFixedAssetTable, setSalesFixedAssetTable] = useState([] as any);
	const [cashInHandGetTable, setcashInHandGet] = useState([] as any);
	const [paymentReceiveTable, setPaymentReceiveTable] = useState([] as any);
	const [chequeTable, setChequeTable] = useState([] as any);
	const [purchaseReturn, setPurchaseReturn] = useState([] as any);

	const mergedArray = useMemo(
		() => invoiceTable.concat(serviceInvoiceTable),
		[invoiceTable, serviceInvoiceTable]
	);
	const fetchData = useCallback(() => {
		dispatch(registerAll(token?._id) as any).then((res: any) => { });
		dispatch(InternalFindAll() as any).then((response: any) => {
			if (response && response.payload) {
				setInvoiceTable(response.payload);
			}
		});
		dispatch(serviceFindAll() as any).then((response: any) => {
			if (response && response.payload) {
				setserviceInvoiceTable(response.payload);
			}
		});
		dispatch(PurchaseFindAll() as any).then((response: any) => {
			if (response && response.payload) {
				setPurchaseList(response.payload);
			}
		});
		dispatch(expenseDetailAll() as any).then((response: any) => {
			setExpenseDetails(response?.payload);
		});
		dispatch(salesReturnGet() as any).then((response: any) => {
			setSalseDetails(response?.payload);
		});
		dispatch(BanksGet(token?._id) as any).then((res: any) => {
			if (res) {
				setBankList(res.payload);
			}
		});
		dispatch(PurchaseFixedAssetGetList() as any).then((res: any) => {
			if (res) {
				setPurchaseFixedAssetTable(res.payload);
			}
		});
		dispatch(SalesFixedAssetGetList() as any).then((res: any) => {
			if (res) {
				setSalesFixedAssetTable(res.payload);
			}
		});
		dispatch(cashInHandGet(token?._id) as any).then((res: any) => {
			if (res) {
				setcashInHandGet(res.payload);
			}
		});
		dispatch(currencyGet(token?._id) as any).then((res: any) => {
			if (res) {
				let data = res.payload.find((value: any) => value.defaultInfo === true);
				if (data?.currencySymbol) {
					setcurrencySymbol(data.currencySymbol);
				} else {
					setcurrencySymbol("₹");
				}
			}
		});
		dispatch(companyAll() as any).then((res: any) => {
			if (res && res.payload) {
				const companyNames = res.payload.map((company: any) => ({
					company_name: company.company_name,
				}));
				setCompanyList(companyNames);
			}
		});
		dispatch(fixedAssetAll() as any).then((response: any) => {
			const data = Array.isArray(response?.payload) ? response.payload : [];
			setFixedAssetDetails(data);
		});

		dispatch(stockPurchaseGet(token?._id) as any);
		dispatch(InternalFindAll() as any);
		dispatch(ProductsGet(token?._id) as any);
		dispatch(cashInHandGet(token?._id) as any).then((res: any) => {
			if (res) {
				setPaymentReceiveTable(res.payload);
			}
		});
		dispatch(ChequeFindAll(token?._id) as any).then((res: any) => {
			if (res) {
				setChequeTable(res.payload);
			}
		});
		dispatch(purchaseReturnFindAll() as any).then((response: any) => {
			setPurchaseReturn(response?.payload);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, token?._id]);
	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		setStockValue(stocklist);
	}, [stocklist]);

	let invoiceTotalser = 0;
	let invoiceTotalproduct = 0;
	let invoiceTotalProductt = 0;
	for (let i = 0; i < mergedArray?.length; i++) {
		const invoice = mergedArray[i];

		if (invoice.billModule === "SERVICE") {
			for (let j = 0; j < invoice.product?.length; j++) {
				const product = invoice.product[j];
				const revenue = product.qty * product.rate;
				if (invoice.tax && invoice.tax.length > 0) {
					for (let k = 0; k < invoice.tax.length; k++) {
						const tax = invoice.tax[0] || 0;

						const taxAmount = (revenue * (tax.taxPersent || 0)) / 100;
						invoiceTotalProductt = revenue - taxAmount || 0;
					}
				} else {
					invoiceTotalProductt = revenue;
				}
				invoiceTotalser += invoiceTotalProductt;
			}
		} else {
			for (let j = 0; j < invoice.product?.length; j++) {
				const product = invoice.product[j];
				const revenue = product.qty * product.rate;
				const discount = (revenue * product.discount || 0) / 100;
				let invoiceTotalProduct = revenue - discount;
				if (invoice.tax && invoice.tax.length > 0) {
					for (let k = 0; k < invoice.tax.length; k++) {
						const tax = invoice.tax[k];
						const taxAmount =
							(invoiceTotalProduct * (tax.taxPersent || 0)) / 100;
						invoiceTotalProduct += taxAmount;
					}
				}
				invoiceTotalproduct += invoiceTotalProduct;
			}
		}
	}

	let torevwithdis =
		parseFloat(invoiceTotalproduct.toFixed(2)) +
		parseFloat(invoiceTotalser.toFixed(2));

	let revenuee = 0;
	let discountt = 0;
	let returntotal = 0;

	for (let j = 0; j < salesReturnDetails.length; j++) {
		for (let i = 0; i < salesReturnDetails[j].product.length; i++) {
			const product = salesReturnDetails[j].product[i];
			revenuee = product.qty * product.rate;
			discountt = product.discount ? (revenuee * product.discount) / 100 : 0;
			returntotal += revenuee - discountt;
		}
	}

	let overallrevenue = torevwithdis - parseFloat(returntotal.toFixed(2));
	const recentExpenseTotal = expenseDetails.reduce(
		(total: any, expense: any) => total + Number(expense.toatalAmount),
		0
	);
	console.log("overallrevenue", overallrevenue);

	// let neeeetIncome =
	// 	parseFloat(overallrevenue.toFixed(2)) -
	// 	parseFloat(recentExpenseTotal.toFixed(2));

	let fixdassetData: any[] = [];
	let fixedassetamount = 0;

	fixedAssetDetails.map((fixedasset: any) => {
		console.log("fixedasset", fixedasset);
		const totalFixedAsset =
			parseFloat(fixedasset.qty) * parseFloat(fixedasset.rate);
		fixedassetamount += totalFixedAsset;

		fixdassetData.push({
			asset_name: fixedasset.asset_name,
			rate: fixedasset.rate,
			qty: fixedasset.qty,
			total: (parseFloat(fixedasset.rate || 0) * fixedasset.qty || 0).toFixed(
				2
			),
		});
	});
	console.log("fixedAssetDetails", fixedAssetDetails);

	let sundryDebtors: [string, number][] = [];

	for (let i = 0; i < mergedArray?.length; i++) {
		if (
			mergedArray[i]?.paymentStatus === "UNPAID" ||
			mergedArray[i]?.paymentStatus === "PARTIALLY PAID"
		) {
			const totalAmount = parseFloat(mergedArray[i]?.toatalAmount) || 0;
			const receivedAmount = parseFloat(mergedArray[i]?.receivedAmount) || 0;

			const balanceAmount = totalAmount - receivedAmount;

			sundryDebtors.push([
				mergedArray[i].clientName,
				parseFloat(balanceAmount.toFixed(2)),
			]);
		}
	}

	let stockamount = 0;
	let totalStockAmount = 0;

	for (let i = 0; i < stockvalue?.length; i++) {
		console.log("stockvalue", stockvalue);
		totalStockAmount = stockvalue[i].qty * stockvalue[i].rate;
		const discount = (totalStockAmount / 100) * stockvalue[i].discount;
		const finalAmount = totalStockAmount - discount;

		stockamount += finalAmount;
	}

	const totalSundryDebtors = sundryDebtors.reduce(
		(total, [, balanceAmount]) => total + balanceAmount,
		0
	);

	let sundryCreditors: [string, number][] = [];

	for (let i = 0; i < purchaseList?.length; i++) {
		if (
			purchaseList[i]?.paymentStatus === "UNPAID" ||
			purchaseList[i]?.paymentStatus === "PARTIALLY PAID"
		) {
			const balanceAmount = purchaseList[i]?.balanceAmount;

			sundryCreditors.push([
				purchaseList[i].vendorName,
				parseFloat(balanceAmount.toFixed(2)),
			]);
		}
	}

	const openingBankAccount = banklist.map((item: any) => ({
		bankname: item.bank_name,
		openingamt: Number(item.opening_balance),
	}));

	const totalOpeningAmt = openingBankAccount.reduce(
		(total: any, account: any) => total + account.openingamt,
		0
	);

	// let currentAssets =
	// 	parseFloat(totalSundryDebtors.toFixed(2)) +
	// 	parseFloat(stockamount.toFixed(2));

	let cashAdding = 0;
	let cashReducing = 0;

	for (let i = 0; i < cashInHandGetTable.length; i++) {
		const amount = parseFloat(cashInHandGetTable[i].amount);

		if (cashInHandGetTable[i].transaction_type === "Add Cash") {
			cashAdding += amount;
		} else if (cashInHandGetTable[i].transaction_type === "Reduce Cash") {
			cashReducing += amount;
		}
	}
	let cashAdjustment = parseFloat((cashAdding - cashReducing).toFixed(2));

	let openingBalanceEquity = parseFloat(
		(Number(totalOpeningAmt) + fixedassetamount).toFixed(2)
	);

	let ownersEquity = openingBalanceEquity + cashAdjustment;

	let cashinHand = totalOpeningAmt;

	for (let i = 0; i < paymentReceiveTable.length; i++) {
		const transaction = paymentReceiveTable[i];
		const amount = parseFloat(transaction.amount);

		if (isNaN(amount)) {
			console.error(
				`Invalid amount for transaction ${i + 1}:`,
				transaction.amount
			);
			continue;
		}

		if (
			transaction.transaction_type === "SALES" ||
			transaction.transaction_type === "PURCHASE RETURN" ||
			transaction.transaction_type === "SALES(FA)" ||
			transaction.transaction_type === "Add Cash" ||
			transaction.transaction_type === "CHEQUE DEPOSIT"
		) {
			cashinHand = parseFloat((cashinHand + amount).toFixed(2));
		} else if (
			transaction.transaction_type === "PURCHASE" ||
			transaction.transaction_type === "SALES RETURN" ||
			transaction.transaction_type === "PURCHASE(FA)" ||
			transaction.transaction_type === "EXPENSE" ||
			transaction.transaction_type === "Reduce Cash" ||
			transaction.transaction_type === "CHEQUE WITHDRAW"
		) {
			cashinHand = parseFloat((cashinHand - amount).toFixed(2));
		}
	}

	let otherCurrentAsset = 0;
	let othercashAmount = 0;
	let otherbalanceAmount = 0;

	let otherwithCurrentAsset = 0;
	let otherwithcashAmount = 0;
	let otherwithbalanceAmount = 0;
	let saleCheque = [];
	for (let i = 0; i < chequeTable.length; i++) {
		const chequeDetails = chequeTable[i];

		const amount = parseFloat(chequeDetails.chequeAmount);
		const balanceAmt = parseFloat(chequeDetails.chequeAmount);

		if (
			chequeDetails.transaction_type === "SALES" ||
			chequeDetails.transaction_type === "SALES(FA)"
		) {
			if (
				chequeDetails.chequeStatus === "OPEN" &&
				chequeDetails.paymentStatus === "PAID"
			) {
				othercashAmount += amount;
				saleCheque.push({
					name: chequeDetails.Name,
					amt: chequeDetails.chequeAmount,
				});
			} else if (
				chequeDetails.chequeStatus === "OPEN" &&
				chequeDetails.paymentStatus === "PARTIALLY PAID"
			) {
				otherbalanceAmount += balanceAmt;
				saleCheque.push({
					name: chequeDetails.Name,
					amt: chequeDetails.chequeAmount,
				});
			}
			otherCurrentAsset = othercashAmount + otherbalanceAmount;
		}
	}

	for (let i = 0; i < chequeTable.length; i++) {
		const chequeDetails = chequeTable[i];
		const amount = parseFloat(chequeDetails.chequeAmount);
		const balanceAmt = parseFloat(chequeDetails.chequeAmount);

		if (
			chequeDetails.transaction_type === "PURCHASE" ||
			chequeDetails.transaction_type === "PURCHASE(FA)"
		) {
			if (
				chequeDetails.chequeStatus === "OPEN" &&
				chequeDetails.paymentStatus === "PAID"
			) {
				otherwithcashAmount += amount;
			} else if (
				chequeDetails.chequeStatus === "OPEN" &&
				chequeDetails.paymentStatus === "PARTIALLY PAID"
			) {
				otherwithbalanceAmount += balanceAmt;
			}
			otherwithCurrentAsset = otherwithcashAmount + otherwithbalanceAmount;
		}
	}

	let totalPurchaseTax = 0;
	let totalIgst = 0;
	let totalGst = 0;
	let totalPurchaceGst = 0;
	let totalPurchase = 0;
	for (let i = 0; i < purchaseList?.length; i++) {
		totalPurchase += parseFloat(purchaseList[i]?.toatalAmount);

		const purchseDetails = purchaseList[i];
		for (let j = 0; j < purchseDetails.product.length; j++) {
			const productDetails = purchseDetails.product[j];

			if (productDetails.igstAmount) {
				totalIgst = productDetails.amount - productDetails.nontaxableAmount;
				totalPurchaseTax += totalIgst;
			} else if (productDetails.prodcgst) {
				totalGst =
					(productDetails.amount - productDetails.nontaxableAmount) / 2;
				totalPurchaceGst += totalGst;
			}
		}
	}

	let totalPurchaseReturnTax = 0;
	let totalreturnIgst = 0;
	let totalreturnGst = 0;
	let totalPurchaseReturnGst = 0;
	let totalPurchaseReturn = 0;
	for (let i = 0; i < purchaseReturn?.length; i++) {
		const purchsereDetails = purchaseReturn[i];
		totalPurchaseReturn += parseFloat(purchaseReturn[i]?.toatalAmount);
		for (let j = 0; j < purchsereDetails.product.length; j++) {
			const productDetails = purchsereDetails.product[j];

			if (productDetails.igstAmount) {
				totalreturnIgst =
					productDetails.amount - productDetails.nontaxableAmount;
				totalPurchaseReturnTax += totalreturnIgst;
			} else if (productDetails.prodcgst) {
				totalreturnGst =
					(productDetails.amount - productDetails.nontaxableAmount) / 2;
				totalPurchaseReturnGst += totalreturnGst;
			}
		}
	}

	let fixedAssetIgst = 0;
	let totalFixedAssetIgst = 0;
	let fixedAssetgst = 0;
	let totalFixedAssetgst = 0;
	let totalPurchaseFA = 0;
	for (let i = 0; i < purchasefixedAsset.length; i++) {
		totalPurchaseFA += parseFloat(salesFixedAssetTable[i]?.toatalAmount || 0);

		const assetDetail = purchasefixedAsset[i];
		for (let j = 0; j < assetDetail.asset.length; j++) {
			const assetTax = assetDetail.asset[j];
			if (assetTax.igst) {
				fixedAssetIgst = assetTax.amount - assetTax.nontaxableAmount;
				totalFixedAssetIgst += fixedAssetIgst;
			} else if (assetTax.gst) {
				fixedAssetgst = assetTax.amount - assetTax.nontaxableAmount / 2;
				totalFixedAssetgst += fixedAssetgst;
			}
		}
	}
	//temprory expenses

	let totalSalesTax = 0;
	let totalSaleIgst = 0;
	let totalSaleGst = 0;
	let totalSaleGstTax = 0;
	let totalSles = 0;
	for (let i = 0; i < mergedArray?.length; i++) {
		const saleDetails = mergedArray[i];
		totalSles += parseFloat(mergedArray[i]?.toatalAmount);
		for (let j = 0; j < saleDetails.product.length; j++) {
			const productDetails = saleDetails.product[j];

			if (productDetails.igstAmount) {
				totalSalesTax = productDetails.amount - productDetails.nontaxableAmount;
				totalSaleIgst += totalSalesTax;
			} else if (productDetails.sgst) {
				totalSaleGstTax =
					(productDetails.amount - productDetails.nontaxableAmount) / 2;
				totalSaleGst += totalSaleGstTax;
			}
		}
	}

	let totalSalesReturnTax = 0;
	let totalSalereturnIgst = 0;
	let totalsalreturnGst = 0;
	let totalSalereReturnGst = 0;
	let totalsalesReturn = 0;
	for (let i = 0; i < salesReturnDetails?.length; i++) {
		const salereDetails = salesReturnDetails[i];
		totalsalesReturn += parseFloat(salesReturnDetails[i]?.toatalAmount);

		for (let j = 0; j < salereDetails.product.length; j++) {
			const productDetails = salereDetails.product[j];

			if (productDetails.igstAmount) {
				totalSalesReturnTax =
					productDetails.amount - productDetails.nontaxableAmount;
				totalSalereturnIgst += totalSalesReturnTax;
			} else if (productDetails.prodcgst) {
				totalsalreturnGst =
					(productDetails.amount - productDetails.nontaxableAmount) / 2;
				totalSalereReturnGst += totalsalreturnGst;
			}
		}
	}

	let fixedsaleAssetIgst = 0;
	let totalSaleFixedAssetIgst = 0;
	let fixedsaleAssetgst = 0;
	let totalSaleFixedAssetgst = 0;
	let totalSalesFA = 0;
	for (let i = 0; i < salesFixedAssetTable.length; i++) {
		totalSalesFA += parseFloat(salesFixedAssetTable[i]?.toatalAmount);

		const assetDetail = salesFixedAssetTable[i];
		for (let j = 0; j < assetDetail.asset.length; j++) {
			const assetTax = assetDetail.asset[j];
			if (assetTax.igst) {
				fixedsaleAssetIgst = assetTax.amount - assetTax.nontaxableAmount;
				totalSaleFixedAssetIgst += fixedsaleAssetIgst;
			} else if (assetTax.gst) {
				fixedsaleAssetgst = assetTax.amount - assetTax.nontaxableAmount / 2;
				totalSaleFixedAssetgst += fixedsaleAssetgst;
			}
		}
	}

	let totalExpenses = 0;
	for (let i = 0; i < expenseDetails.length; i++) {
		totalExpenses += parseFloat(expenseDetails[i]?.toatalAmount);
	}

	let tempExpenses = 0;
	const totalIgstAmt =
		totalPurchaseTax -
		totalPurchaseReturnTax +
		totalFixedAssetIgst +
		tempExpenses;

	const totalGstAmt =
		totalPurchaceGst -
		totalPurchaseReturnGst +
		totalFixedAssetgst +
		tempExpenses;
	const inputGst = totalIgstAmt + totalGstAmt * 2;
	const inputDutiesIgstSales =
		totalSaleIgst - totalSalereturnIgst + totalSaleFixedAssetIgst;
	const inputDutiesGstSales =
		totalSaleGst - totalSalereReturnGst + totalSaleFixedAssetgst;
	const totalinputDutiesforsale =
		inputDutiesIgstSales + inputDutiesGstSales * 2;

	let totalamtsales = parseFloat((totalSles - totalsalesReturn).toFixed(2));
	let totalpuramt = parseFloat(
		(totalPurchase - totalPurchaseReturn).toFixed(2)
	);

	let fixedassetDetails = parseFloat(
		(totalSalesFA - totalPurchaseFA).toFixed(2)
	);
	console.log("totalSalesFA", totalSalesFA);
	console.log("totalPurchaseFA", totalPurchaseFA);
	let saleminuspur = totalamtsales - totalpuramt;

	let addallfixed = fixedassetDetails + saleminuspur;
	console.log("fixedassetDetails ", fixedassetDetails);
	console.log("addallfixed", addallfixed);
	console.log("saleminuspur", saleminuspur);

	let totalincome = parseFloat((addallfixed - totalExpenses).toFixed(2));

	let totalcredits = sundryCreditors
		.reduce((total, [, balanceAmount]) => total + balanceAmount, 0)
		.toFixed(2);
	let totalDebtors = sundryDebtors
		.reduce((total, [, balanceAmount]) => total + balanceAmount, 0)
		.toFixed(2);

	let currentLiabilities = parseFloat(
		(
			Number(totalcredits) +
			Number(totalinputDutiesforsale) +
			Number(otherwithCurrentAsset)
		).toFixed(2)
	);

	let currentAssetss = parseFloat(
		(
			Number(totalDebtors) +
			Number(inputGst) +
			Number(stockamount) +
			Number(cashinHand) +
			Number(otherCurrentAsset)
		).toFixed(2)
	);
	console.log("currentAssetss", currentAssetss);
	// let neeeetIncome =
	// 	parseFloat(overallrevenue.toFixed(2)) -
	// 	parseFloat(recentExpenseTotal.toFixed(2));
	// let neeeetIncome = neeeetnncome - currentAssetss;
	let neeeetIncome =
		parseFloat(currentAssetss.toFixed(2)) -
		parseFloat(currentLiabilities.toFixed(2));
	let retainedEarningss = neeeetIncome + 0; //for reference net income

	let capitalAccount = ownersEquity + retainedEarningss;
	let totalLiablities = currentLiabilities + capitalAccount;

	// let totalAssets =
	// 	parseFloat(currentAssetss.toFixed(2)) +
	// 	parseFloat(fixedassetamount.toFixed(2));

	let totalAssets = parseFloat(
		(Number(currentAssetss) + Number(fixedassetamount)).toFixed(2)
	);
	return (
		<div className="font-poppins">
			<div className="text-[#008065] font-bold">
				<div className="flex flex-col ">
					<span className="font-bold text-[#045545] text-xl items-center mt-4 sm:mt-0 flex justify-center">
						{companyLists.map((company, index) => (
							<div key={index}>{company.company_name}</div>
						))}
					</span>
					<span className="font-bold text-[#045545] text-xl mt-1 px-2 flex justify-center sm:justify-start">
						{" "}
						BalanceSheet{" "}
					</span>
				</div>

				<div className="sm:p-3 text-[#045545]">
					<div className="flex flex-col lg:flex-row lg:items-center sm:space-x-4">

						{/* Period Dropdown */}
						<div className="flex flex-col sm:flex-row sm:space-x-3">
							<div className="xl:pl-20 sm:pl-10 mt-4 sm:mt-0 flex items-center justify-center">
								<label className="text-[16px] mr-3">Period: </label>
								<select
									className="border rounded-md p-1.5 text-gray-700 bg-[#F1F7F6]"
									value={selectedPeriod}
									onChange={(e) => handlePeriodChange(e.target.value)}
								>
									<option value="All">All</option>
									<option value="This Month">This Month</option>
									<option value="Last Month">Last Month</option>
									<option value="This Quarter">This Quarter</option>
									<option value="This Year">This Year</option>
									<option value="Custom">Custom</option>
								</select>
							</div>
							{/* Date Range Display */}
							<div className="flex items-center mt-4 sm:mt-0 w-full sm:w-[230px] justify-center sm:pl-8 ">
								{selectedPeriod === "Custom" ? (
									<>
										<DatePicker
											selected={startDate}
											onChange={(date) => {
												setStartDate(date);
												setSelectedPeriod("Custom");
											}}
											selectsStart
											startDate={startDate}
											endDate={endDate}
											placeholderText="Start Date"
											className=" text-gray-700 w-[110px] p-1.5 rounded-md bg-[#F1F7F6]  text-center"
											dateFormat="dd/MM/yyyy"
										/>
										
										<span className="mx-2">to</span>
										<DatePicker
											selected={endDate}
											onChange={(date) => {
												setEndDate(date);
												setSelectedPeriod("Custom");
											}}
											selectsEnd
											startDate={startDate}
											endDate={endDate}
											placeholderText="End Date"
											className=" text-gray-700 w-[110px] p-1.5 rounded-md bg-[#F1F7F6]  text-center"
											dateFormat="dd/MM/yyyy"
										/>
									</>
								) : (
									<span>
									{/* <span className="p-1 px-4 bg-white rounded-md"> */}
										{/* {startDate && endDate
											? `${formatDate(startDate)} To ${formatDate(endDate)}`
											: "Select a period"} */}
									</span>
								)
								}
							</div>
						</div>
					</div>
				</div>

				<div className="sm:pl-4 px-2 mt-3">
					<div>
						<span>
							Balance Sheet as On <span> {formattedDate}</span>{" "}
						</span>
					</div>
				</div>

				<div className="mt-3 ">

					<div className="  flex  flex-col xl:flex-row gap-3  px-2 ">

				{/* col - 1 Changes */}
					<div className="w-[100%] xl:w-[50%] bg-[#F1F7F6] mt-4 px-2 py-2 shadow-lg rounded-md min-h-[58vh]  relative">
							<h2 className="text-lg font-bold mb-4">Assets</h2>
							
							<div className=" ">
							<StyledDiv ref={scrollRef1}>
								<table className="w-full whitespace-nowrap table-auto border-collapse border border-[#008065]">
									{/* Table Header */}
									<thead className="sticky top-[-1px]">
										<tr className="bg-gray-300">
											<th className="border border-gray-300 px-4 py-2 text-left">Account</th>
											<th className="border border-gray-300 px-4 py-2 text-right">Amount</th>
										</tr>
									</thead>
									{/* Table Body */}
									<tbody className="mt-4">
										{/* Fixed Assets  */} 
										<tr className="hover:bg-gray-200">
											<td className="border border-gray-300 px-4 py-2 ">
												<button
													type="button"
													onClick={(e) => toggleRow('FixedAssetsOpen', e,1)}
													
													className="flex items-center"
												>
													{openRows.includes('FixedAssetsOpen') ? <FaChevronDown /> : <FaChevronRight />}
													<span className="ml-2 !font-semibold">Fixed Assets</span>
												</button>
											</td>
											<td className="border border-gray-300 px-4 py-2 text-right ">
												{fixedassetamount.toFixed(2)}
											</td>
										</tr>
										{/* {isFixedAssetsOpen && */}
										{openRows.includes('FixedAssetsOpen') &&
											fixdassetData.map((data, index) => (
												<tr key={index} className="text-neutral-700">
													<td className="border border-gray-300 px-4 py-2 pl-10 ">{data.asset_name}</td>
													<td className="border border-gray-300 px-4 py-2 text-right ">{data.total}</td>
												</tr>
											))}

										{/* Current Assets */}
										<tr className="hover:bg-gray-200">
											<td className="border border-gray-300 px-4 py-2">
												<button
													type="button"
													onClick={(e) => toggleRow('CurrentAssetsOpen', e,1)}
													
													className="flex items-center"
												>
													{openRows.includes('CurrentAssetsOpen') ? <FaChevronDown /> : <FaChevronRight />}
													<span className="ml-2">Current Assets</span>
												</button>
											</td>
											<td className="border border-gray-300 px-4 py-2 text-right">{currentAssetss}</td>
										</tr>
										{openRows.includes('CurrentAssetsOpen') && (
											<>
												{/* Sundry Debtors */}
												<tr>
													<td className="border border-gray-300 px-4 py-2 pl-10 ">
														<button
															type="button"
															onClick={(e) => toggleRow('SundryDebtorsOpen', e,1)}
															
															className="flex items-center"
														>
															{openRows.includes('SundryDebtorsOpen') ? <FaChevronDown /> : <FaChevronRight />}
															<span className="ml-2">Sundry Debtors</span>

														</button>
													</td>
													<td className="border border-gray-300 px-4 py-2 text-right">{totalDebtors}</td>
												</tr>
												{openRows.includes('SundryDebtorsOpen') && 
													sundryDebtors.map(([clientName, balanceAmount], index) => (
														<tr key={index} className="text-neutral-700">
															<td className="border border-gray-300 px-4 py-2 pl-10">{clientName}</td>
															<td className="border border-gray-300 px-4 py-2 text-right">{balanceAmount.toFixed(2)}</td>
														</tr>
													))}

												{/* Input Duties and Taxes */}

												<tr>
													<td className="border border-gray-300 px-4 py-2 pl-10">
														<button
															type="button"
															onClick={(e) => toggleRow('InputTaxesOpen', e,1)}
															
															className="flex items-center"
														>
															{openRows.includes('InputTaxesOpen') ? <FaChevronDown /> : <FaChevronRight />}
															<span className="ml-2">Input Duties and Taxes</span>

														</button>
													</td>
													<td className="border border-gray-300 px-4 py-2 text-right">{inputGst.toFixed(2)}</td>
												</tr>
												{openRows.includes('InputTaxesOpen') && (
													<>
														{/* Input GST */}
														<tr>
															<td className="border border-gray-300 px-4 py-2 pl-12">
																<button
																	type="button"
																	onClick={(e) => toggleRow('InputGSTOpen', e,1)}
																	className="flex items-center"
																>
																	{openRows.includes('InputGSTOpen') ? <FaChevronDown /> : <FaChevronRight />}
																	<span className="ml-2">Input GST</span>

																</button>
															</td>
															<td className="border border-gray-300 px-4 py-2 text-right">{inputGst.toFixed(2)}</td>
														</tr>
														{/* {isInputGSTOpen && ( */}
														{openRows.includes('InputGSTOpen') && (
															<>
																<tr className="text-neutral-700">
																	<td className="border border-gray-300 px-4 py-2 pl-14">CGST</td>
																	<td className="border border-gray-300 px-4 py-2 text-right">{totalGstAmt.toFixed(2)}</td>
																</tr>
																<tr className="text-neutral-700">
																	<td className="border border-gray-300 px-4 py-2 pl-14">SGST</td>
																	<td className="border border-gray-300 px-4 py-2 text-right">{totalGstAmt.toFixed(2)}</td>
																</tr>
																<tr className="text-neutral-700">
																	<td className="border border-gray-300 px-4 py-2 pl-14">IGST</td>
																	<td className="border border-gray-300 px-4 py-2 text-right">{totalIgstAmt.toFixed(2)}</td>
																</tr>
															</>
														)}
													</>
												)}

												{/* Stock in Hand */}

												<tr>
													<td className="border border-gray-300 px-4 py-2 pl-10">Stock-in-Hand</td>
													<td className="border border-gray-300 px-4 py-2 text-right">{stockamount.toFixed(2)}</td>
												</tr>


												{/* Bank Accounts */}
												{/* <tr>
										<td className="border border-gray-300 px-4 py-2">
											<button
												type="button"
												onClick={(e) => toggleRow('BankAccountsOpen', e,1)}
												className="flex items-center"
											>
												{openRows.includes('BankAccountsOpen') ? <FaChevronDown /> : <FaChevronRight />}
												<span className="ml-2">Bank Accounts</span>

											</button>
										</td>
										<td className="border border-gray-300 px-4 py-2 text-right">{totalOpeningAmt.toFixed(2)}</td>
									</tr>
									//{isBankAccountsOpen &&
									{openRows.includes('BankAccountsOpen') && 
										openingBankAccount.map((item: { bankname: string; openingamt: number }, index: number) => (
											<tr key={index} >
												<td className="border border-gray-300 px-4 py-2 pl-10">{item.bankname}</td>
												<td className="border border-gray-300 px-4 py-2 text-right">{item.openingamt.toFixed(2)}</td>
											</tr>
										))} */}

												{/* Cash Accounts */}
												<tr>
													<td className="border border-gray-300 px-4 py-2">
														<button
															type="button"
															onClick={(e) => toggleRow('CashAccountsOpen', e,1)}
															className="flex items-center"
														>
															{openRows.includes('CashAccountsOpen') ? <FaChevronDown /> : <FaChevronRight />}
															<span className="ml-2">Cash Accounts</span>

														</button>
													</td>
													<td className="border border-gray-300 px-4 py-2 text-right">{cashinHand.toFixed(2)}</td>
												</tr>
												{/* {isCashAccountsOpen && ( */}
												{openRows.includes('CashAccountsOpen') && (
													<tr className="text-neutral-700">
														<td className="border border-gray-300 px-4 py-2 pl-10">Cash</td>
														<td className="border border-gray-300 px-4 py-2 text-right">{cashinHand.toFixed(2)}</td>
													</tr>
												)}

												{/* Other Current Assets */}
												<tr>
													<td className="border border-gray-300 px-4 py-2">
														<button
															type="button"
															onClick={(e) => toggleRow('ChequeAccountsOpen', e,1)}
															
															className="flex items-center"
														>
																{openRows.includes('ChequeAccountsOpen') ? <FaChevronDown /> : <FaChevronRight />}
															<span className="ml-2">Other Current Assets</span>

														</button>
													</td>
													<td className="border border-gray-300 px-4 py-2 text-right">{otherCurrentAsset.toFixed(2)}</td>
												</tr>
												{openRows.includes('ChequeAccountsOpen') && (
													<tr className="text-neutral-700">
														<td className="border border-gray-300 px-4 py-2 pl-10">Undeposited Cheque</td>
														<td className="border border-gray-300 px-4 py-2 text-right">{otherCurrentAsset.toFixed(2)}</td>
													</tr>
												)}
											</>
										)}

									</tbody>
								</table>
							</StyledDiv>
							</div>
							
								{/* Total Assets */}
							<div className="absolute bottom-1 w-full pr-4">
								<div className=" mt-6 font-bold bg-gray-300 py-2 px-4 flex justify-between overflow-x-auto">
									<span >Total Assets</span>
									<span >{totalAssets.toFixed(2)} </span>
								</div>
							</div>
						</div>
				{/* col - 2 */}
						<div className="w-[100%] xl:w-[50%] bg-[#F1F7F6] mt-4 px-2 py-2 shadow-lg rounded-md min-h-[58vh] relative">
							<h2 className="text-lg font-bold mb-4">Equities & Liabilities</h2>
							<div className="">
							<StyledDiv ref={scrollRef2}>
								<table className="w-full whitespace-nowrap table-auto border-collapse border border-[#008065]">
									{/* Table Header */}
									<thead className="sticky top-[-1px]">
										<tr className="bg-gray-300">
											<th className="border border-gray-300 px-4 py-2 text-left">Account</th>
											<th className="border border-gray-300 px-4 py-2 text-right">Amount</th>
										</tr>
									</thead>
									{/* Table Body */}
									<tbody>
										{/* Capital Account */}
										<tr className="hover:bg-gray-200">
											<td className="border border-gray-300 px-4 py-2">
												<button
													type="button"
													onClick={(e) => toggleRow('CapitalAccountOpen', e, 2)}
													className="flex items-center"
												>
													{openRows.includes('CapitalAccountOpen') ? <FaChevronDown /> : <FaChevronRight />}
													<span className="ml-2">Capital Account</span>
												</button>
											</td>
											<td className="border border-gray-300 px-4 py-2 text-right">
												{capitalAccount.toFixed(2)}
											</td>
										</tr>
										{openRows.includes('CapitalAccountOpen') && (
											<>
												{/* Owner's Equity */}
												<tr>
													<td className="border border-gray-300 px-4 py-2 pl-10">
														<button
															type="button"
															onClick={(e) => toggleRow('OwnersEquityOpen', e, 2)}
															className="flex items-center"
														>
															{openRows.includes('OwnersEquityOpen') ? <FaChevronDown /> : <FaChevronRight />}
															<span className="ml-2">Owner's Equity</span>
														</button>
													</td>
													<td className="border border-gray-300 px-4 py-2 text-right">
														{ownersEquity.toFixed(2)}
													</td>
												</tr>
												{openRows.includes('OwnersEquityOpen') && (
													<>
														{/* Opening Balance Equity */}
														<tr>
															<td className="border border-gray-300 px-4 py-2 pl-12">
																<button
																	type="button"
																	onClick={(e) => toggleRow('OpeningBalanceEquityOpen', e, 2)}
																	className="flex items-center"
																>
																	{openRows.includes('OpeningBalanceEquityOpen') ? <FaChevronDown /> : <FaChevronRight />}
																	<span className="ml-2">Opening Balance Equity</span>
																</button>
															</td>
															<td className="border border-gray-300 px-4 py-2 text-right">
																{openingBalanceEquity.toFixed(2)}
															</td>
														</tr>
														{openRows.includes('OpeningBalanceEquityOpen') && (
															<>
																<tr className="text-neutral-700">
																	<td className="border border-gray-300 px-4 py-2 pl-16">
																		Fixed Asset Opening Balance
																	</td>
																	<td className="border border-gray-300 px-4 py-2 text-right">
																		{fixedassetamount.toFixed(2)}
																	</td>
																</tr>
																<tr className="text-neutral-700">
																	<td className="border border-gray-300 px-4 py-2 pl-16">
																		Opening Bank Balance
																	</td>
																	<td className="border border-gray-300 px-4 py-2 text-right">
																		{Number(totalOpeningAmt).toFixed(2)}
																	</td>
																</tr>
															</>
														)}

														{/* Cash Adjustment */}
														<tr>
															<td className="border border-gray-300 px-4 py-2 pl-12">
																<button
																	type="button"
																	onClick={(e) => toggleRow('CashAdjustmentOpen', e, 2)}
																	className="flex items-center"
																> 
																	{openRows.includes('CashAdjustmentOpen') ? <FaChevronDown /> : <FaChevronRight />}
																	<span className="ml-2">Cash Adjustment</span>
																</button>
															</td>
															<td className="border border-gray-300 px-4 py-2 text-right">
																{cashAdjustment.toFixed(2)}
															</td>
														</tr>
														{openRows.includes('CashAdjustmentOpen') && (
															<>
																<tr className="text-neutral-700">
																	<td className="border border-gray-300 px-4 py-2 pl-16">
																		Add Cash
																	</td>
																	<td className="border border-gray-300 px-4 py-2 text-right">
																		{cashAdding.toFixed(2)}
																	</td>
																</tr>
																<tr className="text-neutral-700">
																	<td className="border border-gray-300 px-4 py-2 pl-16">
																		Remove Cash
																	</td>
																	<td className="border border-gray-300 px-4 py-2 text-right">
																		{cashReducing.toFixed(2)}
																	</td>
																</tr>
															</>
														)}
													</>
												)}

												{/* Reserve & Surplus */}
												<tr>
													<td className="border border-gray-300 px-4 py-2 pl-10">
														<button
															type="button"
															onClick={(e) => toggleRow('ReserveSurplusOpen', e, 2)}
															className="flex items-center"
														>
															{openRows.includes('ReserveSurplusOpen') ? <FaChevronDown /> : <FaChevronRight />}
															<span className="ml-2">Reserve & Surplus</span>
														</button>
													</td>
													<td className="border border-gray-300 px-4 py-2 text-right">
														{retainedEarningss.toFixed(2)}
													</td>
												</tr>
												{openRows.includes('ReserveSurplusOpen') && (
													<>
														<tr className="text-neutral-700">
															<td className="border border-gray-300 px-4 py-2 pl-14">
																Retained Earnings
															</td>
															<td className="border border-gray-300 px-4 py-2 text-right">
															0.00
															</td>
														</tr>
														<tr className="text-neutral-700">
															<td className="border border-gray-300 px-4 py-2 pl-14">
																Net Income (Profit)
															</td>
															<td className="border border-gray-300 px-4 py-2 text-right">
																{/* {totalincome} */}
																{neeeetIncome.toFixed(2)}
															</td>
														</tr>
													</>
												)}
											</>
										)}

										{/* Long-term Liabilities */}
										<tr>
											<td className="border border-gray-300 px-4 py-2">Long-term Liabilities</td>
											<td className="border border-gray-300 px-4 py-2 text-right">0</td>
										</tr>

										{/* Current Liabilities */}
										<tr className="hover:bg-gray-200">
											<td className="border border-gray-300 px-4 py-2">
												<button
													type="button"
													onClick={(e) => toggleRow('CurrentLiabilitiesOpen', e, 2)}
													className="flex items-center"
												>
													{openRows.includes('CurrentLiabilitiesOpen') ? <FaChevronDown /> : <FaChevronRight />}
													<span className="ml-2">Current Liabilities</span>
												</button>
											</td>
											<td className="border border-gray-300 px-4 py-2 text-right">
												{currentLiabilities}
											</td>
										</tr>
										{openRows.includes('CurrentLiabilitiesOpen') && (
											<>
												{/* Sundry Creditors */}
												<tr>
													<td className="border border-gray-300 px-4 py-2 pl-10">
														<button
															type="button"
															onClick={(e) => toggleRow('SundryCreditorsOpen', e, 2)}
															className="flex items-center"
														>
															{openRows.includes('SundryCreditorsOpen') ? <FaChevronDown /> : <FaChevronRight />}
															<span className="ml-2">Sundry Creditors</span>
														</button>
													</td>
													<td className="border border-gray-300 px-4 py-2 text-right">
														{totalcredits}
													</td>
												</tr>
												{openRows.includes('SundryCreditorsOpen') && (
													<>
														{sundryCreditors.map(([vendorName, balanceAmount], index) => (
															<tr key={index} className="text-neutral-700">
																<td className="border border-gray-300 px-4 py-2 pl-12">
																	{vendorName}
																</td>
																<td className="border border-gray-300 px-4 py-2 text-right">
																	{balanceAmount.toFixed(2)}
																</td>
															</tr>
														))}
													</>
												)}

												{/* Outward Duties & Taxes */}
												<tr>
													<td className="border border-gray-300 px-4 py-2 pl-10">
														<button
															type="button"
															onClick={(e) => toggleRow('OutwardTaxesOpen', e, 2)}
															className="flex items-center"
														>
															{openRows.includes('OutwardTaxesOpen') ? <FaChevronDown /> : <FaChevronRight />}
															<span className="ml-2">Outward Duties & Taxes</span>
														</button>
													</td>
													<td className="border border-gray-300 px-4 py-2 text-right">
														{totalinputDutiesforsale.toFixed(2)}
													</td>
												</tr>
												{openRows.includes('OutwardTaxesOpen') && (
													<>
														{/* Output GST */}
														<tr>
															<td className="border border-gray-300 px-4 py-2 pl-12">
																<button
																	type="button"
																	onClick={(e) => toggleRow('OutputGSTOpen', e, 2)}
																	className="flex items-center"
																>
																	{openRows.includes('OutputGSTOpen') ? <FaChevronDown /> : <FaChevronRight />}
																	<span className="ml-2">Output GST</span>
																</button>
															</td>
															<td className="border border-gray-300 px-4 py-2 text-right">
																{totalinputDutiesforsale.toFixed(2)}
															</td>
														</tr>
														{openRows.includes('OutputGSTOpen') && (
															<>
																<tr className="text-neutral-700">
																	<td className="border border-gray-300 px-4 py-2 pl-16">Output CGST</td>
																	<td className="border border-gray-300 px-4 py-2 text-right">
																		{inputDutiesGstSales.toFixed(2)}
																	</td>
																</tr>
																<tr className="text-neutral-700">
																	<td className="border border-gray-300 px-4 py-2 pl-16">Output SGST</td>
																	<td className="border border-gray-300 px-4 py-2 text-right">
																		{inputDutiesGstSales.toFixed(2)}
																	</td>
																</tr>
																<tr className="text-neutral-700">
																	<td className="border border-gray-300 px-4 py-2 pl-16">Output IGST</td>
																	<td className="border border-gray-300 px-4 py-2 text-right">
																		{inputDutiesIgstSales.toFixed(2)}
																	</td>
																</tr>
															</>
														)}
													</>
												)}

												{/* Other Current Liabilities */}
												<tr>
													<td className="border border-gray-300 px-4 py-2 pl-10">
														<button
															type="button"
															onClick={(e) => toggleRow('OtherCurrentLiabilitiesOpen', e, 2)}
															className="flex items-center"
														>
															{openRows.includes('OtherCurrentLiabilitiesOpen') ? <FaChevronDown /> : <FaChevronRight />}
															<span className="ml-2">Other Current Liabilities</span>
														</button>
													</td>
													<td className="border border-gray-300 px-4 py-2 text-right">
														{otherwithCurrentAsset.toFixed(2)}
													</td>
												</tr>
												{openRows.includes('OtherCurrentLiabilitiesOpen') && (
													<>
														<tr className="text-neutral-700">
															<td className="border border-gray-300 px-4 py-2 pl-10">Unwithdrawn Checks</td>
															<td className="border border-gray-300 px-4 py-2 text-right">
																{otherwithCurrentAsset.toFixed(2)}
															</td>
														</tr>
													</>
												)}
											</>
										)}
									</tbody>
								</table>
							</StyledDiv>
						
							</div>
							<div className="absolute bottom-1 w-full pr-4">
								<div className="mt-6 font-bold bg-gray-300  py-2 px-4 flex justify-between">
									<span>Total Liabilities</span>
									<span>{`${currencySymbol}${totalLiablities.toFixed(2)}`}</span>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>

		</div>
	);
};

export default BalanceSheet;
