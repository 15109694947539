import { styled } from '@mui/system';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
const Profit = () => {
    const [openRows, setOpenRows] = useState<string[]>([]);
    const scrollRef = useRef<HTMLDivElement>(null);
    const scrollPositionRef = useRef<number>(0); // Store scroll position

    const toggleRow = (rowName: string, event: any) => {
        // Save current scroll position
        if (scrollRef.current) {
            scrollPositionRef.current = scrollRef.current.scrollTop;
        }
        // Update state for openRows
        if (openRows.includes(rowName)) {
            setOpenRows(openRows.filter((name) => name !== rowName));
        } else {
            setOpenRows([...openRows, rowName]);
        }
        // Prevent default scroll behavior
        event.preventDefault();
        event.stopPropagation();
    };

    // Restore scroll position after state changes
    useLayoutEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollPositionRef.current;
        }
    }, [openRows]);


    const StyledDiv = styled("div")({
        position: "relative",
        marginRight: "0.5rem",
        marginTop: "",
        overflowY: "auto",
        overflowX: "auto",
        height: "65vh",
        "@media (min-width: 2000px)": {
            height: "900px",
        },
        "&::-webkit-scrollbar": {
            width: "5px",
            height: "5px",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "text-emerald-200",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#008065",
            borderRadius: "4px"
        },
    });

    return (
        <div className='ml-2 rounded-lg font-semibold text-[#008065] font-poppins'>
            {/* DateFormat */}
            <div className='mr-2'>
                <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 sm:space-x-4 w-full max-w-[500px] justify-center p-4 bg-gray-200 rounded-md shadow-[0_2px_5px_0px_rgba(0,0,0,0.1),0_2px_5px_0px_rgba(0,0,0,0.1)] mb-2">
                    <div className="flex flex-row items-center w-full sm:space-x-2">
                        <label className="text-sm sm:text-base w-1/3 sm:w-auto text-center sm:text-left">From:</label>
                        <input
                            type="date"
                            className="border-2 border-[#008065] bg-gray-200  px-3 py-1 rounded-md text-sm sm:text-base w-full sm:flex-grow focus:outline-none"
                        />

                    </div>
                    <div className="flex flex-row items-center w-full sm:space-x-2">
                        <label className="text-sm sm:text-base w-1/3 sm:w-auto text-center sm:text-left">To:</label>
                        <input
                            type="date"
                            className="border-2 border-[#008065] bg-gray-200  px-3 py-1 rounded-md text-sm sm:text-base w-full sm:flex-grow focus:outline-none"
                        />
                    </div>
                </div>
            </div>
            {/* Header Section */}
            <div className="flex  items-center w-full">
                <h1 className="text-base sm:text-lg md:text-xl font-semibold my-4 text-center sm:text-left whitespace-nowrap text-[#008065]">PROFIT AND LOSS REPORT</h1>
            </div>


            {/* table starts */}
            <StyledDiv ref={scrollRef}>
                <div className='rounded-md'>
                    <div className="">
                        <table className="w-full border-separate border-spacing-x-0 border-spacing">
                            {/* parti and amt section */}
                            <thead className="sticky top-0 w-full bg-gray-300 z-1000 ">
                                <th className="text-left whitespace-nowrap text-[#008065] w-full px-2 py-3 rounded-l-md font-semibold text-[#008065]">Particular</th>
                                <th className="text-right whitespace-nowrap text-[#008065] w-full px-2 py-3 rounded-r-md font-semibold text-[#008065]">Amount</th>
                            </thead>
                            <tr>
                                <td></td>
                            </tr>
                            <tbody className="">
                                {/* Income Row */}
                                <tr
                                    className="group hover:bg-gray-300 cursor-pointer"
                                    onClick={(e) => toggleRow('income', e)}
                                >
                                    <td colSpan={100} className="rounded-md ">
                                        <div className="custom-content flex flex-row items-center p-2">
                                            {openRows.includes('income') ? (
                                                <FaChevronDown className="mr-2" />
                                            ) : (
                                                <FaChevronRight className="mr-2" />
                                            )}
                                            <span className="whitespace-nowrap font-semibold text-[#008065]">Incomes</span>
                                        </div>
                                    </td>
                                </tr>

                                {/* Income Dropdown Rows */}
                                {openRows.includes('income') && (
                                    <>
                                        <tr
                                            className="cursor-pointer items-center "
                                            onClick={(e) => toggleRow('Sale', e)}
                                        >
                                            <td className="text-left pl-8 flex items-center">
                                                <div>
                                                    {openRows.includes('Sale') ? (
                                                        <FaChevronDown className="mr-2" />
                                                    ) : (
                                                        <FaChevronRight className="mr-2" />
                                                    )}
                                                </div>

                                                <span className='whitespace-nowrap py-2 py-2'>Sale Accounts</span>
                                            </td>
                                            <td className="text-right  whitespace-nowrap px-2 items-center py-2">₹5000</td>
                                        </tr>
                                        {openRows.includes('Sale') && (
                                            <>
                                                <tr>
                                                    <td className="text-left  pl-16">
                                                        <ul className="list-disc pl-5">
                                                            <li className='py-2  whitespace-nowrap text-neutral-700'>Sale (Revenue)Account</li>
                                                        </ul>
                                                    </td>
                                                    <td className="text-right  whitespace-nowrap text-neutral-700 px-2">₹4000</td>
                                                </tr>

                                                <tr>
                                                    <td className="text-left  pl-16">
                                                        <ul className="list-disc pl-5">
                                                            <li className='py-2  text-neutral-700 whitespace-nowrap'>Additional Charges on Sale</li>
                                                        </ul>
                                                    </td>
                                                    <td className="text-right  whitespace-nowrap text-neutral-700 px-2">₹1000</td>
                                                </tr>

                                            </>
                                        )}

                                        <tr
                                            className="cursor-pointer"
                                            onClick={(e) => toggleRow('Direct', e)}
                                        >
                                            <td className="text-left  pl-8 flex items-center">
                                                <div>
                                                    {openRows.includes('Direct') ? (
                                                        <FaChevronDown className="mr-2" />
                                                    ) : (
                                                        <FaChevronRight className="mr-2" />
                                                    )}
                                                </div>
                                                <span className='whitespace-nowrap py-2'>Other Incomes (Direct)</span>
                                            </td>
                                            <td className="text-right  whitespace-nowrap px-2 items-center">₹2000</td>
                                        </tr>
                                        {openRows.includes('Direct') && (
                                            <>
                                                <tr>
                                                    <td className="text-left  pl-16">
                                                        <ul className="list-disc pl-5">
                                                            <li className='py-2  text-neutral-700 whitespace-nowrap'>Payment-out Discount</li>
                                                        </ul>
                                                    </td>
                                                    <td className="text-right  whitespace-nowrap text-neutral-700 px-2">₹1200</td>
                                                </tr>



                                            </>
                                        )}

                                        <tr
                                            className="cursor-pointer"
                                            onClick={(e) => toggleRow('Indirect', e)}
                                        >
                                            <td className="text-left  pl-8 flex items-center">
                                                <div>
                                                    {openRows.includes('Indirect') ? (
                                                        <FaChevronDown className="mr-2" />
                                                    ) : (
                                                        <FaChevronRight className="mr-2" />
                                                    )}
                                                </div>
                                                <span className='whitespace-nowrap py-2'>Other Incomes (Indirect)</span>
                                            </td>
                                            <td className="text-right  whitespace-nowrap px-2 items-center">₹1500</td>
                                        </tr>
                                        {openRows.includes('Indirect') && (
                                            <>
                                                <tr>
                                                    <td className="text-left  pl-16">
                                                        <ul className="list-disc pl-5">
                                                            <li className='py-2  text-neutral-700 whitespace-nowrap'>Profit on Sale of Assets</li>
                                                        </ul>
                                                    </td>
                                                    <td className="text-right  whitespace-nowrap text-neutral-700 px-2">₹800</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left  pl-16">
                                                        <ul className="list-disc pl-5">
                                                            <li className='py-2  text-neutral-700 whitespace-nowrap'>Appreciation on Assets</li>
                                                        </ul>
                                                    </td>
                                                    <td className="text-right  whitespace-nowrap text-neutral-700 px-2">₹800</td>
                                                </tr>

                                            </>
                                        )}
                                    </>
                                )}

                                {/* Expense Row */}
                                <tr
                                    className="cursor-pointer group hover:bg-gray-300"
                                    onClick={(e) => toggleRow('expense', e)}
                                >
                                    <td colSpan={100} className="rounded-md">
                                        <div className="custom-content flex flex-row items-center p-2">
                                            {openRows.includes('expense') ? (
                                                <FaChevronDown className="mr-2" />
                                            ) : (
                                                <FaChevronRight className="mr-2" />
                                            )}
                                            <span className="whitespace-nowrap font-semibold text-[#008065]">Expenses</span>
                                        </div>
                                    </td>
                                </tr>
                                {/* Expense Dropdown Rows */}
                                {openRows.includes('expense') && (
                                    <>
                                        <tr className="cursor-pointer" onClick={(e) => toggleRow('Expense_Dropdown', e)}>
                                            <td className="text-left  pl-8 flex items-center">
                                                <div>
                                                    {openRows.includes('Expense_Dropdown') ? (
                                                        <FaChevronDown className="mr-2" />
                                                    ) : (
                                                        <FaChevronRight className="mr-2" />
                                                    )}
                                                </div>
                                                <span className='whitespace-nowrap py-2 '>Cost of Goods sold</span>
                                            </td>
                                            <td className="text-right  whitespace-nowrap px-2 items-center">₹1000</td>
                                        </tr>
                                        {openRows.includes('Expense_Dropdown') && (
                                            <>
                                                {/* Purchase Account Dropdown */}
                                                <tr className="cursor-pointer" onClick={(e) => toggleRow('purchase_account', e)}>
                                                    <td className="text-left  pl-16 flex items-center">
                                                        <div>
                                                            {openRows.includes('purchase_account') ? (
                                                                <FaChevronDown className="mr-2" />
                                                            ) : (
                                                                <FaChevronRight className="mr-2" />
                                                            )}
                                                        </div>
                                                        <span className='whitespace-nowrap py-2'>Purchase Account</span>
                                                    </td>
                                                    <td className="text-right  whitespace-nowrap px-2 items-center">₹500</td>
                                                </tr>
                                                {openRows.includes('purchase_account') && (
                                                    <>
                                                        <tr>
                                                            <td className="text-left  pl-24">
                                                                <ul className="list-disc pl-6">
                                                                    <li className='py-2  text-neutral-700 whitespace-nowrap'>Purchases</li>
                                                                </ul>
                                                            </td>
                                                            <td className="text-right  whitespace-nowrap text-neutral-700 px-2">₹250</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left  pl-24">
                                                                <ul className="list-disc pl-6">
                                                                    <li className='py-2  text-neutral-700 whitespace-nowrap'>Additional Charges on Purchase</li>
                                                                </ul>
                                                            </td>
                                                            <td className="text-right  whitespace-nowrap text-neutral-700 px-2">₹250</td>
                                                        </tr>
                                                    </>
                                                )}

                                                {/* Opening Stock */}
                                                <tr>
                                                    <td className="text-left  pl-16">
                                                        <ul className="list-disc pl-5">
                                                            <li className='py-2  text-neutral-700 whitespace-nowrap'>Opening Stock</li>
                                                        </ul>
                                                    </td>
                                                    <td className="text-right  whitespace-nowrap text-neutral-700 px-2">₹800</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left  pl-16">
                                                        <ul className="list-disc pl-5">
                                                            <li className='py-2  text-neutral-700 whitespace-nowrap'>Closing Stock</li>
                                                        </ul>
                                                    </td>
                                                    <td className="text-right  whitespace-nowrap text-neutral-700 px-2">₹800</td>
                                                </tr>
                                            </>
                                        )}

                                        {/* Direct_expenses Dropdown */}
                                        <tr className="cursor-pointer" onClick={(e) => toggleRow('Direct_expenses', e)}>
                                            <td className="text-left  pl-8 flex items-center">
                                                <div>
                                                    {openRows.includes('Direct_expenses') ? (
                                                        <FaChevronDown className="mr-2" />
                                                    ) : (
                                                        <FaChevronRight className="mr-2" />
                                                    )}
                                                </div>
                                                <span className='whitespace-nowrap py-2'>Direct expenses</span>
                                            </td>
                                            <td className="text-right  whitespace-nowrap px-2 items-center">₹300</td>
                                        </tr>
                                        {openRows.includes('Direct_expenses') && (
                                            <>
                                                <tr>
                                                    <td className="text-left  pl-16">
                                                        <ul className="list-disc pl-5">
                                                            <li className='py-2  text-neutral-700 whitespace-nowrap'>Payment-in Discount</li>
                                                        </ul>
                                                    </td>
                                                    <td className="text-right  whitespace-nowrap text-neutral-700 px-2">₹800</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left  pl-16">
                                                        <ul className="list-disc pl-5">
                                                            <li className='py-2  text-neutral-700 whitespace-nowrap'>Manufacturing Expense</li>
                                                        </ul>
                                                    </td>
                                                    <td className="text-right  whitespace-nowrap text-neutral-700 px-2">₹800</td>
                                                </tr>
                                            </>
                                        )}

                                        {/* Indirect_Expenses Dropdown */}
                                        <tr className="cursor-pointer" onClick={(e) => toggleRow('Indirect_Expenses', e)}>
                                            <td className="text-left  pl-8 flex items-center">
                                                <div>
                                                    {openRows.includes('Indirect_Expenses') ? (
                                                        <FaChevronDown className="mr-2" />
                                                    ) : (
                                                        <FaChevronRight className="mr-2" />
                                                    )}
                                                </div>
                                                <span className='whitespace-nowrap py-2'>Indirect_Expenses</span>
                                            </td>
                                            <td className="text-right  whitespace-nowrap px-2 items-center">₹500</td>
                                        </tr>

                                        {openRows.includes('Indirect_Expenses') && (
                                            <>
                                                {/* cost_financing Dropdown */}
                                                <tr className="cursor-pointer" onClick={(e) => toggleRow('cost_financing', e)}>
                                                    <td className="text-left  pl-16 flex items-center">
                                                        <div>
                                                            {openRows.includes('cost_financing') ? (
                                                                <FaChevronDown className="mr-2" />
                                                            ) : (
                                                                <FaChevronRight className="mr-2" />
                                                            )}
                                                        </div>
                                                        <span className='whitespace-nowrap py-2'>Cost of Financing</span>
                                                    </td>
                                                    <td className="text-right  whitespace-nowrap px-2 items-center">₹200</td>
                                                </tr>
                                                {openRows.includes('cost_financing') && (
                                                    <>
                                                        <tr>
                                                            <td className="text-left  pl-24">
                                                                <ul className="list-disc pl-6">
                                                                    <li className='py-2  text-neutral-700 whitespace-nowrap'>Charges on Loan</li>
                                                                </ul>
                                                            </td>
                                                            <td className="text-right  whitespace-nowrap text-neutral-700 px-2">₹50</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left  pl-24">
                                                                <ul className="list-disc pl-6">
                                                                    <li className='py-2  text-neutral-700 whitespace-nowrap'>Processing Fee for Loans</li>
                                                                </ul>
                                                            </td>
                                                            <td className="text-right  whitespace-nowrap text-neutral-700 px-2 items-center">₹50</td>
                                                        </tr>
                                                    </>
                                                )}
                                                <tr>
                                                    <td className="text-left  pl-16">
                                                        <ul className="list-disc pl-5">
                                                            <li className='py-2  text-neutral-700 whitespace-nowrap'>Depreciation on Assets</li>
                                                        </ul>
                                                    </td>
                                                    <td className="text-right  whitespace-nowrap px-2 items-center">₹800</td>
                                                </tr>
                                                {/* Other_Expenses Dropdown */}
                                                <tr className="cursor-pointer" onClick={(e) => toggleRow('Other_Expenses', e)}>
                                                    <td className="text-left  pl-16 flex items-center">
                                                        <div>
                                                            {openRows.includes('Other_Expenses') ? (
                                                                <FaChevronDown className="mr-2" />
                                                            ) : (
                                                                <FaChevronRight className="mr-2" />
                                                            )}
                                                        </div>
                                                        <span className='whitespace-nowrap py-2'>Other_Expenses</span>
                                                    </td>
                                                    <td className="text-right  whitespace-nowrap px-2 items-center">₹200</td>
                                                </tr>
                                                {openRows.includes('Other_Expenses') && (
                                                    <>
                                                        <tr>
                                                            <td className="text-left  pl-24">
                                                                <ul className="list-disc pl-6">
                                                                    <li className='py-2  text-neutral-700 whitespace-nowrap'>Expenses on Purchase of Assets</li>
                                                                </ul>
                                                            </td>
                                                            <td className="text-right  whitespace-nowrap text-neutral-700 px-2">₹500</td>
                                                        </tr>
                                                    </>
                                                )}
                                            </>
                                        )}

                                    </>
                                )}
                            </tbody>
                            <tr>
                                <td></td>
                            </tr>
                            {/* footer */}
                            {/* Net Loss Section */}
                            <tfoot className=" w-full bg-gray-300 pt-4">
                                <tr className="w-full">
                                    <td className="text-left whitespace-nowrap text-[#008065] w-full px-2 py-3 rounded-l-md  font-semibold">Net Loss (Incomes - Expenses):</td>
                                    <td className="text-right whitespace-nowrap text-red-600 w-full px-2 py-3 rounded-r-md  font-semibold">₹3,064.37</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </StyledDiv>
        </div>
    );
};

export default Profit;
