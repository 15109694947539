import { Box, Checkbox } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { fixedAssetAll } from "../../redux/actions/fixedAssetsAction";

function SalesFAModel({ closeModal, handleSelect, assetRows, setAssetRows }: any) {
    const dispatch = useDispatch();
  const [rows, setRows] = useState([] as any);

  const [existingAssettList, setexistingAssettList] = useState([] as any);
  const [selectedAssets, setselectedAssets] = useState([] as any);
  


  const handleOk = () => {
    const selectedAssetsData = selectedAssets.map((id: string) =>
      existingAssettList.find((item: any) => item.id === id)
    );

    handleSelect(selectedAssetsData);
    setAssetRows(selectedAssetsData);
    closeModal();
  };

  const handleCancelClick = () => {
    closeModal();
  };

  useEffect(() => {
    // Fetch fixed asset data and map it to rows
    dispatch(fixedAssetAll() as any)
      .then((res: any) => {
        const formattedRows = res.payload.map((item: any, index: any) => ({
          id: uuidv4(),
          sno: index + 1,
          ...item,
        })).filter((row:any)=> row.qty > 0);
        setRows(formattedRows);
        setexistingAssettList(formattedRows);
      })
      .catch((error: any) => {
        console.error("Error fetching fixed assets:", error);
      });
  }, [dispatch]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedAssets = existingAssettList
        .filter(
          (item: any) =>
            !assetRows.some(
              (value: any) => value.asset_name === item.asset_name
            )
        )
        .map((item: any) => item.id);
      setselectedAssets(newSelectedAssets);
    } else {
      setselectedAssets([]);
    }
  };
  
  const handleSelectProduct = (productId: string) => {
    const selectedRow = existingAssettList.find((item: any) => item.id === productId);
  
    if (
      assetRows.some(
        (value: any) => value.asset_name === selectedRow.asset_name
      )
    ) {
      return;
    }
  
    setselectedAssets((prevSelectedProducts: any) =>
      prevSelectedProducts.includes(productId)
        ? prevSelectedProducts.filter((id: any) => id !== productId)
        : [...prevSelectedProducts, productId]
    );
  };
  

  const columns: GridColDef[] = [
    {
      field: "select",
      headerName: "",
      width: 80,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Checkbox
          indeterminate={
            existingAssettList?.length > 0 &&
            existingAssettList?.length < rows.length
          }
          checked={selectedAssets.length === rows.length}
          onChange={(event) => handleSelectAll(event)}
        />
      ),
      renderCell: (params) => (
        <Checkbox
          onChange={() => handleSelectProduct(params.row.id)}
          checked={selectedAssets.includes(params.row.id)
           &&
          !assetRows.some(
              (value: any) => value.asset_name === params.row.asset_name
          )
            }
          disabled={assetRows.some(
            (value: any) => value.asset_name === params.row.asset_name
          )}
        />
      ),
    },
    { field: "sno", headerName: "S No", width: 120 },
    { field: "asset_name", headerName: "Asset Name", flex: 2, minWidth: 190 },
    { field: "qty", headerName: "qty", flex: 2, minWidth: 190 },
    { field: "totalAmount", headerName: "amount", flex: 2, minWidth: 190 },
  
  ];



  return (
  
    <div>
      <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 mx-2">
        <motion.div
          className="modal-container"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            type: "spring",
            damping: 18,
          }}
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75">
            <motion.div className="relative rounded-lg overflow-hidden bg-[white] text-center shadow-xl transform transition-all my-8 w-11/12 mx-auto">
              <div
                className="bg-[#F1F7F6] p-[8px] m-[5px]"
                style={{ borderRadius: "8px" }}
              >
               
                <Box sx={{ height: "50vh", width: "100%" }}>
                  <DataGrid
                    // autoHeight
                    sx={{
                      ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                      {
                        color: "rgb(0, 128, 101)",
                        fontWeight: 600,
                        fontSize: "14px",
                      },
                      ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                        fontWeight: 600,
                        fontSize: "14px",
                      },
                      ".css-1rtad1": {
                        display: "block !important",
                      },
                      ".css-j2otgh-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-j2otgh-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate":
                      {
                        color: " rgb(0, 128, 101) !important",
                      },
                    }}
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    pageSizeOptions={[5, 10, 25, 50]}
                    disableRowSelectionOnClick
                  />
                </Box>
              </div>
              <div className="bg-[white] px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-[white] text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#008065] text-base font-medium text-[white] hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleOk}
                >
                  OK
                </button>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default SalesFAModel
