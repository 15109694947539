import { useEffect, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SalesgettMethod } from "../redux/actions/SalesAction";
import CashInHandTables from "./CashInHandTables";
import { BanksGet } from "../redux/actions/bankAction";
import { cashInHandGet } from "../redux/actions/CashInHandActions";

const CashInHandTab = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [banklist, setBankList] = useState([] as any);
	const [paymentReceiveTable, setPaymentReceiveTable] = useState([] as any);
	const usersignin = useSelector((state: any) => state.userSignin);
	const { userInfo } = usersignin;
	const userid = userInfo.token._id;
	useEffect(() => {
		const fetchData = () => {
			dispatch(SalesgettMethod() as any).then((response: any) => {});
		};
		dispatch(BanksGet(userid) as any).then((res: any) => {
			if (res) {
				setBankList(res.payload);
			}
		});
		dispatch(cashInHandGet(userid) as any).then((res: any) => {
			if (res) {
				setPaymentReceiveTable(res.payload);
			}
		});
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// *******************sort*********************

	const handleAddPaymentReceive = () => {
		navigate("/app/cashInHandPage");
	};
	const openingBankAccount = banklist.map((item: any) => ({
		bankname: item.bank_name,
		openingamt: Number(item.opening_balance),
	}));
	const totalOpeningAmt = openingBankAccount.reduce(
		(total: any, account: any) => total + account.openingamt,
		0
	);
	console.log("totalOpeningAmt", totalOpeningAmt);

	let cashinHand = totalOpeningAmt;

	for (let i = 0; i < paymentReceiveTable.length; i++) {
		const transaction = paymentReceiveTable[i];
		const amount = parseFloat(transaction.amount);

		if (isNaN(amount)) {
			console.error(
				`Invalid amount for transaction ${i + 1}:`,
				transaction.amount
			);
			continue;
		}

		if (
			transaction.transaction_type === "SALES" ||
			transaction.transaction_type === "PURCHASE RETURN" ||
			transaction.transaction_type === "SALES(FA)" ||
			transaction.transaction_type === "Add Cash" ||
			transaction.transaction_type === "CHEQUE DEPOSIT"
		) {
			cashinHand += amount;
		} else if (
			transaction.transaction_type === "PURCHASE" ||
			transaction.transaction_type === "SALES RETURN" ||
			transaction.transaction_type === "PURCHASE(FA)" ||
			transaction.transaction_type === "EXPENSE" ||
			transaction.transaction_type === "Reduce Cash" ||
			transaction.transaction_type === "CHEQUE WITHDRAW"
		) {
			cashinHand -= amount;
		}
	}
	// Add unique ids to each row

	return (
		<div>
			<div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
				<div className=" p-4 font-bold text-[#008065]  ml-2">
					Cash Adjustment - Report
					<br />
					Cash in Hand-{(cashinHand || 0).toFixed(2)}
				</div>
				<div className="p-4 font-bold text-[#008065]  ml-2">
					<button
						type="button"
						className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-xs h-8 px-2  block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm"
						style={{ backgroundColor: "#008065" }}
						onClick={handleAddPaymentReceive}
					>
						<FiPlusCircle className="h-5 w-5" />
						<span
							className={`flex-1 ml-3 text-left whitespace-nowrap  text-sm font-bold`}
						>
							Cash Adjustment
						</span>
					</button>
				</div>
			</div>
			<div className="mt-1">
				<CashInHandTables />
			</div>
		</div>
	);
};

export default CashInHandTab;
