import { Box, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";

const AppTable = ({ AppSettingList, handleEdit, handleDelete }: any) => {
  const rows = AppSettingList.map((item: any, index: any) => ({
    id: uuidv4(),
    sno: index + 1,
    ...item,
  }));

  const columns: GridColDef[] = [
    { field: "sno", headerName: "S No", width: 120 },
    {
      field: "cgstPercentage",
      headerName: "CGST(%)",
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) => (
        <>{params.row.cgstPercentage ? params.row.cgstPercentage : "N/A"}</>
      ),
    },
    {
      field: "sgstPercentage",
      headerName: "SGST(%)",
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) => (
        <>{params.row.sgstPercentage ? params.row.sgstPercentage : "N/A"}</>
      ),
    },
    {
      field: "igstPercentage",
      headerName: "IGST(%)",
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) => (
        <>{params.row.igstPercentage ? params.row.igstPercentage : "N/A"}</>
      ),
    },
    {
      field: "vatPercentage",
      headerName: "VAT(%)",
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) => (
        <>{params.row.vatPercentage ? params.row.vatPercentage : "N/A"}</>
      ),
    },
    {
      field: "othersPercentage",
      headerName: "Others(%)",
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) => (
        <>{params.row.othersPercentage ? params.row.othersPercentage : "N/A"}</>
      ),
    },
    {
      field: "discountPercentage",
      headerName: "Discount(%)",
      flex: 2,
      minWidth: 170,
      renderCell: (params: any) => (
        <>
          {params.row.discountPercentage
            ? params.row.discountPercentage
            : "N/A"}
        </>
      ),
    },
    {
      field: "extraFees",
      headerName: "Extra Fees",
      flex: 2,
      minWidth: 160,
      renderCell: (params: any) => (
        <>{params.row.extraFees ? params.row.extraFees : "N/A"}</>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      editable: false,
      flex: 2,
      minWidth: 150,
      renderCell: (params) => (
        <>
          <IconButton aria-label="edit" onClick={() => handleEdit(params.row)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-5 h-5 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => {
              handleDelete(params.row);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-5 h-5  cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </IconButton>
        </>
      ),
    },
  ];
  return (
    <div>
      <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 mx-1">
        {/* <TableContainer sx={{ maxHeight: 345, overflowX: "auto", scrollbarWidth: "thin" }}>
                    <Table stickyHeader aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    sx={{ fontSize: "14px", fontWeight: 600, color: "#008065" }}
                                >
                                    S No
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{ fontSize: "14px", fontWeight: 600, color: "#008065" }}
                                >
                                    CGST(%)
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{ fontSize: "14px", fontWeight: 600, color: "#008065" }}
                                >
                                    SGST(%)
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{ fontSize: "14px", fontWeight: 600, color: "#008065" }}
                                >
                                    IGST(%)
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{ fontSize: "14px", fontWeight: 600, color: "#008065" }}
                                >
                                    VAT(%)
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{ fontSize: "14px", fontWeight: 600, color: "#008065" }}
                                >
                                    OTHERS(%)
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{ fontSize: "14px", fontWeight: 600, color: "#008065" }}
                                >
                                    DISCOUNT(%)
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{ fontSize: "14px", fontWeight: 600, color: "#008065" }}
                                >
                                    EXTRAFEES
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{ fontSize: "14px", fontWeight: 600, color: "#008065" }}
                                >
                                    ACTION
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {AppSettingList?.length > 0 &&
                                AppSettingList?.map((appSetting: any, index: any) => (
                                    <StyledTableRow key={index || appSetting?._id?.$oid}>
                                        <StyledTableCell align="left" sx={{ fontSize: "14px", fontWeight: 600 }}>
                                            {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{ fontSize: "14px", fontWeight: 600 }}>
                                            {appSetting.cgstPercentage ? appSetting.cgstPercentage : "N/A"}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{ fontSize: "14px", fontWeight: 600 }}>
                                            {appSetting.sgstPercentage ? appSetting.sgstPercentage : "N/A"}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{ fontSize: "14px", fontWeight: 600 }}>
                                            {appSetting.igstPercentage ? appSetting.igstPercentage : "N/A"}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{ fontSize: "14px", fontWeight: 600 }}>
                                            {appSetting.vatPercentage ? appSetting.vatPercentage : "N/A"}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{ fontSize: "14px", fontWeight: 600 }}>
                                            {appSetting.othersPercentage ? appSetting.othersPercentage : "N/A"}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{ fontSize: "14px", fontWeight: 600 }}>
                                            {appSetting.discountPercentage ? appSetting.discountPercentage : "N/A"}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{ fontSize: "14px", fontWeight: 600 }}>
                                            {appSetting.extraFees ? appSetting.extraFees : "N/A"}
                                        </StyledTableCell>
                                        <StyledTableCell className="px-6 py-2 text-sm font-semibold">
                                            <div className="inline-flex items-center whitespace-no-wrap">
                                                <div className="relative inline-block w-8 h-4 rounded-full cursor-pointer">
                                                    <Menu
                                                        menuButton={
                                                            <MenuButton>
                                                                <div className="bg-gray-50 px-2 rounded-xl">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        className="h-6 w-6 text-blue-400"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="green"
                                                                        strokeWidth={2}
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </MenuButton>
                                                        }
                                                        transition
                                                    >
                                                        <MenuItem
                                                            onClick={() => handleEdit(appSetting)}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth="1.5"
                                                                stroke="#008065"
                                                                className="w-6 h-4 cursor-pointer"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                                                />
                                                            </svg> Edit
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => { handleDelete(appSetting) }}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth="1.5"
                                                                stroke="#008065"
                                                                className="w-6 h-4  cursor-pointer"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                                                />
                                                            </svg> Delete
                                                        </MenuItem>
                                                    </Menu>
                                                </div>
                                            </div>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                    </Table>


                </TableContainer> */}
        <div
          className="bg-[#F1F7F6] p-[8px] m-[5px]"
          style={{ borderRadius: "8px" }}
        >
          <Box
            sx={{
              height: "auto",
              width: "100%",
              maxHeight: "45vh",
              overflow: "auto",
            }}
          >
            <DataGrid
autoHeight
              sx={{
                ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                  {
                    color: "rgb(0, 128, 101)",
                    fontWeight: 600,
                    fontSize: "14px",
                  },
                ".MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--vertical.css-1b9e9gy":
                  { overflowY: "hidden" },
                ".MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor.css-wop1k0-MuiDataGrid-footerContainer":
                  {
                    display: "none",
                  },
                ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                  fontWeight: 600,
                  fontSize: "14px",
                },
                "& .MuiDataGrid-checkboxInput": {
                color: "#008060 !important",
              },
              }}
              rows={rows}
              columns={columns}
              checkboxSelection
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25, 50]}
              disableRowSelectionOnClick
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AppTable;
