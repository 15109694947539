import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	BarElement, // Import BarElement
	Title,
	Tooltip,
} from "chart.js";
import React, { useCallback, useEffect, useState } from "react";

import DashboardBarDia from "./DashboardBarDia";
import DashboardPieDia from "./DashboardPieDia";
import DashboardLineDia from "./DashboardLineDia";
import DashboardDonutDia from "./DashboardDonut";
import { useDispatch, useSelector } from "react-redux";
import { registerAll } from "../../redux/actions/userAction";
import { stockPurchaseGet } from "../../redux/actions/stockAction";
import { BanksGet } from "../../redux/actions/bankAction";
import { currencyGet } from "../../redux/actions/currencyAction";

ChartJS.register(
	CategoryScale,
	LinearScale,
	LineElement,
	PointElement,
	BarElement, // Register BarElement
	Title,
	Tooltip,
	Legend
);
type SelectedRange =
	| { range: string }
	| { range: string; startDate: string; endDate: string };
interface DashboardDiagramsProps {
	selectedRange: SelectedRange;
}
const DashboardDiagrams: React.FC<DashboardDiagramsProps> = ({
	selectedRange,
}) => {
	const dispatch = useDispatch();
	const usersignin = useSelector((state: any) => state.userSignin);
	const token = usersignin?.userInfo?.token;

	const productdetails = useSelector((state: any) => state.stockPurchaseList);
	const { stocklist } = productdetails;

	const [stockvalue, setStockValue] = useState([] as any);
	const [banklist, setBankList] = useState([] as any);
	const [currencySymbol, setcurrencySymbol] = useState("₹");
	useEffect(() => {
		setStockValue(stocklist);
	}, [stocklist]);
	const fetchData = useCallback(() => {
		dispatch(registerAll(token?._id) as any).then((res: any) => { });
		dispatch(BanksGet(token?._id) as any).then((res: any) => {
			if (res) {
				setBankList(res.payload);
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		fetchData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		dispatch(stockPurchaseGet(token?._id) as any);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let lowstock = [];
	for (let i = 0; i < stockvalue?.length; i++) {
		if (stockvalue[i].qty <= 10) {
			lowstock.push(stockvalue[i]);
		}
	}

	const openingBalanceamt =
		banklist.find((bankdata: any) => bankdata.defaultBankInfo === true)
			?.opening_balance || 0;

	useEffect(() => {
		dispatch(currencyGet(token?._id) as any).then((res: any) => {
			if (res) {
				let data = res.payload.find((value: any) => value.defaultInfo === true);
				if (data?.currencySymbol) {
					setcurrencySymbol(data.currencySymbol);
				} else {
					setcurrencySymbol("₹");
				}
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="">
			<div className="flex flex-col xl:flex-row gap-4 mt-6 ml-2">
				<div className="flex justify-center p-1 bg-white rounded-lg items-center overflow-hidden w-full h-[300px] 2xl:h-[430px]">
					<div className="w-full h-full">
						<DashboardLineDia selectedRange={selectedRange} />
					</div>
				</div>


				<div className="flex justify-center p-1 bg-white rounded-lg items-center overflow-hidden w-full h-[300px] 2xl:h-[430px]">
					<div className="w-full h-full">
						<DashboardBarDia selectedRange={selectedRange} />
					</div>
				</div>

				<div className="relative flex justify-center rounded-lg border border-gray-200 bg-white  dark:border-gray-600 dark:bg-navy-800 dark:text-white w-full 2xl:w-[41%] xl:w-[40%] lg:w-full md:w-full sm:w-11/12 ">
					<div className="flex flex-col items-center space-y-2 p-4 overflow-y-auto min-h-60">
						<p className="text-md font-semibold text-green-600">Low Stock</p>
						<ul className="space-y-1 text-sm text-navy-700 dark:text-white">
							{lowstock?.map((stock, index) => (
								<li
									key={index}
									className="flex items-center space-x-2 relative pl-2"
								>
									<span className="absolute left-0 top-1/2 transform -translate-y-1/2 text-red-600 dark:text-orange-300">
										•
									</span>
									<span className="text-red-600 dark:text-orange-300 ml-2">
										{stock.productName} -
									</span>
									<span className="text-red-600 dark:text-orange-300">
										{stock.qty}
									</span>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
			<div className="flex flex-col xl:flex-row gap-4 mt-6 ml-2">
				<div className="flex justify-center p-1 bg-white rounded-lg items-center overflow-hidden w-full h-[300px] 2xl:h-[430px]">
					<div className="w-full h-full">
						<DashboardPieDia selectedRange={selectedRange} />
					</div>
				</div>
				<div className="flex justify-center p-1 bg-white rounded-lg items-center overflow-hidden w-full h-[300px] 2xl:h-[430px]">
					<div className="w-full h-full">
						<DashboardDonutDia selectedRange={selectedRange} />
					</div>
				</div>

				<div className="relative flex  justify-center rounded-lg border border-gray-200 bg-white  dark:border-gray-600 dark:bg-navy-800 dark:text-white w-full 2xl:w-[41%] xl:w-[40%] lg:w-full md:w-full sm:w-11/12 ">
					<div className="flex flex-col items-center space-y-2 p-4 min-h-60">
						<p className="text-md font-semibold text-green-600 dark:text-gray-300">
							Cash & Bank
						</p>

						<p className="text-sm font-semibold text-green-600 dark:text-gray-300">
							Opening balance
						</p>
						<h4 className="text-md text-navy-700 dark:text-white">
							{`${currencySymbol}.${openingBalanceamt}`}
						</h4>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DashboardDiagrams;
