import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { expenseAll, expenseDelete, expenseSave, expenseUpdate } from '../../redux/actions/expenseAction';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from "uuid";
import ConfirmationPopup from '../commen/ConfirmationPopup';

const ExpenseCategory = () => {
    const usersignin = useSelector((state: any) => state?.userSignin);
    const { userInfo } = usersignin;
    const userid = userInfo.token._id;
    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            expense_Category: "",
            expense_type: "",
            rate: "",
            user_id: userid,
        },
    });

    const dispatch = useDispatch();
    const [expenseList, setExpenseList] = useState([] as any)
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [expenseID, setExpenseID] = useState(null as any)
    const [searchValue, setSearchValue] = useState([] as any);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sortOrder, setSortOrder] = useState<string>("asc");

    const fetchData = () => {

        dispatch(expenseAll() as any).then((res: any) => {
            setExpenseList(res.payload);
            setSearchValue(res.payload);
        });
    };
    const deleteExpense = (data: any) => {
        setShowConfirmation(true);
        setExpenseID(data._id?.$oid);
    };
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (data: any) => {
        if (expenseID) {
            dispatch(expenseUpdate(expenseID, data) as any).then((res: any) => {
                // eslint-disable-next-line eqeqeq
                if (res.type == "EXPENSE_UPDATE_SUCCESS") {
                    fetchData();
                    setExpenseID("");
                    let value = {
                        expense_Category: "",
                        expense_type: "",
                        rate: ""
                    };
                    reset({
                        ...value,
                    });
                }
                toast.success("Update SuccessFully !", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 800,
                });
            });
        } else {
            dispatch(expenseSave(data) as any).then((res: any) => {
                if (res.type === "EXPENSE_POST_SUCCESS") {
                    let value = {
                        expense_Category: "",
                        expense_type: "",
                        rate: ""
                    };
                    reset({
                        ...value,
                    });
                    fetchData()
                    toast.success("Saved Successfully!", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 800,
                    });
                }
            });
        }
    };






    const editExpense = (data: any) => {
        setExpenseID(data._id.$oid);

        let value = {
            expense_Category: data.expense_Category,
            expense_type: data.expense_type,
            rate: data.rate,
        };

        reset({
            ...value,
        });
    };

    const handleConfirm = () => {
        dispatch(expenseDelete(expenseID) as any).then((res: any) => {
            if (res) {
                fetchData();
                setExpenseID(null);
                setShowConfirmation(false);
                let value = {
                    expense_Category: "",
                    expense_type: "",
                    rate: ""
                };
                reset({
                    ...value,
                });
            }
        });
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };
    const handleCancelClick = () => {
        setExpenseID(false);

        let value = {
            expense_Category: "",
            expense_type: "",
            rate: ""
        };
        reset({
            ...value,
        });
    };
    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        let data = [...searchValue];
        data = data?.filter((value: any) =>
            value?.expense_Category?.toLowerCase().includes(query?.toLowerCase())
        );
        setExpenseList(data);
    };
    const handleSortChange = (newSortOrder: string) => {
        const sortedData = [...expenseList].sort((a, b) => {
            if (newSortOrder === "asc") {
                return a.expense_Category.localeCompare(b.expense_Category);
            } else {
                return b.expense_Category.localeCompare(a.expense_Category);
            }
        });
        setExpenseList(sortedData);
        setSortOrder(newSortOrder);
    };
    const rows = expenseList.map((item: any, index: any) => ({
        id: uuidv4(),
        sno: index + 1,
        ...item,
    }));
    const columns: GridColDef[] = [
        { field: "sno", headerName: "S No", width: 150 },
        { field: "expense_Category", headerName: "Expense Category", flex: 2, minWidth: 150 },
        { field: "expense_type", headerName: "Expense Type", flex: 2, minWidth: 150 },

        {
            field: "action",
            headerName: "Action",
            editable: false,
            flex: 2,
            minWidth: 150,
            renderCell: (params: any) => (
                <>
                    <IconButton aria-label="edit"
                        onClick={() => editExpense(params.row)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#008065"
                            className="w-5 h-5 cursor-pointer"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.862 4.487L18.55 2.8a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931Z"
                            />
                        </svg>
                    </IconButton>
                    <IconButton aria-label="delete"
                        onClick={() => {
                            deleteExpense(params.row);
                        }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#008065"
                            className="w-5 h-5 cursor-pointer"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M14.74 9L14.394 18m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                        </svg>
                    </IconButton>
                </>
            ),
        },
    ];

    return (
        <div className="bg-[#E1E8E7] pr-3">
            <form  onSubmit={handleSubmit(onSubmit)}>
                <fieldset className="mx-3 mb-4 rounded-lg">
                    <legend className="p-4 font-bold text-[#008065] text-lg ml-2">
                        Expense Category
                    </legend>


                    <div className="flex flex-col sm:flex-row lg:flex-row xl:flex-row gap-2   sm:px-3  sm:px-3 md:px-3 lg:px-3 xl:px-3 2xl:px-3 mb-3 mt-3">
                        <div className="flex flex-col mb-2 w-full md:w-full  lg:w-1/3 xl:w-1/4  px-8 sm:px-0 self-center">
                            <label
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                }}
                                className="text-[#008065] required"
                            >
                                <span>Expense Category</span>
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    className={
                                        "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                    }
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "13px",
                                        fontWeight: 400,
                                        borderColor: errors.expense_Category
                                            ? "#FF0000"
                                            : "#008065",
                                    }}
                                    placeholder="Expense Category..."
                                    {...register("expense_Category", {
                                        required: "Expense Category is required",
                                        validate: (value) => {
                                            const isDuplicate = expenseList.some(
                                              (item: any) =>
                                                item.expense_Category?.toLowerCase() === value.toLowerCase() &&
                                                item._id.$oid !== expenseID
                                            );
                                            return isDuplicate ? "This expense category already exists" : true;
                                          },
                                      })}

                                />
                                {errors.expense_Category && (
                                    <p style={{
                                        fontFamily: "poppins",
                                        fontWeight: 700,
                                        color: 'red',
                                    }}
                                        className="text-red-400 text-sm">
                                        {errors.expense_Category.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col mb-2 w-full md:w-full  lg:w-1/3 xl:w-1/4 self-center px-8 sm:px-0 ">

                            <label
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                    color: "#008065",
                                }}
                                className="text-[#008065] required"
                            >
                                <span>Expense Type</span>
                            </label>
                            <select
                                className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 lg:w-full"
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "13px",
                                    fontWeight: 400,
                                    borderColor: errors.expense_type
                                        ? "#FF0000"
                                        : "#008065",
                                }}
                                {...register("expense_type", {
                                    required: "Expense Type is required",
                                })}

                            >
                                <option value="" disabled hidden>
                                    Select Expense Type
                                </option>
                                <option value="Direct Expense">Direct Expense</option>
                                <option value="Indirect Expense">Indirect Expense</option>
                            </select>
                            {errors.expense_type && (
                                <p
                                    style={{
                                        fontFamily: "poppins",
                                        fontWeight: 700,
                                        color: "#ff0000",
                                    }}
                                    className="text-red-400 text-sm"
                                >
                                    {errors.expense_type.message}
                                </p>
                            )}
                        </div>

                    </div>
                    <div className="rounded-xl px-3 mb-3 grid sm:justify-end">
                        <div className="flex w-full flex-col sm:flex-row pl:0 sm:pl-5">
                            {/* <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                                <div className="flex flex-col  mb-4 "></div>
                            </div>

                            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                                <div className="flex flex-col  mb-4 "> </div>
                            </div> */}

                            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 px-2 self-center">
                                <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4">
                                    <button
                                        type="button"
                                        className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                                        style={{
                                            backgroundColor: "#008065",
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                        }}
                                        onClick={handleCancelClick}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>

                            <div className="mb-2 sm:mb-0 sm:text-left text-default-color  flex flex-row  font-title flex-1 px-2 self-center">
                                <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4">
                                    <button
                                        // onClick={(e: any) => handleSubmit(e)}
                                        className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                                        style={{
                                            backgroundColor: "#008065",
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {expenseID ? "Update" : "Submit"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </fieldset>
            </form>

            <div>
                <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 mx-2">
                    <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
                        <div className="flex items-center">
                            <div className="relative flex mb-2">
                                <input
                                    onChange={(e) => onSearch(e)}
                                    type="text"
                                    id="simple-search"
                                    className="w-[12rem] bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                                    placeholder="Search"
                                    required
                                />
                                <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                                    <svg
                                        className="w-4 h-4"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 20 20"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div className="block mb-2  ">
                            <div className="relative flex items-center ">
                                <select
                                    defaultValue={""}
                                    id="sortDropdown"
                                    className="block w-[12rem] h-10 p-2 text-sm  border border-[#008065] rounded-lg bg-gray-50  dark:bg-gray-700 dark:border-gray-600 "
                                    onChange={(e) => handleSortChange(e.target.value)}
                                >
                                    <option value="" disabled hidden>
                                        Sort By
                                    </option>
                                    <option value="asc">Category Name A to Z</option>
                                    <option value="desc">Category Name Z to A</option>
                                    {/* Add other sorting options if needed */}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div
                        className="bg-[#F1F7F6]"
                        style={{ borderRadius: "8px" }}
                    >
                        <Box
                            sx={{
                                height: "50vh",
                                width: "100%",
                                overflowX: "auto",
                                overflowY: "hidden",
                            }}
                        >
                            <DataGrid
                                // autoHeight
                                sx={{
                                    ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                                    {
                                        color: "rgb(0, 128, 101)",
                                        fontWeight: 600,
                                        fontSize: "14px",
                                    },
                                    ".MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--vertical.css-1b9e9gy":
                                        { overflowY: "hidden" },
                                    ".green-background": {
                                        backgroundColor: "rgb(193, 225, 193)",
                                    },
                                    ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                                        fontWeight: 600,
                                        fontSize: "14px",
                                    },
                                    "& .MuiDataGrid-checkboxInput": {
                                        color: "#008060 !important",
                                    },
                                }}
                                rows={rows}
                                columns={columns}
                                // checkboxSelection
                                // getRowClassName={getRowClassName}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 25, 50]}
                                disableRowSelectionOnClick
                            />
                        </Box>
                    </div>
                </div>
            </div>
            {showConfirmation && (
                <ConfirmationPopup
                    message="Are you sure you want to proceed?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    );
};

export default ExpenseCategory;
