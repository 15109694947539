import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { userRolerAll } from "../../redux/actions/userAction";
import {
  userProfileAll,
  userProfilesPost,
} from "../../redux/actions/userProfileAction";
import UserTable from "../../Tables/UserTable";

function UserDetails() {
  const usersignin = useSelector((state: any) => state.userSignin);
  const token = usersignin?.userInfo?.token;
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fname: "",
      lname: "",
      address: "",
      city: "",
      state: "",
      postal_code: "",
      phone: "",
      email: "",
      user_roll: "",
      user_id: token?._id,
    },
  });

  const dispatch = useDispatch();
  const [userroll, setUserRoll] = useState([] as any);
  const [userList, setUserList] = useState([] as any);

  const fetchData = () => {
    dispatch(userProfileAll(token?._id) as any).then((response: any) => {
      if (response.type === "USERS_PROFILE_GET_SUCCESS") {
        setUserList(response.payload);
      } else {
      }
    });
  };

  useEffect(() => {
    fetchData();
    dispatch(userRolerAll() as any)
      .then((response: any) => {
        setUserRoll(response.payload);
      })
      .catch((err: any) => {
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const onSubmit = (data: any) => {
    dispatch(userProfilesPost(data) as any)
      .then((res: any) => {
        if (res?.payload.message === "New User created successfully") {
          Swal.fire({
            position: 'center',
            icon: 'success',
            text: 'New User created successfully',
            timer: 3000,
            showConfirmButton: false,
            customClass: {
              popup: 'swal-popup-success', // Custom class for success
              htmlContainer: 'swal-html-container', // Custom class for text
            }
          });
          fetchData();
          reset();
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            text: res?.payload,
            timer: 3000,
            showConfirmButton: false,
            customClass: {
              popup: 'swal-popup-error', // Custom class for error
              htmlContainer: 'swal-html-container', // Custom class for text
            }
          });
        }
      })
      .catch((error: any) => {
        // Handle the error as needed
      });
  };

  const handleInitialSpace = (e: any) => {
    if (e.target.value.startsWith(' ')) {
      e.target.value = e.target.value.trimStart();
    }
  };

  // Function to allow only numbers
  const handleNumberInput = (e: any) => {
    if (!/^\d*$/.test(e.key)) {
      e.preventDefault();
    }
  };


  return (
    <>
      <div className="w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset className="mx-3 mb-4 rounded-lg">
            <legend className="p-4 font-bold text-[#045545] text-lg ml-2">
              User Details
            </legend>
            <div className="rounded-xl py-3 mb-3 bg-[#E1E8E7]">
              <div className="flex w-full flex-col sm:flex-row px-4">
                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                  <div className="flex flex-col mb-6">
                    <label
                      style={{
                        marginTop: "-3.5px",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                      className="required"
                    >
                      <span>First Name</span>
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Enter First Name"
                        className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full md:w-30 lg:w-28  xl:w-52 ${errors.fname ? "border-red-600" : "border-[#008065]"}`}
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        {...register("fname", {
                          required: "First Name is required",
                        })}
                        onInput={handleInitialSpace}
                      />
                    </div>
                    {errors.fname && (
                       <span
                       style={{
                         fontFamily: "poppins",
                         fontWeight: 700,
                         color: "#ff0000",
                       }}
                       className="text-red-400 text-sm"
                     >
                        {errors.fname.message}
                      </span>
                    )}


                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                  <div className="flex flex-col mb-6">
                    <label
                      style={{
                        marginTop: "-3.5px",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                      className="required"
                    >
                      <span>Last Name</span>
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Enter Last Name"
                        className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full md:w-30 lg:w-28  xl:w-52 ${errors.lname
                          ? "border-red-600"
                          : "border-[#008065]"
                          }`}
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        {...register("lname", {
                          required: "Last Name is required",
                        })}
                        onInput={handleInitialSpace}
                      />
                    </div>
                    {errors.lname && (
                       <span
                       style={{
                         fontFamily: "poppins",
                         fontWeight: 700,
                         color: "#ff0000",
                       }}
                       className="text-red-400 text-sm"
                     >
                        {errors.lname.message}
                      </span>
                    )}

                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                  <div className="flex flex-col mb-6">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                    >
                      Address
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Address (Optional)"
                        className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full md:w-30 lg:w-28  xl:w-52"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        {...register("address", {
                        })}
                        onInput={handleInitialSpace}
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                  <div className="flex flex-col mb-6">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                    >
                      City
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="City (Optional)"
                        className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full md:w-30 lg:w-28  xl:w-52"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        {...register("city", {
                        })}
                        onInput={handleInitialSpace}
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                  <div className="flex flex-col mb-6">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                    >
                      State
                    </label>
                    <div className="relative">
                      <input
                        placeholder="State (Optional)"
                        type="text"
                        className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full md:w-30 lg:w-28  xl:w-52"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        {...register("state", {
                        })}
                        onInput={handleInitialSpace}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col sm:flex-row px-4">
                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                  <div className="flex flex-col mb-6">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                    >
                      Postal code
                    </label>
                    <div className="relative">
                      <input
                        placeholder="Postal code (Optional)"
                        type="text"
                        className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full md:w-30 lg:w-28  xl:w-52 ${errors.postal_code
                          ? "border-red-600"
                          : "border-[#008065]"
                          }`}
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        {...register("postal_code", {
                          pattern: {
                            value: /^\d{6}$/,
                            message: "Invalid Postal Code",
                          },
                        })}
                        onInput={handleInitialSpace}
                        onKeyPress={handleNumberInput}
                      />
                    </div>
                    {errors.postal_code && (
                       <span
                       style={{
                         fontFamily: "poppins",
                         fontWeight: 700,
                         color: "#ff0000",
                       }}
                       className="text-red-400 text-sm"
                     >
                        {errors.postal_code.message}
                      </span>
                    )}

                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                  <div className="flex flex-col mb-6 ">
                    <label
                      style={{
                        marginTop: "-3.5px",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                      className="required"
                    >
                      <span className="whitespace-nowrap">Mobile Number</span>
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Mobile Number"
                        className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full md:w-30 lg:w-28  xl:w-52 ${errors.phone
                          ? "border-red-600"
                          : "border-[#008065]"
                          }`}
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        {...register("phone", {
                          required: "Mobile Number is Required",
                          pattern: {
                            value: /^\d{10}$/,
                            message: "Invalid Mobile Number",
                          },
                        })}
                        onInput={handleInitialSpace}
                        onKeyPress={handleNumberInput}
                      />
                    </div>
                    {errors.phone && (
                       <span
                       style={{
                         fontFamily: "poppins",
                         fontWeight: 700,
                         color: "#ff0000",
                       }}
                       className="text-red-400 text-sm"
                     >
                        {errors.phone.message}
                      </span>
                    )}

                  </div>
                </div>


                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                  <div className="flex flex-col mb-6">
                    <label
                      style={{
                        marginTop: "-3.5px",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                      className="required"
                    >
                      <span>Email</span>
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Email"
                        className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full md:w-30 lg:w-28  xl:w-52 ${errors.email
                          ? "border-red-600"
                          : "border-[#008065]"
                          }`}
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email address",
                          },
                        })}
                        onInput={handleInitialSpace}
                      />
                    </div>
                    {errors.email && (
                       <span
                       style={{
                         fontFamily: "poppins",
                         fontWeight: 700,
                         color: "#ff0000",
                       }}
                       className="text-red-400 text-sm"
                     >
                        {errors.email.message}
                      </span>
                    )}

                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                  <div className="flex flex-col mb-6">
                    <label
                      style={{
                        marginTop: "-3.5px",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                      className="required"
                    >
                      <span>User Role</span>
                    </label>
                    <div className="relative">
                      <select
                        defaultValue={""}
                        style={{ fontFamily: "poppins" }}
                        className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-48 md:w-full lg:w-28  xl:w-52
                            ${errors.user_roll
                            ? "border-red-600"
                            : "border-[#008065]"
                          }`}
                        {...register("user_roll", {
                          required: "User role is required",
                        })}
                      >
                        <option value="" disabled hidden>
                          Select a Type
                        </option>
                        {userroll
                          ?.filter((roll: any) => {
                            return roll.roleName !== "Super Admin";
                          })
                          .map((roll: any) => (
                            <option key={roll?.roleName} value={roll?.roleName}>
                              {roll?.roleName}
                            </option>
                          ))}
                      </select>
                    </div>
                    {errors.user_roll && (
                       <span
                       style={{
                         fontFamily: "poppins",
                         fontWeight: 700,
                         color: "#ff0000",
                       }}
                       className="text-red-400 text-sm"
                     >
                        {errors.user_roll.message}
                      </span>
                    )}

                  </div>
                </div>
                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                  <div className="flex flex-col mb-6"></div></div>
              </div>

              <div className="flex w-full flex-col sm:flex-row pl-4">
                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div>
                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div>
                <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
                  <div className="flex flex-col w-44 rounded">
                    <button
                      type="button"
                      onClick={() => reset()}
                      className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065]"
                      style={{
                        backgroundColor: "#008065",
                        fontSize: "14px",
                        fontFamily: "poppins",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 mt-5 ">
                  <div className="flex flex-col w-44 ">
                    <button
                      type="submit"
                      className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065]"
                      style={{
                        backgroundColor: "#008065",
                        fontSize: "14px",
                        fontFamily: "poppins",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </fieldset>
        </form>
        <UserTable userList={userList} />
      </div>
    </>
  );
}

export default UserDetails;
