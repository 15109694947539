import NumberFormat from "../commen/NumberFormat";

const PrintSalesFABillWise = ({ Invoice, componentRef, }: any) => {


  return (
    <>
      <div ref={componentRef}>
        <div style={{ breakBefore: "page" }}></div>
        {/*   image and clien information                */}
        <div className="grid grid-cols-6">
          {Invoice?.imageData ? (
            <div className="col-start-1 col-end-2">
              <div className=" font-title  sm:text-left ">
                <label
                  style={{ fontFamily: "poppins" }}
                  className="   items-start  text-blue      "
                >
                  <input type="file" accept="image/*" className="hidden" />
                  <img
                    className=" pt-7 h-20 2xl:ml-7 xl:ml-7 lg:ml-7 md:ml-7 sm:ml-4 "
                    src={Invoice?.imageData}
                    alt="logonew "
                  />
                </label>
              </div>
            </div>
          ) : null}
          <div className="col-start-2 col-span-4 ">
            <div className=" text-center  pt-7 text-4xl ">
              {Invoice?.companyName}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className=" text-center">
            <div className="mt-1">
              <span>
                {Invoice?.companyAddress ? (
                  <span>Address: {Invoice?.companyAddress},</span>) : null}
                {Invoice?.companyCity ? (
                  <span>{Invoice?.companyCity},</span>
                ) : null}

                {Invoice?.companyState ? (
                  <span>{Invoice?.companyState}-</span>) : null}
                {Invoice?.companyPostalCode ? (
                  <span>{Invoice?.companyPostalCode},</span>) : null}
                {Invoice?.companygst ? (
                  <span> Gstn:
                    {Invoice?.companygst}</span>) : null}

              </span>
            </div>
            <div>
              <span>
                {Invoice?.companyPan ? (
                  <span>PAN: {Invoice?.companyPan},</span>
                ) : null}
                {Invoice?.companyEmail ? (
                  <span>Email: <span className="lowercase">{Invoice?.companyEmail},</span></span>) : null} {Invoice?.companyMobile ? (<span>Mobile: {Invoice?.companyMobile},</span>) : null}
              </span>
            </div>
          </div>
        </div>
        <div className="border "></div>
        {/*        *******************   invoice name                */}

        <div className="bg-white  overflow-x-auto" id="InvoiceWrapper">
          <div className="justify-center flex flex-row relative ">
            <input
              readOnly
              className="w-11/12 rounded-[7px] text-center xs:w-1/4  focus:outline-none truncate"
              autoComplete="nope"
              style={{
                fontFamily: "poppins",
                fontSize: "34px",
                fontWeight: 700,
              }}
              id="input_focus"
              value={Invoice?.invoicehead}
            />
            {/* </h1> */}
          </div>
          {/*     client details           */}
          <div className=" px-8 bg-cover bg-center  rounded-xl flex flex-col sm:flex-row justify-between ">
            <div className="flex-1 md:mx-2">
              <div className="flex flex-col sm:flex-row pt-3  justify-between">
                <div className="">
                  <div className="flex flex-row  items-center mb-2 ">
                    <div
                      className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      style={{
                        color: "#008065",
                        fontWeight: 700,
                      }}
                    >
                      Client Details
                    </div>
                  </div>
                  {Invoice.clientName ? (
                    <>
                      <div className="flex justify-between">
                        <div
                          style={{
                            marginRight: "30px",
                            fontWeight: 700,
                            verticalAlign: "top",
                          }}
                          className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Name
                        </div>
                        <div className=" ">
                          <input
                            className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                            readOnly
                            value={Invoice.clientName}
                            style={{
                              width:"350px",
                              fontWeight: 400,
                              verticalAlign: "top",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}

                  {Invoice.clientMobile ? (<>
                    <div className="flex justify-between">
                      <div
                        style={{
                          marginRight: "30px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Mobile
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice.clientMobile}
                          style={{
                            width:"350px",
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>) : null}

                  {Invoice.clientEmail ? (
                    <>
                      <div className="flex justify-between">
                        <div
                          style={{
                            marginRight: "30px",
                            fontWeight: 700,
                            verticalAlign: "top",
                          }}
                          className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Email
                        </div>
                        <div className="">
                          <input
                            className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"

                            readOnly
                            value={Invoice.clientEmail}
                            style={{

                              width:"350px",
                              fontWeight: 400,
                              verticalAlign: "top",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  {Invoice.clientgst ? (
                    <>
                      <div className="flex justify-between ">
                        <div
                          style={{

                            marginRight: "45px",
                            fontWeight: 700,
                            verticalAlign: "top",
                          }}
                          className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          GST
                        </div>
                        <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  md:w-[70%] sm:w-full">
                          <input
                            className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                            readOnly
                            value={Invoice.clientgst}
                            style={{

                              width:"350px",
                              fontWeight: 400,
                              verticalAlign: "top",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}



                </div>

                <div className="">
                  <div className="flex flex-row items-center justify-between mb-2 mr-8">

                    <div
                      style={{
                        color: "#008065",
                        fontWeight: 700,
                      }}
                      className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      Sales FA No
                    </div>


                    <div className="font-title flex-1 text-right ">
                      <input
                        readOnly
                        value={Invoice?.invoiceNo}
                        style={{
                          fontFamily: "poppins",
                        }}
                        className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full  block focus:outline-none "
                      />
                    </div>
                    <div></div>
                  </div>

                  {Invoice.salesFADate ? (
                    <>
                      <div className="flex flex-row items-center justify-between mb-2 mr-8">
                        <div
                          style={{
                            color: "#008065",
                            fontWeight: 700,
                          }}
                          className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Sales FA Date
                        </div>
                        <div className="font-title flex-1 text-right ">
                          <input
                            readOnly
                            value={Invoice.salesFADate}
                            style={{
                            }}
                            className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full ml-2 sm:ml-auto block focus:outline-none "
                          />
                        </div>
                        <div></div>
                      </div>
                    </>) : null}

                  {Invoice.PurchaseBillNo ? (
                    <>
                      <div className="flex flex-row justify-between items-center mb-2 mr-8 ">
                        <div
                          style={{
                            color: "#008065",
                            fontWeight: 700,
                          }}
                          className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                        >
                          Purchase Bill No
                        </div>
                        <div className="font-title flex-1 text-right">
                          <input
                            readOnly
                            value={Invoice.PurchaseBillNo}
                            style={{
                            }}
                            className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md block w-full ml-2 sm:ml-auto  focus:outline-none "
                          ></input>
                        </div>
                        <div></div>
                      </div>
                    </>
                  ) : null}

                  {Invoice.purchaseBillDate ? (
                    <>
                      <div className="flex flex-row items-center justify-between mb-2 mr-8">
                        <div
                          style={{
                            color: "#008065",
                            fontFamily: "poppins",

                            fontWeight: 700,
                          }}
                          className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Purchase Bill Date
                        </div>
                        <div className="font-title flex-1 text-right ">
                          <input
                            readOnly
                            value={Invoice.purchaseBillDate}
                            style={{
                              fontFamily: "poppins",
                            }}
                            className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full ml-2 sm:ml-auto block focus:outline-none "
                          />
                        </div>
                        <div></div>
                      </div>
                    </>) : null}


                </div>

              </div>
            </div>
          </div>


          {/* product details */}
          <div className="px-8 mt-3">
            <div className="hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 text-white bg-[#008065]">
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className=" sm:w-1/12  text-left "
              >
                <span className="inline-block">S No</span>
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className=" sm:w-6/12 text-center sm:pr-11 "
              >
                <span className="inline-block">Products</span>
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="w-40 sm:w-1/4 text-right pr-8 "
              >
                <span className="inline-block ">Hsn</span>
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className=" w-full sm:w-1/4 text-center  mr-18      "
              >
                Price
              </div>

              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="w-full sm:w-1/4 text-center sm:text-center   "
              >
                Qty
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className=" w-full sm:w-1/4 text-right 2xl:text-right   "
              >
                Total
              </div>
            </div>
          </div>
          {Invoice?.asset?.map((product: any, index: number) => {
            return (
              <div
                key={index}
                className="flex lg:flex-row flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible md:flex-row  xl:flex-row flex-col rounded-lg w-full  2xl:px-6 xl:px-6  md:px-7 px-4  py-1 items-center relative text-sm"
              >
                <span className="inline-block w-2/12  mb-1 sm:mb-0   2xl:px-3  xl:px-3 lg:px-3 md:px-3">
                  <input
                    readOnly
                    value={index + 1}
                    style={{
                      fontFamily: "poppins",
                      fontWeight: 400,
                    }}
                    className="font-title  block w-full 2xl:text-left  2xl:ml-3 xl:ml-2 md:ml-3  xl:text-left  lg:text-left  md:text-left  sm:text-left  sm:ml-7  text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  />
                </span>
                <div
                  style={{
                    fontFamily: "poppins",
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 text-right   flex flex-row sm:block "
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-1/2 flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Description
                  </span>
                  <span className="inline-block w-full  mb-1 sm:mb-0   2xl:px-3  xl:px-3 lg:px-3 md:px-3">
                    <input
                      readOnly
                      value={product.asset_name}
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 400,
                      }}
                      className="font-title  block w-full  2xl:ml-3 xl:ml-2 md:ml-3    sm:ml-7  text-left focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
                <div
                  style={{
                    fontFamily: "poppins",
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 text-right flex flex-row sm:block   "
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-full flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    HsnNo
                  </span>
                  <span className="inline-block mb-1 sm:mb-0 ">
                    <input
                      readOnly
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 400,
                      }}
                      value={product.hsn ? product.hsn : "N/A"}
                      className="font-title  block text-right focus:outline-none text-xs sm:text-sm  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
                <div
                  style={{
                    fontFamily: "poppins",
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 text-left   flex flex-row sm:block "
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-full flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Price
                  </span>
                  <span className="inline-block  mb-1 sm:mb-0 ">
                    <input
                      style={{
                        fontFamily: "poppins",
                        marginTop: "",
                        fontWeight: 400,
                      }}
                      readOnly
                      value={product.rate}
                      type={"number"}
                      className="font-title text-right pr-8 block w-full  "
                    />
                  </span>
                </div>
                <div
                  style={{
                    fontFamily: "poppins",
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 text-right flex flex-row sm:block   "
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Quantity
                  </span>
                  <span className="inline-block  w-full mb-1 sm:mb-0">
                    <input
                      readOnly
                      value={product.qty}
                      type={"number"}
                      style={{
                        fontFamily: "poppins",
                        marginTop: "",
                        fontWeight: 400,
                      }}
                      className="font-title text-right pr-14 block w-full  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>

                <div
                  style={{
                    fontFamily: "poppins",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                  className=" w-full sm:w-1/4 text-right  flex flex-row sm:block"
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-1/2  flex flex-row items-center text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Total
                  </span>
                  <span className="font-block w-1/2 sm:w-full  md:w-full whitespace-nowrap lg:w-full lg:pr-4 md:pr-4  sm:pr-7  lg:pr-4  xl:pr-5 ">
                    <NumberFormat value={product.amount}></NumberFormat>
                  </span>
                </div>
              </div>
            );
          })}
          {/********************************** * subtotal *******************************/}
          {Invoice?.subtotal ? (
            <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center ">
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
              >
                subtotal
              </div>

              <div
                className="font-block w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >
                <NumberFormat
                  value={Invoice?.subtotal}

                />
              </div>
            </div>
          ) : null}

          {/* ******************************TAX VALUES**************/}
          {Invoice?.tax
            ?.sort((a: any, b: any) => {
              const order = ['Discount', 'CGST', 'SGST','IGST', 'OTHERS'];
              return order.indexOf(a.taxName) - order.indexOf(b.taxName);
            })
            .map((tax: any, index: number) => {


              return (
                <div key={index}>

                  <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center">
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                    >
                      {tax.taxName}
                      {tax.taxName !== "Extra Fees" ? <span className="ml-2">@</span> : ''}
                      {tax.taxName !== "Extra Fees" ? tax.taxPersent : ''}
                      {tax.taxName !== "Extra Fees" ? (<span>%</span>) : ""}
                    </div>

                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className="font-title w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1">
                      <NumberFormat
                        value={tax.taxAmount}

                      />
                    </div>
                  </div>


                </div>
              );
            })}
          {Invoice?.toatalAmount ? (
            <div className="flex flex-row sm:flex-row sm:justify-end  rounded-lg sm:visible w-full px-4 py-1 items-center ">
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
              >
                Total
              </div>

              <div style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
                className="font-title w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1">
                <NumberFormat
                  value={Invoice?.toatalAmount}


                />
              </div>
            </div>
          ) : null}

          {/*************************************Amount In Words *********************/}
          <div className="flex flex-row sm:flex-row sm:justify-start rounded-lg sm:visible w-full  sm:px-4 py-1 items-center ">

            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="font-title w-1/2 sm:w-1/4 text-left pl-4 md:pl-5 lg:pl-4 flex flex-row sm:block mb-1 sm:mb-0 whitespace-nowrap capitalize"
            >
              {Invoice?.totalInWords}

            </div>
          </div>


          {/********************************signature*******************************/}
          {(Invoice?.signature || Invoice?.seal) ? (
            <div className="flex flex-row justify-between">
              <div className={Invoice?.signPostion === "R" ? "lg:order-2 xl:order-2 2xl:order-2 md:order-2 flex flex-col lg:w-[48%] xl:w-1/3 md:w-5/12 w-1/4 2xl:w-1/3 items-left pl-8 justify-center w-full h-40 rounded-lg mt-2  " : "flex flex-col lg:w-[48%] xl:w-1/3 md:w-5/12 sm:w-1/3 2xl:w-1/3 items-left pl-8 w-full h-32  rounded-lg mt-2"}>
                {Invoice?.signature && (<p className="font-semibold text-[#008065] pt-2">Signature:</p>)}
                <div className="relative">
                  {Invoice?.signature ? (
                    <>
                      <img
                        className="w-[230px] h-[80px] inline-block shrink-0 pb-2 "
                        alt="sign"
                        src={
                          Invoice?.signature
                            ? Invoice?.signature
                            : Invoice?.signature
                        }
                      />
                    </>
                  ) : (
                    <>
                    </>
                  )}
                </div>
              </div>
              <div className={Invoice?.sealPostion === "L" ? "lg:order-1 xl:order-1 2xl:order-1 md:order-1 flex flex-col lg:w-1/2 xl:w-1/3 md:w-5/12 sm:w-1/4 2xl:w-1/3 items-center pr-8 justify-center w-full h-40  mt-2" : " flex flex-col lg:w-1/2 xl:w-1/3 md:w-5/12 sm:w-1/3 2xl:w-1/3 items-center pr-8 justify-center w-full h-32 mt-2 "}>
                {Invoice?.seal && (<p className="font-semibold text-[#008065] pt-2">Seal:</p>)}
                <div className="relative">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className="object-cover  pb-2 flex flex-col items-center px-4    "
                  >
                    {Invoice?.seal ? (
                      <>
                        <img
                          className="w-[230px] h-[80px] inline-block shrink-0 pb-2 "
                          alt="sign"
                          src={Invoice?.seal ? Invoice?.seal : Invoice?.seal}
                        />
                      </>
                    ) : (
                      <>
                      </>
                    )}
                  </label>
                </div>
              </div>
            </div>

          ) : null}
        </div>
      </div>
    </>
  );
};

export default PrintSalesFABillWise;
