import React, { useState } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import BalanceSheet from "./BalanceSheet";
import { Button, Menu, MenuItem } from "@mui/material";
import Profit from "./Profit";
import TrialBalanceSheet from "./TrialBalanceSheet";

const Reports = () => {

  const [openTab, setOpenTab] = useState(1);


  // Transaction report
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };


  // Party report
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorEl2);

  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  // GST reports
  const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement>(null);
  const open3 = Boolean(anchorEl3);

  const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  // Item/ Stock report 8
  const [anchorEl4, setAnchorEl4] = useState<null | HTMLElement>(null);
  const open4 = Boolean(anchorEl4);

  const handleClick4 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl4(event.currentTarget);
  };

  const handleClose4 = () => {
    setAnchorEl4(null);
  };
  // Business Status
  const [anchorEl5, setAnchorEl5] = useState<null | HTMLElement>(null);
  const open5 = Boolean(anchorEl5);

  const handleClick5 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl5(event.currentTarget);
  };

  const handleClose5 = () => {
    setAnchorEl5(null);
  };

  // Taxes
  const [anchorEl6, setAnchorEl6] = useState<null | HTMLElement>(null);
  const open6 = Boolean(anchorEl6);

  const handleClick6 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl6(event.currentTarget);
  };

  const handleClose6 = () => {
    setAnchorEl6(null);
  };

  // Expense report
  const [anchorEl7, setAnchorEl7] = useState<null | HTMLElement>(null);
  const open7 = Boolean(anchorEl7);

  const handleClick7 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl7(event.currentTarget);
  };

  const handleClose7 = () => {
    setAnchorEl7(null);
  };


  return (
    <div>

      {/*  arrange */}
      <div className="font-poppins ">
        <ul className="flex flex-wrap text-sm font-medium text-center text-[#008065]  dark:text-gray-400 ">
          {/* Bakancesheet */}
          <li className="mr-1">
              <button
                style={{
                  borderBottom: openTab === 1 ? "2px solid #008065" : "",
                }}
                onClick={() => setOpenTab(1)}
                className={
                  "inline-block p-4 text-[#008065] !text-md cursor-pointer  font-bold hover:!bg-gray-300"
                }
              >
                BalanceSheet
              </button>
            </li>

            {/* Profit And Loss */}
          <li className="mr-1">
              <button
                style={{
                  borderBottom: openTab === 2 ? "2px solid #008065" : "",
                }}
                onClick={() => setOpenTab(2)}
                className={
                  "inline-block p-4 text-[#008065] !text-md cursor-pointer  font-bold hover:!bg-gray-300"
                }
              >
                Profit And Loss
              </button>
            </li>

             {/* Trial Balance Report */}
          <li className="mr-1">
              <button
                style={{
                  borderBottom: openTab === 3 ? "2px solid #008065" : "",
                }}
                onClick={() => setOpenTab(3)}
                className={
                  "inline-block p-4 text-[#008065] !text-md cursor-pointer  font-bold hover:!bg-gray-300"
                }
              >
                Trial Balance Sheet
              </button>
            </li>

          {/* Transaction Report */}
          <li className=" mr-1">
            <div className=" ">
              <Button
                id="menu-button-1"
                aria-controls={open1 ? "menu-1" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                variant="contained"
                onClick={handleClick1}

                style={{
                  borderBottom: [11, 12, 13, 14, 15, 16].includes(openTab) ? "2px solid #008065" : "",
                }}

                // endIcon={<ChevronDownIcon />}
                className="!bg-none !bg-transparent !p-4 !shadow-none !text-[#008065] !text-md hover:!bg-gray-300  !font-bold !normal-case !dark:text-gray-400 !rounded-none "
              >
                Transaction Report  <span className="ml-1"><FaChevronDown /></span>
                  {/* <span className="ml-1"> { open1 ? <FaChevronDown /> : <FaChevronRight />} </span> */}
              </Button>

              <Menu
                id="menu-1"
                anchorEl={anchorEl1}
                open={open1}
                onClose={handleClose1}
              >
                <MenuItem className={`${openTab === 11 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(11); handleClose1(); }}> Sale</MenuItem>
                <MenuItem className={`${openTab === 12 ? "!text-[#008065] !bg-gray-100 !font-bold" : "!text-gray-700"}`} onClick={() => { setOpenTab(12); handleClose1(); }}>Purchase</MenuItem>
                <MenuItem className={`${openTab === 13 ? "!text-[#008065] !bg-gray-100 !font-bold" : "!text-gray-700"}`} onClick={() => { setOpenTab(13); handleClose1(); }}>Day book</MenuItem>
                <MenuItem className={`${openTab === 14 ? "!text-[#008065] !bg-gray-100 !font-bold" : "!text-gray-700"}`} onClick={() => { setOpenTab(14); handleClose1(); }}>All Transactions</MenuItem>
                {/* <MenuItem className={`${openTab === 15 ? "!text-[#008065] !bg-gray-100 !font-bold" : "!text-gray-700"}`} onClick={() => { setOpenTab(15); handleClose1(); }}>Profit And Loss</MenuItem> */}
                <MenuItem className={`${openTab === 15 ? "!text-[#008065] !bg-gray-100 !font-bold" : "!text-gray-700"}`} onClick={() => { setOpenTab(15); handleClose1(); }}>Bill Wise Profit</MenuItem>
                <MenuItem className={`${openTab === 16 ? "!text-[#008065] !bg-gray-100 !font-bold" : "!text-gray-700"}`} onClick={() => { setOpenTab(16); handleClose1(); }}>Cash flow</MenuItem>
                {/* <MenuItem className={`${openTab === 18 ? "!text-[#008065] !bg-gray-100 !font-bold" : "!text-gray-700"}`} onClick={() => { setOpenTab(18); handleClose1(); }}>Trial Balance Report</MenuItem> */}
                {/* <MenuItem className={`${openTab === 19 ? "!text-[#008065] !bg-gray-100 !font-bold" : "!text-gray-700"}`} onClick={() => { setOpenTab(9); handleClose1(); }}>Balance Sheet</MenuItem> */}

              </Menu>

            </div>

          </li>

          {/* Party report */}
          <li className="mr-1">
            <div className=" ">

              <Button
                id="menu-button2"
                aria-controls={open2 ? "menu-2" : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? "true" : undefined}
                variant="contained"
                onClick={handleClick2}
                style={{
                  borderBottom: [21, 22, 23, 24].includes(openTab) ? "2px solid #008065" : "",
                }}
                // endIcon={<ChevronDownIcon />}
                className="!bg-none !bg-transparent !p-4 !shadow-none !text-[#008065] !text-md hover:!bg-gray-300  !font-bold !normal-case !dark:text-gray-400 !rounded-none"
              >
                Party report <span className="ml-1"><FaChevronDown /></span>
              </Button>
              <Menu
                id="menu-2"
                anchorEl={anchorEl2}
                open={open2}
                onClose={handleClose2}
              >
                <MenuItem className={`${openTab === 21 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(21); handleClose2(); }}>Party Statement</MenuItem>
                <MenuItem className={`${openTab === 22 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(22); handleClose2(); }}>Party wise Profit & Losss</MenuItem>
                <MenuItem className={`${openTab === 23 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(23); handleClose2(); }}>All parties</MenuItem>
                <MenuItem className={`${openTab === 24 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(24); handleClose2(); }}>Party Report By Item</MenuItem>

              </Menu>
            </div>
          </li>

          {/* GST reports */}
          <li className="mr-1">
            <div className=" ">

              <Button
                id="menu-button3"
                aria-controls={open3 ? "menu-3" : undefined}
                aria-haspopup="true"
                aria-expanded={open3 ? "true" : undefined}
                variant="contained"
                onClick={handleClick3}
                style={{
                  borderBottom: [31, 32, 33, 34].includes(openTab) ? "2px solid #008065" : "",
                }}
                // endIcon={<ChevronDownIcon />}
                className="!bg-none !bg-transparent !p-4 !shadow-none !text-[#008065] !text-md hover:!bg-gray-300  !font-bold !normal-case !dark:text-gray-400 !rounded-none"
              >
                GST reports <span className="ml-1"><FaChevronDown /></span>
              </Button>
              <Menu
                id="menu-3"
                anchorEl={anchorEl3}
                open={open3}
                onClose={handleClose3}>

                <MenuItem className={`${openTab === 31 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(31); handleClose3(); }}> GSTR 1</MenuItem>
                <MenuItem className={`${openTab === 32 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(32); handleClose3(); }}>GSTR 2</MenuItem>
                <MenuItem className={`${openTab === 33 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(33); handleClose3(); }}>GSTR 3 B</MenuItem>
                <MenuItem className={`${openTab === 34 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(34); handleClose3(); }}>GSTR 9</MenuItem>

              </Menu>
            </div>
          </li>

          {/* Item/ Stock report */}
          <li className="mr-1">
            <div className=" ">

              <Button
                id="menu-button4"
                aria-controls={open4 ? "menu-4" : undefined}
                aria-haspopup="true"
                aria-expanded={open4 ? "true" : undefined}
                variant="contained"
                onClick={handleClick4}
                style={{
                  borderBottom: [41, 42, 43, 44, 45, 46].includes(openTab) ? "2px solid #008065" : "",
                }}
                // endIcon={<ChevronDownIcon />}
                className="!bg-none !bg-transparent !p-4 !shadow-none !text-[#008065] !text-md hover:!bg-gray-300  !font-bold !normal-case !dark:text-gray-400 !rounded-none"
              >
                Item/ Stock report <span className="ml-1"><FaChevronDown /></span>
              </Button>
              <Menu
                id="menu-4"
                anchorEl={anchorEl4}
                open={open4}
                onClose={handleClose4}
              >
                <MenuItem className={`${openTab === 41 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(41); handleClose4(); }}> Stock summary</MenuItem>
                <MenuItem className={`${openTab === 42 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(42); handleClose4(); }}>Stock Detail</MenuItem>
                <MenuItem className={`${openTab === 43 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(43); handleClose4(); }}>Stock Transfer Report</MenuItem>
                <MenuItem className={`${openTab === 44 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(44); handleClose4(); }}>Low Stock Summary</MenuItem>
                <MenuItem className={`${openTab === 45 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(45); handleClose4(); }}>item5</MenuItem>
                <MenuItem className={`${openTab === 46 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(46); handleClose4(); }}>item6</MenuItem>
              </Menu>
            </div>
          </li>

          {/* Business Status */}
          <li className="mr-1">
            <div className=" ">

              <Button
                id="menu-button5"
                aria-controls={open5 ? "menu-5" : undefined}
                aria-haspopup="true"
                aria-expanded={open5 ? "true" : undefined}
                variant="contained"
                onClick={handleClick5}
                style={{
                  borderBottom: [51, 52].includes(openTab) ? "2px solid #008065" : "",
                }}
                // endIcon={<ChevronDownIcon />}
                className="!bg-none !bg-transparent !p-4 !shadow-none !text-[#008065] !text-md hover:!bg-gray-300  !font-bold !normal-case !dark:text-gray-400 !rounded-none"
              >
                Business Status <span className="ml-1"><FaChevronDown /></span>
              </Button>
              <Menu
                id="menu-5"
                anchorEl={anchorEl5}
                open={open5}
                onClose={handleClose5}
              >
                <MenuItem className={`${openTab === 51 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(51); handleClose5(); }}> Bank Statement </MenuItem>
                <MenuItem className={`${openTab === 52 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(52); handleClose5(); }}>Discount Report</MenuItem>
              </Menu>
            </div>
          </li>

          {/* Taxes */}
          <li className="mr-1">
            <div className=" ">

              <Button
                id="menu-button6"
                aria-controls={open6 ? "menu-6" : undefined}
                aria-haspopup="true"
                aria-expanded={open6 ? "true" : undefined}
                variant="contained"
                onClick={handleClick6}
                style={{
                  borderBottom: [61, 62].includes(openTab) ? "2px solid #008065" : "",
                }}
                // endIcon={<ChevronDownIcon />}
                className="!bg-none !bg-transparent !p-4 !shadow-none !text-[#008065] !text-md hover:!bg-gray-300  !font-bold !normal-case !dark:text-gray-400 !rounded-none"

              >
                Taxes  <span className="ml-1"><FaChevronDown /></span>
              </Button>
              <Menu
                id="menu-6"
                anchorEl={anchorEl6}
                open={open6}
                onClose={handleClose6}
              >
                <MenuItem className={`${openTab === 61 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(61); handleClose6(); }}> GST Report </MenuItem>
                <MenuItem className={`${openTab === 62 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(62); handleClose6(); }}>GST Rate Report</MenuItem>
              </Menu>
            </div>
          </li>

          {/* Expense report */}
          <li className="mr-1">
            <div className=" ">

              <Button
                id="menu-button7"
                aria-controls={open7 ? "menu-7" : undefined}
                aria-haspopup="true"
                aria-expanded={open7 ? "true" : undefined}
                variant="contained"
                onClick={handleClick7}
                style={{
                  borderBottom: [71, 72, 73].includes(openTab) ? "2px solid #008065" : "",
                }}
                // endIcon={<ChevronDownIcon />}
                className="!bg-none !bg-transparent !p-4 !shadow-none !text-[#008065] !text-md hover:!bg-gray-300  !font-bold !normal-case !dark:text-gray-400 !rounded-none"
              >
                Expense report  <span className="ml-1"><FaChevronDown /></span>
              </Button>
              <Menu
                id="menu-7"
                anchorEl={anchorEl7}
                open={open7}
                onClose={handleClose7}
              >
                <MenuItem className={`${openTab === 71 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(71); handleClose7(); }}> Expense</MenuItem>
                <MenuItem className={`${openTab === 72 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(72); handleClose7(); }}>Expense Category Report </MenuItem>
                <MenuItem className={`${openTab === 73 ? "!text-[#008065] !bg-gray-100 !font-bold " : "!text-gray-700"}`} onClick={() => { setOpenTab(73); handleClose7(); }}>Expense Item Report </MenuItem>
              </Menu>
            </div>
          </li>

        </ul>
      </div>

      <div className="w-full">
        {/* <div className={openTab === 1 ? "block  " : "hidden"}>
          <BalanceSheet />
        </div> */}
        {openTab === 1 && <BalanceSheet />}
        {openTab === 2 && <Profit />}
        {openTab === 3 && <TrialBalanceSheet />}
      </div>

    </div>



  );
};

export default Reports;
