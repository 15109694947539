import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { registerAll, updateUser } from "../../redux/actions/userAction";

const TaxTab = ({ setAppTabVisibility }: { setAppTabVisibility: (visible: boolean) => void }) => {
  const [selectedTaxType, setSelectedTaxType] = useState("Bill Wise");
  const [selectedGstTaxType, setSelectedGstTaxType] = useState("GST");
  const [initialState, setInitialState] = useState({
    taxType: "Bill Wise",
    gstType: "GST",
    isAppTabVisible: true,
  });

  const dispatch = useDispatch();
  const userId = JSON.parse(sessionStorage.getItem("userInfo") || "{}");

  // Fetch data and initialize the state
  const fetchData = useCallback(async () => {
    if (!userId?.token?._id) return;
    const res = await dispatch(registerAll(userId.token._id) as any);
    if (res?.type === "REGISTER_GET_SUCCESS") {
      const taxType = res.payload?.taxType || "Bill Wise";
      const gstType = res.payload?.gstValue || "GST";
      const isVisible = taxType === "Bill Wise";

      setSelectedTaxType(taxType);
      setSelectedGstTaxType(gstType);
      setAppTabVisibility(isVisible);

      setInitialState({ taxType, gstType, isAppTabVisible: isVisible });
    }
  }, [dispatch, setAppTabVisibility, userId?.token?._id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleTaxTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedTaxType(value);
    setAppTabVisibility(value === "Bill Wise");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const obj = {
      taxType: selectedTaxType,
      template: selectedTaxType === "Product Wise" ? "" : undefined,
      invoiceType: selectedTaxType === "Bill Wise" ? "invoice-type-01" : "invoice-type-02",
      billModule: selectedTaxType !== "None" ? "PRODUCT" : "",
      gstValue: selectedGstTaxType,
    };
    const res = await dispatch(updateUser(userId?.token?._id, obj) as any);
    if (res) {
      fetchData();
      sessionStorage.setItem("isAppTabVisible", JSON.stringify(selectedTaxType === "Bill Wise"));
      toast.success("Saved Successfully!", { position: toast.POSITION.BOTTOM_CENTER, autoClose: 800 });
    }
  };

  const handleCancel = () => {
    setSelectedTaxType(initialState.taxType);
    setSelectedGstTaxType(initialState.gstType);
    setAppTabVisibility(initialState.isAppTabVisible);
     };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <fieldset className="mx-2 mb-4 rounded-lg p-2 md:p-3 h-auto">
          <legend className="p-4 font-bold text-[#045545] text-lg ml-2">Tax Settings</legend>
          <div className="grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-1">
            <div>
              <label
                htmlFor="tax-type-select"
                className="block mb-2 text-sm text-[#008065] font-semibold"
              >
                Select Sales Tax Type
              </label>
              <select
                id="tax-type-select"
                value={selectedTaxType}
                onChange={handleTaxTypeChange}
                className="block w-full p-2 bg-white border-2 rounded-md shadow-sm focus:outline-none sm:text-sm border-[#008065]"
              >
                <option value="Bill Wise">Bill Wise Tax</option>
                <option value="Product Wise">Product Wise Tax</option>
              </select>
              {selectedTaxType === "Product Wise" && (
                <div className="mt-2">
                  <label
                    htmlFor="gst-tax-type-select"
                    className="block mb-2 text-sm text-[#008065] font-semibold"
                  >
                    Select GST Type
                  </label>
                  <select
                    id="gst-tax-type-select"
                    value={selectedGstTaxType}
                    onChange={(e) => setSelectedGstTaxType(e.target.value)}
                    className="block w-full p-2 bg-white border-2 rounded-md shadow-sm focus:outline-none sm:text-sm border-[#008065]"
                  >
                    <option value="GST">GST</option>
                    <option value="IGST">IGST</option>
                  </select>
                </div>
              )}
            </div>
          </div>
          <div className="flex w-full flex-col sm:flex-row px-4">
            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
              <div className="flex flex-col mb-6"></div>
            </div>
            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
              <div className="flex flex-col">
                <div className="relative"></div>
              </div>
            </div>
            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 px-2 mt-5">
              <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40 rounded">
                <button
                  type="button"
                  className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065]"
                  style={{
                    backgroundColor: "#008065",
                    fontSize: "14px",
                    fontFamily: "poppins",
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2 mt-5">
              <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40">
                <button
                  type="submit"
                  className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065]"
                  style={{
                    backgroundColor: "#008065",
                    fontSize: "14px",
                    fontFamily: "poppins",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default TaxTab;
