/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { defaultInputSmStyle, IconStyle } from "../commen/defaultStyles";
import NumberFormat from "../commen/NumberFormat";
import DeleteIcon from "../Icons/DeleteIcon";
import InvoiceIcon from "../Icons/InvoiceIcon";

import PurchaseFAModel from "./PurchaseFAModel";

function PurchaseFAProductData({
  productError,
  formData,
  setFormData,
  paramsId,
  setStockList,
  updateAssetErrors,
  assetErrors,
  assetRows,
  setAssetRows,
  setassetErrors,
  editValue,
  setCashAmount,
  setSelectedPayment,
  setchequeAmount,
  setcreditAmount,
  setbalanceAmount,
  setreceivedAmount,
  fieldErrors,
  vendorErrors,
  creditAmount
}: any) {
  // *******************************Product Sction*********************************

  const [newExistProduct, setNewexistProduct] = useState([{ exist: "", originalValue: '' }] as any);
  const [assetTaxx, setAssetTax] = useState()


  const handleInputChange = (index: number, fieldName: string, value: any) => {
    if (fieldName === " ") {
      setAssetTax(value)
    }
    // newExistProduct[index]["exist"] = value;
    const newArray: any = [...assetRows];
    newArray[index][fieldName] = value;
    updateAssetErrors(index, fieldName, value);

    // Function to perform calculations
    const calculateAmounts = (index: number) => {
      const rate = parseFloat(newArray[index].rate) || 0;
      const qty = parseFloat(newArray[index].qty) || 0;
      const discount = parseFloat(newArray[index].discount) || 0;
      const assetTax = parseFloat(newArray[index].assetTax) || 0;
      // const cgst = parseFloat(newArray[index].cgst) || 0;
      // const igst = parseFloat(newArray[index].igst) || 0;

      // Base amount calculation
      let baseAmount = rate * qty;
      // Update amount based on discount
      let discountedAmount = baseAmount - (baseAmount * discount / 100);

      // Calculate tax based on selected expenseTax
      let taxAmount = 0;
      let taxPercentage = 0
      let igstTaxPercentage = 0
      if (newArray[index]?.assetTax?.startsWith("GST")) {
        switch (newArray[index].assetTax) {
          case "GST 5%":
            taxAmount = 5 / 100 * discountedAmount;
            taxPercentage = 5
            break;
          case "GST 12%":
            taxAmount = 12 / 100 * discountedAmount;
            taxPercentage = 12
            break;
          case "GST 18%":
            taxAmount = 18 / 100 * discountedAmount;
            taxPercentage = 18
            break;
          case "GST 28%":
            taxAmount = 28 / 100 * discountedAmount;
            taxPercentage = 28
            break;
        }
      } else if (newArray[index]?.assetTax?.startsWith("IGST")) {
        switch (newArray[index].assetTax) {
          case "IGST 5%":
            taxAmount = 5 / 100 * discountedAmount;
            igstTaxPercentage = 5;
            break;
          case "IGST 12%":
            taxAmount = 12 / 100 * discountedAmount;
            igstTaxPercentage = 12;
            break;
          case "IGST 18%":
            taxAmount = 18 / 100 * discountedAmount;
            igstTaxPercentage = 18;
            break;
          case "IGST 28%":
            taxAmount = 28 / 100 * discountedAmount;
            igstTaxPercentage = 28;
            break;
        }
      }

      // Update amounts in the newArray
      newArray[index]["amount"] = (discountedAmount + taxAmount).toFixed(2);
      newArray[index]["nontaxableAmount"] = baseAmount - (discount / 100 * baseAmount);
      newArray[index]["gst"] = taxPercentage
      newArray[index]["igst"] = igstTaxPercentage
      newArray[index]["cgstAmount"] = (assetTax / 100) * rate;
      // newArray[index]["sgstAmount"] = (sgst / 100) * rate;
    };

    // Check if the field changed is qty, rate, discount, or assetTax
    if (fieldName === "qty" || fieldName === "rate" || fieldName === "discount" || fieldName === "assetTax") {
      calculateAmounts(index);
    }

    setAssetRows(newArray);

    // Recalculate subtotal and total amounts
    let subAmount = newArray.reduce((acc: number, item: any) => acc + Number(item.amount), 0).toFixed(2);
    let subAmountNonTax = newArray.reduce((acc: number, item: any) => acc + item.nontaxableAmount, 0);

    if (paramsId) {
      setFormData({
        ...formData,
        asset: newArray,
        subtotal: subAmount,
        toatalAmount: parseFloat(subAmount),
        
      });
      let receivedAmount: any = subAmount == 0 ? 0 : subAmount - formData.receivedAmount
      setbalanceAmount(parseFloat(receivedAmount.toFixed(2)))
    } else {
      setFormData({
        ...formData,
        asset: newArray,
        subtotal: subAmount,
        toatalAmount: subAmount,
        nontaxableTotal: subAmountNonTax,
      });
      if (creditAmount) {
        setbalanceAmount(subAmount)
        setcreditAmount(subAmount)

    }
    else {
        setCashAmount(subAmount)
        setchequeAmount(subAmount)
        setreceivedAmount(subAmount)
        setbalanceAmount('')
        fieldErrors.cashAmount = ''
        fieldErrors.creditAmount = ''
        fieldErrors.chequeAmount = ''
        vendorErrors["chequeNum"] = ''
        formData.chequeNum = ''
    }
    }
  };


  const handleExistingCalulation = (arr: any) => {
    if (arr.length === 0)
      arr.push({ amount: "", nontaxableAmount: "", nontaxableTotal: "", hsn: "", prodcut: "", qty: "", gst: "", igst: "", cgst: "", sgst: "", rate: "", igstAmount: "", cgstAmount: "", sgstAmount: "", discount: "" });
    const newArray: any = [...arr];
    for (let i = 0; i < newArray.length; i++) {
      if (newArray[i].qty || newArray[i].rate) {
        // Function to perform calculations
        const calculateAmounts = (index: number) => {
          const rate = parseFloat(newArray[index].rate) || 0;
          const qty = parseFloat(newArray[index].qty) || 0;
          const discount = parseFloat(newArray[index].discount) || 0;
          const sgst = parseFloat(newArray[index].sgst) || 0;
          const cgst = parseFloat(newArray[index].cgst) || 0;
          const igst = parseFloat(newArray[index].igst) || 0;

          // Base amount calculation
          let baseAmount = rate * qty;

          // Update amount based on discount
          let discountedAmount = baseAmount - (baseAmount * discount / 100);

          // Calculate tax based on selected expenseTax
          let taxAmount = 0;
          let taxPercentage = 0
          let igstTaxPercentage = 0
          if (newArray[index]?.expenseTax?.startsWith("GST")) {
            switch (newArray[index].expenseTax) {
              case "GST 5%":
                taxAmount = 5 / 100 * discountedAmount;
                taxPercentage = 5
                break;
              case "GST 12%":
                taxAmount = 12 / 100 * discountedAmount;
                taxPercentage = 12
                break;
              case "GST 18%":
                taxAmount = 18 / 100 * discountedAmount;
                taxPercentage = 18
                break;
              case "GST 28%":
                taxAmount = 28 / 100 * discountedAmount;
                taxPercentage = 28
                break;
            }
          } else if (newArray[index]?.expenseTax?.startsWith("IGST")) {
            switch (newArray[index].expenseTax) {
              case "IGST 5%":
                taxAmount = 5 / 100 * discountedAmount;
                igstTaxPercentage = 5;
                break;
              case "IGST 12%":
                taxAmount = 12 / 100 * discountedAmount;
                igstTaxPercentage = 12;
                break;
              case "IGST 18%":
                taxAmount = 18 / 100 * discountedAmount;
                igstTaxPercentage = 18;
                break;
              case "IGST 28%":
                taxAmount = 28 / 100 * discountedAmount;
                igstTaxPercentage = 28;
                break;
            }
          }

          // Update amounts in the newArray
          newArray[index]["amount"] = (discountedAmount + taxAmount).toFixed(2);
          newArray[index]["nontaxableAmount"] = baseAmount - (discount / 100 * baseAmount);
          newArray[index]["gst"] = taxPercentage
          newArray[index]["igst"] = igstTaxPercentage
          newArray[index]["cgstAmount"] = (cgst / 100) * rate;
          newArray[index]["sgstAmount"] = (sgst / 100) * rate;
        };

        // Check if the field changed is qty, rate, discount, or expenseTax
        if (newArray[i] === "qty" || newArray[i] === "rate" || newArray[i] === "discount" || newArray[i] === "expenseTax") {
          calculateAmounts(i);
        }

        setAssetRows(newArray);

        // Recalculate subtotal and total amounts
        let subAmount = newArray.reduce((acc: number, item: any) => acc + Number(item.amount), 0).toFixed(2);
        let subAmountNonTax = newArray.reduce((acc: number, item: any) => acc + item.nontaxableAmount, 0);

        if (paramsId) {
          setFormData({
            ...formData,
            product: newArray,
            subtotal: subAmount,
            toatalAmount: parseFloat(subAmount),
          });
          // setBalanceAmount(parseFloat(subAmount) - formData.receivedAmount);
        } else {
          setFormData({
            ...formData,
            product: newArray,
            subtotal: subAmount,
            toatalAmount: subAmount,
            nontaxableTotal: subAmountNonTax,
          });
          // setBalanceAmount(creditAmount ? subAmount : subAmount);
          setCashAmount(subAmount);
        }
      }
    }
    setAssetRows(newArray);

     let finalErrors: any = [];
    // let object: any = {};
    let values = newArray?.map((item: any, index: any) => {
      let object: any = {};
      object.asset_name = item.asset_name ? true : false;
      object.rate = item.rate ? true : false;
      object.qty = item.qty ? true : false;
      finalErrors.push(object);
      return object
    });
    setassetErrors(values);
  };


  const taxOptions = [
    { label: "GST 5%", value: "GST 5%" },
    { label: "GST 12%", value: "GST 12%" },
    { label: "GST 18%", value: "GST 18%" },
    { label: "GST 28%", value: "GST 28%" },
    { label: "IGST 5%", value: "IGST 5%" },
    { label: "IGST 12%", value: "IGST 12%" },
    { label: "IGST 18%", value: "IGST 18%" },
    { label: "IGST 28%", value: "IGST 28%" },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleConfirm = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const removeRow = (index: any, row: any) => {

    const newArray = [...assetRows];
    const newErrors = [...assetErrors];
    const existArray = [...newExistProduct];
    existArray.splice(index, 1)
    setNewexistProduct(() => {
      return existArray.length === 0 ? [{ exist: '', originalValue: '' }] : existArray
    });

    newArray.splice(index, 1);
    handleExistingCalulation(newArray);
    setAssetRows(newArray);
    // setbalanceAmount("")
    setCashAmount('')
    // setSelectedPayment('')
    formData.paymentType = ''
    if (newErrors.length > 1)
      newErrors.splice(index, 1);
    setassetErrors(newErrors);
    let amountValue = 0
    for (let i = 0; i < newArray.length; i++) {
        amountValue += newArray[i].amount
    }
    if (paramsId) {
      setSelectedPayment(formData.paymentType)
      setbalanceAmount(parseFloat((amountValue - formData.receivedAmount).toFixed(2)))
    }
    else {
      setCashAmount('')
      setbalanceAmount("")
      setcreditAmount("")
      setchequeAmount("")
      setSelectedPayment('')
    }
    if (existArray.length === 0) {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        ...prevFormData.tax = [],
        subtotal: 0,
        toatalAmount: 0,
      }));
    }

  };

  useEffect(() => {
    if (editValue) {
      setAssetRows(formData.asset);
      let obj: any = []
      formData.asset.map((item: any) => {
        obj.push({ exist: item.qty, originalValue: item.qty })
      })
      setNewexistProduct(obj)
      // setNewexistProduct((prev:any)=>[...prev,{exist: Number(data.productQuantity),originalValue: Number(data.productQuantity)}])
    } else {
      setAssetRows([
        { asset_name: "", discount: "", rate: "", qty: "", amount: 0, taxpersentage: "" },
      ]);
    }
  }, [editValue]);

  const handleSelect = (dataArray: any[]) => {

    setAssetRows(dataArray);
    const containsEmptyRows = assetRows.some((row: any) =>
      Object.values(row).some((value) => !value)
    );
    const containsEmptyRowsinExistProduct = newExistProduct.some((row: any) =>
      Object.values(row).some((value) => !value)
    );

    if (containsEmptyRowsinExistProduct) {
      const nonEmptyRows = newExistProduct.filter((row: any) =>
        Object.values(row).some(Boolean)
      );
      setNewexistProduct(nonEmptyRows);
    }
    if (containsEmptyRows) {
      const nonEmptyRows = assetRows.filter((row: any) =>
        Object.values(row).some(Boolean)
      );
      setAssetRows(nonEmptyRows);
    }

    const newRows = dataArray.map((data) => ({

      asset_name: data.asset_name,
      rate: 0,
      qty: 0,
      discount: "",
      taxpersentage: "",
      amount: 0,
      asset_id: data._id.$oid,
      fixedRate:"",
      transactionType: "Purchase FA"
    }));

    setAssetRows((prevRows: any[]) => {
      const updatedRows = [...prevRows, ...newRows];
      handleExistingCalulation(updatedRows);
      return updatedRows;
    });

    setNewexistProduct((prev: any) => [
      ...prev,
      ...dataArray.map((row: any) => ({
        exist: Number(row.productQty),
        originalValue: Number(row.productQty)
      }))
    ]);

    setIsModalOpen(false);
  };


  return (
    <div className="mb-4  overflow-x-auto">

      <div className="sm:overflow-x-auto xl:overflow-hidden custom-scrollbar">
        <div className="sm:w-[160%] xl:w-auto">
          <div
            className={
              "hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 mb-2 text-white"
            }
            style={{ backgroundColor: "#008065" }}
          >
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-6/12 sm:w-1/4 md:w-[17%] lg:w-[22%] xl:w-[23%] 2xl:w-[305] text-left"}
            >
              <span className="inline-block">S No</span>
            </div>
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 md:w-6/12 2xl:w-10/12 text-left sm:pr-10"}
            >
              <span className="inline-block">Asset</span>
            </div>

            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 text-left xl:mr-2 2xl:ml-6"}
            >
              Rate
            </div>
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 text-left xl:w-ml-4 2xl:ml-6"}
            >
              Qty
            </div>
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 text-left xl:ml-4 2xl:ml-6 lg:mr-9"}
            >
              Disc(%)
            </div>
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 text-left xl:ml-4 2xl:ml-6"}
            >
              Tax
            </div>

            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={"  w-full sm:w-4/12 text-right sm:pr-10"}
            >
              Total
            </div>
          </div>

          {assetRows?.map((productrow: any, index: any) => (

            <div
              key={index}
              className={
                "flex flex-col sm:flex-row rounded-lg  w-full   items-center relative " +
                (index % 2 !== 0 ? " bg-gray-50 " : "")
              }
            >
              <div
                style={{
                  fontFamily: "poppins",
                  textAlign: "left",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={"w-full sm:w-5/12  text-left  pr-3  flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden w-1/2 flex flex-row items-center">
                  S No
                </span>

                <div className="font-title flex-1 text-right sm:text-center 2xl:text-left 2xl:ml-8 pr-4 sm:pr-0">
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 900,
                    }}

                  >
                    {index + 1}
                  </div>
                </div>
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full sm:w-[310%] lg:w-[320%] 2xl:w-[380%] text-right pr-3 flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden w-1/2 flex flex-row items-center">
                  Assets
                </span>

                <div className="font-title flex-1 text-left">
                  <input
                    autoComplete="nope"
                    value={productrow.asset_name
                      ? productrow.asset_name
                      : ""}
                    placeholder="Asset Name"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      borderColor:
                        !assetErrors[index]?.asset_name && productError
                          ? "red"
                          : "#00A787",
                    }}
                    className={
                      defaultInputSmStyle +
                      " text-left border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full "
                    }
                    onChange={(event) => {
                      const value = event.target.value.trimStart();
                      handleInputChange(index, "asset_name", value);
                    }}
                    disabled
                  />

                </div>
              </div>

              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full  text-right  pr-3  flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden  w-1/2 flex flex-row items-center">
                  Rate
                </span>

                <div className="font-title flex-1 text-left">
                  <input
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      borderColor:
                        !assetErrors[index]?.rate && productError
                          ? "red"
                          : "#00A787",
                    }}
                    autoComplete="nope"
                    value={productrow.rate}
                    onChange={(event) => {
                      const value = event.target.value.replace(/[^0-9.]/g, '');
                      handleInputChange(index, "rate", value);
                    }}
                    placeholder="Rate"
                    type=""
                    className={
                      defaultInputSmStyle +
                      " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                    }

                  />

                </div>
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full text-right  pr-3 flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden md:hidden w-1/2 flex flex-row items-center">
                  Quantity
                </span>

                <div className="font-title flex-1 text-left">
                  <input
                    autoComplete="nope"
                    type=""
                    value={productrow.qty}
                    onChange={(event) => {
                      let value = event.target.value.replace(/[^0-9]/g, '');
                      if (value.startsWith('0')) {
                        value = value.substring(1); // Remove the leading '0'
                      }
                      handleInputChange(index, "qty", value);
                    }}
                    placeholder="Qty"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      borderColor:
                        !assetErrors[index]?.qty && productError
                          ? "red"
                          : "#00A787",
                    }}
                    className={
                      defaultInputSmStyle +
                      " text-right remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full  "
                    }
                  />

                </div>
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full  text-right  pr-3  flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden md:hidden w-1/2 flex flex-row items-center">
                  Discount
                </span>

                <div className="font-title flex-1 text-left">
                  <input
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      borderColor: "#00A787",
                    }}
                    autoComplete="nope"
                    value={productrow.discount}
                    onChange={(event) => {
                      const value = event.target.value.replace(/[^0-9.]/g, '');
                      if (parseFloat(value) > 100) {
                        return 0;
                      }
                      handleInputChange(index, "discount", value)
                    }}
                    placeholder="Discount"
                    type=""
                    className={
                      defaultInputSmStyle +
                      " text-right  border-2 focus:outline-none rounded-[7px] px-2 h-9   w-full  "
                    }
                  />
                </div>

              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full  text-left  pr-3  flex flex-row sm:block mb-2 sm:w-[310%] lg:w-[110%] 2xl:w-[180%]"}
              >
                <span className="sm:hidden md:hidden w-1/2 flex flex-row items-center">
                  Tax
                </span>

                <div className="font-title flex-1 text-left">
                  <select
                    name="assetTax"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      borderColor: "#00A787",
                    }}
                    value={productrow.assetTax || assetTaxx || ""}
                    onChange={(event) => {
                      const value = event.target.value.trimStart();
                      handleInputChange(index, "assetTax", value);
                    }}
                    className={`${defaultInputSmStyle} text-left border-2 focus:outline-none rounded-[7px] h-9 w-full`}
                  >
                    <option value="" disabled>
                      Select Tax
                    </option>
                    {taxOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>

              </div>


              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full  text-right pr-2 sm:pr-12 flex flex-row sm:block whitespace-nowrap"}
              >
                <span className="sm:hidden w-1/2 flex flex-row items-center">
                  Total
                </span>
                <span className={"inline-block w-1/2 sm:w-full px-1 h-9 pt-2 overflow-hidden"}>
                  <NumberFormat value={productrow?.amount} />
                </span>
              </div>

              <div
                onClick={() => removeRow(index, productrow)}
                className="w-full sm:w-10 sm:absolute sm:right-0 pl-2 h-9 pt-2 sm:mt-[-17px] mb-3 md:mb-0"
              >
                <div className="w-full text-[#9C0000] font-semibold font-title sm:h-8 sm:w-8 cursor-pointer rounded-2xl bg-[#FFE0E0] flex justify-center items-center text-center p-[5px]">
                  <DeleteIcon className="h-4 w-4" style={IconStyle} />
                  <span className="block sm:hidden">Delete Product</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Add Product Actions */}

      <div
        style={{ fontFamily: "poppins", fontSize: "14px" }}
        className="flex flex-col sm:flex-row rounded-lg sm:visible w-full px-0 md:px-4 pl-2 py-0 md:py-2 items-center sm:justify-end"
      >
        <div
          style={{ fontFamily: "poppins", fontSize: "14px" }}
          className="w-full  text-right md:mr-5 2xl:mr-0 xl:mr-0 lg:mr-0 md:pr-0 2xl:pr-8 xl:pr-8 lg:pr-8 sm:pr-8 flex flex-row sm:block mb-1"
        ></div>
        <div className="w-full  text-right md:mr-5 2xl:mr-0 xl:mr-0 lg:mr-0 md:pr-0 2xl:pr-8 xl:pr-8 lg:pr-8 sm:pr-8 flex flex-row sm:block mb-1">

        </div>
        <div
          style={{ fontFamily: "poppins", fontSize: "14px" }}
          className="font-title w-full text-right md:pr-8 2xl:pr-8 xl:pr-8 lg:pr-8 sm:pr-8 flex flex-row sm:block mb-1"
        >
          {/* {productlist.length ? > 0 ?} */}
          <button
            type="button"
            className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-xs h-8 px-2  block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm"
            style={{ backgroundColor: "#008065" }}
            onClick={handleConfirm}
          >
            <InvoiceIcon style={IconStyle} className="h-5 w-5" />
            Add Existing Purchase FA
          </button>
        </div>
      </div>
      {
        isModalOpen && (
          <PurchaseFAModel
            setStockList={setStockList}
            closeModal={closeModal}
            setAssetRows={setAssetRows}
            assetRows={assetRows}
            handleSelect={handleSelect}
          />
        )
      }
      {/* {
        showConfirmation && (
          <StockUpdateModel
            message="Are you sure you want to proceed?"
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )
      } */}
    </div >
  );
}

export default PurchaseFAProductData;

