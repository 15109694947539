import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ServiceDetails, ServiceDetailsGet, ServicesDeactive, serviceUpdate } from '../../redux/actions/serviceActions';
import ServiceTable from '../../Tables/ServiceTable';
import ConfirmationDeactivePopup from '../commen/ConfirmationDeactivePopup';
import { serviceUnitsAll } from '../../redux/actions/serviceUnitsActions';

const ServiceSettings = () => {
  const usersignin = useSelector((state: any) => state.userSignin);
  const token = usersignin?.userInfo?.token;

  const dispatch = useDispatch()
  const [serviceId, setServiceId] = useState("serv-0001" as any);

  const [formData, setFormData] = useState({
    service_id: null,
    service_name: "",
    rate: "",
    units: "",
    service_units: "",
    description: "",
    status: true,
    user_id: token?._id,
  } as any);

  const [serviceList, setServiceList] = useState([] as any);
  const [serv_id, setServid] = useState(null as any);

  // const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedUnit(event.target.value);
  //   setFormData({ ...formData, units: event.target.value });
  //   setServicePageErrors({ ...servicePageErrors, units: "" })
  // };


  const handleChange = (e: any) => {
    const { name, value } = e.target;
    let newvalue = value.trimStart();
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: newvalue,
    }));
    setServicePageErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const [servicePageErrors, setServicePageErrors] = useState<{
    service_id?: string;
    service_name?: string;
    rate?: string;
    units?: string
  }>({});

  const validateProdForm = () => {
    let isValid = true;
    const errors: any = {};

    // if (formData?.service_id.trim() === "") {
    //   console.log("Service ID Before Validation:", formData.service_id);
    //   console.log("Trimmed Service ID Length:", formData.service_id.trim().length);
    //   console.log("service ID========>", formData.service_id)
    //   errors.service_id = "ID  is Required.";
    //   isValid = false;
    // }
    if (formData?.service_name.trim() === "") {
      errors.service_name = "Service Name is Required.";
      isValid = false;
    }
    if (formData?.rate.trim() === "") {
      errors.rate = "Rate is Required.";
      isValid = false;
    }
    if (formData?.units.trim() === "") {
      errors.units = "Units is Required.";
      isValid = false;
    }

    setServicePageErrors(errors);
    return isValid;
  };


  const fetchData = () => {
    dispatch(ServiceDetailsGet(token?._id) as any).then((res: any) => {
      if (res) {
        if (res.payload.length > 0) {
          const [, num] = res.payload[0].service_id.split("-");
          let numeric_part = Number(num);
          let next_numeric_part = numeric_part + 1;
          const paddedIndex = String(next_numeric_part).padStart(4, "0");
          setServiceId(`serv-${paddedIndex}`);
        } else {
          const paddedIndex = String(1).padStart(4, "0");
          setServiceId(`serv-${paddedIndex}`);
        }
        setServiceList(res.payload);
      }
    });
  };
  const fetchDataForUnits = () => {
    dispatch(serviceUnitsAll(token?._id) as any).then((res: any) => {
    
    });
  }
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [servieNew_id, setServiceNewId] = useState(null as any);
  const handleConfirm = () => {

    dispatch(ServicesDeactive(servieNew_id, formData) as any).then((res: any) => {
      if (res) {
        fetchData();
        setServiceNewId(null);
        setFormData({
          service_id: "",
          service_name: "",
          service_units: "",
          rate: "",
          units: "",
          qty: "",
          description: "",
          status: "",
          user_id: token?._id,
        } as any);
        setShowConfirmation(false);
      }
    });
  }

  const handleDeactiveSection = (data: any) => {

    setShowConfirmation(true);
    setServiceNewId(data?._id?.$oid);
    setFormData({
      service_id: data.service_id,
      service_name: data.service_name,
      service_units: data.service_units,
      rate: data.rate,
      units: data.units,
      qty: data.qty,
      description: data.description,
      status: data.status,
      user_id: token?._id,
    } as any);
  }

  const handleEditSection = (data: any) => {
    setFormData({
      service_id: data.service_id,
      service_name: data.service_name,
      service_units: data.service_units,
      rate: data.rate,
      units: data.units,
      qty: data.qty,
      description: data.description,
      status: data.status,
      user_id: token?._id,
    });

    setServid(data?._id?.$oid)

  }
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const validationErrors: Partial<any> = {};

    const Duplicate = serviceList.some((service: any) => {
      return (
        service.service_name.trim().toLowerCase() ===
        formData.service_name.trim().toLowerCase() &&
        service._id.$oid !== serv_id
      );
    });

    if (Duplicate) {
      validationErrors.product_name = "Service Name already exists.";
    }


    if (validateProdForm()) {
      if (serv_id) {
        dispatch(serviceUpdate(serv_id, formData) as any).then((res: any) => {
          if (res) {
            toast.success("Update SuccessFully !", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 800,
            });
            fetchData()
            resetData();
          }
        });
      } else {
        dispatch(ServiceDetails(formData) as any).then((res: any) => {
          if (res) {
            toast.success("Saved SuccessFully !", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 800,
            });
            fetchData()
            resetData();
          }
        });

      }

    }

  };
  useEffect(() => {
    fetchData()
    fetchDataForUnits()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetData = () => {
    setServicePageErrors({
      ...servicePageErrors,
      service_id: "",
      service_name: "",
      rate: "",
      units: "",
    })
    setFormData({
      service_id: "",
      service_name: "",
      service_units: "",
      rate: "",
      units: "",
      description: "",
      status: true,
      user_id: token?._id,
    })

  }

  const handleCancel = () => {
    setShowConfirmation(false);
  };


  return (
    <div className='mr-5 lg:mr-0'>
      <>
        <fieldset className="mx-3 mb-3 rounded-lg">
          <legend className="p-4  font-bold text-[#045545]  text-lg    ml-2 ">
            Service Details
          </legend>

          <form>
          <div className="rounded-xl bg-[#E1E8E7]">
  {/* Parent container with Tailwind grid classes */}
  <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 px-8 md:px-4 xl:px-6">
    {/* Service ID Input */}
    <div className="flex flex-col px-0 2xl:px-4">
      <label
        style={{ fontFamily: "poppins" }}
        className="text-[#008065] text-sm font-semibold required"
      >
        <span>Service ID</span>
      </label>
      <div className="relative">
        <input
          type="text"
          style={{
            fontFamily: "poppins",
            borderColor: servicePageErrors.service_id ? "red" : "#008065",
          }}
          autoComplete="nope"
          className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
          placeholder="Service ID"
          name="service_id"
          value={formData.service_id ? formData.service_id : serviceId}
          onChange={(e) => handleChange(e)}
        />
      </div>
      {servicePageErrors.service_id && (
        <span
          style={{
            fontFamily: "poppins",
            fontWeight: 700,
            color: "#ff0000",
          }}
          className="text-red-400 text-xs"
        >
          {servicePageErrors.service_id}
        </span>
      )}
    </div>

    {/* Service Name Input */}
    <div className="flex flex-col px-0 2xl:px-4">
      <label
        style={{ fontFamily: "poppins" }}
        className="text-[#008065] text-sm font-semibold required"
      >
        <span>Service Name</span>
      </label>
      <div className="relative">
        <input
          type="text"
          style={{
            fontFamily: "poppins",
            borderColor: servicePageErrors.service_name ? "red" : "#008065",
          }}
          autoComplete="off"
          className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
          placeholder="Service Name"
          name="service_name"
          value={formData.service_name}
          onChange={(e) => handleChange(e)}
        />
      </div>
      {servicePageErrors.service_name && (
        <span
          style={{
            fontFamily: "poppins",
            fontWeight: 700,
            color: "#ff0000",
          }}
          className="text-red-400 text-sm"
        >
          {servicePageErrors.service_name}
        </span>
      )}
    </div>

    {/* Rate Input */}
    <div className="flex flex-col px-0 2xl:px-4">
      <label
        style={{ fontFamily: "poppins" }}
        className="text-[#008065] text-sm font-semibold required"
      >
        <span>Rate</span>
      </label>
      <div className="relative">
        <input
          type="text"
          style={{
            fontFamily: "poppins",
            borderColor: servicePageErrors.rate ? "red" : "#008065",
          }}
          autoComplete="off"
          className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
          placeholder="Rate"
          name="rate"
          value={formData.rate}
          onChange={(e) => {
            const { value } = e.target;
            const regex = /^\d*\.?\d*$/;
            if (value === "" || regex.test(value)) {
              handleChange(e);
              setFormData({ ...formData, rate: value });
            }
          }}
        />
      </div>
      {servicePageErrors.rate && (
        <span
          style={{
            fontFamily: "poppins",
            fontWeight: 700,
            color: "#ff0000",
          }}
          className="text-red-400 text-sm"
        >
          {servicePageErrors.rate}
        </span>
      )}
    </div>

    {/* Select Units */}
    <div className="flex flex-col px-0 2xl:px-4">
      <label
        style={{ fontFamily: "poppins" }}
        className="text-[#008065] text-sm font-semibold required"
      >
        <span>Select Units</span>
      </label>
      <div className="relative">
        <select
          name="units"
          value={formData.units || ""}
          onChange={(e) => handleChange(e)}
          style={{
            fontFamily: "poppins",
            borderColor: !servicePageErrors.units ? "#008065" : "#ff0000",
          }}
          className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
        >
          <option value="" disabled hidden>
            Select Units
          </option>
          <option value="Qty">Qty</option>
          <option value="Ltr">Ltr</option>
          <option value="Kg">Kg</option>
          <option value="Others">Others</option>
        </select>
      </div>
      {servicePageErrors.units && (
        <span
          style={{
            fontFamily: "poppins",
            fontWeight: 700,
            color: "#ff0000",
          }}
          className="text-red-400 text-sm"
        >
          {servicePageErrors.units}
        </span>
      )}
    </div>

    {/* Service Units */}
    <div className="flex flex-col px-0 2xl:px-4">
      <label
        style={{ fontFamily: "poppins" }}
        className="text-[#008065] text-sm font-semibold"
      >
        <span>Service Units</span>
      </label>
      <div className="relative">
        <input
          type="text"
          style={{
            fontFamily: "poppins",
            borderColor: "#008065",
          }}
          className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
          placeholder="Eg: Per Month (Optional)"
          name="service_units"
          value={formData.service_units}
          onChange={(e) => handleChange(e)}
        />
      </div>
    </div>
  </div>
</div>




            <div className="flex w-full flex-col sm:flex-row mb-2 mt-5 2xl:px-2">
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-8 md:px-4 xl:px-6 2xl:px-8 2xl:w-[78%]">
                <div className="flex flex-col mb-4 w-full">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className=" text-[#008065]  text-sm font-semibold "
                  >
                    <span>Description</span>
                  </label>
                  <div className="relative">
                    <textarea
                      style={{ fontFamily: "poppins" }}
                      autoComplete="off"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-[100px] w-full 2xl:w-[78%]`}
                      placeholder="Description (Optional)"
                      name="description"
                      onChange={(e) => handleChange(e)}
                      value={formData.description}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  emptydiv">
                <div className="flex flex-col mb-4 "></div>
              </div>
            </div>
            <div
              className="sm:flex md:pl-[10px] md:pr-4"
              style={{ justifyContent: "end", marginBottom: "1rem" }}
            >
              <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5 justify-center">
                <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 goods rounded">
                  <button
                    type="button"
                    className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                    onClick={resetData}
                    style={{ fontFamily: "poppins", fontSize: "14px" }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5 justify-center">
                <div className="flex flex-col  w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40  goods  rounded">
                  <button
                    type="submit"
                    className="rounded-xl font-bold text-white items-center bg-[#008065]   text-sm h-8  w-full "
                    style={{ fontFamily: "poppins", fontSize: "14px" }}
                    onClick={(e: any) => handleSubmit(e)}
                  >
                    {/* {service_id ? "Update" : "Submit"} */}
                    {formData.service_id ? "Update" : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </fieldset>
        <ServiceTable serviceList={serviceList} handleEditSection={handleEditSection} handleDeactiveSection={handleDeactiveSection} />
      </>
      {showConfirmation && (
        <ConfirmationDeactivePopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
}

export default ServiceSettings;
