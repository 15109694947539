import { useEffect, useState } from "react";
import BankTable from "../../Tables/BankTable";
import { useDispatch, useSelector } from "react-redux";
import {
  BankDelete,
  BankSave,
  BanksGet,
  BanksUpdate,
} from "../../redux/actions/bankAction";
import ConfirmationPopup from "../commen/ConfirmationPopup";
import { toast } from "react-toastify";

interface FieldErrors {
  account_number?: string;
  ifsc_code?: string;
}


const BankPage = () => {
  const dispatch = useDispatch();
  const usersignin = useSelector((state: any) => state.userSignin);
  const {
    userInfo: { token },
  } = usersignin;
  const [formData, setFormData] = useState({
    bank_name: "",
    holder_name: "",
    account_number: "",
    ifsc_code: "",
    opening_balance: "",
    user_id: token?._id,
    defaultBankInfo: false,
  });
  const [banklist, setBankList] = useState([] as any);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const [bankid, setBankid] = useState(null as any);

  // ***************************cancel button section*****************************
  const resetProduct = () => {
    setFormData({
      bank_name: "",
      holder_name: "",
      account_number: "",
      ifsc_code: "",
      opening_balance: "",
      user_id: token?._id,
      defaultBankInfo: false,
    });
  };

  const handleCancelClick = () => {
    let obje = {
      bank_name: null,
      holder_name: null,
      account_number: null,
      ifsc_code: null,
      user_id: null,
    };
    setErrors({ ...obje });
    resetProduct();
    setBankid(null);
    setFieldErrors({});

  };

  // ***************************Value Enter Sction*****************************
  const [errors, setErrors] = useState({} as any);
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({
      ...errors,
      [name]: "",
    });
    const fieldName = name as keyof FieldErrors;
    let error = "";
    if (fieldName === "account_number" && !value.match(/^[0-9]{9,18}$/) && value) {
      error = "Invalid Account number";
    } else if (fieldName === "ifsc_code" && !value.match(/^[A-Z]{4}0[A-Z0-9]{6}$/) && value) {
      error = "Invalid IFSC Code";
    }
    setFieldErrors((prevErrors: any) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, defaultBankInfo: e.target.checked });
  };

  // ***************************Value Submit Sction*****************************
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const validationErrors: Partial<any> = {};
    if (!formData.bank_name.trim()) {
      validationErrors.bank_name = "Bank Name is Required.";
    }
    if (!formData.holder_name.trim()) {
      validationErrors.holder_name = "Holder Name is Required.";
    }
    if (!formData.account_number.trim()) {
      validationErrors.account_number = "Account Number is Required.";
    }
    if (!formData.ifsc_code.trim()) {
      validationErrors.ifsc_code = "IFSC is Required.";
    }

    const duplicate = banklist.some((bank: any) => ((bank.bank_name === formData.bank_name && bank.holder_name === formData.holder_name ) || (bank.account_number === formData.account_number) || (bank.ifsc_code === formData.ifsc_code)) && bank._id.$oid !== bankid);
    if (duplicate) {
      toast.error("Account Details already exists", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 800,
      });
      return;
    }

    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      if (bankid) {
        dispatch(BanksUpdate(bankid, formData) as any).then((res: any) => {
          if (res) {
            setFormData({
              bank_name: "",
              holder_name: "",
              account_number: "",
              ifsc_code: "",
              opening_balance:"",
              user_id: token?._id,
              defaultBankInfo: false,
            });
            setBankid(null);
            fetchData();
          }
          toast.success("Update SuccessFully !", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
        });
      } else {
        if (formData) {
          dispatch(BankSave(formData) as any).then((res: any) => {
            if (res) {
              setFormData({
                bank_name: "",
                holder_name: "",
                account_number: "",
                ifsc_code: "",
                opening_balance:"",
                user_id: token?._id,
                defaultBankInfo: false,
              });
              fetchData();
              setBankid(null);
            }
            toast.success("Saved SuccessFully !", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 800,
            });
          });
        }

      }
    }
  };

  const onDelete = (data: any) => {
    setShowConfirmation(true);
    setBankid(data._id?.$oid);
  };
  // **********************************Get Call Section**************************************
  const fetchData = () => {
    dispatch(BanksGet(token?._id) as any).then((res: any) => {
      if (res) {
        setBankList(res.payload);
      }
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEdit = (data: any) => {
    let obje = {
      bank_name: null,
      holder_name: null,
      account_number: null,
      ifsc_code: null,
      user_id: null,
    };
    setErrors({ ...obje });
    setFormData({
      bank_name: data.bank_name,
      holder_name: data.holder_name,
      account_number: data.account_number,
      ifsc_code: data.ifsc_code,
      opening_balance: data.opening_balance,
      user_id: token?._id,
      defaultBankInfo: data.defaultBankInfo,
    });
    setBankid(data._id?.$oid);
  };

  const handleConfirm = () => {
    dispatch(BankDelete(bankid) as any).then((res: any) => {
      if (res) {
        fetchData();
        setBankid(null);
        setShowConfirmation(false);
        resetProduct();
      }
    });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  return (
    <div className="pr-5 md:pr-5  lg:pr-0  xl:pr-0">
      <div className=' className="bg-[#E1E8E7]'>
        <fieldset className="mx-3 mb-4 rounded-lg">
          <legend className="p-4  font-bold text-[#008065]  text-lg   ml-2">
            Bank Info
          </legend>
          <div className="rounded-xl xl:px-2 lg:px-2 md:px-2">
            <div className="flex ml-2 gap-2">
              <div>
                <input
                  type="checkbox"
                  name="defaultBankInfo"
                  placeholder="defaultBankInfo"
                  checked={formData.defaultBankInfo}
                  onChange={handleCheckboxChange}
                  className={
                    "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                  }
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                />
              </div>
              <div className="text-[#008065] font-semibold">
                <label>Default Bank Info</label>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 gap-4 w-full px-8 xl:px-0">
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-col font-title flex-1 px-2 ">
                <div className="flex flex-col  mb-4 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className="mb-1 text-[#008065]  text-sm font-semibold required"
                  >
                    <span>Bank Name</span>
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      name="bank_name"
                      style={{ fontFamily: "poppins" }}
                      autoComplete="nope"
                      placeholder=" Bank Name"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full ${errors.bank_name
                         ? " border-2  border-[red] w-11/12"
                          : ""
                        }`}
                      value={formData.bank_name}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.bank_name && (
                    <span
                      style={{ 
                        fontFamily: "poppins", 
                        fontWeight: 700, 
                        color: "#ff0000", 
                      }}
                      className="text-red-400 text-sm"
                    >
                      {errors.bank_name}
                    </span>
                  )}
                </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-col font-title flex-1 px-2">
                <div className="flex flex-col  mb-4 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className="mb-1 text-[#008065]  text-sm font-semibold required  w-44"
                  >
                    <span>Account Holder Name</span>
                  </label>
                  <div className="relative">
                    <input
                      name="holder_name"
                      style={{ fontFamily: "poppins" }}
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full ${errors.holder_name
                         ? " border-2  border-[red] w-11/12"
                        : ""
                      }`}
                      placeholder="Account Holder Name"
                      value={formData.holder_name}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.holder_name && (
                    <span
                      style={{ 
                        fontFamily: "poppins", 
                        fontWeight: 700, 
                        color: "#ff0000",
                       }}
                      className="text-red-400 text-sm"
                    >
                      {errors.holder_name}
                    </span>
                  )}
                </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-col font-title flex-1 px-2">
                <div className="flex flex-col  mb-4 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className="mb-1 text-[#008065]  text-sm font-semibold required"
                  >
                    <span>Account Number</span>
                  </label>
                  <div className="relative">
                    <input
                      name="account_number"
                      type=""
                      placeholder="Account Number"
                      style={{ fontFamily: "poppins" }}
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full ${(errors.account_number || fieldErrors.account_number) 
                        ? " border-2  border-[red] w-11/12" 
                        : ""
                      }`}
                      value={formData.account_number}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^[0-9]]/g, '');
                        handleChange(e);
                        setFormData({ ...formData, account_number: value });
                      }}
                    />
                  </div>
                  {fieldErrors.account_number ? (
                    <span
                      style={{ 
                        fontFamily: "poppins", 
                        fontWeight: 700, 
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-sm"
                    >
                      {fieldErrors.account_number}
                    </span>
                  ) : errors.account_number ? (
                    <span
                      style={{ 
                        fontFamily: "poppins", 
                        fontWeight: 700, 
                        color: "#ff0000", 
                      }}
                      className="text-red-400 text-sm"
                    >
                      {errors.account_number}
                    </span>
                  ) : (<></>)}
                </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-col font-title flex-1 px-2">
                <div className="flex flex-col  mb-4 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className="mb-1 text-[#008065]  text-sm font-semibold required"
                  >
                    <span>IFSC Code</span>
                  </label>
                  <div className="relative">
                    <input
                      name="ifsc_code"
                      style={{ fontFamily: "poppins" }}
                      placeholder="IFSC Code"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full ${(errors.ifsc_code || fieldErrors.ifsc_code) 
                        ? " border-2  border-[red] w-11/12" 
                        : ""
                      }`}
                      value={formData.ifsc_code}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {fieldErrors.ifsc_code ? (
                    <span
                      style={{ 
                        fontFamily: "poppins", 
                        fontWeight: 700, 
                        color: "#ff0000",
                       }}
                      className="text-red-400 text-sm"
                    >
                      {fieldErrors.ifsc_code}
                    </span>
                  ) : errors.ifsc_code ? (
                    <span
                      style={{ 
                        fontFamily: "poppins", 
                        fontWeight: 700, 
                        color: "#ff0000",
                       }}
                      className="text-red-400 text-sm"
                    >
                      {errors.ifsc_code}
                    </span>
                  ) : (<></>)}
                </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-col font-title flex-1 px-2">
                <div className="flex flex-col  mb-4 ">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className="mb-1 text-[#008065]  text-sm font-semibold"
                  >
                    <span>Opening Balance</span>
                  </label>
                  <div className="relative">
                    <input
                      type="number"
                      name="opening_balance"
                      style={{ fontFamily: "poppins" }}
                      placeholder="Opening Balance"
                      className={'border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full'}
                      value={formData.opening_balance}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {/* {fieldErrors.ifsc_code ? (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-xs"
                    >
                      {fieldErrors.ifsc_code}
                    </span>
                  ) : errors.ifsc_code ? (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-xs"
                    >
                      {errors.ifsc_code}
                    </span>
                  ) : (<></>)} */}
                </div>
              </div>

            </div>
          </div>
          <div className="rounded-xl px-3 mb-3">
            <div className="flex w-full flex-col sm:flex-row">
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col  mb-4 "></div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                <div className="flex flex-col  mb-4 "> </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 px-2 xl:justify-end justify-center">
                <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4">
                  <button
                    type="button"
                    className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                    style={{
                      backgroundColor: "#008065",
                      fontFamily: "poppins",
                      fontSize: "14px",
                    }}
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </button>
                </div>
              </div>

              <div className="mb-2 sm:mb-0 sm:text-left text-default-color  flex flex-row  font-title flex-2 px-2 xl:justify-end justify-center">
                <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4">
                  <button
                    onClick={(e: any) => handleSubmit(e)}
                    className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                    style={{
                      backgroundColor: "#008065",
                      fontFamily: "poppins",
                      fontSize: "14px",
                    }}
                  >
                    {bankid ? "Update" : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <BankTable
        bankDetails={banklist}
        deleteBank={onDelete}
        editBank={onEdit}
      />

      {showConfirmation && (
        <ConfirmationPopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default BankPage;
