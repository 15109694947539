/* eslint-disable eqeqeq */
import { Box, Checkbox } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { expenseAll } from "../../redux/actions/expenseAction";
import { VendorProductAll } from "../../redux/actions/vendorAction";

function ExpenseModel({
    closeModal,
    handleSelect,
    prodcutRows,
}: any) {
    const dispatch = useDispatch();
    const [existingProductList, setExistingProductList] = useState([] as any);
    const [categoryList, setCategoryList] = useState([] as any)
    const [selectedProducts, setSelectedProducts] = useState([] as any);
    const usersignin = useSelector((state: any) => state.userSignin);
    const {
        userInfo: { token },
    } = usersignin;



    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {

            const newSelectedProducts = existingProductList
                .filter(
                    (item: any) =>
                        item.expense_Category &&
                        !prodcutRows.some(
                            (value: any) => value.expense_Category === item.expense_Category
                        )
                )
                .map((item: any) => item.expense_Category);
            setSelectedProducts(newSelectedProducts);
        } else {
            setSelectedProducts([]);
        }
    };

    const handleSelectProduct = (expenseCategory: string) => {
        setSelectedProducts((prevSelectedProducts: any) =>
            prevSelectedProducts.includes(expenseCategory)
                ? prevSelectedProducts.filter((name: string) => name !== expenseCategory)
                : [...prevSelectedProducts, expenseCategory]
        );
    };

    const handleOk = () => {
        const selectedProductsData = selectedProducts.map((productName: string) =>
            existingProductList.find((item: any) => item.expense_Category === productName)
        );
        handleSelect(selectedProductsData);
        closeModal();
    };

    const handleCancelClick = () => {
        closeModal();
    };



    const fetchData = () => {

        dispatch(expenseAll() as any).then((res: any) => {
            setExistingProductList(res.payload);
            setCategoryList(res.payload)
        });
    };
    const fetchDatas = () => {
        dispatch(VendorProductAll(token._id) as any).then((res: any) => {

        })
    }
    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value.toLowerCase();
        const filteredData = categoryList.filter((category: any) =>
            category.expense_Category.toLowerCase().includes(query)
        );
        setExistingProductList(filteredData);
    };

    // **
    useEffect(() => {
        fetchData();
        fetchDatas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rows = existingProductList.map((product: any, index: any) => ({
        id: uuidv4(),
        sno: index + 1,
        ...product,
    }));

    const columns: GridColDef[] = [
        {
            field: "select",
            headerName: "",
            width: 80,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => (
                <Checkbox
                    indeterminate={
                        selectedProducts.length > 0 && selectedProducts.length < rows.length
                    }
                    checked={selectedProducts.length === rows.length}
                    onChange={(event) => handleSelectAll(event)}
                />
            ),
            renderCell: (params) => (
                <Checkbox
                    onChange={() => handleSelectProduct(params.row.expense_Category)}
                    checked={
                        selectedProducts.includes(params.row.expense_Category)
                    }
                // disabled={
                //     !params.row.qty ||
                //     prodcutRows?.some(
                //         (value: any) => value.productName === params.row.productName
                //     )
                // }
                />
            ),
        },
        { field: "sno", headerName: "S No", width: 190 },
        {
            field: "expense_Category",
            headerName: "Expense Category",
            flex: 2,
            minWidth: 190,
        },
        // {
        //     field: "rate",
        //     headerName: "Rate",
        //     flex: 2,
        //     minWidth: 150,
        // },

    ];

    return (
        <div>
            <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 mx-2">
                <motion.div
                    className="modal-container"
                    aria-labelledby="modal-title"
                    role="dialog"
                    aria-modal="true"
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                    }}
                    transition={{
                        type: "spring",
                        damping: 18,
                    }}
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75">
                        <motion.div className="relative rounded-lg overflow-hidden bg-[white] text-center shadow-xl transform transition-all my-8 w-1/3 mx-auto">
                            <div
                                className="bg-[#F1F7F6] p-[8px] m-[5px]"
                                style={{ borderRadius: "8px" }}
                            >
                                <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
                                    <div className="flex items-center">
                                        <div className="relative flex mb-2">
                                            <input
                                                onChange={(e) => onSearch(e)}
                                                type="text"
                                                id="simple-search"
                                                className="w-44  bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                                                placeholder="Search"
                                                required
                                            />
                                            <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                                                <svg
                                                    className="w-4 h-4"
                                                    aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <Box sx={{ height: "45vh", width: "100%" }}>
                                    <DataGrid
                                        // autoHeight
                                        sx={{
                                            ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                                            {
                                                color: "rgb(0, 128, 101)",
                                                fontWeight: 600,
                                                fontSize: "14px",
                                            },
                                            ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                                                fontWeight: 600,
                                                fontSize: "14px",
                                            },
                                            ".css-1rtad1": {
                                                display: "block !important",
                                            },
                                            ".css-j2otgh-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-j2otgh-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate":
                                            {
                                                color: " rgb(0, 128, 101) !important",
                                            },
                                        }}
                                        rows={rows}
                                        columns={columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 5,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[5, 10, 25, 50]}
                                        disableRowSelectionOnClick
                                    />
                                </Box>
                            </div>
                            <div className="bg-[white] px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-[white] text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={handleCancelClick}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#008065] text-base font-medium text-[white] hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={handleOk}
                                >
                                    OK
                                </button>
                            </div>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
}

export default ExpenseModel;
