
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {  useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { cashHandEditFindOne, cashInHandPost, cashinHandUpdate } from "../../redux/actions/CashInHandActions";
import { useEffect } from "react";

const CashInHand = () => {

  const dispatch = useDispatch();
  const usersignin = useSelector((state: any) => state?.userSignin);
  // const token = usersignin?.userInfo?.token;


  const { userInfo } = usersignin;
  const userid = userInfo?.token._id;
  const params = useParams();
  const { id } = params;

  const formatDate = (date:any) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const today = new Date();

  const navigate = useNavigate();

  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      transaction_type: "Add Cash",   
      name:"",
      amount: "",
      adjustmentDate: formatDate(today),
      description: "",
      user_id: userid,
    },
  });

  const cashAddReduce = ["Add Cash", "Reduce Cash"];

  const onSubmit = (data: any) => {
    if (id) {
      let postupdatedata = {
        //balanceAmount: previousBalanceAmount - data.receivedAmount,
        transaction_type: data.transaction_type,
        amount: data.amount,
        adjustmentDate: data.adjustmentDate,
        description: data.description,
        user_id: userid,
        cashinhandId:id,
        name:"",
      };
      dispatch(cashinHandUpdate(id, postupdatedata) as any).then((res: any) => {
        // if (id) {
        // }
        if (res) {
          // fetchData();
          toast.success("Cash Update Successfully!", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
          reset();
          navigate("/app/cashInHand");
          // reset()
          // setselectedOption("")
          // setPendingInvoiceList("")
        }
      });
    }
    else {

      let postdata = {
        transaction_type: data.transaction_type,
        amount: data.amount,
        adjustmentDate: data.adjustmentDate,
        description: data.description,
        user_id: userid,
        cashinhandId:"",
        name:"",
      };

      dispatch(cashInHandPost(postdata) as any).then((res: any) => {
        if (res?.payload?.paymentreceive_id) {

        }
        if (res) {
          // fetchData();
          toast.success("Cash Saved Successfully!", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
          reset();
          navigate("/app/cashInHand");
          // reset()
          // setselectedOption("")
          // setPendingInvoiceList("")
        }
      });
    }
  };

  

  const fetchDatas = () => {
    if (id) {
      dispatch(cashHandEditFindOne(id) as any).then((res: any) => {
        if (res?.type === "CASHINHAND_EDIT_FINDONE_SUCCESS") {
          setValue('transaction_type', res.payload?.transaction_type)
          setValue('amount', res.payload?.amount)
          setValue('adjustmentDate', res.payload?.adjustmentDate)
          setValue('description', res.payload?.description)
        }

      })
    }
  }

  useEffect(() => {
    
    fetchDatas()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleCancelClick = () => {
    reset();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-[#E1E8E7] pr-2 sm:pr-0" style={{ fontFamily: "Poppins" }}>
          <div className="p-4 text-2xl font-bold text-[#045545] ml-2">
            Cash Adjustment
          </div>
          <fieldset className="mx-3 rounded-lg px-2">
            <legend className="p-4 font-bold text-[#008065]">
              Cash Adjustment
            </legend>

            <div className="flex flex-col sm:flex-row lg:flex-row xl:flex-row gap-2   sm:px-3  md:px-3 lg:px-3 xl:px-3 2xl:px-3 mb-3 mt-3">
              {/* Adjustment Type */}
              <div className="flex flex-col mb-2 px-6 sm:px-0 w-full lg:w-1/4">
                <label className="required font-bold text-[#008065] text-sm">
                  Adjustment
                </label>
                <select
                  className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full  lg:w-11/12"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                    borderColor: errors.transaction_type ? "#FF0000" : "#008065",
                  }}
                  {...register("transaction_type", {
                    required: "Adjustment Type is required",
                  })}
                >
                  {cashAddReduce.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              {/* Amount */}
              <div className="flex flex-col mb-2 px-6 sm:px-0 w-full lg:w-1/4">
                <label className="font-bold text-[#008065] text-sm required">
                  <span>
                  Amount</span>
                </label>
                <input
                  type="number"
                  placeholder="Enter Amount"
                  className="border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full  lg:w-11/12"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                    borderColor: errors.amount ? "#ff0000" : "#008065",
                  }}
                  {...register("amount" , {
                    required: "Amount is required",
                    validate: (value:any) => value > 0 || "Enter Valid Amount",
                  })}
                  
                />
                { errors.amount && (
                    <span
                    style={{
                      fontFamily: "poppins",
                      fontWeight: 700,
                      color: "#ff0000",
                    }}
                    className="text-red-400 text-sm"
                  >
                      {errors.amount.message}
                    </span>
                  )}
              </div>

              {/* Adjustment Date */}
              <div className="flex flex-col mb-2 px-6 sm:px-0 w-full lg:w-1/4">
                <label className=" font-bold text-[#008065] text-sm">
                  <span>
                  Adjustment Date
                  </span>
                </label>
                <input
                readOnly
                  type="text" 
                  className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full  lg:w-11/12"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                    borderColor: errors.adjustmentDate ? "#FF0000" : "#008065",                 
                  }}
                  {...register("adjustmentDate", {
                    required: "Adjustment Date is required",
                  })}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row lg:flex-row xl:flex-row gap-2   sm:px-3  md:px-3 lg:px-3 xl:px-3 2xl:px-3 mb-3 mt-3">
              {/* Description */}
              <div className="flex flex-col mb-4 px-6 sm:px-0 w-full lg:w-1/2 xl:w-1/3 ">
                <label className="text-[#008065] font-semibold text-sm">
                  Description
                </label>
                <textarea
                  style={{ fontFamily: "Poppins" }}
                  className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-[100px] w-full sm:w-8/12 lg:w-11/12"
                  placeholder="Description (Optional)"
                  {...register("description")}
                />
              </div>
            </div>

            {/* Submit and Cancel Buttons */}
            <div className="rounded-xl px-3 mb-3 grid sm:justify-end">
            <div className="flex flex-col sm:flex-row ">
            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 px-2 self-center">
            <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4 ">
              <button
                type="button"
                className="rounded-xl font-bold text-white bg-[#008065] text-sm h-8 w-full"
                style={{ fontFamily: "Poppins" }}
                onClick={handleCancelClick}
              >
                Cancel
              </button>
              </div>
            </div>

            <div className="mb-2 sm:mb-0 sm:text-left text-default-color  flex flex-row  font-title flex-1 px-2 self-center">
              <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4">
              <button
                type="submit"
                className="rounded-xl font-bold text-white bg-[#008065] text-sm h-8 w-full"
                style={{ fontFamily: "Poppins" }}
              >
                Submit
              </button>
              </div>
              </div>
            </div>
            </div>

          </fieldset>
        </div>
      </form>
    </div>
  );
};

export default CashInHand;
