import api from "../axiosConfig";
import { CLIENT_DELETE_FAIL, CLIENT_DELETE_REQUEST, CLIENT_DELETE_SUCCESS, CLIENT_GET_FAIL, CLIENT_GET_FIND_ONE_FAIL, CLIENT_GET_FIND_ONE_REQUEST, CLIENT_GET_FIND_ONE_SUCCESS, CLIENT_GET_REQUEST, CLIENT_GET_SUCCESS, CLIENT_POST_FAIL, CLIENT_POST_REQUEST, CLIENT_POST_SUCCESS, CLIENT_UPDATE_FAIL, CLIENT_UPDATE_REQUEST, CLIENT_UPDATE_SUCCESS } from "../constants/clientConstants";


export const ClientSave = (client: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: CLIENT_POST_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.post(`/api/client/clientSave`, client, {
      headers: { Authorization: `Bearer ${token?.tocken}` }
    });
    return dispatch({ type: CLIENT_POST_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: CLIENT_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ClientUpdate = (id: any, prod: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: CLIENT_UPDATE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.put(`/api/client/clientUpdate/${id}`, prod, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: CLIENT_UPDATE_SUCCESS, payload: data });
  } catch (error: any) {
    return dispatch({
      type: CLIENT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ClientAll = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: CLIENT_GET_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.get(`/api/client/all/${id}`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: CLIENT_GET_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: CLIENT_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ClientFindOne = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: CLIENT_GET_FIND_ONE_REQUEST });
  try {
   
    const { data } = await api.get(`/api/client/${id}`);
    return dispatch({ type: CLIENT_GET_FIND_ONE_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: CLIENT_GET_FIND_ONE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const ClientDelete = (id: any) => async (dispatch: any, getState: any) => {
  dispatch({ type: CLIENT_DELETE_REQUEST });
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    const { data } = await api.delete(`/api/client/Clientdelete/${id}`, {
      headers: { Authorization: `Bearer ${token?.tocken}` },
    });
    return dispatch({ type: CLIENT_DELETE_SUCCESS, payload: data });

  } catch (error: any) {
    dispatch({
      type: CLIENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};