/* eslint-disable eqeqeq */
import { Box, Checkbox } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { ServiceDetailsGet } from "../../../redux/actions/serviceActions";

function ServiceModel({
  isOpen,
  closeModal,
  setProductRows,
  serviceRows,
  handleSelectService,
  prodcutRows,
}: any) {
  const dispatch = useDispatch();
  const [existingServiceList, setExistingServiceList] = useState([] as any);
  const [selectedProducts, setSelectedProducts] = useState([] as any);
  const usersignin = useSelector((state: any) => state.userSignin);
  const {
    userInfo: { token },
  } = usersignin;

  const servicelist = useSelector((state: any) => state.serviceGet);
  const { serviceGetdata } = servicelist;

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedProducts = existingServiceList
        .filter(
          (item: any) =>
            !serviceRows.some(
              (value: any) => value.service_name === item.service_name
            )
        )
        .map((item: any) => item.service_name);
      setSelectedProducts(newSelectedProducts);
    } else {
      setSelectedProducts([]);
    }
  };

  const handleSelectProduct = (productName: string) => {
    setSelectedProducts((prevSelectedProducts: any) =>
      prevSelectedProducts.includes(productName)
        ? prevSelectedProducts.filter((name: string) => name !== productName)
        : [...prevSelectedProducts, productName]
    );
  };

  const handleOk = () => {
    const selectedProductsData = selectedProducts.map((service_name: string) =>
      existingServiceList.find(
        (item: any) => item.service_name === service_name
      )
    );
    handleSelectService(selectedProductsData);
    // closeModal();
  };

  const handleCancelClick = () => {
    closeModal();
  };

  const rows = existingServiceList?.map((item: any, index: any) => ({
    id: uuidv4(),
    ...item,
    Sl_No: index + 1,
  }));

  const columns: GridColDef[] = [
    {
      field: "select",
      headerName: "",
      width: 80,
      editable: false,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <Checkbox
          sx={{
            ".css-j2otgh-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
              color: "green !important",
            },
          }}
          indeterminate={
            selectedProducts.length > 0 && selectedProducts.length < rows.length
          }
          checked={selectedProducts.length === rows.length}
          onChange={(event) => handleSelectAll(event)}
        />
      ),
      renderCell: (params) => (
        <Checkbox
          onChange={() => handleSelectProduct(params.row.service_name)}
          checked={
            selectedProducts.includes(params.row.service_name) &&
            !serviceRows.some(
              (value: any) => value.service_name === params.row.service_name
            )
          }
          disabled={serviceRows.some(
            (value: any) => value.service_name === params.row.service_name
          )}
        />
      ),
    },
    {
      field: "Sl_No",
      headerName: "Sl No",
      minWidth: 250,
      editable: false,
      flex: 2,
    },
    {
      field: "service_name",
      headerName: "Service Name",
      editable: false,
      minWidth: 260,
      renderCell: (params: any) => (
        <>{params?.row?.service_name ? params?.row?.service_name : "N/A"}</>
      ),
    },
    {
      field: "rate",
      headerName: "Rate",
      editable: false,
      minWidth: 260,
      flex: 2,
      renderCell: (params: any) => (
        <>{params?.row?.rate ? params?.row?.rate : "N/A"}</>
      ),
    },
    {
      field: "productQuantity",
      headerName: "Quantity",
      editable: false,
      minWidth: 260,
      flex: 2,
      renderCell: (params: any) => (
        <>
          {params?.row?.productQuantity ? params?.row?.productQuantity : "N/A"}
        </>
      ),
    },
    {
      field: "units",
      headerName: "Units",
      editable: false,
      minWidth: 260,
      flex: 2,
      renderCell: (params: any) => (
        <>{params?.row?.units ? params?.row?.units : "N/A"}</>
      ),
    },
  ];

  const fetchData = () => {
    dispatch(ServiceDetailsGet(token?._id) as any).then((res: any) => {
      if (res && res.payload) {
        const filteredList = res.payload.filter((item: any) => item.status !== false);
        
        setExistingServiceList(filteredList);
      }
    }).catch((error:any) => {
      console.error("Error fetching data:", error);
    });
  };
  
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const Value = e.target.value;
    let data = [...serviceGetdata];
    
    data = data?.filter((value: any) =>
      value?.service_name?.toLowerCase().includes(Value?.toLowerCase())
    );

    setExistingServiceList(data);
  };

  return (
    <motion.div
      className="modal-container"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        type: "spring",
        damping: 18,
      }}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75">
        <motion.div className="relative rounded-lg bg-[white] text-center shadow-xl transform transition-all my-8 w-11/12 mx-auto">
          <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row p-2.5">
            <div className="flex items-center">
              <div className="relative flex mb-2">
                <input
                  onChange={(e) => onSearch(e)}
                  type="text"
                  id="simple-search"
                  className="w-44  bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                  placeholder="Search"
                  required
                />
                <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                  <svg
                    className="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <Box
            sx={{
              height: 400,
              width: "100%",
              overflowX: "auto",
              overflowY: "auto",
            }}
          >
            <DataGrid
autoHeight
              sx={{
                color: "#000",
                fontSize: "14px",
                fontWeight: 500,
                ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                  {
                    backgroundColor: "#fff",
                    color: "#008060 !important",
                    fontWeight: 600,
                    fontSize: "14px",
                  },
                "& .MuiDataGrid-checkboxInput": {
                  color: "#008060 !important",
                },
                ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                  fontWeight: 600,
                  fontSize: "14px",
                },
                ".css-1rtad1": {
                  display: "block !important",
                },
                ".css-y7zzkd-MuiButtonBase-root-MuiCheckbox-root.Mui-checked ":
                  {
                    color: "#008060 !important",
                  },
                ".css-y7zzkd-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate":
                  {
                    color: "#008060 !important",
                  },
                ".css-j2otgh-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
                  color: "#008060 !important",
                },
              }}
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25, 50]}
            />
          </Box>
          <div className="bg-[white] px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-[white] text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#008065] text-base font-medium text-[white] hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleOk}
            >
              OK
            </button>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default ServiceModel;
