import styled from "@emotion/styled";
import React, { useState } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
const StyledDiv = styled("div")({
  position: "relative",
  marginRight: "0.5rem",
  marginTop: "0rem",
  overflowY: "auto",
  height: "73vh",
  "@media(min-width:2300px)": {
    height: "950px",
  },
  "&::-webkit-scrollbar": {
    width: "7px",
    height: "7px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#FFFFFF",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#d1d5db",
    borderRadius: "2px",
  },
});
const TrialBalanceSheet = () => {
  const [companyLists, setCompanyList] = useState(
    [] as { company_name: string }[]
  );
  

  const [isCurrent, setIsCurrent] = useState(false);
  const [isSubAccordion1, setIsSubAccordion1] = useState(false);
  const [isInputduties, setInputduties] = useState(false);
  const [isCaseAccount, setCase] = useState(false);
  const [isSubAccordion2, setIsSubAccordion2] = useState(false);
  const [isOpening, setOpening] = useState(false);
  const [isCase1, setCase1] = useState(false);
  const [isCaseAdjument, setCaseAdjument] = useState(false);
  const [isOutput, setOutput] = useState(false);
  const [isCurrents, setCurrents] = useState(false);
  const [isGst, setGst] = useState(false);
  const [isCurrentAssets, setCurrentAssets] = useState(false);
  const [isBankAccount, setBankAcccount] = useState(false);
  const [isSundry, setSundry] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isEquities, setEquities] = useState(false);
  const [isSale, setSale] = useState(false);
  const [isPurchase, setPurchase] = useState(false);
  const [isDirect, setDirect] = useState(false);
  const [isIndirect, setIndirect] = useState(false);
  const [isCurrentLiabilities, setCurrentLiabilities] = useState(false);
  const [isCapital, setCapital] = useState(false);

  const toggleAccordion1 = (e: any) => {
    setIsCurrent((prev) => !prev);
  };
  const toggleCaseAdjument = (e: any) => {
    e.stopPropagation(); //
    setCaseAdjument((prev) => !prev);
  };
  const toggleSubAccordion1 = (e: any) => {
    e.stopPropagation(); //
    setIsSubAccordion1((prev) => !prev);
  };
  const toggleSundry = (e: any) => {
    e.stopPropagation(); //
    setSundry((prev) => !prev);
  };

  const toggleCash = (e: any) => {
    e.stopPropagation(); //
    setCase1((prev) => !prev);
  };
  const toggleOutput = () => {
    setOutput(!isOutput);
  };

  const toggleOpening = (e: any) => {
    e.stopPropagation(); //
    setOpening((prev) => !prev);
  };

  const toggleInputduties = (e: any) => {
    e.stopPropagation();
    setInputduties((prev) => !prev);
  };
  const toggleInputgst = (e: any) => {
    e.stopPropagation();
    setGst((prev) => !prev);
  };
  const toggleCashAccount = (e: any) => {
    e.stopPropagation();
    setCase((prev) => !prev);
  };

  // const toggleOwnerEquity = (e: any) => {
  //   e.stopPropagation();
  //   setIsSubAccordion4((prev) => !prev);
  // };

  const toggleSubAccordion2 = (e: any) => {
    e.stopPropagation();
    setIsSubAccordion2((prev) => !prev);
  };
  const toggleCurrent = (e: any) => {
    e.stopPropagation();
    setCurrents((prev) => !prev);
  };
  const toggleCurrentAssets = (e: any) => {
    e.stopPropagation();
    setCurrentAssets((prev) => !prev);
  };
  const toggleBankAccount = (e: any) => {
    e.stopPropagation();
    setBankAcccount((prev) => !prev);
  };

  const toggleAccordion = (e: any) => {
    e.stopPropagation();

    setIsOpen((prev) => !prev);
  };
  const toggleIndirect = (e: any) => {
    e.stopPropagation();
    setIndirect((prev) => !prev);
  };
  const toggleDirect = (e: any) => {
    e.stopPropagation();
    setDirect((prev) => !prev);
  };

  const togglePurchase = (e: any) => {
    e.stopPropagation();
    setPurchase((prev) => !prev);
  };

  const toggleSale = (e: any) => {
    e.stopPropagation();
    setSale((prev) => !prev);
  };
  // const toggleSubAccordion = () => {
  //   setSubAccordionOpen(!isSubAccordionOpen);
  // };

  const toggleEquities = (e: any) => {
    e.stopPropagation();
    setEquities((prev) => !prev);
  };
  // const toggleEquities1 = () => {
  //   setEquities(!isReserve);
  // };

  const toggleCapital = (e: any) => {
    e.stopPropagation();
    setCapital((prev) => !prev);
  };

  const toggleCurrentLiabilities = (e: any) => {
    e.stopPropagation();
    setCurrentLiabilities((prev) => !prev);
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <span className="font-bold text-[#045545] text-xl items-center mt-4 sm:mt-0">
        {companyLists.map((company, index) => (
							<div key={index}>{company.company_name}</div>
						))}
        </span>
        <span className="font-bold text-[#045545] text-lg items-center mt-3">
          {" "}
          Trial Balance Sheet{" "}
        </span>
      </div>
      <div
        className="w-full  pl-4 mt-4 flex gap-3 flex-col sm:flex-row mb-8"
        style={
          {
            // backgroundColor: "#F1F7F6", // Custom background color (green)
          }
        }
      >
        <div className="flex gap-3">
          <div className="p-1">
            <span className=" text-[#008065] text-lg font-bold">Period : </span>
          </div>

          <div className="flex  justify-center">
            <select
              id=""
              className="block w-full p-1 bg-white border-2 rounded-md shadow-sm focus:outline-none  border-[#008065]"
            >
              <option value="" disabled hidden>
                Please select
              </option>
              <option value="All">All</option>
              <option value="This Month">This Month</option>
              <option value="Last Month">Last Month</option>
              <option value="This Quarter">This Quarter</option>
              <option value="This Year">This Year</option>
              <option value="Custom">Custom</option>
            </select>
          </div>
        </div>

        <div className="flex px-3 p-1 bg-white border-2 rounded-md border-[#008065] justify-center ">
          <div className=" flex justify-center items-center">
            <span>29/11/2024</span>
          </div>

          <span className="flex justify-center items-center px-2">To</span>

          <div className="  flex justify-center items-center">
            <span>29/11/2024</span>
          </div>
        </div>
      </div>

      <div className="max-w-8xl   shadow-md rounded-md    overflow-x-auto">
        <StyledDiv>
          <table className="min-w-full  border-collapse  relative ">
            <thead className="sticky top-0 bg-gray-300  z-10 text-[#008065]">
              <tr>
                <th className="px-4 py-1 text-left font-semibold border-gray-300 text-green  ">
                  Account
                </th>
                <th
                  colSpan={2}
                  className="px-4 pl-12   py-1  font-semibold text-green "
                >
                  Closing Balance
                </th>
              </tr>
              <tr className="bg-gray-200">
                <th className="px-4 py-1 text-left   w-8/12"></th>
                <th className="px-4 py-1 text-right font-semibold text-green ">
                  Debit
                </th>
                <th className="px-4 py-1 text-right font-semibold text-green ">
                  Credit
                </th>
              </tr>
            </thead>
            <tbody>
              <h2 className="font-bold mb-2 px-4 text-lg mt-3 text-[#008065]">
                Assets
              </h2>

              <tr
                className="px-4 cursor-pointer mb-2 ml-2 mt-4 hover:bg-gray-300"
                onClick={toggleAccordion}
              >
                <td className="md:px-4 px-3 py-2 font-semibold space-y-2 md:overflow-hidden sm:overflow-auto rounded-l-md">
                  <div className="flex items-center text-[#008065]">
                    <span className="mr-2 transform transition-transform">
                      {isOpen ? <FaChevronDown /> : <FaChevronRight />}
                    </span>
                    Fixed Assets
                  </div>
                </td>
                <td className="px-4 py-2 text-right font-bold text-[#008065]">
                  2543316.06
                </td>
                <td className="px-4 py-2 text-right text-[#008065] font-bold rounded-r-md">
                  2543316.06
                </td>
              </tr>

              {isOpen && (
                <>
                  <tr className="mt-4 font-bold">
                    <td className="md:px-6 px-4 py-2 text-black">
                      <ul className="list-disc list-inside">
                        <li>bike</li>
                      </ul>
                    </td>
                    <td className="px-4 py-2 text-right text-black">
                      2000000.00
                    </td>
                    <td className="px-4 py-2 text-right text-black">
                      2000000.00
                    </td>
                  </tr>
                </>
              )}
            </tbody>

            <br />
            <tbody className="space-y-3 mt-3">
              <tr
                className="cursor-pointer hover:bg-gray-300"
                onClick={toggleAccordion1}
              >
                <td className="md:px-4 px-3 py-2 font-semibold  space-y-2 md:overflow-hidden sm:overflow-auto rounded-l-md ml-4 p-4">
                  <div className="flex items-center text-[#008065]">
                    <span className="mr-2 transform transition-transform ">
                      {isCurrent ? <FaChevronDown /> : <FaChevronRight />}
                    </span>
                    Current Assets
                  </div>
                </td>
                <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                  2543316.06
                </td>
                <td className="px-4 py-2 text-right border-gray-300 rounded-r-md text-[#008065] font-bold">
                  ---
                </td>
              </tr>
              <br />
              {isCurrent && (
                <>
                  <tr
                    className=" cursor-pointer mt-4"
                    onClick={toggleSubAccordion1}
                  >
                    <td className="md:px-8 px-6 py-2 font-semibold border-gray-300">
                      <div className="flex items-center text-[#008065]">
                        <span className="mr-2 transform transition-transform">
                          {isSubAccordion1 ? (
                            <FaChevronDown />
                          ) : (
                            <FaChevronRight />
                          )}
                        </span>
                        Sundry Debtors
                      </div>
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
                      1000000.00
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
                      ---
                    </td>
                  </tr>

                  {isSubAccordion1 && (
                    <>
                      <tr className=" mt-4 font-bold">
                        <td className="md:px-9 px-8 py-2 text-black border-gray-300">
                          <ul className="list-disc list-inside">
                            <li>Car</li>
                          </ul>
                        </td>
                        <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                          ---
                        </td>
                        <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                          ---
                        </td>
                      </tr>
                      <tr className=" mt-4 font-bold">
                        <td className="md:px-9 px-8 py-2 text-black border-gray-300">
                          <ul className="list-disc list-inside">
                            <li>bike</li>
                          </ul>
                        </td>
                        <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                          200000.00
                        </td>
                        <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                          200000.00
                        </td>
                      </tr>
                    </>
                  )}
                  <br />

                  <tr
                    className=" cursor-pointer mt-4"
                    onClick={toggleInputduties}
                  >
                    <td className="md:px-8  px-6 py-2 font-semibold border-gray-300">
                      <div className="flex items-center text-[#008065]">
                        <span className="mr-2 transform transition-transform">
                          {isInputduties ? (
                            <FaChevronDown />
                          ) : (
                            <FaChevronRight />
                          )}
                        </span>
                        Input Duties and Taxes
                      </div>
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
                      1000000.00
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
                      ---
                    </td>
                  </tr>

                  {isInputduties && (
                    <>
                      <tr className=" mt-4" onClick={toggleInputgst}>
                        <td className="md:px-12 px-10 py-2 font-semibold border-gray-300">
                          <div className="flex items-center text-[#008065]">
                            <span className="mr-2 transform transition-transform">
                              {isGst ? <FaChevronDown /> : <FaChevronRight />}
                            </span>
                            Input GST
                          </div>
                        </td>
                        <td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
                          200000.00
                        </td>
                        <td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
                          ---
                        </td>
                      </tr>

                      {isGst && (
                        <>
                          <tr className="mt-4">
                            <td className="md:px-14 px-12 py-2 text-black font-bold border-gray-300">
                              <ul className="list-disc list-inside">
                                <li>CGST </li>
                              </ul>
                            </td>
                            <td className="px-4  py-2 text-right border-gray-300 text-black font-bold">
                              2000000.00
                            </td>
                            <td className="px-4 py-2 text-right border-gray-300 text-black font-bold">
                              2000000.00
                            </td>
                          </tr>
                          <tr className="mt-4">
                            <td className="md:px-14 px-12 py-2 text-black font-bold border-gray-300">
                              <ul className="list-disc list-inside">
                                <li>SGST </li>
                              </ul>
                            </td>
                            <td className="px-4 py-2 text-right border-gray-300 font-bold text-black">
                              ---
                            </td>
                            <td className="px-4 py-2 text-right border-gray-300 font-bold text-black">
                              ---
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  )}
                  <tr className="mt-4 font-bold">
                    <td className="md:px-9 px-6 py-2 text-black border-gray-300">
                      <ul className="list-disc list-inside">
                        <li>Opening Stock </li>
                      </ul>
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 text-black">
                      ---
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 text-black">
                      ---
                    </td>
                  </tr>

                  {/* Cash Accounts */}
                  {/* <tr
                    className=" cursor-pointer mt-4"
                    onClick={toggleBankAccount}
                  >
                    <td className="px-8 py-2 font-semibold border-gray-300">
                      <div className="flex items-center text-[#008065]">
                        <span className="mr-2 transform transition-transform ">
                          {isBankAccount ? (
                            <FaChevronDown />
                          ) : (
                            <FaChevronRight />
                          )}
                        </span>
                        Bank Accounts
                      </div>
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 text-[#008065]">
                      500000.00
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 text-[#008065]">
                      ---
                    </td>
                  </tr>
                  {isBankAccount && (
                    <tr>
                      <td className="px-10 py-2 text-black border-gray-300 text-black-700">
                        <ul className="list-disc list-inside">
                          <li>RRR Enterprise Bank</li>
                        </ul>
                      </td>
                      <td className="px-4 py-2 text-right border-gray-300 text-black">
                        ---
                      </td>
                      <td className="px-4 py-2 text-right border-gray-300 text-black">
                        ---
                      </td>
                    </tr>
                  )} */}

                  <tr
                    className=" cursor-pointer mt-4"
                    onClick={toggleCashAccount}
                  >
                    <td className="md:px-8 px-6 py-2 font-semibold border-gray-300">
                      <div className="flex items-center text-[#008065]">
                        <span className="mr-2 transform transition-transform">
                          {isCaseAccount ? (
                            <FaChevronDown />
                          ) : (
                            <FaChevronRight />
                          )}
                        </span>
                        Cash Accounts
                      </div>
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
                      1000000.00
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
                      ---
                    </td>
                  </tr>

                  {isCaseAccount && (
                    <tr>
                      <td className="px-10 py-2 text-black font-bold border-gray-300 text-black-700">
                        <ul className="list-disc list-inside">
                          <li>Cash</li>
                        </ul>
                      </td>
                      <td className="px-4 py-2 text-right border-gray-300 text-black font-bold">
                        ---
                      </td>
                      <td className="px-4 py-2 text-right border-gray-300 text-black font-bold">
                        ---
                      </td>
                    </tr>
                  )}

                  <tr
                    className=" cursor-pointer mt-4"
                    onClick={toggleCurrentAssets}
                  >
                    <td className="px-8 py-2 font-semibold border-gray-300">
                      <div className="flex items-cente text-[#008065] ">
                        <span className="mr-2 transform transition-transform cursor-pointer">
                          {isCurrentAssets ? (
                            <FaChevronDown />
                          ) : (
                            <FaChevronRight />
                          )}
                        </span>
                        Other Current Assets
                      </div>
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                      500000.00
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                      ---
                    </td>
                  </tr>
                  {isCurrentAssets && (
                    <tr className="font-bold">
                      <td className="px-10 py-2 text-black border-gray-300">
                        <ul className="list-disc list-inside">
                          <li>Undeposited Cheque</li>
                        </ul>
                      </td>
                      <td className="px-4 py-2 text-right border-gray-300 text-black">
                        ---
                      </td>
                      <td className="px-4 py-2 text-right border-gray-300 text-black">
                        ---
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
            <br />

            <tbody>
              <div className="md:px-4 px-4  w-72">
                <h2 className="font-bold mb-2  text-lg text-[#008065] ">
                  Equities & Liabilities
                </h2>
              </div>
              <tr
                className=" cursor-pointer hover:bg-gray-300"
                onClick={toggleCapital}
              >
                <td className="md:px-4 px-3 py-2 font-semibold border-gray-300 space-y-2 md:overflow-hidden sm:overflow-auto rounded-l-md ">
                  <div className="flex items-center text-[#008065]">
                    <span className="mr-2 transform transition-transform">
                      {isCapital ? <FaChevronDown /> : <FaChevronRight />}
                    </span>
                    Capital Accounts
                  </div>
                </td>
                <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                  2543316.06
                </td>
                <td className="px-4 py-2 text-right border-gray-300 rounded-r-md text-[#008065] font-bold">
                  ---
                </td>
              </tr>
              <br />

              {isCapital && (
                <>
                  <tr className=" cursor-pointer " onClick={toggleEquities}>
                    <td className="px-8 py-2 font-semibold border-gray-300">
                      <div className="flex items-center text-[#008065]">
                        <span className="mr-2 transform transition-transform">
                          {isEquities ? <FaChevronDown /> : <FaChevronRight />}
                        </span>
                        Owner's Equlity
                      </div>
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                      1000000.00
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                      ---
                    </td>
                  </tr>

                  {isEquities && (
                    <>
                      <tr className=" cursor-pointer " onClick={toggleOpening}>
                        <td className="px-12 py-2 font-semibold border-gray-300">
                          <div className="flex items-center text-[#008065]">
                            <span className="mr-2 transform transition-transform">
                              {isOpening ? (
                                <FaChevronDown />
                              ) : (
                                <FaChevronRight />
                              )}
                            </span>
                            Opening Balance Equlity
                          </div>
                        </td>
                        <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                          200000.00
                        </td>
                        <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                          ---
                        </td>
                      </tr>
                      {isOpening && (
                        <>
                          <tr className="">
                            <td className="px-14 py-2 text-black font-bold border-gray-300">
                              <ul className="list-disc list-inside">
                                <li>Fixed Asset Opening Balance</li>
                              </ul>
                            </td>
                            <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                              ---
                            </td>
                            <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                              ---
                            </td>
                          </tr>
                          <tr className="">
                            <td className="px-14 py-2 text-black font-bold border-gray-300">
                              <ul className="list-disc list-inside">
                                <li>Opening Bank Balance</li>
                              </ul>
                            </td>
                            <td className="px-4 py-2 text-right border-gray-300 font-bold text-black">
                              ---
                            </td>
                            <td className="px-4 py-2 text-right border-gray-300  font-bold text-black">
                              ---
                            </td>
                          </tr>
                        </>
                      )}

                      <tr className="cursor-pointer " onClick={toggleCash}>
                        <td className="px-12 py-2 font-semibold border-gray-300">
                          <div className="flex items-center text-[#008065]">
                            <span className="mr-2 transform transition-transform">
                              {isCase1 ? <FaChevronDown /> : <FaChevronRight />}
                            </span>
                            Adjustments
                          </div>
                        </td>
                        <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                          200000.00
                        </td>
                        <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                          ---
                        </td>
                      </tr>
                      {isCase1 && (
                        <>
                          <tr className="cursor-pointer"  onClick={toggleCaseAdjument}>
                            <td className="px-14 py-2 font-semibold border-gray-300">
                              <div className="flex items-center text-[#008065]">
                                <span
                                  className="mr-2 transform transition-transform"
                                 
                                >
                                  {isCaseAdjument ? (
                                    <FaChevronDown />
                                  ) : (
                                    <FaChevronRight />
                                  )}
                                </span>
                                Cash Adjument
                              </div>
                            </td>
                            <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                              200000.00
                            </td>
                            <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                              ---
                            </td>
                          </tr>

                          {isCaseAdjument && (
                            <>
                              <tr className="">
                                <td className="px-16 py-2 text-black font-bold border-gray-300">
                                  <ul className="list-disc list-inside">
                                    <li>Add Cash</li>
                                  </ul>
                                </td>
                                <td className="px-4 py-2 text-right border-gray-300 text-black font-bold">
                                  20000.00
                                </td>
                                <td className="px-4 py-2 text-right border-gray-300 text-black font-bold">
                                  ---
                                </td>
                              </tr>
                              <tr className="">
                                <td className="px-16 py-2  border-gray-300 text-black font-bold">
                                  <ul className="list-disc list-inside">
                                    <li>Remove Cash</li>
                                  </ul>
                                </td>
                                <td className="px-4 py-2 text-right border-gray-300 text-black font-bold">
                                  20000.00
                                </td>
                                <td className="px-4 py-2 text-right border-gray-300 text-black font-bold">
                                  ---
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <br />
                  <tr className="mt-4 font-bold">
                    <td className="px-10 py-2 text-black-700 border-gray-300">
                      <ul className="list-disc list-inside">
                        <li>Retained Earnings</li>
                      </ul>
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                      ---
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                      ---
                    </td>
                  </tr>
                </>
              )}
            </tbody>

            <tbody>
              <tr className="mt-4 font-bold">
                <td className="px-8 py-2 text-black-700 border-gray-300">
                  <ul className="list-disc list-inside">
                    <li>Long-term Liabilities</li>
                  </ul>
                </td>
                <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                  ---
                </td>
                <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                  ---
                </td>
              </tr>
            </tbody>
            <br />
            <tbody>
              {/* Parent Row (Main Accordion) */}
              <tr
                className="cursor-pointer hover:bg-gray-300"
                onClick={toggleCurrentLiabilities}
              >
                <td className="md:px-4 px-3 py-2 font-semibold border-gray-300 space-y-2 md:overflow-hidden sm:overflow-auto rounded-l-md">
                  <div className="flex items-center text-[#008065]">
                    <span className="mr-2 transform transition-transform">
                      {isCurrentLiabilities ? (
                        <FaChevronDown />
                      ) : (
                        <FaChevronRight />
                      )}
                    </span>
                    Current Liabilities
                  </div>
                </td>
                <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                  2543316.06
                </td>
                <td className="px-4 py-2 text-right border-gray-300 rounded-r-md text-[#008065] font-bold">
                  ---
                </td>
              </tr>

              {/* Child Accordions (Second-Level) */}
              {isCurrentLiabilities && (
                <>
                  {/* Child Row 1 - Sundry Debtors */}
                  <tr className="cursor-pointer " onClick={toggleSundry}>
                    <td className="px-8 py-2 font-semibold border-gray-300">
                      <div className="flex items-center text-[#008065]">
                        <span className="mr-2 transform transition-transform cursor-pointer">
                          {isSundry ? <FaChevronDown /> : <FaChevronRight />}
                        </span>
                        Sundry Debtors
                      </div>
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                      1000000.00
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                      ---
                    </td>
                  </tr>
                  {isSundry && (
                    <tr className="font-bold">
                      <td className="px-10 py-2 text-black-700 border-gray-300">
                        <ul className="list-disc list-inside">
                          <li>Other Income (Direct)</li>
                        </ul>
                      </td>
                      <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                        ---
                      </td>
                      <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                        ---
                      </td>
                    </tr>
                  )}

                  {/* Child Row 2 - Outward Duties and Taxes */}
                  <tr className="cursor-pointer" onClick={toggleSubAccordion2}>
                    <td className="px-8 py-2 font-semibold border-gray-300">
                      <div className="flex items-center text-[#008065]">
                        <span className="mr-2 transform transition-transform cursor-pointer">
                          {isSubAccordion2 ? (
                            <FaChevronDown />
                          ) : (
                            <FaChevronRight />
                          )}
                        </span>
                        Outward Duties and Taxes
                      </div>
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                      500000.00
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                      ---
                    </td>
                  </tr>
                  {isSubAccordion2 && (
                    <>
                      <tr className="cursor-pointer" onClick={toggleOutput}>
                        <td className="px-12 py-2 font-semibold border-gray-300">
                          <div className="flex items-center text-[#008065]">
                            <span className="mr-2 transform transition-transform cursor-pointer">
                              {isOutput ? (
                                <FaChevronDown />
                              ) : (
                                <FaChevronRight />
                              )}
                            </span>
                            Output GST
                          </div>
                        </td>
                        <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                          100000.00
                        </td>
                        <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                          ---
                        </td>
                      </tr>
                      {isOutput && (
                        <>
                          <tr className="mt-4  font-bold">
                            <td className="px-14 py-2 text-black border-gray-300">
                              <ul className="list-disc list-inside">
                                <li>Output CGST</li>
                              </ul>
                            </td>
                            <td className="px-4 py-2 text-right border-gray-300 text-blac">
                              ---
                            </td>
                            <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                              ---
                            </td>
                          </tr>
                          <tr className="mt-4 font-bold">
                            <td className="px-14 py-2 text-black-700 border-gray-300">
                              <ul className="list-disc list-inside">
                                <li>Output SGST</li>
                              </ul>
                            </td>
                            <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                              ---
                            </td>
                            <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                              ---
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  )}

                  {/* Child Row 3 - Other Current Liabilities */}
                  {/* <tr className="cursor-pointer " onClick={toggleCurrent}>
                    <td className="px-8 py-2 font-semibold border-gray-300">
                      <div className="flex items-center text-[#008065]">
                        <span className="mr-2 transform transition-transform cursor-pointer">
                          {isCurrents ? <FaChevronDown /> : <FaChevronRight />}
                        </span>
                        Other Current Liabilities
                      </div>
                    </td>
                    <td className="px-4 py-2 text-[#008065] border-gray-300">
                      500000.00
                    </td>
                    <td className="px-4 py-2 text-[#008065] border-gray-300">
                      ---
                    </td>
                  </tr> */}
                  <tr className="cursor-pointer" onClick={toggleCurrent}>
                    <td className="px-8 py-2 font-semibold border-gray-300">
                      <div className="flex items-center text-[#008065]">
                        <span className="mr-2 transform transition-transform cursor-pointer">
                          {isCurrents ? (
                            <FaChevronDown />
                          ) : (
                            <FaChevronRight />
                          )}
                        </span>
                        Other Current Liabilities
                      </div>
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                      500000.00
                    </td>
                    <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
                      ---
                    </td>
                  </tr>
                  {isCurrents && (
                    <tr className="font-bold">
                      <td className="px-10 py-2 text-black border-gray-300">
                        <ul className="list-disc list-inside">
                          <li>Unwithdrawn Cheques</li>
                        </ul>
                      </td>
                      <td className="px-4 py-2 text-right border-gray-300 text-black">
                        ---
                      </td>
                      <td className="px-4 py-2 text-right border-gray-300 text-black">
                        ---
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>

            <br />

            <tbody className="">
              <h2 className="font-bold mb-2 px-4 text-lg mt-3 text-[#008065]">
                Incomes
              </h2>
              <tr className=" cursor-pointer hover:bg-gray-300" onClick={toggleSale}>
                <td className="md:px-4 px-3 py-2 font-semibold border-gray-300 space-y-2 md:overflow-hidden sm:overflow-auto rounded-l-md">
                  <div className="flex items-center text-[#008065]">
                    <span className="mr-2 transform transition-transform">
                      {isSale ? <FaChevronDown /> : <FaChevronRight />}
                    </span>
                    Sale Accounts
                  </div>
                </td>
                <td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
                  2543316.06
                </td>
                <td className="px-4 py-2 text-right border-gray-300 font-bold rounded-r-md text-[#008065]">
                  ---
                </td>
              </tr>

              {/* Child Rows */}
              {isSale && (
                <tr className="font-bold ">
                  <td className="px-5  py-2 text-balck-700 border-gray-300">
                    <ul className="list-disc list-inside">
                      <li>Sale(Revenue)Account</li>
                    </ul>
                  </td>
                  <td className="px-4 py-2 text-right border-gray-300 text-balck-700">
                    ---
                  </td>
                  <td className="px-4 py-2 text-right border-gray-300 text-balck-700">
                    ---
                  </td>
                </tr>
              )}

             
              <br />
              <tr className="font-bold">
                <td className="px-5 py-2 text-black-700 border-gray-300">
                  <ul className="list-disc list-inside">
                    <li> Profit on Sales of Assets</li>
                  </ul>
                </td>
                <td className="px-4 py-2 text-right border-gray-300">---</td>
                <td className="px-4 py-2 text-right border-gray-300">---</td>
              </tr>
            </tbody>
            <br />
            <tbody className="">
              <h2 className="font-bold mb-2 px-4 text-lg mt-3 text-[#008065]">
                Expenses
              </h2>
              <tr
                className=" cursor-pointer hover:bg-gray-300"
                onClick={togglePurchase}
              >
                <td className="md:px-4 px-3 py-2 font-semibold border-gray-300 space-y-2 md:overflow-hidden sm:overflow-auto rounded-l-md">
                  <div className="flex items-center text-[#008065]">
                    <span className="mr-2 transform transition-transform">
                      {isPurchase ? <FaChevronDown /> : <FaChevronRight />}
                    </span>
                    Purchase Accounts
                  </div>
                </td>
                <td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
                  2543316.06
                </td>
                <td className="px-4 py-2 text-right border-gray-300 font-bold rounded-r-md text-[#008065]">
                  ---
                </td>
              </tr>

              {isPurchase && (
                
                <tr className="mt-2 font-bold">
                  <td className="px-5 py-2 text-black-700 border-gray-300">
                    <ul className="list-disc list-inside">
                      <li>Purchase</li>
                    </ul>
                  </td>
                  <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                    ---
                  </td>
                  <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                    ---
                  </td>
                </tr>
              )}
            </tbody>
            <br />

            <tbody className="">
              <tr
                className=" cursor-pointer hover:bg-gray-300  "
                onClick={toggleDirect}
              >
                <td className="md:px-4 px-3 py-2 font-semibold border-gray-300 space-x-2 md:overflow-hidden sm:overflow-auto rounded-l-md">
                  <div className="flex items-center text-[#008065]">
                    <span className="mr-2 transform transition-transform">
                      {isDirect ? <FaChevronDown /> : <FaChevronRight />}
                    </span>
                    Direct Expenses
                  </div>
                </td>
                <td className="px-4 py-2 text-right text-[#008065] font-bold ">
                  2543316.06
                </td>
                <td className="px-4 py-2 text-right rounded-r-md   font-bold text-[#008065]">
                  ---
                </td>
              </tr>

              {isDirect && (
                <tr className="font-bold">
                  <td className="px-5 py-2 text-black-700 border-gray-300">
                    <ul className="list-disc list-inside">
                      <li> Purchase</li>
                    </ul>
                  </td>
                  <td className="px-4 py-2 text-right border-gray-300 text-black-700">
                    ---
                  </td>
                  <td className="px-4 py-2 text-right border-gray-300 rounded-r-md text-black-700">
                    ---
                  </td>
                </tr>
              )}
            </tbody>
            <br />
            <tbody className="">
              <tr
                className=" cursor-pointer hover:bg-gray-300"
                onClick={toggleIndirect}
              >
                <td className="md:px-4 px-3 py-2 font-semibold border-gray-300 space-y-2 md:overflow-hidden sm:overflow-auto rounded-l-md">
                  <div className="flex items-center text-[#008065]">
                    <span className="mr-2 transform transition-transform">
                      {isIndirect ? <FaChevronDown /> : <FaChevronRight />}
                    </span>
                    Indirect Expenses
                  </div>
                </td>
                <td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold  ">
                  2543316.06
                </td>
                <td className="px-4 py-2 text-right border-gray-300 rounded-r-md text-[#008065] font-bold">
                  ---
                </td>
              </tr>

              {/* Child Rows */}
              {isIndirect && (
                <tr className="font-bold">
                  <td className="px-5 py-2 text-black-700 border-gray-300">
                    <ul className="list-disc list-inside">
                      <li>Purchase</li>
                    </ul>
                  </td>
                  <td className="px-4 py-2 text-right border-gray-300 text-black-700 ">
                    ---
                  </td>
                  <td className="px-4 py-2 text-right border-gray-300">---</td>
                </tr>
              )}
              <br />
              <tr className="  bottom-0 left-0   bg-gray-300">
                <td className="py-3 px-4 text-green font-bold text-lg text-[#008065]">
                  Total
                </td>
                <td className="px-4 py-3 text-right border-gray-300 text-[#008065] font-bold">
                  20000.00
                </td>
                <td className="px-4 py-3 text-right border-gray-300 text-[#008065] font-bold">
                  200000.00{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </StyledDiv>
      </div>
    </>
  );
};

export default TrialBalanceSheet;
