import { useEffect, useState } from 'react';
import UpiModel from '../../models/UpiModel';

function UpiPayment({ formData, handleChange, setIsUpiPaymentOpen, setFormData, editValue, handleOpen, downloadUpiClicked, setDownloadUpiClicked, upiList }: any) {
    const [errors, setErrors] = useState({
        upiNumber: "",
        upiType: "",
    } as any);


    const test = () => {
        if ((formData.upiNumber || formData.upiType) && downloadUpiClicked) {
            return true;
        }
        setDownloadUpiClicked(false);
        return false;
    }

    const [isModalUpi, setIsModalUpi] = useState(false);
    const handleUPI = () => {
        setIsModalUpi(true);
    };
    const closeModal = () => {
        setIsModalUpi(false);
    }

    const handleSelect = (data: any) => {
        setErrors((prevValue: any) => {
            return {
                ...prevValue,
                upiNumber: data.upi_number ? data.upi_number : '',
                upiType: data.upi_pay ? data.upi_pay : '',
            }
        })

        setFormData({
            ...formData,
            upiNumber: data.upi_number,
            upiType: data.upi_pay,
        });
        setIsModalUpi(false);
    };
    useEffect(() => {

        if (editValue || upiList) {
            setErrors((prevValue: any) => {
                return {
                    ...prevValue,
                    upiNumber: formData?.upiNumber ? formData.upiNumber : '',
                    upiType: formData?.upiType ? formData.upiType : '',
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editValue, upiList])
    return (
        <div className='sm:pl-5'>
            <div
                className={`felx-col`}
            >
                <div className="flex justify-between">
                    <label className="block my-2 text-sm font-semibold text-black">
                        UPI Payment:
                    </label>
                    <div className="block mb-2 mt-1 ">
                        <button
                            type="button"
                            className="mr-6 mt-1 text-sm font-semibold text-[#008065]"
                            onClick={handleUPI}
                        >
                            Add Existing UPI payment
                        </button>
                    </div>
                </div>
                <div className="flex flex-row">
                    <div className="w-full  border-2 border-[#008065] border-solid  rounded ">
                        <div className="text-gray-700 md:flex md:items-center mx-6 p-2">
                            <div className="mb-1 md:mb-0 md:w-1/3">
                                <label
                                    // for="forms-labelLeftInputCode"
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        // borderColor: !errors.upiType && test() ? "red" : "#008065",
                                    }}
                                >
                                    UPI Pay
                                </label>
                            </div>
                            <div className="md:w-2/3 md:flex-grow">
                                <input
                                    className="w-full h-10 px-3 text-base placeholder-gray-600   border  rounded-lg focus:outline-none"
                                    type="text"
                                    placeholder=" UPI payment.."
                                    name="upiType"
                                    value={formData.upiType}
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        borderColor: !errors.upiType && test() ? "red" : "#008065",
                                    }}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setErrors((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                upiType: e.target.value
                                            }
                                        })
                                    }}
                                />
                                {!errors.upiType && test() && (
                                    <p
                                        style={{
                                            fontFamily: "poppins",
                                            fontWeight: 700,
                                            color: "#FF0000",
                                        }}
                                        className="text-red-400 text-xs"
                                    >
                                        {"UPI Type is required"}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="text-gray-700 md:flex md:items-center mx-6 p-2">
                            <div className="mb-1 md:mb-0 md:w-1/3">
                                <label
                                    // for="forms-labelLeftInputCode"
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        borderColor: !errors.upiNumber && test() ? "red" : "#008065",
                                    }}
                                >
                                    UPI Number
                                </label>
                            </div>
                            <div className="md:w-2/3 md:flex-grow">
                                <input
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:outline-none"
                                    type="text"
                                    placeholder=" UPI No..."
                                    name="upiNumber"
                                    value={formData.upiNumber}
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        borderColor: !errors.upiNumber && test() ? "red" : "#008065",
                                    }}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setErrors((prevValue: any) => {
                                            return {
                                                ...prevValue,
                                                upiNumber: e.target.value
                                            }
                                        })
                                    }}
                                />
                                {!errors.upiNumber && test() && (
                                    <p
                                        style={{
                                            fontFamily: "poppins",
                                            fontWeight: 700,
                                            color: "#FF0000",
                                        }}
                                        className="text-red-400 text-xs"
                                    >
                                        {"UPI Number is required"}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <button onClick={() => handleOpen("addTrueUpi")} type="button">
                            <svg
                                className="h-6  text-grey"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            {isModalUpi && <UpiModel closeModal={closeModal} handleSelect={handleSelect} />}
        </div>

    )
}

export default UpiPayment
