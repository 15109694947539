/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ClientsTable from "../../Tables/ClientsTable";
import {
  ClientAll,
  ClientDelete,
  ClientSave,
  ClientUpdate,
} from "../../redux/actions/clientAction";
import ConfirmationPopup from "../commen/ConfirmationPopup";

function ClientPage() {
  const User_id = sessionStorage.getItem("userInfo");
  const userId = JSON.parse(User_id as any);

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    client_name: "",
    client_address: "",
    city: "",
    gst: "",
    pan_no: "",
    postal_code: "",
    state: "",
    client_email: "",
    client_mobile: "",
    user_id: userId?.token?._id,
  } as any);

  const [clientList, setClientList] = useState([] as any);
  const [clientid, setClientid] = useState(null as any);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const fetchData = () => {
    dispatch(ClientAll(userId?.token?._id) as any).then((res: any) => {
      if (res) {
        setClientList(res.payload);
      }
    });
  };

  useEffect(() => {
    if (userId?.token?._id) {
      fetchData();
    }
  }, []);

  // ***************************Value Enter Sction*****************************

  const [clientPageErrors, setClientPageErrors] = useState<{
    client_name?: string;
    client_mobile?: string;
    client_email?: string;
    pan_no?: string;
    gst?: string;
  }>({});


  const validateProdForm = () => {
    let isValid = true;
    const errors: any = {};

    // Validate required fields
    if (formData.client_name.trim() === "") {
      errors.client_name = "Client Name is Required.";
      isValid = false;
    }


    const duplicate = clientList.some((client: any) => {
      return (
        (formData.client_mobile && client.client_mobile === formData.client_mobile && client._id.$oid !== clientid) ||
        (formData.gst && client.gst === formData.gst && client._id.$oid !== clientid) ||
        (formData.pan_no && client.pan_no === formData.pan_no && client._id.$oid !== clientid) ||
        (formData.client_email && client.client_email === formData.client_email && client._id.$oid !== clientid)
      );
    });

    if (duplicate) {
      if (formData.client_mobile && clientList.some((client: any) => client.client_mobile === formData.client_mobile && client._id.$oid !== clientid)) {
        errors.client_mobile = "Mobile Number is Already Exist";
      }
      if (formData.gst && clientList.some((client: any) => client.gst === formData.gst && client._id.$oid !== clientid)) {
        errors.gst = "GST No is Already Exist";
      }
      if (formData.pan_no && clientList.some((client: any) => client.pan_no === formData.pan_no && client._id.$oid !== clientid)) {
        errors.pan_no = "PAN No is Already Exist";
      }
      if (formData.client_email && clientList.some((client: any) => client.client_email === formData.client_email && client._id.$oid !== clientid)) {
        errors.client_email = "Email is Already Exist";
      }
      isValid = false;
    }

    setClientPageErrors(errors);
    return isValid;
  };



  //   setErrors(validationErrors);
  const postalcodeRegex = /^\d{6}(?:-\d{4})?$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newValue = value.trimStart(); // Trim only leading spaces
    setClientPageErrors({ ...clientPageErrors, [name]: "" });
    let errorMessage = "";
    let isValidPostalCode = true;
    let isValidEmail = true;
    let isValidPan = true;
    let isValidMobile = true;
    let isValidGst = true;

    // Validate based on field name
    if (name === "postal_code" && newValue) {
      isValidPostalCode = postalcodeRegex.test(newValue);
      if (!isValidPostalCode) {
        errorMessage = "Invalid Postal Code";
      }
    }
    if (name === "pan_no" && newValue) {
      newValue = newValue.toUpperCase();
      isValidPan = panRegex.test(newValue);
      errorMessage = isValidPan ? "" : "Invalid PAN Number";
    }
    if (name === "client_email" && newValue) {
      isValidEmail = emailRegex.test(newValue);
      errorMessage = isValidEmail ? "" : "Invalid Email Address";
    }
    if (name === "client_mobile" && newValue) {
      isValidMobile = newValue.length === 10;
      errorMessage = isValidMobile ? "" : "Invalid Mobile Number";
    }
    if (name === "gst" && newValue) {
      newValue = newValue.toUpperCase();
      isValidGst = gstRegex.test(newValue);
      errorMessage = isValidGst ? "" : "Invalid GST Number";
    }

    setFormData({
      ...formData,
      [name]: newValue,
      errors: {
        ...formData.errors,
        [`${name}ErrorMessage`]: errorMessage,
      },
      isValidPostalCode: name === "postal_code" ? isValidPostalCode : formData.isValidPostalCode,
      isValidEmail: name === "client_email" ? isValidEmail : formData.isValidEmail,
      isValidPan: name === "pan_no" ? isValidPan : formData.isValidPan,
      isValidGst: name === "gst" ? isValidGst : formData.isValidGst,
      isValidMobile: name === "client_mobile" ? isValidMobile : formData.isValidMobile,
    });
  };

  const onEdit = (data: any) => {
    setClientPageErrors({ ...clientPageErrors, client_name: "" });
    setClientid(data._id?.$oid);
    setFormData({
      client_name: data.client_name,
      client_address: data.client_address,
      city: data.city,
      gst: data.gst,
      pan_no: data.pan_no,
      postal_code: data.postal_code,
      state: data.state,
      client_email: data.client_email,
      client_mobile: data.client_mobile,
      user_id: userId.token._id,
    });
  };
  const onDelete = (data: any) => {
    setShowConfirmation(true);
    setClientid(data._id?.$oid);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const isFormValid = validateProdForm();
    const clientNameValid = formData.client_name.trim().length > 0;
    let optionalFieldsValid = true;

    // Check optional fields
    const optionalFields = ["client_address", "city", "state"];
    optionalFields.forEach(field => {
      if (formData[field] && formData[field].trim().length === 0) {
        optionalFieldsValid = false;
      }
    });

    if (
      isFormValid &&
      clientNameValid &&
      optionalFieldsValid &&
      !formData?.errors?.postal_codeErrorMessage &&
      !formData?.errors?.client_mobileErrorMessage &&
      !formData?.errors?.pan_noErrorMessage &&
      !formData.errors?.client_emailErrorMessage &&
      !formData.errors?.gstErrorMessage
    ) {
      if (clientid) {
        dispatch(ClientUpdate(clientid, formData) as any).then((res: any) => {
          if (res) {
            setFormData({
              client_name: "",
              client_address: "",
              city: "",
              gst: "",
              pan_no: "",
              postal_code: "",
              state: "",
              client_email: "",
              client_mobile: "",
              user_id: userId.token._id,
            });
            setClientid(null);
            fetchData();
            toast.success("Update Successfully!", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 800,
            });
            resetProduct();
          }
        });
      } else {
        dispatch(ClientSave(formData) as any).then((res: any) => {
          if (res) {
            setFormData({
              client_name: "",
              client_address: "",
              city: "",
              gst: "",
              pan_no: "",
              postal_code: "",
              state: "",
              client_email: "",
              client_mobile: "",
              user_id: userId.token._id,
            });
            fetchData();
            setClientid(null);
            toast.success("Saved Successfully!", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 800,
            });
            resetProduct();
          }
        });
      }
    } else {
      if (!clientNameValid) {
        setClientPageErrors((prev) => ({
          ...prev,
          client_name: "Client Name cannot be empty or spaces only"
        }));
      }
      validateProdForm();
    }
  };


  const handleConfirm = () => {
    dispatch(ClientDelete(clientid) as any).then((res: any) => {
      if (res) {
        fetchData();
        setClientid(null);
        setShowConfirmation(false);
        resetProduct();
      }
    });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  // *********************cancel button section*************************
  const resetProduct = () => {
    setClientPageErrors({ ...clientPageErrors, client_name: "" });
    setFormData({
      client_name: "",
      client_address: "",
      city: "",
      gst: "",
      pan_no: "",
      postal_code: "",
      state: "",
      client_email: "",
      client_mobile: "",
      user_id: userId.token._id,
    });
    setClientPageErrors({
      client_mobile: "",
    })
    setClientid(null);
    // Reset the form using reset() from react-hook-form
  };

  return (
    <>
      <div className="w-full pr-5 lg:pr-0">
        <form>
          <fieldset className="mx-3 mb-4 rounded-lg">
            <legend className="p-4  font-bold text-[#045545]  text-lg    ml-2">
              Client
            </legend>

            <div className="rounded-xl  py-3 mb-3  bg-[#E1E8E7]">
              <div className="grid grid-cols-1 gap-4 w-full px-6 md:px-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
                <div className="mb-2 sm:mb-0 sm:text-left text-default-color font-title flex flex-col px-2 xl:px-6">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="required"
                  >
                    <span>Client Name</span>
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      name="client_name"
                      placeholder="Client Name"
                      className={"border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: clientPageErrors.client_name
                          ? "red"
                          : "#008065",
                      }}
                      value={formData.client_name}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {clientPageErrors.client_name && (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-sm"
                    >
                      {clientPageErrors.client_name}
                    </span>
                  )}
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color font-title flex flex-col px-2 xl:px-6">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                      paddingTop: "2px",
                    }}
                  >
                    Client Address
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      placeholder=" Address (Optional)"
                      name="client_address"
                      className={"border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      value={formData.client_address}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color font-title flex flex-col px-2 xl:px-6">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                      paddingTop: "2px",
                    }}
                  >
                    City
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="City (Optional)"
                      name="city"
                      className={"border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      value={formData.city}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color font-title flex flex-col px-2 xl:px-6">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                      paddingTop: "2px",
                    }}
                  >
                    State
                  </label>
                  <div className="relative">
                    <input
                      placeholder="State (Optional)"
                      type="text"
                      name="state"
                      className={"border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      value={formData.state}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color font-title flex flex-col px-2 xl:px-6">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                      paddingTop: "2px",
                    }}
                  >
                    Postal Code
                  </label>
                  <div className="relative">
                    <input
                      type=""
                      name="postal_code"
                      placeholder="PostalCode (Optional)"
                      className={"border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: formData.errors?.postal_codeErrorMessage
                          ? "#FF0000"
                          : "#008065",
                      }}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (re.test(e.target.value) || e.target.value == "") {
                          handleChange(e);
                        }
                      }}
                      value={formData.postal_code}
                    />
                  </div>
                  {formData.errors?.postal_codeErrorMessage && (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-sm"
                    >
                      {formData.errors.postal_codeErrorMessage}
                    </span>
                  )}
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color font-title flex flex-col px-2 xl:px-6">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                      paddingTop: "2px",
                    }}
                  >
                    Client Email
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      name="client_email"
                      placeholder="ClientEmail (Optional)"
                      className={"border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"}
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: clientPageErrors?.client_email || formData.errors?.client_emailErrorMessage
                          ? "#FF0000"
                          : "#008065",
                      }}
                      value={formData.client_email}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {(clientPageErrors?.client_email || formData.errors?.client_emailErrorMessage) && (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-sm"
                    >
                      {clientPageErrors?.client_email || formData.errors.client_emailErrorMessage}
                    </span>
                  )}
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color font-title flex flex-col px-2 xl:px-6">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                      paddingTop: "2px",
                    }}
                  >
                    <span>Client Mobile</span>
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      name="client_mobile"
                      maxLength={10}
                      className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                      placeholder="Client Mobile (Optional)"
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: formData.errors?.client_mobileErrorMessage || clientPageErrors?.client_mobile
                          ? "red"
                          : "#008065",
                      }}
                      value={formData.client_mobile}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (re.test(e.target.value) || e.target.value == "") {
                          handleChange(e);
                        }
                      }}
                    />
                  </div>
                  {(clientPageErrors?.client_mobile ||
                    formData.errors?.client_mobileErrorMessage) && (
                      <span
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#FF0000",
                        }}
                        className="text-red-400 text-sm"
                      >
                        {clientPageErrors?.client_mobile ||
                          formData.errors?.client_mobileErrorMessage}
                      </span>
                    )}
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color font-title flex flex-col px-2 xl:px-6">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                      paddingTop: "2px",
                    }}
                  >
                    PAN No
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="PAN No (Optional)"
                      name="pan_no"
                      className="border-2  border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: clientPageErrors?.pan_no || formData.errors?.pan_noErrorMessage
                          ? "#FF0000"
                          : "#008065",
                      }}
                      value={formData.pan_no}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {(clientPageErrors?.pan_no || formData.errors?.pan_noErrorMessage) && (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-sm"
                    >
                      {clientPageErrors?.pan_no || formData.errors.pan_noErrorMessage}
                    </span>
                  )}

                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color font-title flex flex-col px-2 xl:px-6">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                      paddingTop: "2px",
                    }}
                  >
                    GST
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="GST (Optional)"
                      name="gst"
                      className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: clientPageErrors?.gst || formData.errors?.gstErrorMessage
                          ? "#FF0000"
                          : "#008065",
                      }}
                      value={formData.gst}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {(clientPageErrors?.gst || formData.errors?.gstErrorMessage) && (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-sm"
                    >
                      {clientPageErrors?.gst || formData.errors.gstErrorMessage}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex w-full flex-col sm:flex-row px-4">

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                  <div className="flex flex-col  ">
                    <div className="relative"></div>
                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                  <div className="flex flex-col  ">
                    <div className="relative"></div>
                  </div>
                </div>

                <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5 self-center">
                  <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40  rounded">
                    <button
                      type="button"
                      onClick={resetProduct}
                      className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                      style={{
                        backgroundColor: "#008065",
                        fontSize: "14px",
                        fontFamily: "poppins",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>

                <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5 self-center">
                  <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40 ">
                    <button
                      type="submit"
                      className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                      style={{
                        backgroundColor: "#008065",
                        fontSize: "14px",
                        fontFamily: "poppins",
                      }}
                      onClick={(e: any) => handleSubmit(e)}
                    >
                      {clientid ? "Update" : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="rounded-xl  py-3 mb-3  bg-[#E1E8E7]">
              <div className="flex w-full flex-col sm:flex-row px-4">
                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  ">
                  <div className="flex flex-col mb-6 w-full px-3">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                      className="required"
                    >
                      <span>Client Name</span>
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        name="client_name"
                        placeholder="Client Name"
                        className={
                          "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full sm:w-full md:w-28 lg:w-40 xl:w-48 2xl:w-56"
                        }
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: clientPageErrors.client_name
                            ? "red"
                            : "#008065",
                        }}
                        value={formData.client_name}
                        onChange={(e) => handleChange(e)}

                      />
                    </div>
                    {clientPageErrors.client_name && (
                      <span
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {clientPageErrors.client_name}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                  <div className="flex flex-col mb-6 w-full px-3">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                    >
                      Client Address
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        placeholder=" Address (Optional)"
                        name="client_address"
                        className={
                          "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full sm:w-full md:w-28 lg:w-40 xl:w-48 2xl:w-56"
                        }
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        value={formData.client_address}
                        onChange={(e) => handleChange(e)}

                      />
                    </div>
                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                  <div className="flex flex-col mb-6 w-full px-3">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                    >
                      City
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="City (Optional)"
                        name="city"
                        className={
                          "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full sm:w-full md:w-28 lg:w-40 xl:w-48 2xl:w-56"
                        }
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        value={formData.city}
                        onChange={(e) => handleChange(e)}

                      />
                    </div>
                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                  <div className="flex flex-col mb-6 w-full px-3">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                    >
                      State
                    </label>
                    <div className="relative">
                      <input
                        placeholder="State (Optional)"
                        type="text"
                        name="state"
                        className={
                          "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full sm:w-full md:w-28 lg:w-40 xl:w-48 2xl:w-56"
                        }
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        value={formData.state}
                        onChange={(e) => handleChange(e)}

                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex w-full flex-col sm:flex-row px-4">
                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                  <div className="flex flex-col mb-6 w-full px-3">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                    >
                      Postal Code
                    </label>
                    <div className="relative">
                      <input
                        type=""
                        name="postal_code"
                        placeholder="PostalCode (Optional)"
                        className={
                          "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full sm:w-full md:w-28 lg:w-40 xl:w-48 2xl:w-56"
                        }
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: formData.errors?.postal_codeErrorMessage
                            ? "#FF0000"
                            : "#008065",
                        }}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (re.test(e.target.value) || e.target.value == "") {
                            handleChange(e);
                          }
                        }}
                        value={formData.postal_code}
                      />
                    </div>
                    {formData.errors?.postal_codeErrorMessage && (
                      <span
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {formData.errors.postal_codeErrorMessage}
                      </span>
                    )}
                  </div>
                </div>
                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  ">
                  <div className="flex flex-col mb-6 w-full px-3">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                    >
                      Client Email
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        name="client_email"
                        placeholder="ClientEmail (Optional)"
                        className={
                          "border-2  focus:outline-none rounded-[7px] px-2 h-9 w-full sm:w-full md:w-28 lg:w-40 xl:w-48 2xl:w-56"
                        }
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: clientPageErrors?.client_email || formData.errors?.client_emailErrorMessage
                            ? "#FF0000"
                            : "#008065",
                        }}
                        value={formData.client_email}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    {(clientPageErrors?.client_email || formData.errors?.client_emailErrorMessage) && (
                      <span
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {clientPageErrors?.client_email || formData.errors.client_emailErrorMessage}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                  <div className="flex flex-col mb-6 w-full px-3">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                    >
                      <span>Client Mobile</span>
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        name="client_mobile"
                        maxLength={10}
                        className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full sm:w-full md:w-28 lg:w-40 xl:w-48 2xl:w-56"
                        placeholder="Client Mobile (Optional)"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor:

                            formData.errors?.client_mobileErrorMessage
                              ? "red"
                              : "#008065",
                        }}
                        value={formData.client_mobile}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (re.test(e.target.value) || e.target.value == "") {
                            handleChange(e);
                          }
                        }}
                      />
                    </div>
                    {(
                      formData.errors?.client_mobileErrorMessage) && (
                        <span
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 700,
                            color: "#FF0000",
                          }}
                          className="text-red-400 text-xs"
                        >
                          {
                            formData.errors?.client_mobileErrorMessage}
                        </span>
                      )}
                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                  <div className="flex flex-col mb-6 w-full px-3">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                    >
                      PAN No
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="PAN No (Optional)"
                        name="pan_no"
                        className="border-2  focus:outline-none rounded-[7px] px-2 h-9 w-full sm:w-full md:w-28 lg:w-40 xl:w-48 2xl:w-56"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: clientPageErrors?.pan_no || formData.errors?.pan_noErrorMessage
                            ? "#FF0000"
                            : "#008065",
                        }}
                        value={formData.pan_no}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    {(clientPageErrors?.pan_no || formData.errors?.pan_noErrorMessage) && (
                      <span
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {clientPageErrors?.pan_no || formData.errors.pan_noErrorMessage}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex w-full flex-col sm:flex-row px-4">
                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  ">
                  <div className="flex flex-col mb-6 w-full px-3">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                    >
                      GST
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="GST (Optional)"
                        name="gst"
                        className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full sm:w-full md:w-28 lg:w-40 xl:w-48 2xl:w-56"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: clientPageErrors?.gst || formData.errors?.gstErrorMessage
                            ? "#FF0000"
                            : "#008065",
                        }}
                        value={formData.gst}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    {(clientPageErrors?.gst || formData.errors?.gstErrorMessage) && (
                      <span
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {clientPageErrors?.gst || formData.errors.gstErrorMessage}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                  <div className="flex flex-col  ">
                    <div className="relative"></div>
                  </div>
                </div>

                <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5 self-center">
                  <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40  rounded">
                    <button
                      type="button"
                      onClick={resetProduct}
                      className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                      style={{
                        backgroundColor: "#008065",
                        fontSize: "14px",
                        fontFamily: "poppins",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>

                <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 mt-5 self-center">
                  <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40 ">
                    <button
                      type="submit"
                      className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                      style={{
                        backgroundColor: "#008065",
                        fontSize: "14px",
                        fontFamily: "poppins",
                      }}
                      onClick={(e: any) => handleSubmit(e)}
                    >
                      {clientid ? "Update" : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>  */}
          </fieldset>
        </form>
        <ClientsTable
          clientList={clientList}
          edit={onEdit}
          deleteId={onDelete}
        />

        {showConfirmation && (
          <ConfirmationPopup
            message="Are you sure you want to proceed?"
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )}
      </div>
    </>
  );
}

export default ClientPage;
