import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IconStyle, defaultInputSmStyle } from "../../commen/defaultStyles";
import NumberFormat from "../../commen/NumberFormat";
import StockUpdateModel from "../../commen/StockUpdateModel";
import DeleteIcon from "../../Icons/DeleteIcon";
import InvoiceIcon from "../../Icons/InvoiceIcon";
import QuotationModel from '../../models/QuotationModel';

const QuotationNewDetails = ({
  formData,
  setFormData,
  updateProductErrors,
  paramsId,
  stockList,
  setStockList,
  prodcutErrors,
  prodcutRows,
  setProductRows,
  productError,
  addRow,
  setProdcutErrors,
  editValue,
  setCashAmount,
  creditAmount,
  setchequeAmount,
  setcreditAmount,
  setbalanceAmount,
  setSelectedPayment,

}: any) => {
  const template = useSelector((state: any) => state.userColorTemplate);
  const { userdata } = template;
  const [newExistProduct, setNewexistProduct] = useState([{ exist: "", originalValue: '' }] as any);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const handleInputChange = (index: any, fieldName: any, value: any) => {
    // newExistProduct[index]["exist"] = value;
    const newArray: any = [...prodcutRows];
    // if (fieldName === "qty") {
    //   const stockQty = stockList[index]?.qty;
    //   if (stockQty !== undefined && Number(value) > stockQty) {
    //     setShowConfirmation(true);
    //     return
    //   }
    // }
    newArray[index][fieldName] = value;
    updateProductErrors(index, fieldName, value);
    if (fieldName === "qty" || fieldName === "rate" || fieldName === "discount") {
      if (
        fieldName === "discount" &&
        ((userdata.gstValue === "IGST" && userdata.taxType === "Product Wise") ||
          (formData.gstType === "IGST" && formData.billTaxType === "Product Wise"))
      ) {
        
        newArray[index]["amount"] =
                    newArray[index].rate && newArray[index].qty
                        ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
                        : !newArray[index].rate
                            ? 0
                            : 0;
                let discount =
                    newArray[index].rate && newArray[index].qty
                        ? parseFloat(newArray[index].amount) * (parseFloat(newArray[index].discount ? newArray[index].discount : 0) / 100)
                        : !newArray[index].rate
                            ? 0
                            : 0;

                newArray[index]["amount"] =
                    newArray[index].rate && newArray[index].qty
                        ? parseFloat(newArray[index].amount) - discount
                        : !newArray[index].rate
                            ? 0
                            : 0;
                let igst =
                    newArray[index].rate && newArray[index].qty
                        ? parseFloat(newArray[index].amount) * (parseFloat(newArray[index].igst) / 100)
                        : !newArray[index].rate
                            ? 0
                            : 0;
                newArray[index]["amount"] =
                    newArray[index].rate && newArray[index].qty
                        ? parseFloat(newArray[index].amount) + igst
                        : !newArray[index].rate
                            ? 0
                            : 0;
                newArray[index]["amount"] = (newArray[index]["amount"]).toFixed(2);

                newArray[index]["nontaxableAmount"] = (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)) - ((newArray[index]["discount"] / 100) * (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)))
                newArray[index]["igstAmount"] = parseFloat(newArray[index].igst) / 100 * parseFloat(newArray[index].rate)
                setProductRows(newArray);
      } else if (fieldName === "discount" &&
        ((userdata.gstValue === "GST" && userdata.taxType === "Product Wise") ||
          (formData.gstType === "GST" && formData.billTaxType === "Product Wise"))) {

        newArray[index]["amount"] =
          newArray[index].rate && newArray[index].qty
            ? (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)) - parseFloat(newArray[index].discount ? newArray[index].discount : 0) / 100 * parseFloat(newArray[index].rate)
            : !newArray[index].rate
              ? 0
              : 0;
        newArray[index]["amount"] =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].amount) + ((parseFloat(newArray[index].sgst) + parseFloat(newArray[index].cgst)) / 100 * parseFloat(newArray[index].amount))
            : !newArray[index].rate
              ? 0
              : 0;
        newArray[index]["amount"] =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
            : !newArray[index].rate
              ? 0
              : 0;
        let discount =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].amount) * (parseFloat(newArray[index].discount ? newArray[index].discount : 0) / 100)
            : !newArray[index].rate
              ? 0
              : 0;

        newArray[index]["amount"] =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].amount) - discount
            : !newArray[index].rate
              ? 0
              : 0;
        let gst =
          newArray[index].rate && newArray[index].qty
            ? ((parseFloat(newArray[index].sgst) + parseFloat(newArray[index].cgst)) / 100 * parseFloat(newArray[index].amount))
            : !newArray[index].rate
              ? 0
              : 0;
        newArray[index]["amount"] =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].amount) + gst
            : !newArray[index].rate
              ? 0
              : 0;
        newArray[index]["nontaxableAmount"] = (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)) - ((newArray[index]["discount"] / 100) * (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)))
        newArray[index]["cgstAmount"] = parseFloat(newArray[index].cgst) / 100 * parseFloat(newArray[index].rate)
        newArray[index]["sgstAmount"] = parseFloat(newArray[index].sgst) / 100 * parseFloat(newArray[index].rate)
        setProductRows(newArray);
      } else {
        if (fieldName === "qty" &&
          ((userdata.gstValue === "IGST" && userdata.taxType === "Product Wise") ||
            (formData.gstType === "IGST" && formData.billTaxType === "Product Wise"))
        ) {
          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
              : !newArray[index].rate
                ? 0
                : 0;
          let discount =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].amount) * (parseFloat(newArray[index].discount ? newArray[index].discount : 0) / 100)
              : !newArray[index].rate
                ? 0
                : 0;

          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].amount) - discount
              : !newArray[index].rate
                ? 0
                : 0;
          let igst =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].amount) * (parseFloat(newArray[index].igst) / 100)
              : !newArray[index].rate
                ? 0
                : 0;
          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].amount) + igst
              : !newArray[index].rate
                ? 0
                : 0;
          newArray[index]["amount"] = (newArray[index]["amount"]).toFixed(2);

          newArray[index]["nontaxableAmount"] = (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)) - ((newArray[index]["discount"] / 100) * (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)))
                newArray[index]["igstAmount"] = parseFloat(newArray[index].prodigst) / 100 * parseFloat(newArray[index].rate)
          setProductRows(newArray);
        } else if (
          fieldName === "qty" &&
          ((userdata.gstValue === "GST" && userdata.taxType === "Product Wise") ||
            (formData.gstType === "GST" && formData.billTaxType === "Product Wise"))
        ) {
          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)) - parseFloat(newArray[index].discount ? newArray[index].discount : 0) / 100 * parseFloat(newArray[index].rate)
              : !newArray[index].rate
                ? 0
                : 0;
          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
              : !newArray[index].rate
                ? 0
                : 0;
          let discount =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].amount) * (parseFloat(newArray[index].discount ? newArray[index].discount : 0) / 100)
              : !newArray[index].rate
                ? 0
                : 0;

          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].amount) - discount
              : !newArray[index].rate
                ? 0
                : 0;
          let gst =
            newArray[index].rate && newArray[index].qty
              ? ((parseFloat(newArray[index].sgst) + parseFloat(newArray[index].cgst)) / 100 * parseFloat(newArray[index].amount))
              : !newArray[index].rate
                ? 0
                : 0;
          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].amount) + gst
              : !newArray[index].rate
                ? 0
                : 0;
          newArray[index]["nontaxableAmount"] = (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)) - ((newArray[index]["discount"] / 100) * (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)))
          newArray[index]["cgstAmount"] = parseFloat(newArray[index].cgst) / 100 * parseFloat(newArray[index].rate)
          newArray[index]["sgstAmount"] = parseFloat(newArray[index].sgst) / 100 * parseFloat(newArray[index].rate)
          setProductRows(newArray);
        }
      }

      let subAmount: any = 0;
      for (let i = 0; i < newArray.length; i++) {
        subAmount += Number(newArray[i].amount);
      }
      let subAmountNonTax: any = 0;
      for (let i = 0; i < newArray.length; i++) {
        subAmountNonTax += newArray[i].nontaxableAmount;
      }
      subAmount = subAmount.toFixed(2);
      if (paramsId) {
        setFormData({
          ...formData,
          product: newArray,
          subtotal: subAmount,
          // tax: formData.tax,
          nontaxableTotal: subAmountNonTax,
          toatalAmount: parseFloat(subAmount),
        });
        let receivedAmount = parseFloat(subAmount) - formData.receivedAmount
        setbalanceAmount(receivedAmount)
      }
      else {
        setFormData({
          ...formData,
          product: newArray,
          subtotal: subAmount,
          toatalAmount: subAmount,
          nontaxableTotal: subAmountNonTax
        });
        if (creditAmount) {
          setbalanceAmount(subAmount)
        }
        // setCashAmount(subAmount)
        // setchequeAmount(subAmount)
        // setcreditAmount(subAmount)
      }

    }
    else {
      setProductRows(newArray);
    }
  };

  const handleExistingCalulation = (arr: any) => {
    if (arr.length === 0)
      arr.push({ amount: "", nontaxableAmount: "", nontaxableTotal: "", hsn: "", prodcut: "", qty: "", gst: "", igst: "", cgst: "", sgst: "", rate: "", igstAmount: "", cgstAmount: "", sgstAmount: "", discount: "" });
    const newArray: any = [...arr];
    for (let i = 0; i < newArray.length; i++) {
      if (newArray[i].qty || newArray[i].rate) {
        if (userdata.gstValue === "IGST" && userdata.taxType === "Product Wise") {
          newArray[i]["amount"] =
            newArray[i].rate && newArray[i].qty
              ? parseFloat(newArray[i].rate) * parseFloat(newArray[i].qty) - parseFloat(newArray[i].discount) / 100 * parseFloat(newArray[i].rate)
              : !newArray[i].rate
                ? 0
                : 0;
          newArray[i]["amount"] =
            newArray[i].rate && newArray[i].qty
              ? parseFloat(newArray[i].amount) * parseFloat(newArray[i].igst) / 100 + parseFloat(newArray[i].amount)
              : !newArray[i].amount
                ? 0
                : 0;
          newArray[i]["igstAmount"] = parseFloat(newArray[i].igst) / 100 * parseFloat(newArray[i].rate)
          newArray[i]["igst"] = parseFloat(newArray[i].igst)
          newArray[i]["nontaxableAmount"] = (parseFloat(newArray[i].rate) * parseFloat(newArray[i].qty)) - parseFloat(newArray[i].discount) / 100 * ((parseFloat(newArray[i].rate) * parseFloat(newArray[i].qty)));
          setProductRows(newArray);

        }
       
        else if (userdata.gstValue === "GST" && userdata.taxType === "Product Wise") {
          newArray[i]["amount"] =
            newArray[i].rate && newArray[i].qty
              ? parseFloat(newArray[i].rate) * parseFloat(newArray[i].qty) - parseFloat(newArray[i].discount) / 100 * parseFloat(newArray[i].rate)
              : !newArray[i].rate
                ? 0
                : 0;
          newArray[i]["amount"] =
            newArray[i].rate && newArray[i].qty
              ? parseFloat(newArray[i].amount) + ((parseFloat(newArray[i].sgst) + parseFloat(newArray[i].cgst)) / 100 * parseFloat(newArray[i].amount))
              : !newArray[i].rate
                ? 0
                : 0;
          newArray[i]["cgstAmount"] = parseFloat(newArray[i].cgst) / 100 * parseFloat(newArray[i].rate)
          newArray[i]["sgstAmount"] = parseFloat(newArray[i].sgst) / 100 * parseFloat(newArray[i].rate)
          newArray[i]["gst"] = parseFloat(newArray[i].sgst) + parseFloat(newArray[i].cgst)
          newArray[i]["nontaxableAmount"] = (parseFloat(newArray[i].rate) * parseFloat(newArray[i].qty)) - parseFloat(newArray[i].discount) / 100 * ((parseFloat(newArray[i].rate) * parseFloat(newArray[i].qty)));


          setProductRows(newArray);

        }

        let subAmount: any = 0;
        for (let i = 0; i < newArray.length; i++) {
          subAmount += newArray[i].amount;
        }
        let subAmountNonTax: any = 0;
        for (let i = 0; i < newArray.length; i++) {
          subAmountNonTax += newArray[i].nontaxableAmount;
        }

        if (paramsId) {
          setFormData({
            ...formData,
            product: newArray,
            subtotal: subAmount,
            // tax: formData.tax,
            toatalAmount: subAmount,
          });
        }
        else {

          setFormData({
            ...formData,
            product: newArray,
            subtotal: subAmount,
            toatalAmount: subAmount,
            nontaxableTotal: subAmountNonTax

          });
        }
      }
    }
    // else {
    setProductRows(newArray);
    // }

    let finalErrors: any = [];
    // let object: any = {};
    let values = newArray?.map((item: any, index: any) => {
      let object: any = {};
      object.prodcut = item.prodcut ? true : false;
      object.rate = item.rate ? true : false;
      object.qty = item.qty ? true : false;
      finalErrors.push(object);

      return object
    });
    setProdcutErrors(values);
  };

  const removeRow = (index: any, row: any) => {
    const newArray = [...prodcutRows];
    const newErrors = [...prodcutErrors];
    const existArray = [...newExistProduct];
    existArray.splice(index, 1)
    setNewexistProduct(() => {
      return existArray.length === 0 ? [{ exist: '', originalValue: '' }] : existArray
    });
    // if(existArray.length === 1) {
    //   setNewexistProduct([{exist: '',originalValue:''}]);
    // }
    newArray.splice(index, 1);
    handleExistingCalulation(newArray);
    setProductRows(newArray);
    setbalanceAmount("")
    setCashAmount('')
    setSelectedPayment('')
    setcreditAmount("")
    setchequeAmount("")
    formData.paymentType = ''
    if (newErrors.length > 1)
      newErrors.splice(index, 1);
    setProdcutErrors(newErrors);

    if (existArray.length === 0) {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        ...prevFormData.tax = [],
        subtotal: 0,
        toatalAmount: 0,
      }));
    }

  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleConfirm = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };



  useEffect(() => {
    if (editValue) {
      setProductRows(formData.product);
      let obj: any = []
      formData.product.map((item: any) => {
        obj.push({ exist: item.qty, originalValue: item.qty })
      })
      setNewexistProduct(obj)
      // setNewexistProduct((prev:any)=>[...prev,{exist: Number(data.productQuantity),originalValue: Number(data.productQuantity)}])
    } else {
      setProductRows([
        { prodcut: "", hsn: "", rate: "", qty: "", amount: 0, productName: "", igstAmount: "", cgstAmount: "", igst: "", cgst: "", sgst: "", discount: "" },
      ]);
    }
  }, [editValue]);

  const handleSelect = (dataArray: any[]) => {
    const containsEmptyRows = prodcutRows.some((row: any) =>
      Object.values(row).some((value) => !value)
    );
    const containsEmptyRowsinExistProduct = newExistProduct.some((row: any) =>
      Object.values(row).some((value) => !value)
    );

    if (containsEmptyRowsinExistProduct) {
      const nonEmptyRows = newExistProduct.filter((row: any) =>
        Object.values(row).some(Boolean)
      );
      setNewexistProduct(nonEmptyRows);
    }
    if (containsEmptyRows) {
      const nonEmptyRows = prodcutRows.filter((row: any) =>
        Object.values(row).some(Boolean)
      );
      setProductRows(nonEmptyRows);
    }

    const newRows = dataArray.map((data) => ({
      prodcut: data.product_name,
      hsn: data.hsn,
      rate: data.price,
      qty: data.qty,
      amount: (parseFloat(data.price) ? parseFloat(data.price) : 0) * (parseFloat(data.qty)),
      productName: data.product_name,
      igst: data.igstTax,
      cgst: data.CgstPercentage,
      sgst: data.SgstPercentage,
      discount: data.discount ? data.discount : 0,
      igstAmount: "",
      cgstAmount: "",
      sgstAmount: "",
      description: data.description,
      units: data.units
    }));

    setProductRows((prevRows: any[]) => {
      const updatedRows = [...prevRows, ...newRows];
      handleExistingCalulation(updatedRows);
      return updatedRows;
    });

    setNewexistProduct((prev: any) => [
      ...prev,
      ...dataArray.map((row: any) => ({
        exist: Number(row.productQty),
        originalValue: Number(row.productQty)
      }))
    ]);

    setIsModalOpen(false);
  };



  return (
    <div className="mb-4  overflow-x-auto">

      <div className="sm:overflow-x-auto xl:overflow-hidden custom-scrollbar">
        <div className="sm:w-[160%] xl:w-auto">
          <div
            className={
              "hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 mb-2 text-white"
            }
            style={{ backgroundColor: "#008065" }}
          >
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-6/12 sm:w-1/4 md:w-[17%] lg:w-[22%] xl:w-[23%] 2xl:w-[305] text-left"}
            >
              <span className="inline-block">S No</span>
            </div>
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 md:w-6/12 2xl:w-10/12 text-left sm:pr-10"}
            >
              <span className="inline-block">Products</span>
            </div>
            {userdata?.hsnNo ? (
              <>
                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className={" w-full sm:w-1/4 text-right xl:ml-10"}
                >
                  <span className="inline-block">HSN/SAC</span>
                </div>
              </>
            ) : (<></>)}
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 text-right xl:mr-2 2xl:ml-6"}
            >
              Rate
            </div>
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 text-right xl:w-ml-4 2xl:ml-6"}
            >
              Qty
            </div>
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 text-right xl:ml-4 2xl:ml-6"}
            >
              Disc(%)
            </div>
            {formData?.billTaxType === "Product Wise" ? (
              <>
                {userdata?.gstValue === "IGST" ? (
                  <>
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={" w-full sm:w-1/4 text-right 2xl:mr-6"}
                    >
                      <span className="inline-block">IGST(%)</span>
                    </div>
                  </>
                ) : (<>
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={" w-full sm:w-1/4 text-right xl:ml-6 2xl:ml-10"}
                  >
                    <span className="inline-block">CGST(%)</span>
                  </div>
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={" w-full sm:w-1/4 text-right xl:ml-4 2xl:ml-10"}
                  >
                    <span className="inline-block">SGST(%)</span>
                  </div>
                </>)}
              </>
            ) : <></>
            }
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={"  w-full sm:w-4/12 text-right sm:pr-10"}
            >
              Total
            </div>
          </div>

          {prodcutRows?.map((productrow: any, index: any) => (
            <div
              key={index}
              className={
                "flex flex-col sm:flex-row rounded-lg  w-full mt-2  items-center relative " +
                (index % 2 !== 0 ? " bg-gray-50 " : "")
              }
            >
              <div
                style={{
                  fontFamily: "poppins",
                  textAlign: "left",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={"w-full sm:w-5/12  text-left  pr-3  flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden w-1/2 flex flex-row items-center">
                  S No
                </span>

                <div className="font-title flex-1 text-right sm:text-center 2xl:text-left 2xl:ml-8 pr-4 sm:pr-0">
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 900,
                    }}

                  >
                    {index + 1}
                  </div>
                </div>
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full sm:w-[310%] lg:w-[320%] 2xl:w-[380%] text-right pr-3 flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden w-1/2 flex flex-row items-center">
                  Products
                </span>

                <div className="font-title flex-1 text-left">
                  <input
                    autoComplete="nope"
                    value={productrow.prodcut ? productrow.prodcut : ""}
                    placeholder="Product Name"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      borderColor:
                        !prodcutErrors[index]?.prodcut && productError
                          ? "red"
                          : "#00A787",
                    }}
                    className={
                      defaultInputSmStyle +
                      " text-left border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full "
                    }
                    onChange={(event) => {
                      const value = event.target.value.trimStart();
                      handleInputChange(index, "prodcut", value);
                    }}
                    disabled
                  />
                  {/* {!prodcutErrors[index]?.prodcut && productError && (
                  <p
                    style={{
                      fontFamily: "poppins",
                      fontWeight: 700,
                      color: "#ff0000",
                    }}
                    className="absolute text-red-400 text-xs whitespace-nowrap"
                  >
                    Product is required
                  </p>
                )} */}
                </div>
              </div>
              {userdata?.hsnNo ? (
                <>
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={" w-full  text-right  pr-3  flex flex-row sm:block mb-2"}
                  >
                    <span className="sm:hidden w-1/2 flex flex-row items-center">
                      HSN/SAC
                    </span>

                    <div className="font-title flex-1 text-left">
                      <input
                        disabled
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: "#00A787",
                        }}
                        autoComplete="nope"
                        value={productrow.hsn}
                        onChange={(event) => {
                          const value = event.target.value.replace(/[^0-9]/g, '');
                          handleInputChange(index, "hsn", value)
                        }}
                        placeholder="HSN/SAC"
                        type=""
                        className={
                          defaultInputSmStyle +
                          " text-right  border-2 focus:outline-none rounded-[7px] px-2 h-9   w-full  "
                        }
                      />
                    </div>
                  </div>
                </>
              ) : (<></>)}
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full  text-right  pr-3  flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden  w-1/2 flex flex-row items-center">
                  Rate
                </span>

                <div className="font-title flex-1 text-left">
                  <input
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      borderColor:
                        !prodcutErrors[index]?.rate && productError
                          ? "red"
                          : "#00A787",
                    }}
                    autoComplete="nope"
                    value={productrow.rate}
                    onChange={(event) => {
                      const value = event.target.value.replace(/[^0-9.]/g, '');
                      handleInputChange(index, "rate", value);
                    }}
                    disabled
                    placeholder="Rate"
                    type=""
                    className={
                      defaultInputSmStyle +
                      " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                    }

                  />
                  {/* {!prodcutErrors[index]?.rate && productError && (
                  <p
                    style={{
                      fontFamily: "poppins",
                      fontWeight: 700,
                      color: "#ff0000",
                    }}
                    className="absolute text-red-400 text-xs whitespace-nowrap"
                  >
                    Rate is required
                  </p>
                )} */}
                </div>
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full text-right  pr-3 flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden md:hidden w-1/2 flex flex-row items-center">
                  Quantity
                </span>

                <div className="font-title flex-1 text-left">
                  <input
                    autoComplete="nope"
                    type=""
                    value={productrow.qty}
                    onChange={(event) => {
                      let value = event.target.value.replace(/[^0-9]/g, '');
                      if (value.startsWith('0')) {
                        value = value.substring(1); // Remove the leading '0'
                      }
                      handleInputChange(index, "qty", value);
                    }}
                    placeholder="Qty"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      borderColor:
                        !prodcutErrors[index]?.qty && productError
                          ? "red"
                          : "#00A787",
                    }}
                    className={
                      defaultInputSmStyle +
                      " text-right remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full  "
                    }
                  />
                  {/* {!prodcutErrors[index]?.qty && productError && (
                  <p
                    style={{
                      fontFamily: "poppins",
                      fontWeight: 700,
                      color: "#ff0000",
                    }}
                    className="absolute text-red-400 text-xs whitespace-nowrap"
                  >
                    Quantity is required
                  </p>
                )} */}
                </div>
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full  text-right  pr-3  flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden md:hidden w-1/2 flex flex-row items-center">
                  Discount
                </span>

                <div className="font-title flex-1 text-left">
                  <input
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      borderColor: "#00A787",
                    }}
                    autoComplete="nope"
                    value={productrow.discount}
                    onChange={(event) => {
                      const value = event.target.value.replace(/[^0-9.]/g, '');
                      if (parseFloat(value) > 100) {
                        return 0;
                      }
                      handleInputChange(index, "discount", value)
                    }}
                    placeholder="Discount"
                    type=""
                    className={
                      defaultInputSmStyle +
                      " text-right  border-2 focus:outline-none rounded-[7px] px-2 h-9   w-full  "
                    }
                  />
                </div>

              </div>
              {formData?.billTaxType === "Product Wise" ? (
                <>
                  {userdata?.gstValue === "IGST" ? (
                    <>
                      <div
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                        className={" w-full  text-right  pr-3  flex flex-row sm:block mb-2"}
                      >
                        <span className="sm:hidden w-1/2 flex flex-row items-center">
                          IGST(%)
                        </span>

                        <div className="font-title flex-1 text-left">
                          <input
                            disabled
                            style={{
                              fontFamily: "poppins",
                              fontSize: "14px",
                              fontWeight: 400,
                              borderColor: "#00A787",
                            }}
                            autoComplete="nope"
                            value={productrow.igst}
                            onChange={(event) => {
                              const value = event.target.value.replace(/[^0-9]/g, '');
                              handleInputChange(index, "igst", value)
                            }}
                            placeholder="IGST"
                            type=""
                            className={
                              defaultInputSmStyle +
                              " text-right  border-2 focus:outline-none rounded-[7px] px-2 h-9   w-full  "
                            }
                          />
                        </div>
                      </div>
                    </>
                  ) : (<>
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={" w-full  text-right  pr-3  flex flex-row sm:block mb-2"}
                    >
                      <span className="sm:hidden w-1/2 flex flex-row items-center">
                        CGST
                      </span>

                      <div className="font-title flex-1 text-left">
                        <input
                          disabled
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: "#00A787",
                          }}
                          autoComplete="nope"
                          value={productrow.cgst}
                          onChange={(event) => {
                            const value = event.target.value.replace(/[^0-9]/g, '');
                            handleInputChange(index, "cgst", value)
                          }}
                          placeholder="CGST"
                          type=""
                          className={
                            defaultInputSmStyle +
                            " text-right  border-2 focus:outline-none rounded-[7px] px-2 h-9   w-full  "
                          }
                        />
                      </div>

                    </div>
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={" w-full  text-right  pr-3  flex flex-row sm:block mb-2"}
                    >
                      <span className="sm:hidden w-1/2 flex flex-row items-center">
                        SGST
                      </span>

                      <div className="font-title flex-1 text-left">
                        <input
                          disabled
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: "#00A787",
                          }}
                          autoComplete="nope"
                          value={productrow.sgst}
                          onChange={(event) => {
                            const value = event.target.value.replace(/[^0-9]/g, '');
                            handleInputChange(index, "sgst", value)
                          }}
                          placeholder="SGST"
                          type=""
                          className={
                            defaultInputSmStyle +
                            " text-right  border-2 focus:outline-none rounded-[7px] px-2 h-9   w-full  "
                          }
                        />
                      </div>

                    </div>

                  </>)}
                </>) : <></>
              }

              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full  text-right pr-2 sm:pr-12 flex flex-row sm:block whitespace-nowrap"}
              >
                <span className="sm:hidden w-1/2 flex flex-row items-center">
                  Total
                </span>
                <span className={"inline-block w-1/2 sm:w-full px-1 h-9 pt-2 overflow-hidden"}>
                  <NumberFormat value={productrow?.amount} />
                </span>
              </div>

              <div
                onClick={() => removeRow(index, productrow)}
                className="w-full sm:w-10 sm:absolute sm:right-0 pl-2 h-9 pt-2 sm:mt-[-17px] mb-2 md:mb-0"
              >
                <div className="w-full text-[#9C0000] font-semibold font-title sm:h-8 sm:w-8 cursor-pointer rounded-2xl bg-[#FFE0E0] flex justify-center items-center text-center p-[5px]">
                  <DeleteIcon className="h-4 w-4" style={IconStyle} />
                  <span className="block sm:hidden">Delete Product</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Add Product Actions */}

      <div
        style={{ fontFamily: "poppins", fontSize: "14px" }}
        className="flex flex-col sm:flex-row rounded-lg sm:visible w-full px-0 md:px-4 pl-2  py-0 md:py-2 items-center sm:justify-end"
      >
        <div
          style={{ fontFamily: "poppins", fontSize: "14px" }}
          className="w-full  text-right md:mr-5 2xl:mr-0 xl:mr-0 lg:mr-0 md:pr-0 2xl:pr-8 xl:pr-8 lg:pr-8 sm:pr-8 flex flex-row sm:block mb-1"
        ></div>
        <div className="w-full  text-right md:mr-5 2xl:mr-0 xl:mr-0 lg:mr-0 md:pr-0 2xl:pr-8 xl:pr-8 lg:pr-8 sm:pr-8 flex flex-row sm:block mb-1">
          {/* <button
          type="button"
          className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-sm h-8 px-2  block w-full whitespace-nowrap "
          style={{ backgroundColor: "#008065" }}
          onClick={addRowValue}
        >
          Add New Product
        </button> */}
        </div>
        <div
          style={{ fontFamily: "poppins", fontSize: "14px" }}
          className="font-title w-full text-right md:pr-8 2xl:pr-8 xl:pr-8 lg:pr-8 sm:pr-8 flex flex-row sm:block mb-1"
        >
          {/* {productlist.length ? > 0 ?} */}
          <button
            type="button"
            className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-xs h-8 px-2  block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm"
            style={{ backgroundColor: "#008065" }}
            onClick={handleConfirm}
          >
            <InvoiceIcon style={IconStyle} className="h-5 w-5" />
            Add Existing Product
          </button>
        </div>
      </div>
      {
        isModalOpen && (
          <QuotationModel
            setStockList={setStockList}
            closeModal={closeModal}
            setProductRows={setProductRows}
            prodcutRows={prodcutRows}
            handleSelect={handleSelect}
          />
        )
      }
      {
        showConfirmation && (
          <StockUpdateModel
            message="Are you sure you want to proceed?"
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )
      }
    </div >
  )
}

export default QuotationNewDetails
