import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from "chart.js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { currencyGet } from "../../redux/actions/currencyAction";
import { InternalFindAll } from "../../redux/actions/InternalInvoiceAction";
import { serviceFindAll } from "../../redux/actions/invoiceServiceActions";
import { paymentOutGetList } from "../../redux/actions/paymentOutAction";
import { paymentReceiveGetList } from "../../redux/actions/paymentReceiveAction";
import { registerAll } from "../../redux/actions/userAction";
import { purchaseReturnFindAll } from "../../redux/actions/purchaseReturnActions";
import { SalesFixedAssetGetList } from "../../redux/actions/salesFixedAssetAction";
import { salesReturnGet } from "../../redux/actions/salesReturnAction";
import { PurchaseFindAll } from "../../redux/actions/purchasePageNewAction";
import { expenseDetailAll } from "../../redux/actions/expensePageActions";
import { PurchaseFixedAssetGetList } from "../../redux/actions/purchaseFixedAssetAction";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);
type SelectedRange =
	| { range: string }
	| { range: string; startDate: string; endDate: string };

interface DashboardBarDiaProps {
	selectedRange: SelectedRange;
}
const DashboardBarDia: React.FC<DashboardBarDiaProps> = ({ selectedRange }) => {
	const dispatch = useDispatch();
	const usersignin = useSelector((state: any) => state.userSignin);
	const token = usersignin?.userInfo?.token;
	const [currencySymbol, setcurrencySymbol] = useState("₹");
	const [serviceInvoiceTable, setserviceInvoiceTable] = useState([] as any);
	const [invoiceTable, setInvoiceTable] = useState([] as any);
	const [paymentOutTable, setPaymentOutTable] = useState([] as any);
	const [paymentInTable, setPaymentInTable] = useState([] as any);
	const [purchaseReturn, setPurchaseReturn] = useState([] as any);
	const [salesFixedAssetTable, setSalesFixedAssetTable] = useState([] as any);
	const [salesReturnDetails, setSalseDetails] = useState([] as any);
	const [purchaseList, setPurchaseList] = useState([] as any);
	const [expenseDetails, setExpenseDetails] = useState([] as any);
	const [purchasefixedAsset, setPurchaseFixedAssetTable] = useState([] as any);
	const fetchData = useCallback(() => {
		dispatch(registerAll(token?._id) as any);
		dispatch(InternalFindAll() as any).then((response: any) => {
			if (response && response.payload) {
				setInvoiceTable(response.payload);
			}
		});
		dispatch(serviceFindAll() as any).then((response: any) => {
			if (response && response.payload) {
				setserviceInvoiceTable(response.payload);
			}
		});
		dispatch(paymentOutGetList() as any).then((res: any) => {
			if (res) {
				setPaymentOutTable(res.payload);
			}
		});
		dispatch(paymentReceiveGetList() as any).then((response: any) => {
			setPaymentInTable(response?.payload.reverse());
		});
		dispatch(purchaseReturnFindAll() as any).then((response: any) => {
			setPurchaseReturn(response?.payload);
		});
		dispatch(SalesFixedAssetGetList() as any).then((res: any) => {
			if (res) {
				setSalesFixedAssetTable(res.payload);
			}
		});
		dispatch(salesReturnGet() as any).then((response: any) => {
			setSalseDetails(response?.payload);
		});
		dispatch(PurchaseFindAll() as any).then((response: any) => {
			if (response && response.payload) {
				setPurchaseList(response.payload);
			}
		});
		dispatch(expenseDetailAll() as any).then((response: any) => {
			setExpenseDetails(response?.payload);
		});
		dispatch(PurchaseFixedAssetGetList() as any).then((res: any) => {
			if (res) {
				setPurchaseFixedAssetTable(res.payload);
				// setPaymentSearch(res.payload)
			}
		});
	}, [dispatch, token]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		dispatch(currencyGet(token?._id) as any).then((res: any) => {
			if (res) {
				let data = res.payload.find((value: any) => value.defaultInfo === true);
				if (data?.currencySymbol) {
					setcurrencySymbol(data.currencySymbol);
				} else {
					setcurrencySymbol("₹");
				}
			}
		});
	}, [dispatch, token?._id]);
	const cashinflowtable = useMemo(() => {
		return invoiceTable
			.concat(serviceInvoiceTable)
			.concat(purchaseReturn)
			.concat(salesFixedAssetTable);
	}, [invoiceTable, serviceInvoiceTable, purchaseReturn, salesFixedAssetTable]);
	const cashoutflowtable = useMemo(() => {
		return salesReturnDetails
			.concat(purchaseList)
			.concat(expenseDetails)
			.concat(purchasefixedAsset);
	}, [salesReturnDetails, purchaseList, expenseDetails, purchasefixedAsset]);

	const initialBarData = {
		labels: ["January", "February", "March", "April", "May"],
		datasets: [
			{
				label: `Inflow (${currencySymbol})`,
				data: [1500, 2000, 2500, 3000, 1800],
				backgroundColor: "rgba(75, 192, 192, 0.5)",
				borderColor: "rgba(75, 192, 192, 1)",
				borderWidth: 1,
			},
			{
				label: `Outflow (${currencySymbol})`,
				data: [1000, 1500, 2000, 2200, 1600],
				backgroundColor: "rgba(255, 99, 132, 0.5)",
				borderColor: "rgba(255, 99, 132, 1)",
				borderWidth: 1,
			},
		],
	};
	const [bardata, setBarData] = useState(initialBarData);
	useEffect(() => {
		let data: any = { labels: [], datasets: [] };

		if (selectedRange.range === "Last 1 month") {
			const today = new Date();
			const endDate = new Date(today);
			endDate.setHours(23, 59, 59, 999);

			const lastMonthStart = new Date(today);
			lastMonthStart.setDate(today.getDate() - 30);

			const weeks = [];
			for (let i = 0; i < 5; i++) {
				const startOfWeek = new Date(lastMonthStart);
				startOfWeek.setDate(lastMonthStart.getDate() + i * 7);

				const endOfWeek = new Date(startOfWeek);
				endOfWeek.setDate(startOfWeek.getDate() + 7);

				if (endOfWeek > today) {
					endOfWeek.setTime(today.getTime());
				}

				weeks.push({ start: startOfWeek, end: endOfWeek });
			}

			const weeklyInflow = weeks.map((week) =>
				cashinflowtable
					.filter((invoice: any) => {
						const invoiceDate = new Date(invoice.creation_date?.$date);

						return invoiceDate >= week.start && invoiceDate <= week.end;
					})
					.reduce(
						(acc: any, paymentin: any) =>
							acc + parseFloat(paymentin.toatalAmount || 0),
						0
					)
			);

			const weeklyOutflow = weeks.map((week) =>
				cashoutflowtable
					.filter((invoice: any) => {
						const invoiceDate = new Date(
							invoice.creation_date?.$date || invoice.creation_date?.date
						);
						return invoiceDate >= week.start && invoiceDate <= week.end;
					})
					.reduce(
						(acc: any, paymentout: any) =>
							acc + parseFloat(paymentout.toatalAmount || 0),
						0
					)
			);

			data = {
				labels: weeks.map((week, index) => {
					const monthName = week.start.toLocaleString("default", {
						month: "short",
					});
					return `Week ${index + 1} (${monthName})`;
				}),
				datasets: [
					{
						label: `Inflow (${currencySymbol})`,
						data: weeklyInflow,
						backgroundColor: "rgba(153, 102, 255, 0.6)",
						borderColor: "#a78bfa",
						borderWidth: 1,
					},
					{
						label: `Outflow (${currencySymbol})`,
						data: weeklyOutflow,
						backgroundColor: "rgba(255, 99, 132, 0.5)",
						borderColor: "rgba(255, 99, 132, 1)",
						borderWidth: 1,
					},
				],
			};
		} else if (selectedRange.range === "Today") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate()
			);
			const todayEnd = new Date(todayStart);
			todayEnd.setDate(todayEnd.getDate() + 1);

			const todayPaymentinCount = cashinflowtable.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);

				return invoiceDate >= todayStart && invoiceDate < todayEnd;
			});

			let cashincome = 0;
			todayPaymentinCount.forEach((paymentin: any) => {
				cashincome += parseFloat(paymentin.toatalAmount || 0);
			});

			const todayPaymentoutCount = cashoutflowtable.filter((invoice: any) => {
				const invoiceDate = new Date(
					invoice.creation_date?.$date || invoice.creation_date?.date
				);
				return invoiceDate >= todayStart && invoiceDate < todayEnd;
			});

			let cashoutgoing = 0;
			todayPaymentoutCount.forEach((paymentout: any) => {
				cashoutgoing += parseFloat(paymentout.toatalAmount || 0);
			});

			data = {
				labels: ["Today"],
				datasets: [
					{
						label: `Inflow (${currencySymbol})`,
						data: [cashincome],
						backgroundColor: "rgba(153, 102, 255, 0.6)",
						borderColor: "#a78bfa",
						borderWidth: 1,
					},
					{
						label: `Outflow (${currencySymbol})`,
						data: [cashoutgoing],
						backgroundColor: "rgba(255, 99, 132, 0.5)",
						borderColor: "rgba(255, 99, 132, 1)",
						borderWidth: 1,
					},
				],
			};
		} else if (selectedRange.range === "Last 7 days") {
			const today = new Date();

			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate()
			);

			const todayEnd = new Date(todayStart);
			todayEnd.setHours(23, 59, 59, 999);

			const sevenDaysAgo = new Date(todayStart);
			sevenDaysAgo.setDate(todayStart.getDate() - 6);

			const days = [];
			for (let i = 0; i < 7; i++) {
				const dayStart = new Date(sevenDaysAgo);
				dayStart.setDate(sevenDaysAgo.getDate() + i);
				const dayEnd = new Date(dayStart);
				dayEnd.setHours(23, 59, 59, 999);
				days.push({ start: dayStart, end: dayEnd });
			}

			const weekPairs = [
				[days[0], days[1]],
				[days[2], days[3]],
				[days[4], days[5]],
				[days[6]],
			];

			const weeklyInflow = weekPairs.map((pair) => {
				return cashinflowtable
					.filter((invoice: any) => {
						const invoiceDate = new Date(invoice.creation_date?.$date);
						return (
							invoiceDate >= pair[0].start &&
							invoiceDate <= pair[pair.length - 1].end
						);
					})
					.reduce(
						(acc: any, paymentin: any) =>
							acc + parseFloat(paymentin.toatalAmount || 0),
						0
					);
			});

			const weeklyOutflow = weekPairs.map((pair) => {
				return cashoutflowtable
					.filter((invoice: any) => {
						const invoiceDate = new Date(
							invoice.creation_date?.$date || invoice.creation_date?.date
						);
						return (
							invoiceDate >= pair[0].start &&
							invoiceDate <= pair[pair.length - 1].end
						);
					})
					.reduce(
						(acc: any, paymentout: any) =>
							acc + parseFloat(paymentout.toatalAmount || 0),
						0
					);
			});

			const labels = weekPairs.map((pair, index) => {
				if (pair.length === 2) {
					return `Day ${index * 2 + 1}-${index * 2 + 2}`;
				} else {
					return `Day 7`;
				}
			});

			data = {
				labels: labels,
				datasets: [
					{
						label: `Inflow (${currencySymbol})`,
						data: weeklyInflow,
						backgroundColor: "rgba(153, 102, 255, 0.6)",
						borderColor: "#a78bfa",
						borderWidth: 1,
					},
					{
						label: `Outflow (${currencySymbol})`,
						data: weeklyOutflow,
						backgroundColor: "rgba(255, 99, 132, 0.5)",
						borderColor: "rgba(255, 99, 132, 1)",
						borderWidth: 1,
					},
				],
			};
		} else if (selectedRange.range === "Last 3 months") {
			const today = new Date();

			const todayEnd = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);

			const threeMonthsAgo = new Date(todayEnd);
			threeMonthsAgo.setMonth(todayEnd.getMonth() - 2);

			const months = [];
			for (let i = 0; i < 3; i++) {
				const monthStart = new Date(threeMonthsAgo);
				monthStart.setMonth(threeMonthsAgo.getMonth() + i);
				monthStart.setDate(1);

				const monthEnd = new Date(monthStart);
				monthEnd.setMonth(monthStart.getMonth() + 1);
				monthEnd.setDate(0);

				months.push({ start: monthStart, end: monthEnd });
			}

			const monthlyInflow = months.map((month) => {
				return cashinflowtable
					.filter((invoice: any) => {
						const invoiceDate = new Date(invoice.creation_date?.$date);
						return invoiceDate >= month.start && invoiceDate <= month.end;
					})
					.reduce(
						(acc: any, paymentin: any) =>
							acc + parseFloat(paymentin.toatalAmount || 0),
						0
					);
			});

			const monthlyOutflow = months.map((month) => {
				return cashoutflowtable
					.filter((invoice: any) => {
						const invoiceDate = new Date(
							invoice.creation_date?.$date || invoice.creation_date?.date
						);
						return invoiceDate >= month.start && invoiceDate <= month.end;
					})
					.reduce(
						(acc: any, paymentout: any) =>
							acc + parseFloat(paymentout.toatalAmount || 0),
						0
					);
			});

			const labels = months.map((month) => {
				return month.start.toLocaleString("default", { month: "long" });
			});

			data = {
				labels: labels,
				datasets: [
					{
						label: `Inflow (${currencySymbol})`,
						data: monthlyInflow,
						backgroundColor: "rgba(153, 102, 255, 0.6)",
						borderColor: "#a78bfa",
						borderWidth: 1,
					},
					{
						label: `Outflow (${currencySymbol})`,
						data: monthlyOutflow,
						backgroundColor: "rgba(255, 99, 132, 0.5)",
						borderColor: "rgba(255, 99, 132, 1)",
						borderWidth: 1,
					},
				],
			};
		} else if (selectedRange.range === "Last 6 months") {
			const today = new Date();

			const todayEnd = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);

			const sixMonthsAgo = new Date(todayEnd);
			sixMonthsAgo.setMonth(todayEnd.getMonth() - 5);

			const months = [];
			for (let i = 0; i < 6; i++) {
				const monthStart = new Date(sixMonthsAgo);
				monthStart.setMonth(sixMonthsAgo.getMonth() + i);
				monthStart.setDate(1);

				const monthEnd = new Date(monthStart);
				monthEnd.setMonth(monthStart.getMonth() + 1);
				monthEnd.setDate(0);

				months.push({ start: monthStart, end: monthEnd });
			}

			const monthlyInflow = months.map((month) => {
				return cashinflowtable
					.filter((invoice: any) => {
						const invoiceDate = new Date(invoice.creation_date?.$date);
						return invoiceDate >= month.start && invoiceDate <= month.end;
					})
					.reduce(
						(acc: any, paymentin: any) =>
							acc + parseFloat(paymentin.toatalAmount || 0),
						0
					);
			});

			const monthlyOutflow = months.map((month) => {
				return cashoutflowtable
					.filter((invoice: any) => {
						const invoiceDate = new Date(
							invoice.creation_date?.$date || invoice.creation_date?.date
						);
						return invoiceDate >= month.start && invoiceDate <= month.end;
					})
					.reduce(
						(acc: any, paymentout: any) =>
							acc + parseFloat(paymentout.toatalAmount || 0),
						0
					);
			});

			const labels = months.map((month) => {
				return month.start.toLocaleString("default", { month: "long" });
			});

			data = {
				labels: labels,
				datasets: [
					{
						label: `Inflow (${currencySymbol})`,
						data: monthlyInflow,
						backgroundColor: "rgba(153, 102, 255, 0.6)",
						borderColor: "#a78bfa",
						borderWidth: 1,
					},
					{
						label: `Outflow (${currencySymbol})`,
						data: monthlyOutflow,
						backgroundColor: "rgba(255, 99, 132, 0.5)",
						borderColor: "rgba(255, 99, 132, 1)",
						borderWidth: 1,
					},
				],
			};
		} else if (selectedRange.range === "Last 12 months") {
			const today = new Date();

			const todayEnd = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);

			const twelveMonthsAgo = new Date(todayEnd);
			twelveMonthsAgo.setMonth(todayEnd.getMonth() - 11);

			const months = [];
			for (let i = 0; i < 12; i++) {
				const monthStart = new Date(twelveMonthsAgo);
				monthStart.setMonth(twelveMonthsAgo.getMonth() + i);
				monthStart.setDate(1);

				const monthEnd = new Date(monthStart);
				monthEnd.setMonth(monthStart.getMonth() + 1);
				monthEnd.setDate(0);

				months.push({ start: monthStart, end: monthEnd });
			}

			const monthlyInflow = months.map((month) => {
				return cashinflowtable
					.filter((invoice: any) => {
						const invoiceDate = new Date(invoice.creation_date?.$date);
						return invoiceDate >= month.start && invoiceDate <= month.end;
					})
					.reduce(
						(acc: any, paymentin: any) =>
							acc + parseFloat(paymentin.toatalAmount || 0),
						0
					);
			});

			const monthlyOutflow = months.map((month) => {
				return cashoutflowtable
					.filter((invoice: any) => {
						const invoiceDate = new Date(
							invoice.creation_date?.$date || invoice.creation_date?.date
						);
						return invoiceDate >= month.start && invoiceDate <= month.end;
					})
					.reduce(
						(acc: any, paymentout: any) =>
							acc + parseFloat(paymentout.toatalAmount || 0),
						0
					);
			});

			const labels = months.map((month) => {
				return month.start.toLocaleString("default", { month: "short" });
			});

			data = {
				labels: labels,
				datasets: [
					{
						label: `Inflow (${currencySymbol})`,
						data: monthlyInflow,
						backgroundColor: "rgba(153, 102, 255, 0.6)",
						borderColor: "#a78bfa",
						borderWidth: 1,
					},
					{
						label: `Outflow (${currencySymbol})`,
						data: monthlyOutflow,
						backgroundColor: "rgba(255, 99, 132, 0.5)",
						borderColor: "rgba(255, 99, 132, 1)",
						borderWidth: 1,
					},
				],
			};
		} else if (selectedRange.range === "Last 16 months") {
			const today = new Date();

			const todayEnd = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);

			const sixteenMonthsAgo = new Date(todayEnd);
			sixteenMonthsAgo.setMonth(todayEnd.getMonth() - 15);

			const months = [];
			for (let i = 0; i < 16; i++) {
				const monthStart = new Date(sixteenMonthsAgo);
				monthStart.setMonth(sixteenMonthsAgo.getMonth() + i);
				monthStart.setDate(1);

				const monthEnd = new Date(monthStart);
				monthEnd.setMonth(monthStart.getMonth() + 1);
				monthEnd.setDate(0);

				months.push({ start: monthStart, end: monthEnd });
			}

			const monthlyInflow = months.map((month) => {
				return cashinflowtable
					.filter((invoice: any) => {
						const invoiceDate = new Date(invoice.creation_date?.$date);
						return invoiceDate >= month.start && invoiceDate <= month.end;
					})
					.reduce(
						(acc: any, paymentin: any) =>
							acc + parseFloat(paymentin.toatalAmount || 0),
						0
					);
			});

			const monthlyOutflow = months.map((month) => {
				return cashoutflowtable
					.filter((invoice: any) => {
						const invoiceDate = new Date(
							invoice.creation_date?.$date || invoice.creation_date?.date
						);
						return invoiceDate >= month.start && invoiceDate <= month.end;
					})
					.reduce(
						(acc: any, paymentout: any) =>
							acc + parseFloat(paymentout.toatalAmount || 0),
						0
					);
			});

			const labels = months.map((month) => {
				const monthName = month.start.toLocaleString("default", {
					month: "short",
				});
				const yearShort = month.start.getFullYear() % 100;
				return `${monthName} '${yearShort}`;
			});

			data = {
				labels: labels,
				datasets: [
					{
						label: `Inflow (${currencySymbol})`,
						data: monthlyInflow,
						backgroundColor: "rgba(153, 102, 255, 0.6)",
						borderColor: "#a78bfa",
						borderWidth: 1,
					},
					{
						label: `Outflow (${currencySymbol})`,
						data: monthlyOutflow,
						backgroundColor: "rgba(255, 99, 132, 0.5)",
						borderColor: "rgba(255, 99, 132, 1)",
						borderWidth: 1,
					},
				],
			};
		} else if (
			selectedRange.range === "Custom" &&
			"startDate" in selectedRange &&
			"endDate" in selectedRange
		) {
			const startDate = new Date(
				(selectedRange as { startDate: string }).startDate
			);
			const endDate = new Date((selectedRange as { endDate: string }).endDate);
			endDate.setHours(23, 59, 59, 999);

			const todayPaymentinCount = cashinflowtable.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= startDate && invoiceDate < endDate;
			});

			let cashincome = 0;
			todayPaymentinCount.forEach((paymentin: any) => {
				cashincome += parseFloat(paymentin.toatalAmount || 0);
			});

			const todayPaymentoutCount = cashoutflowtable.filter((invoice: any) => {
				const invoiceDate = new Date(
					invoice.creation_date?.$date || invoice.creation_date?.date
				);
				return invoiceDate >= startDate && invoiceDate < endDate;
			});

			let cashoutgoing = 0;
			todayPaymentoutCount.forEach((paymentout: any) => {
				cashoutgoing += parseFloat(paymentout.toatalAmount || 0);
			});

			data = {
				labels: [
					`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`,
				],
				datasets: [
					{
						label: `Inflow (${currencySymbol})`,
						data: [cashincome],
						backgroundColor: "rgba(153, 102, 255, 0.6)",
						borderColor: "#a78bfa",
						borderWidth: 1,
					},
					{
						label: `Outflow (${currencySymbol})`,
						data: [cashoutgoing],
						backgroundColor: "rgba(255, 99, 132, 0.5)",
						borderColor: "rgba(255, 99, 132, 1)",
						borderWidth: 1,
					},
				],
			};
		}

		setBarData(data);
	}, [
		selectedRange,
		currencySymbol,
		paymentInTable,
		paymentOutTable,
		cashinflowtable,
		cashoutflowtable,
	]);

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			title: {
				display: true,
				text: "Cash Inflow and Outflow",
			},
			tooltip: {
				callbacks: {
					label: function (context: any) {
						return currencySymbol + context.raw;
					},
				},
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					callback: function (value: any) {
						return currencySymbol + value;
					},
				},
			},
		},
	};
	return (
		<div className="w-full h-full">
			<Bar
				data={bardata}
				options={options}
			/>
		</div>

	);
};

export default DashboardBarDia;
