/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currencyGet } from "../../redux/actions/currencyAction";
import { expenseDetailAll } from "../../redux/actions/expensePageActions";
import {
	InternalDashboardGetCount,
	InternalFindAll,
} from "../../redux/actions/InternalInvoiceAction";
import { serviceFindAll } from "../../redux/actions/invoiceServiceActions";
import { InternalFindNotification } from "../../redux/actions/NotificationAction";
import { PurchaseFindAll } from "../../redux/actions/purchasePageNewAction";
import { SalesgettMethod } from "../../redux/actions/SalesAction";
import { salesReturnGet } from "../../redux/actions/salesReturnAction";
import { stockPurchaseGet } from "../../redux/actions/stockAction";
import { registerAll } from "../../redux/actions/userAction";
import ExpensesSvg from "../../Svg/ExpensesSvg";
import ProductSvg from "../../Svg/ProductSvg";
import Purchase from "../../Svg/Purchase";
import Revenue from "../../Svg/Revenue";
import SalesSvg from "../../Svg/SalesSvg";

type SelectedRange =
	| { range: string }
	| { range: string; startDate: string; endDate: string };
interface DashboardWidgetsProps {
	selectedRange: SelectedRange;
}
const DashboardWidgets: React.FC<DashboardWidgetsProps> = ({
	selectedRange,
}) => {
	const dispatch = useDispatch();
	const productdetails = useSelector((state: any) => state.stockPurchaseList);
	const { stocklist } = productdetails;
	const usersignin = useSelector((state: any) => state.userSignin);
	const token = usersignin?.userInfo?.token;

	// const [count, setCount] = useState([] as any);
	// const [purchasecount, setpurchasecount] = useState("" as any);
	// const [prodcount, setprodcount] = useState("" as any);
	// const [clientcount, setclientcount] = useState("" as any);
	// const [salesamount, setsalesamount] = useState("" as any);
	const [stockvalue, setStockValue] = useState([] as any);

	const [serviceInvoiceTable, setserviceInvoiceTable] = useState([] as any);
	const [invoiceTable, setInvoiceTable] = useState([] as any);
	const [purchaseList, setPurchaseList] = useState([] as any);
	const [expenseDetails, setExpenseDetails] = useState([] as any);
	const [salesReturnDetails, setSalseDetails] = useState([] as any);

	const [revenueTotal, setrevenueTotal] = useState(0);
	const [expensesTotal, setexpensesTotal] = useState(0);
	const [purchaseTotal, setpurchaseTotal] = useState(0);
	const [salesTotal, setsalesTotal] = useState(0);

	const fetchData = useCallback(() => {
		dispatch(InternalDashboardGetCount() as any)
		// .then((data: any) => {
		// 	setCount(data?.payload?.counts);
		// 	setpurchasecount(data?.payload?.Purchasecount);
		// 	setprodcount(data?.payload?.Productscount);
		// 	setclientcount(data?.payload?.clientcount);
		// 	// setsalesamount(data?.payload?.salesamount);
		// });
		dispatch(registerAll(token?._id) as any).then((res: any) => {});
		dispatch(InternalFindAll() as any).then((response: any) => {
			if (response && response.payload) {
				setInvoiceTable(response.payload);
			}
		});
		dispatch(serviceFindAll() as any).then((response: any) => {
			if (response && response.payload) {
				setserviceInvoiceTable(response.payload);
			}
		});
		dispatch(PurchaseFindAll() as any).then((response: any) => {
			if (response && response.payload) {
				setPurchaseList(response.payload);
			}
		});
		dispatch(expenseDetailAll() as any).then((response: any) => {
			setExpenseDetails(response?.payload);
		});
		dispatch(salesReturnGet() as any).then((response: any) => {
			setSalseDetails(response?.payload);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		setStockValue(stocklist);
	}, [stocklist]);

	const fetchSalesData = () => {
		dispatch(SalesgettMethod() as any)
		// .then((response: any) => {
		// 	if (response?.type === "SALES_GET_SUCCESS") {
		// 		setsalesamount(response.payload?.length);
		// 	}
		// });
	};

	const fetchNotificationcount = () => {
		dispatch(InternalFindNotification() as any);
	};

	useEffect(() => {
		fetchData();
		fetchSalesData();
		fetchNotificationcount();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(stockPurchaseGet(token?._id) as any);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let stockamount = 0;

	let totalStock = 0;

	for (let i = 0; i < stockvalue?.length; i++) {
		stockamount = stockvalue[i].qty * stockvalue[i].rate;
		totalStock += stockamount;
	}

	const mergedArray = useMemo(
		() => invoiceTable.concat(serviceInvoiceTable),
		[invoiceTable, serviceInvoiceTable]
	);
	// let serviceprototal = 0;
	// for (let j = 0; j < mergedArray?.length; j++) {
	// 	serviceprototal += Number(mergedArray[j].toatalAmount);
	// }

	// let actualsale = serviceprototal - returntotal || returnamountsale;

	const [currencySymbol, setcurrencySymbol] = useState("₹");
	useEffect(() => {
		dispatch(currencyGet(token?._id) as any).then((res: any) => {
			if (res) {
				let data = res.payload.find((value: any) => value.defaultInfo === true);
				if (data?.currencySymbol) {
					setcurrencySymbol(data.currencySymbol);
				} else {
					setcurrencySymbol("₹");
				}
			}
		});
	}, []);

	useEffect(() => {
		if (selectedRange.range === "Today") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate()
			);
			const todayEnd = new Date(todayStart);
			todayEnd.setDate(todayEnd.getDate() + 1);

			const recentStocks = stockvalue?.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= todayStart && invoiceDate < todayEnd;
			});

			let stockamount = 0;

			let totalStock = 0;

			for (let i = 0; i < recentStocks?.length; i++) {
				stockamount = recentStocks[i].qty * recentStocks[i].rate;
				totalStock += stockamount;
			}

			const recentInvoice = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= todayStart && invoiceDate <= todayEnd;
			});

			let invoiceTotalser = 0;
			let invoiceTotalproduct = 0;
			let invoiceTotalProductt = 0;
			for (let i = 0; i < recentInvoice?.length; i++) {
				const invoice = recentInvoice[i];

				if (invoice.billModule === "SERVICE") {
					for (let j = 0; j < invoice.product?.length; j++) {
						const product = invoice.product[j];
						const revenue = product.qty * product.rate;
						if (invoice.tax && invoice.tax.length > 0) {
							for (let k = 0; k < invoice.tax.length; k++) {
								const tax = invoice.tax[0] || 0;

								const taxAmount = (revenue * (tax.taxPersent || 0)) / 100;
								invoiceTotalProductt = revenue - taxAmount || 0;
							}
						} else {
							invoiceTotalProductt = revenue;
						}
						invoiceTotalser += invoiceTotalProductt;
					}
				} else {
					for (let j = 0; j < invoice.product?.length; j++) {
						const product = invoice.product[j];
						const revenue = product.qty * product.rate;
						const discount = (revenue * product.discount || 0) / 100;
						let invoiceTotalProduct = revenue - discount;
						if (invoice.tax && invoice.tax.length > 0) {
							for (let k = 0; k < invoice.tax.length; k++) {
								const tax = invoice.tax[k];
								const taxAmount =
									(invoiceTotalProduct * (tax.taxPersent || 0)) / 100;
								invoiceTotalProduct += taxAmount;
							}
						}
						invoiceTotalproduct += invoiceTotalProduct;
					}
				}
			}

			let torevwithdis = invoiceTotalproduct + invoiceTotalser;

			const recentSalesReturn = salesReturnDetails.filter((sale: any) => {
				const saleDate = new Date(sale.creation_date?.$date);

				return saleDate >= todayStart && saleDate <= todayEnd;
			});

			let revenuee = 0;
			let discountt = 0;
			let returntotal = 0;

			for (let j = 0; j < recentSalesReturn.length; j++) {
				for (let i = 0; i < recentSalesReturn[j].product.length; i++) {
					const product = recentSalesReturn[j].product[i];
					revenuee = product.qty * product.rate;
					discountt = product.discount
						? (revenuee * product.discount) / 100
						: 0;
					returntotal += revenuee - discountt;
				}
			}

			let overallrevenue = torevwithdis - returntotal;
			const recentExpenses = expenseDetails.filter((expense: any) => {
				const expenseDate = new Date(
					expense.creation_date?.$date || expense.creation_date?.date
				);
				return expenseDate >= todayStart && expenseDate <= todayEnd;
			});
			const recentExpenseTotal = recentExpenses.reduce(
				(total: any, expense: any) => total + Number(expense.toatalAmount),
				0
			);
			const recentPurchase = purchaseList.filter((expense: any) => {
				const expenseDate = new Date(expense.creation_date?.$date);
				return expenseDate >= todayStart && expenseDate <= todayEnd;
			});
			let purchasetotal = 0;
			for (let j = 0; j < recentPurchase?.length; j++) {
				purchasetotal += Number(purchaseList[j].toatalAmount || 0);
			}

			const recenttotalSales = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= todayStart && invoiceDate <= todayEnd;
			});

			let serviceprototal = 0;
			for (let j = 0; j < recenttotalSales?.length; j++) {
				serviceprototal += Number(recenttotalSales[j].toatalAmount || 0);
			}

			let returntotalgst = 0;

			for (let j = 0; j < recentSalesReturn.length; j++) {
				for (let i = 0; i < recentSalesReturn[j].product.length; i++) {
					const product = recentSalesReturn[j].product[i];

					returntotalgst += product.amount || 0;
				}
			}

			let actualsale = serviceprototal - returntotalgst;
			setsalesTotal(actualsale);
			setpurchaseTotal(purchasetotal);
			setexpensesTotal(recentExpenseTotal);
			setrevenueTotal(overallrevenue);
		} else if (selectedRange.range === "Last 7 days") {
			const today = new Date();

			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate()
			);
			const todayEnd = new Date(todayStart);
			todayEnd.setDate(todayEnd.getDate() + 1);
			const sevenDaysAgo = new Date(todayStart);
			sevenDaysAgo.setDate(todayStart.getDate() - 7);

			const recentStocks = stockvalue?.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= sevenDaysAgo && invoiceDate < todayEnd;
			});

			let stockamount = 0;

			let totalStock = 0;

			for (let i = 0; i < recentStocks?.length; i++) {
				stockamount = recentStocks[i].qty * recentStocks[i].rate;
				totalStock += stockamount;
			}
			const recentInvoice = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= sevenDaysAgo && invoiceDate < todayEnd;
			});

			let invoiceTotalser = 0;
			let invoiceTotalproduct = 0;

			recentInvoice.forEach((invoice: any) => {
				if (invoice.billModule === "SERVICE") {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;

						const taxAmount =
							(revenue * (invoice.tax?.[0]?.taxPersent || 0)) / 100;
						const productTotal = revenue - taxAmount;
						invoiceTotalser += productTotal;
					});
				} else {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;
						const discount = (revenue * (product.discount || 0)) / 100;
						let productTotal = revenue - discount;

						if (invoice.tax) {
							invoice.tax.forEach((tax: any) => {
								const taxAmount = (productTotal * (tax.taxPersent || 0)) / 100;
								productTotal += taxAmount;
							});
						}
						invoiceTotalproduct += productTotal;
					});
				}
			});

			let torevwithdis = invoiceTotalproduct + invoiceTotalser;

			const recentSalesReturn = salesReturnDetails.filter((sale: any) => {
				const saleDate = new Date(sale.creation_date?.$date);
				return saleDate >= sevenDaysAgo && saleDate < todayEnd;
			});

			let returnTotal = 0;
			recentSalesReturn.forEach((sale: any) => {
				sale.product?.forEach((product: any) => {
					const revenue = product.qty * product.rate;
					const discount = (revenue * (product.discount || 0)) / 100;
					returnTotal += revenue - discount;
				});
			});

			let overallRevenue = torevwithdis - returnTotal;

			const recentExpenses = expenseDetails.filter((expense: any) => {
				const expenseDate = new Date(
					expense.creation_date?.$date || expense.creation_date?.date
				);
				return expenseDate >= sevenDaysAgo && expenseDate <= todayEnd;
			});
			const recentExpenseTotal = recentExpenses.reduce(
				(total: any, expense: any) => total + Number(expense.toatalAmount),
				0
			);

			const recentPurchase = purchaseList.filter((expense: any) => {
				const expenseDate = new Date(expense.creation_date?.$date);
				return expenseDate >= sevenDaysAgo && expenseDate <= todayEnd;
			});
			let purchasetotal = 0;
			for (let j = 0; j < recentPurchase?.length; j++) {
				purchasetotal += Number(purchaseList[j].toatalAmount);
			}

			const recenttotalSales = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= sevenDaysAgo && invoiceDate <= todayEnd;
			});

			let serviceprototal = 0;
			for (let j = 0; j < recenttotalSales?.length; j++) {
				serviceprototal += Number(recenttotalSales[j].toatalAmount);
			}

			let returntotalgst = 0;

			for (let j = 0; j < recentSalesReturn.length; j++) {
				for (let i = 0; i < recentSalesReturn[j].product.length; i++) {
					const product = recentSalesReturn[j].product[i];

					returntotalgst += product.amount;
				}
			}

			let actualsale = serviceprototal - returntotalgst;
			setsalesTotal(actualsale);
			setpurchaseTotal(purchasetotal);
			setexpensesTotal(recentExpenseTotal);
			setrevenueTotal(overallRevenue);
		} else if (selectedRange.range === "Last 1 month") {
			const today = new Date();

			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate()
			);
			const todayEnd = new Date(todayStart);
			todayEnd.setDate(todayEnd.getDate() + 1);
			const oneMonthAgo = new Date(today);
			oneMonthAgo.setMonth(today.getMonth() - 1);

			const recentStocks = stockvalue?.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= oneMonthAgo && invoiceDate < todayEnd;
			});

			let stockamount = 0;

			let totalStock = 0;

			for (let i = 0; i < recentStocks?.length; i++) {
				stockamount = recentStocks[i].qty * recentStocks[i].rate;
				totalStock += stockamount;
			}

			const recentInvoice = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= oneMonthAgo && invoiceDate < todayEnd;
			});

			let invoiceTotalser = 0;
			let invoiceTotalproduct = 0;

			recentInvoice.forEach((invoice: any) => {
				if (invoice.billModule === "SERVICE") {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;

						const taxAmount =
							(revenue * (invoice.tax?.[0]?.taxPersent || 0)) / 100;
						const productTotal = revenue - taxAmount;
						invoiceTotalser += productTotal;
					});
				} else {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;
						const discount = (revenue * (product.discount || 0)) / 100;
						let productTotal = revenue - discount;

						if (invoice.tax) {
							invoice.tax.forEach((tax: any) => {
								const taxAmount = (productTotal * (tax.taxPersent || 0)) / 100;
								productTotal += taxAmount;
							});
						}
						invoiceTotalproduct += productTotal;
					});
				}
			});

			let torevwithdis = invoiceTotalproduct + invoiceTotalser;

			const recentSalesReturn = salesReturnDetails.filter((sale: any) => {
				const saleDate = new Date(sale.creation_date?.$date);
				return saleDate >= oneMonthAgo && saleDate < todayEnd;
			});

			let returnTotal = 0;
			recentSalesReturn.forEach((sale: any) => {
				sale.product?.forEach((product: any) => {
					const revenue = product.qty * product.rate;
					const discount = (revenue * (product.discount || 0)) / 100;
					returnTotal += revenue - discount;
				});
			});

			let overallRevenue = torevwithdis - returnTotal || 0;
			const recentExpenses = expenseDetails.filter((expense: any) => {
				const expenseDate = new Date(
					expense.creation_date?.$date || expense.creation_date?.date
				);
				return expenseDate >= oneMonthAgo && expenseDate <= todayEnd;
			});
			const recentExpenseTotal = recentExpenses.reduce(
				(total: any, expense: any) => total + Number(expense.toatalAmount),
				0
			);

			const recentPurchase = purchaseList.filter((expense: any) => {
				const expenseDate = new Date(expense.creation_date?.$date);
				return expenseDate >= oneMonthAgo && expenseDate <= todayEnd;
			});
			let purchasetotal = 0;
			for (let j = 0; j < recentPurchase?.length; j++) {
				purchasetotal += Number(purchaseList[j].toatalAmount);
			}

			const recenttotalSales = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= oneMonthAgo && invoiceDate <= todayEnd;
			});

			let serviceprototal = 0;
			for (let j = 0; j < recenttotalSales?.length; j++) {
				serviceprototal += Number(recenttotalSales[j].toatalAmount || 0);
			}

			let returntotalgst = 0;

			for (let j = 0; j < recentSalesReturn.length; j++) {
				for (let i = 0; i < recentSalesReturn[j].product.length; i++) {
					const product = recentSalesReturn[j].product[i];

					returntotalgst += parseFloat(product.amount) || 0;
				}
			}

			let actualsale = serviceprototal - returntotalgst;
			setsalesTotal(actualsale);
			setpurchaseTotal(purchasetotal);
			setexpensesTotal(recentExpenseTotal);
			setrevenueTotal(overallRevenue);
		} else if (selectedRange.range === "Last 3 months") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);
			const todayEnd = new Date(todayStart);
			todayEnd.setDate(todayEnd.getDate() + 1);
			const threeMonthAgo = new Date(today);
			threeMonthAgo.setMonth(today.getMonth() - 3);
			const recentStocks = stockvalue?.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= threeMonthAgo && invoiceDate < todayEnd;
			});

			let stockamount = 0;

			let totalStock = 0;

			for (let i = 0; i < recentStocks?.length; i++) {
				stockamount = recentStocks[i].qty * recentStocks[i].rate;
				totalStock += stockamount;
			}
			const recentInvoice = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= threeMonthAgo && invoiceDate < todayStart;
			});

			let invoiceTotalser = 0;
			let invoiceTotalproduct = 0;

			recentInvoice.forEach((invoice: any) => {
				if (invoice.billModule === "SERVICE") {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;

						const taxAmount =
							(revenue * (invoice.tax?.[0]?.taxPersent || 0)) / 100;
						const productTotal = revenue - taxAmount;
						invoiceTotalser += productTotal;
					});
				} else {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;
						const discount = (revenue * (product.discount || 0)) / 100;
						let productTotal = revenue - discount;

						if (invoice.tax) {
							invoice.tax.forEach((tax: any) => {
								const taxAmount = (productTotal * (tax.taxPersent || 0)) / 100;
								productTotal += taxAmount;
							});
						}
						invoiceTotalproduct += productTotal;
					});
				}
			});

			let torevwithdis = invoiceTotalproduct + invoiceTotalser;

			const recentSalesReturn = salesReturnDetails.filter((sale: any) => {
				const saleDate = new Date(sale.creation_date?.$date);
				return saleDate >= threeMonthAgo && saleDate < todayStart;
			});

			let returnTotal = 0;
			recentSalesReturn.forEach((sale: any) => {
				sale.product?.forEach((product: any) => {
					const revenue = product.qty * product.rate;
					const discount = (revenue * (product.discount || 0)) / 100;
					returnTotal += revenue - discount;
				});
			});

			let overallRevenue = torevwithdis - returnTotal;
			const recentExpenses = expenseDetails.filter((expense: any) => {
				const expenseDate = new Date(
					expense.creation_date?.$date || expense.creation_date?.date
				);
				return expenseDate >= threeMonthAgo && expenseDate <= todayEnd;
			});
			const recentExpenseTotal = recentExpenses.reduce(
				(total: any, expense: any) => total + Number(expense.toatalAmount),
				0
			);

			const recentPurchase = purchaseList.filter((expense: any) => {
				const expenseDate = new Date(expense.creation_date?.$date);
				return expenseDate >= threeMonthAgo && expenseDate <= todayEnd;
			});
			let purchasetotal = 0;
			for (let j = 0; j < recentPurchase?.length; j++) {
				purchasetotal += Number(purchaseList[j].toatalAmount);
			}

			const recenttotalSales = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= threeMonthAgo && invoiceDate <= todayEnd;
			});

			let serviceprototal = 0;
			for (let j = 0; j < recenttotalSales?.length; j++) {
				serviceprototal += Number(recenttotalSales[j].toatalAmount);
			}

			let returntotalgst = 0;

			for (let j = 0; j < recentSalesReturn.length; j++) {
				for (let i = 0; i < recentSalesReturn[j].product.length; i++) {
					const product = recentSalesReturn[j].product[i];

					returntotalgst += product.amount;
				}
			}

			let actualsale = serviceprototal - returntotalgst;
			setsalesTotal(actualsale);
			setpurchaseTotal(purchasetotal);
			setexpensesTotal(recentExpenseTotal);
			setrevenueTotal(overallRevenue);
		} else if (selectedRange.range === "Last 6 months") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);
			const todayEnd = new Date(todayStart);
			todayEnd.setDate(todayEnd.getDate() + 1);
			const sixMonthAgo = new Date(today);
			sixMonthAgo.setMonth(today.getMonth() - 6);
			const recentStocks = stockvalue?.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= sixMonthAgo && invoiceDate < todayEnd;
			});

			let stockamount = 0;

			let totalStock = 0;

			for (let i = 0; i < recentStocks?.length; i++) {
				stockamount = recentStocks[i].qty * recentStocks[i].rate;
				totalStock += stockamount;
			}
			const recentInvoice = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= sixMonthAgo && invoiceDate < todayStart;
			});

			let invoiceTotalser = 0;
			let invoiceTotalproduct = 0;

			recentInvoice.forEach((invoice: any) => {
				if (invoice.billModule === "SERVICE") {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;

						const taxAmount =
							(revenue * (invoice.tax?.[0]?.taxPersent || 0)) / 100;
						const productTotal = revenue - taxAmount;
						invoiceTotalser += productTotal;
					});
				} else {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;
						const discount = (revenue * (product.discount || 0)) / 100;
						let productTotal = revenue - discount;

						if (invoice.tax) {
							invoice.tax.forEach((tax: any) => {
								const taxAmount = (productTotal * (tax.taxPersent || 0)) / 100;
								productTotal += taxAmount;
							});
						}
						invoiceTotalproduct += productTotal;
					});
				}
			});

			let torevwithdis = invoiceTotalproduct + invoiceTotalser;

			const recentSalesReturn = salesReturnDetails.filter((sale: any) => {
				const saleDate = new Date(sale.creation_date?.$date);
				return saleDate >= sixMonthAgo && saleDate < todayStart;
			});

			let returnTotal = 0;
			recentSalesReturn.forEach((sale: any) => {
				sale.product?.forEach((product: any) => {
					const revenue = product.qty * product.rate;
					const discount = (revenue * (product.discount || 0)) / 100;
					returnTotal += revenue - discount;
				});
			});
			let overallRevenue = torevwithdis - returnTotal;
			const recentExpenses = expenseDetails.filter((expense: any) => {
				const expenseDate = new Date(
					expense.creation_date?.$date || expense.creation_date?.date
				);
				return expenseDate >= sixMonthAgo && expenseDate <= todayEnd;
			});
			const recentExpenseTotal = recentExpenses.reduce(
				(total: any, expense: any) => total + Number(expense.toatalAmount),
				0
			);

			const recentPurchase = purchaseList.filter((expense: any) => {
				const expenseDate = new Date(expense.creation_date?.$date);
				return expenseDate >= sixMonthAgo && expenseDate <= todayEnd;
			});
			let purchasetotal = 0;
			for (let j = 0; j < recentPurchase?.length; j++) {
				purchasetotal += Number(purchaseList[j].toatalAmount);
			}

			const recenttotalSales = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= sixMonthAgo && invoiceDate <= todayEnd;
			});

			let serviceprototal = 0;
			for (let j = 0; j < recenttotalSales?.length; j++) {
				serviceprototal += Number(recenttotalSales[j].toatalAmount);
			}

			let returntotalgst = 0;

			for (let j = 0; j < recentSalesReturn.length; j++) {
				for (let i = 0; i < recentSalesReturn[j].product.length; i++) {
					const product = recentSalesReturn[j].product[i];

					returntotalgst += product.amount;
				}
			}

			let actualsale = serviceprototal - returntotalgst;
			setsalesTotal(actualsale);
			setpurchaseTotal(purchasetotal);
			setexpensesTotal(recentExpenseTotal);
			setrevenueTotal(overallRevenue);
		} else if (selectedRange.range === "Last 12 months") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);
			const todayEnd = new Date(todayStart);
			todayEnd.setDate(todayEnd.getDate() + 1);
			const twelveMonthAgo = new Date(today);
			twelveMonthAgo.setMonth(today.getMonth() - 12);
			const recentStocks = stockvalue?.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= twelveMonthAgo && invoiceDate < todayEnd;
			});

			let stockamount = 0;

			let totalStock = 0;

			for (let i = 0; i < recentStocks?.length; i++) {
				stockamount = recentStocks[i].qty * recentStocks[i].rate;
				totalStock += stockamount;
			}

			const recentInvoice = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= twelveMonthAgo && invoiceDate < todayStart;
			});

			let invoiceTotalser = 0;
			let invoiceTotalproduct = 0;

			recentInvoice.forEach((invoice: any) => {
				if (invoice.billModule === "SERVICE") {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;

						const taxAmount =
							(revenue * (invoice.tax?.[0]?.taxPersent || 0)) / 100;
						const productTotal = revenue - taxAmount;
						invoiceTotalser += productTotal;
					});
				} else {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;
						const discount = (revenue * (product.discount || 0)) / 100;
						let productTotal = revenue - discount;

						if (invoice.tax) {
							invoice.tax.forEach((tax: any) => {
								const taxAmount = (productTotal * (tax.taxPersent || 0)) / 100;
								productTotal += taxAmount;
							});
						}
						invoiceTotalproduct += productTotal;
					});
				}
			});

			let torevwithdis = invoiceTotalproduct + invoiceTotalser;

			const recentSalesReturn = salesReturnDetails.filter((sale: any) => {
				const saleDate = new Date(sale.creation_date?.$date);
				return saleDate >= twelveMonthAgo && saleDate < todayStart;
			});

			let returnTotal = 0;
			recentSalesReturn.forEach((sale: any) => {
				sale.product?.forEach((product: any) => {
					const revenue = product.qty * product.rate;
					const discount = (revenue * (product.discount || 0)) / 100;
					returnTotal += revenue - discount;
				});
			});

			let overallRevenue = torevwithdis - returnTotal;
			const recentExpenses = expenseDetails.filter((expense: any) => {
				const expenseDate = new Date(
					expense.creation_date?.$date || expense.creation_date?.date
				);
				return expenseDate >= twelveMonthAgo && expenseDate <= todayEnd;
			});
			const recentExpenseTotal = recentExpenses.reduce(
				(total: any, expense: any) => total + Number(expense.toatalAmount),
				0
			);

			const recentPurchase = purchaseList.filter((expense: any) => {
				const expenseDate = new Date(expense.creation_date?.$date);
				return expenseDate >= twelveMonthAgo && expenseDate <= todayEnd;
			});
			let purchasetotal = 0;
			for (let j = 0; j < recentPurchase?.length; j++) {
				purchasetotal += Number(purchaseList[j].toatalAmount);
			}

			const recenttotalSales = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= twelveMonthAgo && invoiceDate <= todayEnd;
			});

			let serviceprototal = 0;
			for (let j = 0; j < recenttotalSales?.length; j++) {
				serviceprototal += Number(recenttotalSales[j].toatalAmount);
			}

			let returntotalgst = 0;

			for (let j = 0; j < recentSalesReturn.length; j++) {
				for (let i = 0; i < recentSalesReturn[j].product.length; i++) {
					const product = recentSalesReturn[j].product[i];

					returntotalgst += product.amount;
				}
			}

			let actualsale = serviceprototal - returntotalgst;
			setsalesTotal(actualsale);
			setpurchaseTotal(purchasetotal);
			setexpensesTotal(recentExpenseTotal);
			setrevenueTotal(overallRevenue);
		} else if (selectedRange.range === "Last 16 months") {
			const today = new Date();
			const todayStart = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate(),
				23,
				59,
				59,
				999
			);
			const todayEnd = new Date(todayStart);
			todayEnd.setDate(todayEnd.getDate() + 1);
			const sixteenMonthAgo = new Date(today);
			sixteenMonthAgo.setMonth(today.getMonth() - 16);

			const recentInvoice = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= sixteenMonthAgo && invoiceDate < todayStart;
			});

			let invoiceTotalser = 0;
			let invoiceTotalproduct = 0;

			recentInvoice.forEach((invoice: any) => {
				if (invoice.billModule === "SERVICE") {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;

						const taxAmount =
							(revenue * (invoice.tax?.[0]?.taxPersent || 0)) / 100;
						const productTotal = revenue - taxAmount;
						invoiceTotalser += productTotal;
					});
				} else {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;
						const discount = (revenue * (product.discount || 0)) / 100;
						let productTotal = revenue - discount;

						if (invoice.tax) {
							invoice.tax.forEach((tax: any) => {
								const taxAmount = (productTotal * (tax.taxPersent || 0)) / 100;
								productTotal += taxAmount;
							});
						}
						invoiceTotalproduct += productTotal;
					});
				}
			});

			let torevwithdis = invoiceTotalproduct + invoiceTotalser;

			const recentSalesReturn = salesReturnDetails.filter((sale: any) => {
				const saleDate = new Date(sale.creation_date?.$date);
				return saleDate >= sixteenMonthAgo && saleDate < todayStart;
			});

			let returnTotal = 0;
			recentSalesReturn.forEach((sale: any) => {
				sale.product?.forEach((product: any) => {
					const revenue = product.qty * product.rate;
					const discount = (revenue * (product.discount || 0)) / 100;
					returnTotal += revenue - discount;
				});
			});

			let overallRevenue = torevwithdis - returnTotal;
			const recentExpenses = expenseDetails.filter((expense: any) => {
				const expenseDate = new Date(
					expense.creation_date?.$date || expense.creation_date?.date
				);
				return expenseDate >= sixteenMonthAgo && expenseDate <= todayEnd;
			});
			const recentExpenseTotal = recentExpenses.reduce(
				(total: any, expense: any) => total + Number(expense.toatalAmount),
				0
			);

			const recentPurchase = purchaseList.filter((expense: any) => {
				const expenseDate = new Date(expense.creation_date?.$date);
				return expenseDate >= sixteenMonthAgo && expenseDate <= todayEnd;
			});
			let purchasetotal = 0;
			for (let j = 0; j < recentPurchase?.length; j++) {
				purchasetotal += Number(purchaseList[j].toatalAmount);
			}

			const recenttotalSales = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= sixteenMonthAgo && invoiceDate <= todayEnd;
			});

			let serviceprototal = 0;
			for (let j = 0; j < recenttotalSales?.length; j++) {
				serviceprototal += Number(recenttotalSales[j].toatalAmount);
			}

			let returntotalgst = 0;

			for (let j = 0; j < recentSalesReturn.length; j++) {
				for (let i = 0; i < recentSalesReturn[j].product.length; i++) {
					const product = recentSalesReturn[j].product[i];

					returntotalgst += product.amount;
				}
			}

			let actualsale = serviceprototal - returntotalgst;
			setsalesTotal(actualsale);
			setpurchaseTotal(purchasetotal);
			setexpensesTotal(recentExpenseTotal);
			setrevenueTotal(overallRevenue);
		} else if (
			selectedRange.range === "Custom" &&
			"startDate" in selectedRange &&
			"endDate" in selectedRange
		) {
			const startDate = new Date(selectedRange.startDate);
			const endDate = new Date(selectedRange.endDate);
			endDate.setHours(23, 59, 59, 999);

			const recentInvoice = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= startDate && invoiceDate <= endDate;
			});

			let invoiceTotalser = 0;
			let invoiceTotalproduct = 0;

			recentInvoice.forEach((invoice: any) => {
				if (invoice.billModule === "SERVICE") {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;

						const taxAmount =
							(revenue * (invoice.tax?.[0]?.taxPersent || 0)) / 100;
						const productTotal = revenue - taxAmount;
						invoiceTotalser += productTotal;
					});
				} else {
					invoice.product?.forEach((product: any) => {
						const revenue = product.qty * product.rate;
						const discount = (revenue * (product.discount || 0)) / 100;
						let productTotal = revenue - discount;

						if (invoice.tax) {
							invoice.tax.forEach((tax: any) => {
								const taxAmount = (productTotal * (tax.taxPersent || 0)) / 100;
								productTotal += taxAmount;
							});
						}
						invoiceTotalproduct += productTotal;
					});
				}
			});

			let torevwithdis = invoiceTotalproduct + invoiceTotalser;

			const recentSalesReturn = salesReturnDetails.filter((sale: any) => {
				const saleDate = new Date(sale.creation_date?.$date);
				return saleDate >= startDate && saleDate < endDate;
			});

			let returnTotal = 0;
			recentSalesReturn.forEach((sale: any) => {
				sale.product?.forEach((product: any) => {
					const revenue = product.qty * product.rate;
					const discount = (revenue * (product.discount || 0)) / 100;
					returnTotal += revenue - discount;
				});
			});

			let overallRevenue = torevwithdis - returnTotal;
			const recentExpenses = expenseDetails.filter((expense: any) => {
				const expenseDate = new Date(
					expense.creation_date?.$date || expense.creation_date?.date
				);
				return expenseDate >= startDate && expenseDate <= endDate;
			});
			const recentExpenseTotal = recentExpenses.reduce(
				(total: any, expense: any) => total + Number(expense.toatalAmount),
				0
			);
			const recentPurchase = purchaseList.filter((expense: any) => {
				const expenseDate = new Date(expense.creation_date?.$date);
				return expenseDate >= startDate && expenseDate <= endDate;
			});
			let purchasetotal = 0;
			for (let j = 0; j < recentPurchase?.length; j++) {
				purchasetotal += Number(purchaseList[j].toatalAmount);
			}

			const recenttotalSales = mergedArray.filter((invoice: any) => {
				const invoiceDate = new Date(invoice.creation_date?.$date);
				return invoiceDate >= startDate && invoiceDate <= endDate;
			});

			let serviceprototal = 0;
			for (let j = 0; j < recenttotalSales?.length; j++) {
				serviceprototal += Number(recenttotalSales[j].toatalAmount);
			}

			let returntotalgst = 0;

			for (let j = 0; j < recentSalesReturn.length; j++) {
				for (let i = 0; i < recentSalesReturn[j].product.length; i++) {
					const product = recentSalesReturn[j].product[i];

					returntotalgst += product.amount;
				}
			}

			let actualsale = serviceprototal - returntotalgst;
			setsalesTotal(actualsale);
			setpurchaseTotal(purchasetotal);
			setexpensesTotal(recentExpenseTotal);
			setrevenueTotal(overallRevenue);
		}
	}, [
		stockvalue,
		selectedRange,
		mergedArray,
		salesReturnDetails,
		expenseDetails,
		purchaseList,
	]);

	return (
		<div className="font-poppins">
			<div className="grid grid-cols-1 gap-5 sm:gap-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-5 mt-6 ml-2">
				<div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-full">
					<div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
						<div className="rounded-lg p-2.5  bg-[#dcfce7] dark:bg-navy-700">
							<span className="flex items-center dark:text-white">
								<SalesSvg />
							</span>
						</div>
					</div>
					<div className="h-50 ml-4 flex w-auto flex-col justify-center">
						<p className="text-green-600 text-sm font-bold">Sales</p>
						<h4 className="text-md text-navy-700 dark:text-white w-[150px] md:w-[250px] lg:w-[138px] xl:w-[124px] 2xl:w-[150px] overflow-x-auto  whitespace-nowrap hide-scrollbar">
							{`${currencySymbol}${salesTotal.toFixed(2)}`}
						</h4>
					</div>
				</div>

				<div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-full ">
					<div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
						<div className="rounded-lg bg-[#fee2e2] p-1.5 dark:bg-navy-700">
							<span className="flex items-center  dark:text-white ">
								<Purchase />
							</span>
						</div>
					</div>
					<div className="h-50 ml-4 flex w-auto flex-col justify-center">
						<p className="text-green-600 text-sm font-bold">Purchase </p>
						<h4 className="text-md text-navy-700 dark:text-white w-[150px] md:w-[250px] lg:w-[138px] xl:w-[124px] 2xl:w-[150px] overflow-x-auto  whitespace-nowrap hide-scrollbar">
							{`${currencySymbol}${purchaseTotal.toFixed(2)}`}
						</h4>
					</div>
				</div>

				<div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-full">
					<div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
						<div className="rounded-lg bg-[#fef3c7]  dark:bg-[red]-700">
							<span className="flex items-center  dark:text-white">
								<Revenue />
							</span>
						</div>
					</div>
					<div className="h-50 ml-4 flex w-auto flex-col justify-center">
						<p className="text-green-600 text-sm font-bold">Total Revenue</p>
						<h4 className="text-md text-navy-700 dark:text-white w-[150px] md:w-[250px] lg:w-[138px] xl:w-[124px] 2xl:w-[150px] overflow-x-auto  whitespace-nowrap hide-scrollbar">
							{" "}
							{`${currencySymbol}${revenueTotal.toFixed(2)}`}
						</h4>
					</div>
				</div>

				<div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-full">
					<div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
						<div className="rounded-lg bg-[#ede9fe] dark:bg-navy-700">
							<span className="flex items-center  dark:text-white">
								<ExpensesSvg />
							</span>
						</div>
					</div>
					<div className="h-50 ml-4 flex w-auto flex-col justify-center">
						<p className="text-green-600 text-sm font-bold">Total Expenses</p>
						<h4 className="text-md text-navy-700 dark:text-white w-[150px] md:w-[250px] lg:w-[138px] xl:w-[124px] 2xl:w-[150px] overflow-x-auto  whitespace-nowrap hide-scrollbar">
							{`${currencySymbol}${expensesTotal.toFixed(2)}`}
						</h4>
					</div>
				</div>

				<div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-full">
					<div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
						<div className="rounded-lg bg-[#ffedd5] p-1.5 dark:bg-navy-700">
							<span className="flex items-center  p-1.5 dark:text-white">
								<ProductSvg />
							</span>
						</div>
					</div>
					<div className="h-50 ml-4 flex w-auto flex-col justify-center">
						<p className="text-green-600 text-sm font-bold">
							Stock Value
							<span
								className="text-green-600 font-bold"
								style={{ fontSize: "0.65rem" }}
							>
								(Today)
							</span>
						</p>
						<h4 className="text-md text-navy-700  dark:text-white w-[150px] md:w-[250px] lg:w-[138px] xl:w-[124px] 2xl:w-[150px] overflow-x-auto  whitespace-nowrap hide-scrollbar">
							{`${currencySymbol}${totalStock.toFixed(2)}`}
						</h4>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DashboardWidgets;
