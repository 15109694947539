import { useState } from "react";
import AppTab from "./AppTab";
import ColourSettingPage from "./ColourSettingPage";
import CountryPage from "./CountryPage";
import FontDropdown from "./fontFamilylist";
import Template from "./InvoiceDetails/Template";
import SignaturePage from "./SignaturePage";
import { TableSettings } from "./TableSettings";

import InvoiceSettings from "./InvoiceDetails/Invoice_setting";
import InvoiceTypes from "./InvoiceTypes";
import TaxSettings from "./TaxSettings";


const AppSettingPage = () => {
  const [openTab, setOpenTab] = useState(1);
  return (
    <div>
      <div className="bg-[#E1E8E7] lg:h-[650px] xl:h-[650px] 2xl:h-[650px] h-screen  ">
        <div className=" ">
          <ul className="flex flex-wrap text-sm font-medium text-center text-[#008065]  dark:text-gray-400 ">
            <li className="-mb-px mr-1">
              <button
                style={{
                  borderBottom: openTab === 1 ? "2px solid #008065" : "",
                }}
                onClick={() => setOpenTab(1)}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Tax Settings
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setOpenTab(2)}
                style={{
                  borderBottom: openTab === 2 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Currency
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setOpenTab(3)}
                style={{
                  borderBottom: openTab === 3 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Signature
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setOpenTab(4)}
                style={{
                  borderBottom: openTab === 4 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Table Settings
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setOpenTab(5)}
                style={{
                  borderBottom: openTab === 5 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Invoice Settings
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setOpenTab(6)}
                style={{
                  borderBottom: openTab === 6 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Colour Settings
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setOpenTab(7)}
                style={{
                  borderBottom: openTab === 7 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Font Family
              </button>
            </li>
            {/* <li className="mr-1">
              <button
                onClick={() => setOpenTab(8)}
                style={{
                  borderBottom: openTab === 8 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Tax Settings

              </button>
            </li> */}
            <li className="mr-1">
              <button
                onClick={() => setOpenTab(9)}
                style={{
                  borderBottom: openTab === 9 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Invoice Types

              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setOpenTab(10)}
                style={{
                  borderBottom: openTab === 10 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Invoice Templates
              </button>
            </li>
          </ul>
        </div>
        <div className="mt-6">
          {openTab === 1 && <AppTab />}
          {openTab === 2 && <CountryPage />}
          {openTab === 3 && <SignaturePage />}
          {openTab === 4 && <TableSettings />}
          {openTab === 5 && <InvoiceSettings />}
          {openTab === 6 && <ColourSettingPage />}
          {openTab === 7 && <FontDropdown />}
          {/* {openTab === 8 && <TaxSettings />} */}
          {openTab === 9 && <InvoiceTypes />}
          {openTab === 10 && <Template />}
        </div>
      </div>
    </div>
  );
};

export default AppSettingPage;
