import React, { useEffect, useState } from 'react'
import { registerAll, updateUser } from '../../../redux/actions/userAction';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const AmountInWords = () => {
    const dispatch = useDispatch()
    const [selectedFormat, setSelectedFormat] = useState("");
    const User_id = sessionStorage.getItem("userInfo");
    const userId = JSON.parse(User_id as any);

    const handleChange = (event: any) => {
        const selectedFormat = event.target.value;
        setSelectedFormat(selectedFormat);
    };
    const fetchData = () => {
        dispatch(registerAll(userId?.token?._id) as any).then((res: any) => {
            if (res && res.type === "REGISTER_GET_SUCCESS") {
                // const taxType = res?.payload?.taxType ? res?.payload?.taxType : "Bill Wise";
                // setSelectedFormat(taxType);
                const format = res?.payload?.AmountFormat || ""; 
                setSelectedFormat(format);
            }
        });
    };
    useEffect(() => {
        if (userId?.token?._id) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleSubmit = (e: any) => {
        e.preventDefault();
        let obj = {
            AmountFormat: selectedFormat,
          };
        dispatch(updateUser(userId?.token?._id, obj) as any).then((res: any) => {
            if (res) {
                fetchData();
                toast.success("Saved Successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 800,
                });
            }
        });
    };

    return (
        <div className="bg-[#E1E8E7] h-full" style={{ fontFamily: "poppins" }}>
            <div>
                <form
                    onSubmit={handleSubmit}
                >
                    <fieldset className="mx-2 mb-4 rounded-lg p-2 md:p-3 h-auto">
                        <legend className="p-4  font-bold text-[#008065]  ml-2">
                            Amount In Words
                        </legend>
                        <div className="grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-1">
                            <div>
                                <label
                                    htmlFor="tax-type-select"
                                    className="block mb-2 text-sm text-[#008065] font-semibold"
                                >
                                    Select Format
                                </label>
                                <select
                                    id="tax-type-select"
                                    value={selectedFormat}
                                    onChange={handleChange}
                                    className="block w-full p-2 bg-white border-2 rounded-md shadow-sm focus:outline-none sm:text-sm border-[#008065]"
                                >
                                    <option value="" disabled hidden>
                                        selected  Format
                                    </option>
                                    <option value="Indian Format">Indian Format</option>
                                    <option value="International Format">International Format</option>
                                </select>
                                {/* {error && (
                                    <p style={{ color: "red", fontSize: '13px', marginLeft: '3px' }}>
                                        Tax Type is required
                                    </p>
                                )} */}
                            </div>
                        </div>
                        <div className="flex w-full flex-col sm:flex-row px-4">
                            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                <div className="flex flex-col mb-6"></div>
                            </div>
                            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                <div className="flex flex-col">
                                    <div className="relative"></div>
                                </div>
                            </div>
                            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 px-2 mt-5">
                                <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40 rounded">
                                    <button
                                        type="button"
                                        className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065]"
                                        style={{
                                            backgroundColor: "#008065",
                                            fontSize: "14px",
                                            fontFamily: "poppins",
                                        }}
                                    // onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2 mt-5">
                                <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40">
                                    <button
                                        type="submit"
                                        className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065]"
                                        style={{
                                            backgroundColor: "#008065",
                                            fontSize: "14px",
                                            fontFamily: "poppins",
                                        }}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    )
}

export default AmountInWords
